import { useEffect } from 'react';
import ReactDOM from 'react-dom';
import csx from 'classnames';

import { ToastContents, ToastContentsProps } from './ToastContents';

import styles from './Toast.module.scss';

export type ToastProps = ToastContentsProps & {
  duration?: number,
  onClose?: () => void,
  open?: boolean,
}

export function Toast ({
  callToAction,
  className = undefined,
  duration,
  icon,
  message,
  onClose = () => {},
  onClick,
  open,
}: ToastProps) {
  let startTime: number;

  function step (timestamp) {
    // console.log(timestamp);
    if (startTime === undefined) {
      startTime = timestamp;
    }
    const elapsed = timestamp - startTime;

    if (duration && elapsed < duration) {
      window.requestAnimationFrame(step);
    }
    else {
      onClose();
    }
    return () => {
      onClose();
    };
  }

  useEffect(() => {
    if (duration && open) {
      window.requestAnimationFrame(step);
    }
    else {
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ duration, open ]);
  if (!open) {
    return null;
  }
  return (
    typeof window === 'object'
      ? (ReactDOM.createPortal(
        <div className={styles.wrapper} data-testid="toasty">
          <ToastContents
            className={csx(styles.toast, className)}
            onClick={onClick}
            icon={icon}
            callToAction={callToAction}
            message={message}
            />
        </div>,
        document.body,
      )
      )
      : <div />
  );
}
