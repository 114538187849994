import { useEffect, useState } from 'react';

import { isBrowser } from '@grid-is/browser-utils';

/**
 * A React hook to indicate wether the application has been hydrated & is running in the browser. It's
 * useful for only rendering specific components in the browser (not during SSG or SSR).
 *
 * @returns {boolean} true if the application has finished hydrating & is rendered client side, else false
 */
export function useMounted () {
  const [ mounted, setMounted ] = useState(false);
  useEffect(() => {
    setMounted(isBrowser());
  }, []);
  return mounted;
}
