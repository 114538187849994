import { ReactNode, useCallback, useEffect, useState } from 'react';

import { setUserProperty, UserType } from '@/api/user';
import { useDeps } from '@/bootstrapping/dependencies';

import { BannerType, BillingContext, PayWallFeature, PayWallGate } from './BillingContext';
import { TimeToUpgradeModal, TimeToUpgradeModalProps } from './UpgradeModals/TimeToUpgradeModal/TimeToUpgradeModal';
import { TrialEligibilityModal } from './UpgradeModals/TrialEligibilityModal/TrialEligibilityModal';
import { UsageOverageModal } from './UpgradeModals/UsageOverageModal';
import { modalTextByPeriod } from './UpgradeModals/UsageOverageModal/utils';
import { checkUsageOverage, getDaysUntilTrialEnds, hasBeenInTrial, isInTrial, UsageOverage } from './utils';

function tryingToAccessSharing (feature?: PayWallFeature) {
  return feature && [ 'sharing', 'embed' ].includes(feature);
}

function shouldShowTrialEligibilityModal (feature?: PayWallFeature) {
  return feature && [ 'sharing', 'embed' ].includes(feature);
}

export type BillingBoundaryProps = {
  user?: UserType,
  children: ReactNode,
}

export const BillingBoundary = ({ children, user }: BillingBoundaryProps) => {
  const [ payWall, setPayWall ] = useState<PayWallGate>();
  const [ banner, setBanner ] = useState<BannerType>();
  const [ modal, setModal ] = useState<TimeToUpgradeModalProps>();
  const [ usageOverage, setUsageOverage ] = useState<UsageOverage | null>(null);

  const { api, userEvents } = useDeps();

  const ensureCanUseFeature = useCallback((feature: PayWallGate['feature'], onSuccess: PayWallGate['onSuccess'], initiatedFrom: PayWallGate['initiatedFrom']) => {
    if (tryingToAccessSharing(feature)) {
      const canShareDocuments = user?.account.features.can_share_documents;
      if (canShareDocuments) {
        onSuccess();
      }
      else {
        setPayWall({ feature, onSuccess, initiatedFrom });
      }
    }
  }, [ user?.account.features.can_share_documents ]);

  let daysLeftInTrial = 0;
  if (user && isInTrial(user)) {
    daysLeftInTrial = getDaysUntilTrialEnds(user);
  }

  const hasSeenInProductTrialExpiredModal = user?.properties?.in_product_trial_expired_modal_shown;
  const shouldShowTrialExpiredModal = (
    hasBeenInTrial(user) &&
    daysLeftInTrial === 0 &&
    user?.account.subscription_status === 'cancelled' &&
    !hasSeenInProductTrialExpiredModal
  );

  useEffect(() => {
    async function displayWarningsIfNecessary () {
      let banner: BannerType | undefined;
      const usageOverage = await checkUsageOverage(api.billing, user);
      if (daysLeftInTrial > 0) {
        banner = {
          description: `${daysLeftInTrial} day${daysLeftInTrial === 1 ? '' : 's'} left of your trial.`,
          confirmButton: 'Upgrade now',
          url: '/settings/billing',
        };
      }
      else if (shouldShowTrialExpiredModal) {
        const onAcknowledge = async (redirectToBilling: boolean) => {
          await setUserProperty(
            'in_product_trial_expired_modal_shown',
            new Date().toISOString(),
          );
          if (redirectToBilling) {
            window.location.href = '/settings/billing';
          }
          setModal(undefined);
        };
        setModal({
          title: 'Trial ended',
          description: 'All your docs are now private and can\'t be embedded publicly. Upgrade to keep sharing your docs.',
          confirmButton: 'Upgrade',
          onSuccess: () => onAcknowledge(true),
          onCancel: () => onAcknowledge(false),
        });
      }
      else if (usageOverage?.period) {
        const texts = modalTextByPeriod[usageOverage?.period];
        banner = {
          description: texts.description,
          confirmButton: texts.confirmButton,
          url: texts.url,
          tracking: {
            onOpen: () => userEvents.overageMessagingShown(
              usageOverage.overages.includes('document_views'),
              usageOverage.overages.includes('embed_domains'),
              usageOverage.overages.includes('form_submissions'),
              usageOverage.overages.includes('seats'),
              'Banner',
            ),
            onConfirm:  () => userEvents.overageMessagingClicked(
              usageOverage.overages.includes('document_views'),
              usageOverage.overages.includes('embed_domains'),
              usageOverage.overages.includes('form_submissions'),
              usageOverage.overages.includes('seats'),
              'Banner',
            ),
          },
        };
      }
      setUsageOverage(usageOverage);
      setBanner(banner);
    }

    displayWarningsIfNecessary();
  }, [ api.billing, daysLeftInTrial, user, shouldShowTrialExpiredModal, userEvents ]);

  return (
    <BillingContext.Provider value={{ ensureCanUseFeature, banner }}>
      {shouldShowTrialEligibilityModal(payWall?.feature) && user && (
        <TrialEligibilityModal
          payWallFeature={payWall?.feature!}
          user={user}
          onSuccess={() => {
            const onSuccess = payWall?.onSuccess;
            if (onSuccess) {
              onSuccess();
            }
            setPayWall(undefined);
          }}
          initiatedFrom={payWall?.initiatedFrom!}
          onCancel={() => setPayWall(undefined)}
          />
      )}
      {modal && (
        <TimeToUpgradeModal
          confirmButton={modal.confirmButton}
          description={modal.description}
          title={modal.title}
          onCancel={modal.onCancel}
          onSuccess={modal.onSuccess}
          />
      )}
      {usageOverage && (
        <UsageOverageModal
          usageOverage={usageOverage}
          onCancel={() => setUsageOverage(null)}
          />
      )}
      {children}
    </BillingContext.Provider>
  );
};
