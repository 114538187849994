import { NextIncomingMessage } from 'next/dist/server/request-meta';

export function locationProps (req?: NextIncomingMessage) {
  let protocol: 'http:' | 'https:' = 'https:';
  const host = req && req.headers
    ? String(req.headers['x-forwarded-host'] || req.headers.host)
    : window.location.host;

  if (host?.includes('localhost')) {
    protocol = 'http:';
  }
  return {
    protocol: protocol,
    host: host,
    origin: protocol + '//' + host,
  };
}
