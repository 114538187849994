import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Separator } from '@/components/Separator';

import styles from './optionmenu.module.scss';

function csx (...args) {
  return args.filter(Boolean).join(' ');
}

const NBSP = '\u00a0';

export class OptionMenu extends React.Component {
  static getDerivedStateFromProps (props, state) {
    if (props.cursor !== state.cursor) {
      state.scrollUpdate = true;
      state.mouseCursor = -1;
      state.cursor = props.cursor;
    }
    return null;
  }

  static groupOptions (options, flat = false) {
    if (!options || !options.length) {
      return [];
    }
    let root = [];
    const ungrouped = [];
    const groups = {};
    options.forEach((opt, index) => {
      const option = { ...opt, listindex: index };
      const group = option.group;
      if (!group) {
        ungrouped.push(option);
      }
      else if (!groups[group]) {
        groups[group] = [ option ];
        root.push(groups[group]);
      }
      else {
        groups[group].push(option);
      }
    });
    root = ungrouped.concat(root);

    // re-index items based on final order
    const flatList = [];
    root.forEach(option => {
      if (Array.isArray(option)) {
        option.forEach(d => {
          d.index = flatList.length;
          flatList.push(d);
        });
      }
      else {
        option.index = flatList.length;
        flatList.push(option);
      }
    });

    return flat ? flatList : root;
  }

  static propTypes = {
    options: PropTypes.array,
    className: PropTypes.string,
    onSelect: PropTypes.func,
    renderOption: PropTypes.func,
    nomatch: PropTypes.node,
    fullScreen: PropTypes.bool,
    selected: PropTypes.oneOfType([ PropTypes.string, PropTypes.number, PropTypes.bool ]),
    cursor: PropTypes.number,
    id: PropTypes.string,
  };

  constructor (props) {
    super(props);
    this.state = {
      cursor: props.cursor,
      mouseCursor: -1,
      scrollUpdate: true,
    };
  }

  componentDidMount () {
    if (this.cursorNode) {
      setTimeout(() => {
        this.nodeInView(true);
      }, 1);
    }
  }

  componentDidUpdate () {
    if (this.cursorNode && this.state.mouseCursor === -1) {
      if (this.state.scrollUpdate) {
        /* eslint-disable-next-line react/no-direct-mutation-state */
        this.state.scrollUpdate = false;
        this.nodeInView();
      }
    }
  }

  onMouseMove = e => {
    const i = parseInt(e.currentTarget.dataset.index, 10);
    if (i !== this.state.mouseCursor) {
      this.setState({ mouseCursor: i });
    }
  };

  onMouseLeave = () => {
    this.setState({ mouseCursor: -1 });
  };

  selectOption = e => {
    const elm = e.currentTarget;
    this.cursorNode = elm;
    this.selectedNode = elm;
    if (this.props.onSelect) {
      // Prevents further propagation so that clicking menu items does not result in outside click when menu is used in menu or modal
      e.stopPropagation();
      const option = this.props.options[elm.dataset.listindex * 1];
      this.props.onSelect(option.value);
    }
  };

  nodeInView (forceTop) {
    const node = this.cursorNode;
    const view = this.menu;
    if (node && view) {
      let topOffs = node.offsetTop;
      const groupNode = node.parentNode && node.parentNode.parentNode;
      // is item grouped & the first child of its group?
      if (groupNode && groupNode.dataset.group && node.parentNode.firstChild === node) {
        topOffs = groupNode.offsetTop;
      }
      const focusedRect = node.getBoundingClientRect();
      const menuRect = view.getBoundingClientRect();
      if (forceTop) {
        view.scrollTop = topOffs;
      }
      else if (menuRect && focusedRect.bottom > menuRect.bottom + 4) {
        view.scrollTop = node.offsetTop + node.clientHeight - view.offsetHeight;
      }
      else if (menuRect && focusedRect.top < menuRect.top) {
        view.scrollTop = topOffs;
      }
    }
  }

  renderGroup (title, children) {
    return (
      <div key={title} className={styles.group} data-group>
        <div className={styles.title}>{title}</div>
        <div className={styles.inner}>
          {children}
        </div>
      </div>
    );
  }

  renderOption (option) {
    const haveLabel = option.label || option.label === '';
    return <div className={styles.option}>{(haveLabel ? option.label : option.value) || NBSP}</div>;
  }

  renderMenuItem (option) {
    const atCursor = this.state.mouseCursor > -1
      ? this.state.mouseCursor === option.index
      : this.state.cursor === option.index;
    const selected = this.props.selected === option.value;
    return (
      <Fragment key={option.value}>
        {option.separator && <Separator className={styles.separator} />}
        <div
          role="button"
          data-listindex={option.listindex}
          data-index={option.index}
          data-obid={`option-menu-button-${option.label || option.value}`}
          onClick={option.disabled ? undefined : this.selectOption}
          onMouseMove={this.onMouseMove}
          className={csx(
            styles.menuitem,
            atCursor && styles.highlighted,
            selected && styles.selected,
            option.disabled && styles.disabled,
          )}
          ref={elm => {
            if (atCursor) {
              this.cursorNode = elm;
            }
            if (selected) {
              this.selectedNode = elm;
            }
          }}
          >
          {this.props.renderOption
            ? this.props.renderOption(option, selected, atCursor)
            : this.renderOption(option)}
        </div>
      </Fragment>
    );
  }

  renderOptions (options) {
    return options.map(option => {
      if (Array.isArray(option)) {
        return option.length ? this.renderGroup(
          option[0].group,
          option.map(suboption => this.renderMenuItem(suboption)),
        ) : null;
      }
      else {
        return this.renderMenuItem(option);
      }
    });
  }

  render () {
    const options = OptionMenu.groupOptions(this.props.options);
    if (!options.length && !this.props.nomatch) {
      return null;
    }
    return (
      <div
        className={csx(this.props.className, styles.options, this.props.fullScreen ? styles.fullScreen : null)}
        ref={elm => {
          this.menu = elm;
        }}
        onMouseDown={e => e.preventDefault()}
        onMouseLeave={this.onMouseLeave}
        role="listbox"
        id={this.props.id}
        >
        {options.length
          ? this.renderOptions(options)
          : <div className={styles.nomatch}>{this.props.nomatch}</div>
        }
      </div>
    );
  }
}
