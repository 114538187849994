export function getViewport () {
  if (typeof window !== 'undefined') {
    return {
      width: Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0),
      height: Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0),
    };
  }
  return {
    width: 0,
    height: 0,
  };
}
