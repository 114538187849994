import { Button } from '@/grid-ui/Button';
import { Modal } from '@/grid-ui/Modal';

import styles from './TimeToUpgradeModal.module.scss';

export type TimeToUpgradeModalProps = {
  title: string,
  description: string,
  confirmButton: string,
  onSuccess?: () => Promise<void>,
  onCancel?: () => Promise<void>,
}

export const TimeToUpgradeModal =  ({ confirmButton, description, title, onCancel, onSuccess }: TimeToUpgradeModalProps) => {
  return (
    <Modal
      open
      header={title}
      size="small"
      onClose={onCancel}
      >
      <section>
        <div className={styles.description}>
          <span>{description}</span>
        </div>
        <div className={styles.buttonGroup}>
          <Button
            onClick={onSuccess}
            buttonType="primary"
            buttonSize="large"
            >{confirmButton}
          </Button>
        </div>
      </section>
    </Modal>
  );
};
