import React, { useState } from 'react';
import csx from 'classnames';

import { tracking } from '@grid-is/tracking';

import { Agreement, updateAgreements } from '@/api/user';
import { Button } from '@/grid-ui/Button';
import { Checkbox } from '@/grid-ui/Checkbox';
import { TextLink } from '@/grid-ui/TextLink';
import { BasePage } from '@/components/BasePage';
import { EXTENDED_PERMISSIONS, LIMITED_PERMISSIONS } from '@/constants';

import { ExtendedPolicy } from './ExtendedPolicy';

import styles from './privacyform.module.scss';

 type PrivacyFormProps = {
   onSubmit?: () => void,
 };

export function PrivacyForm ({ onSubmit = () => {} }: PrivacyFormProps) {
  const [ agreements, setAgreements ] = useState({
    limitedPermissions: false,
    extendedPermissions: false,
  });

  const [ error, setError ] = useState(null);
  const [ formInvalid, setFormInvalid ] = useState(false);

  const onCheckboxChange = data => {
    setAgreements({
      ...agreements,
      ...data,
    });
    if (data.limitedPermissions) {
      setFormInvalid(false);
    }
  };

  const onFormSubmit = e => {
    e.preventDefault();
    const agreementArray: Agreement[] = [];
    if (agreements.limitedPermissions) {
      agreementArray.push(LIMITED_PERMISSIONS);
    }
    else {
      return setFormInvalid(true);
    }
    if (agreements.extendedPermissions) {
      agreementArray.push(EXTENDED_PERMISSIONS);
    }
    updateAgreements({ add: agreementArray })
      .then(() => {
        tracking.logEvent('Privacy Policy Accepted', {
          policy_type: agreements.extendedPermissions ? 'extended permissions' : 'limited permissions',
          policy_type_before: null,
        });
        onSubmit();
      })
      .catch(error => setError(error));
  };

  if (error) {
    throw error;
  }

  return (
    <BasePage title="Privacy policy" layout="document" hideMenus hideNavigation>
      <div className={styles.privacyForm}>
        <h1>Terms & Privacy</h1>
        <h6>Help us help you</h6>
        <ExtendedPolicy className={styles.textBlock} />
        <form onSubmit={onFormSubmit}>
          <div className={styles.checkboxes}>
            <Checkbox
              name="extendedPermissions"
              label="Share additional data for product development"
              checked={agreements.extendedPermissions}
              onChange={value => onCheckboxChange({ extendedPermissions: value })}
              />
            <Checkbox
              name="limitedPermissions"
              label={<span>I have read and hereby accept the <TextLink href="https://calculatorstudio.co/terms" target="_blank" rel="noreferrer">Terms and Conditions</TextLink> and <TextLink href="https://calculatorstudio.co/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</TextLink></span>}
              required
              checked={agreements.limitedPermissions}
              onChange={value => onCheckboxChange({ limitedPermissions: value })}
              />
            <div className={csx(styles.formInvalid, formInvalid && styles.show)}>To continue you need to accept the Terms and Conditions and Privacy Policy.</div>
          </div>
          <div className={styles.actions}>
            <Button type="submit" buttonType="primary" buttonSize="large">Continue</Button>
          </div>
        </form>
      </div>
    </BasePage>
  );
}
