import { IconBuilder } from './IconBuilder';
// How to add Icons:
// 1. Add the icon into the corresponding folder (./Shapes/Clarity or ./Shapes/GRID).
// 2. Import the icon to this file and add to exported icons const.
// 3. Add the icon name into ./types.ts.
// Clarity icons
import { AlignLeftText } from './Shapes/Clarity/AlignLeftText';
import { AlignRightText } from './Shapes/Clarity/AlignRightText';
import { Angle } from './Shapes/Clarity/Angle';
import { Application } from './Shapes/Clarity/Application';
import { Arrow } from './Shapes/Clarity/Arrow';
import { Ban } from './Shapes/Clarity/Ban';
import { Bars } from './Shapes/Clarity/Bars';
import { Bell } from './Shapes/Clarity/Bell';
import { BlockQuote } from './Shapes/Clarity/BlockQuote';
import { Bold } from './Shapes/Clarity/Bold';
import { BulletList } from './Shapes/Clarity/BulletList';
import { Calendar } from './Shapes/Clarity/Calendar';
import { CenterText } from './Shapes/Clarity/CenterText';
import { ChatBubble } from './Shapes/Clarity/ChatBubble';
import { Check } from './Shapes/Clarity/Check';
import { CheckCircle } from './Shapes/Clarity/CheckCircle';
import { CircleArrow } from './Shapes/Clarity/CircleArrow';
import { Cloud } from './Shapes/Clarity/Cloud';
import { Code } from './Shapes/Clarity/Code';
import { Cog } from './Shapes/Clarity/Cog';
import { Computer } from './Shapes/Clarity/Computer';
import { Dashboard } from './Shapes/Clarity/Dashboard';
import { DataCluster } from './Shapes/Clarity/DataCluster';
import { Display } from './Shapes/Clarity/Display';
import { Download } from './Shapes/Clarity/Download';
import { EllipsisHorizontal } from './Shapes/Clarity/EllipsisHorizontal';
import { EllipsisVertical } from './Shapes/Clarity/EllipsisVertical';
import { Envelope } from './Shapes/Clarity/Envelope';
import { ErrorStandard } from './Shapes/Clarity/ErrorStandard';
import { ExclamationTriangle } from './Shapes/Clarity/ExclamationTriangle';
import { Eye } from './Shapes/Clarity/Eye';
import { EyeHide } from './Shapes/Clarity/EyeHide';
import { File } from './Shapes/Clarity/File';
import { FileGroup } from './Shapes/Clarity/FileGroup';
import { Filter } from './Shapes/Clarity/Filter';
import { Flag } from './Shapes/Clarity/Flag';
import { Folder } from './Shapes/Clarity/Folder';
import { Form } from './Shapes/Clarity/Form';
import { GridView } from './Shapes/Clarity/GridView';
import { Heart } from './Shapes/Clarity/Heart';
import { Help } from './Shapes/Clarity/Help';
import { History } from './Shapes/Clarity/History';
import { Home } from './Shapes/Clarity/Home';
import { Image } from './Shapes/Clarity/Image';
import { Import } from './Shapes/Clarity/Import';
import { InfoCircle } from './Shapes/Clarity/InfoCircle';
import { Italic } from './Shapes/Clarity/Italic';
import { Landscape } from './Shapes/Clarity/Landscape';
import { LaunchPad } from './Shapes/Clarity/Launchpad';
import { Link } from './Shapes/Clarity/Link';
import { Lock } from './Shapes/Clarity/Lock';
import { Logout } from './Shapes/Clarity/Logout';
import { Mobile } from './Shapes/Clarity/Mobile';
import { Note } from './Shapes/Clarity/Note';
import { NumberList } from './Shapes/Clarity/NumberList';
import { PaintRoller } from './Shapes/Clarity/PaintRoller';
import { PdfFile } from './Shapes/Clarity/PdfFile';
import { Pencil } from './Shapes/Clarity/Pencil';
import { PhoneHandset } from './Shapes/Clarity/PhoneHandset';
import { Plus } from './Shapes/Clarity/Plus';
import { PlusCircle } from './Shapes/Clarity/PlusCircle';
import { PopOut } from './Shapes/Clarity/PopOut';
import { Redo } from './Shapes/Clarity/Redo';
import { Refresh } from './Shapes/Clarity/Refresh';
import { Resize } from './Shapes/Clarity/Resize';
import { Scissors } from './Shapes/Clarity/Scissors';
import { Search } from './Shapes/Clarity/Search';
import { Share } from './Shapes/Clarity/Share';
import { Shrink } from './Shapes/Clarity/Shrink';
import { Star } from './Shapes/Clarity/Star';
import { Stop } from './Shapes/Clarity/Stop';
import { Switch } from './Shapes/Clarity/Switch';
import { Sync } from './Shapes/Clarity/Sync';
import { TalkBubbles } from './Shapes/Clarity/TalkBubbles';
import { Text } from './Shapes/Clarity/Text';
import { TimesCircle } from './Shapes/Clarity/TimesCircle';
import { Trash } from './Shapes/Clarity/Trash';
import { TwoWayArrows } from './Shapes/Clarity/TwoWayArrows';
import { Undo } from './Shapes/Clarity/Undo';
import { UnknownStatus } from './Shapes/Clarity/UnknownStatus';
import { Upload } from './Shapes/Clarity/Upload';
import { User } from './Shapes/Clarity/User';
import { Users } from './Shapes/Clarity/Users';
import { ViewColumns } from './Shapes/Clarity/ViewColumns';
import { WindowClose } from './Shapes/Clarity/WindowClose';
import { WindowMin } from './Shapes/Clarity/WindowMin';
import { Wrench } from './Shapes/Clarity/Wrench';
import { AddData } from './Shapes/GRID/AddData';
// GRID icons
import { Airtable } from './Shapes/GRID/Airtable';
import { BackgroundColor } from './Shapes/GRID/BackgroundColor';
import { Close } from './Shapes/GRID/Close';
import { Conversation } from './Shapes/GRID/Conversation';
import { Csv } from './Shapes/GRID/Csv';
import { DocumentBadge } from './Shapes/GRID/DocumentBadge';
import { DocumentMilestones } from './Shapes/GRID/DocumentMilestones';
import { DocumentSettings } from './Shapes/GRID/DocumentSettings';
import { DocumentStatistics } from './Shapes/GRID/DocumentStatistics';
import { DocumentStatisticsPro } from './Shapes/GRID/DocumentStatisticsPro';
import { Domain } from './Shapes/GRID/Domain';
import { DomainLock } from './Shapes/GRID/DomainLock';
import { DragHandle } from './Shapes/GRID/DragHandle';
import { Dropbox } from './Shapes/GRID/Dropbox';
import { Duplicate } from './Shapes/GRID/Duplicate';
import { ElementArea } from './Shapes/GRID/ElementArea';
import { ElementBar } from './Shapes/GRID/ElementBar';
import { ElementButton } from './Shapes/GRID/ElementButton';
import { ElementCalendar } from './Shapes/GRID/ElementCalendar';
import { ElementCanvas } from './Shapes/GRID/ElementCanvas';
import { ElementCheckbox } from './Shapes/GRID/ElementCheckbox';
import { ElementCode } from './Shapes/GRID/ElementCode';
import { ElementColumn } from './Shapes/GRID/ElementColumn';
import { ElementCombo } from './Shapes/GRID/ElementCombo';
import { ElementDropdown } from './Shapes/GRID/ElementDropdown';
import { ElementEmbed } from './Shapes/GRID/ElementEmbed';
import { ElementForm } from './Shapes/GRID/ElementForm';
import { ElementH1 } from './Shapes/GRID/ElementH1';
import { ElementH2 } from './Shapes/GRID/ElementH2';
import { ElementH3 } from './Shapes/GRID/ElementH3';
import { ElementImage } from './Shapes/GRID/ElementImage';
import { ElementInput } from './Shapes/GRID/ElementInput';
import { ElementKpi } from './Shapes/GRID/ElementKpi';
import { ElementLayout } from './Shapes/GRID/ElementLayout';
import { ElementLine } from './Shapes/GRID/ElementLine';
import { ElementMath } from './Shapes/GRID/ElementMath';
import { ElementP } from './Shapes/GRID/ElementP';
import { ElementParagraph } from './Shapes/GRID/ElementParagraph';
import { ElementPie } from './Shapes/GRID/ElementPie';
import { ElementQuote } from './Shapes/GRID/ElementQuote';
import { ElementRadio } from './Shapes/GRID/ElementRadio';
import { ElementRuler } from './Shapes/GRID/ElementRuler';
import { ElementScatter } from './Shapes/GRID/ElementScatter';
import { ElementSheet } from './Shapes/GRID/ElementSheet';
import { ElementSlidebreak } from './Shapes/GRID/ElementSlidebreak';
import { ElementSlider } from './Shapes/GRID/ElementSlider';
import { ElementSource } from './Shapes/GRID/ElementSource';
import { ElementTable } from './Shapes/GRID/ElementTable';
import { ElementTangle } from './Shapes/GRID/ElementTangle';
import { ElementText } from './Shapes/GRID/ElementText';
import { ElementTimer } from './Shapes/GRID/ElementTimer';
import { ElementTooltip } from './Shapes/GRID/ElementTooltip';
import { ElementWaterfall } from './Shapes/GRID/ElementWaterfall';
import { Embed } from './Shapes/GRID/Embed';
import { Enter } from './Shapes/GRID/Enter';
import { Excel } from './Shapes/GRID/Excel';
import { FontColor } from './Shapes/GRID/FontColor';
import { Fx } from './Shapes/GRID/Fx';
import { Globe } from './Shapes/GRID/Globe';
import { GoogleDrive } from './Shapes/GRID/GoogleDrive';
import { GoogleSheets } from './Shapes/GRID/GoogleSheets';
import { GridBurger } from './Shapes/GRID/GridBurger';
import { GridFullColor } from './Shapes/GRID/GridFullColor';
import { GridGreen } from './Shapes/GRID/GridGreen';
import { GridMono } from './Shapes/GRID/GridMono';
import { HeartGradient } from './Shapes/GRID/HeartGradient';
import { HelpRing } from './Shapes/GRID/HelpRing';
import { HubSpot } from './Shapes/GRID/HubSpot';
import { ImportUrl } from './Shapes/GRID/ImportUrl';
import { NewDocument } from './Shapes/GRID/NewDocument';
import { Notion } from './Shapes/GRID/Notion';
import { NotionGrayscale } from './Shapes/GRID/NotionGrayscale';
import { OneDrive } from './Shapes/GRID/OneDrive';
import { PdfFilePro } from './Shapes/GRID/PdfFilePro';
import { Present } from './Shapes/GRID/Present';
import { ProBadge } from './Shapes/GRID/ProBadge';
import { QuestionMark } from './Shapes/GRID/QuestionMark';
import { RestoreDefaults } from './Shapes/GRID/RestoreDefaults';
import { SectionBreak } from './Shapes/GRID/SectionBreak';
import { Slack } from './Shapes/GRID/Slack';
import { SlashSlash } from './Shapes/GRID/SlashSlash';
import { Smartsheet } from './Shapes/GRID/Smartsheet';
import { Spreadsheet } from './Shapes/GRID/Spreadsheet';
import { SpreadsheetClosed } from './Shapes/GRID/SpreadsheetClosed';
import { Steps } from './Shapes/GRID/Steps';
import { Template } from './Shapes/GRID/Template';
import { Tick } from './Shapes/GRID/Tick';
import { Wand } from './Shapes/GRID/Wand';
import { Badge, Direction, IconBase, IconStatus, IconTypes } from './types';

const icons: Record<IconTypes, IconBase> = {
  'add-data': AddData,
  'airtable': Airtable,
  'angle': Angle,
  'align-left-text': AlignLeftText,
  'align-right-text': AlignRightText,
  'arrow': Arrow,
  'application': Application,
  'background-color': BackgroundColor,
  'ban': Ban,
  'bars': Bars,
  'bell': Bell,
  'block-quote': BlockQuote,
  'bold': Bold,
  'bullet-list': BulletList,
  'close': Close,
  'chat-bubble': ChatBubble,
  'calendar': Calendar,
  'center-text': CenterText,
  'check': Check,
  'check-circle': CheckCircle,
  'circle-arrow': CircleArrow,
  'csv': Csv,
  'cloud': Cloud,
  'code': Code,
  'cog': Cog,
  'computer': Computer,
  'conversation': Conversation,
  'dashboard': Dashboard,
  'data-cluster': DataCluster,
  'display': Display,
  'document-badge': DocumentBadge,
  'domain': Domain,
  'domain-lock': DomainLock,
  'document-milestone': DocumentMilestones,
  'document-settings': DocumentSettings,
  'document-statistics': DocumentStatistics,
  'document-statistics-pro': DocumentStatisticsPro,
  'download': Download,
  'duplicate': Duplicate,
  'drag-handle': DragHandle,
  'dropbox': Dropbox,
  'element-bar': ElementBar,
  'element-calendar': ElementCalendar,
  'element-column': ElementColumn,
  'element-combo': ElementCombo,
  'element-image': ElementImage,
  'element-embed': ElementEmbed,
  'element-form': ElementForm,
  'element-canvas': ElementCanvas,
  'element-line': ElementLine,
  'element-area': ElementArea,
  'element-pie': ElementPie,
  'element-scatter': ElementScatter,
  'element-sheet': ElementSheet,
  'element-source': ElementSource,
  'element-table': ElementTable,
  'element-text': ElementText,
  'element-button': ElementButton,
  'element-checkbox': ElementCheckbox,
  'element-dropdown': ElementDropdown,
  'element-input': ElementInput,
  'element-radio': ElementRadio,
  'element-slider': ElementSlider,
  'element-code': ElementCode,
  'element-h1': ElementH1,
  'element-h2': ElementH2,
  'element-h3': ElementH3,
  'element-p': ElementP,
  'element-paragraph': ElementParagraph,
  'element-quote': ElementQuote,
  'element-timer': ElementTimer,
  'element-ruler': ElementRuler,
  'element-math': ElementMath,
  'element-slidebreak': ElementSlidebreak,
  'element-layout': ElementLayout,
  'element-tangle': ElementTangle,
  'element-tooltip': ElementTooltip,
  'element-waterfall': ElementWaterfall,
  'element-kpi': ElementKpi,
  'ellipsis-horizontal': EllipsisHorizontal,
  'ellipsis-vertical': EllipsisVertical,
  'error-standard': ErrorStandard,
  'embed': Embed,
  'enter': Enter,
  'envelope': Envelope,
  'eye': Eye,
  'eye-hide': EyeHide,
  'exclamation-triangle': ExclamationTriangle,
  'file': File,
  'filter': Filter,
  'file-group': FileGroup,
  'flag': Flag,
  'folder': Folder,
  'font-color': FontColor,
  'form': Form,
  'fx': Fx,
  'globe': Globe,
  'google-drive': GoogleDrive,
  'google-sheets': GoogleSheets,
  'grid-burger': GridBurger,
  'grid-view': GridView,
  'grid-mono': GridMono,
  'grid-green': GridGreen,
  'grid-full-color': GridFullColor,
  'heart': Heart,
  'heart-gradient': HeartGradient,
  'help': Help,
  'help-ring': HelpRing,
  'history': History,
  'home': Home,
  'hubspot': HubSpot,
  'image': Image,
  'import': Import,
  'import-url': ImportUrl,
  'info-circle': InfoCircle,
  'italic': Italic,
  'landscape': Landscape,
  'launchpad': LaunchPad,
  'link': Link,
  'lock': Lock,
  'logout': Logout,
  'mobile': Mobile,
  'new-document': NewDocument,
  'notion': Notion,
  'notion-grayscale': NotionGrayscale,
  'note': Note,
  'number-list': NumberList,
  'one-drive': OneDrive,
  'pdf-file': PdfFile,
  'pdf-file-pro': PdfFilePro,
  'pencil': Pencil,
  'phone-handset': PhoneHandset,
  'plus': Plus,
  'plus-circle': PlusCircle,
  'pop-out': PopOut,
  'present': Present,
  'pro-badge': ProBadge,
  'question-mark': QuestionMark,
  'paint-roller': PaintRoller,
  'refresh': Refresh,
  'scissors': Scissors,
  'section-break': SectionBreak,
  'share': Share,
  'switch': Switch,
  'redo': Redo,
  'resize': Resize,
  'restore-defaults': RestoreDefaults,
  'search': Search,
  'shrink': Shrink,
  'slack': Slack,
  'slashslash': SlashSlash,
  'steps': Steps,
  'stop': Stop,
  'smartsheet': Smartsheet,
  'spreadsheet': Spreadsheet,
  'spreadsheet-closed': SpreadsheetClosed,
  'star': Star,
  'sync': Sync,
  'talk-bubbles': TalkBubbles,
  'template': Template,
  'text': Text,
  'tick': Tick,
  'times-circle': TimesCircle,
  'trash': Trash,
  'two-way-arrows': TwoWayArrows,
  'undo': Undo,
  'unknown-status': UnknownStatus,
  'upload': Upload,
  'user': User,
  'users': Users,
  'wand': Wand,
  'view-columns': ViewColumns,
  'window-close': WindowClose,
  'window-min': WindowMin,
  'wrench': Wrench,
  'xlsx': Excel,
};

export type IconProps = {
  name: IconTypes,
  className?: string,
  title?: string,
  size?: number,
  variant?: 'solid' | 'outline' | 'fullColor',
  direction?: Direction,
  badge?: Badge,
  status?: IconStatus,
}

export function Icon ({
  badge,
  className = '',
  direction,
  name,
  size = 40,
  variant = 'outline',
  status,
  title,
}: IconProps) {
  if (!icons[name]) {
    return null;
  }
  const path = icons[name][variant];
  return (
    <IconBuilder
      name={name}
      path={path || icons[name].outline} // all icons have outline, use that as fallback if no solid icon path exists
      className={className}
      direction={direction}
      size={size}
      badge={badge}
      status={status}
      title={title}
      />
  );
}
