import { NextPageContext } from 'next';

import { getCookie } from '@grid-is/browser-utils';

import { paths } from './generatedTypes';
import { getJSON } from './request';

type FeatureFlagBootstrapData = paths['/flags']['get']['responses']['200']['content']['application/json'];

export async function getBootstrappedFlags (ctx?: Pick<NextPageContext, 'req'>) {
  // This request may be issued server-side, so we need to take care of the authorization bit ourselves
  // XXX: solve the auth problem generically
  const headers: any = {};
  const accessToken = getCookie('access_token', ctx);
  if (accessToken) {
    headers.Authorization = `Bearer ${accessToken}`;
  }
  const request = ctx?.req;
  if (request) {
    headers['client-ip'] = request.headers['client-ip'] ||
                           request.headers['cf-connecting-ip'] ||
                           request.socket.remoteAddress;
  }
  return getJSON<FeatureFlagBootstrapData>('/flags', { headers });
}
