import { AllHTMLAttributes } from 'react';
import csx from 'classnames';

import { Badge, Direction, Icon, IconTypes } from '@grid-is/icon';

import { Tooltip } from '@/grid-ui/Tooltip';

import styles from './IconButton.module.scss';

type NativeButtonProps = AllHTMLAttributes<HTMLButtonElement>

export type IconButtonProps = NativeButtonProps & {
  iconName: IconTypes,
  iconDirection?: Direction,
  iconBadge?: Badge,
  buttonSize?: 'large'| 'medium' | 'small',
  buttonType?: 'primary' | 'secondary' | 'tertiary' | 'grayScale',
  labelColor?: string,
  type?: 'submit' | 'reset' | 'button',
  solid?: boolean,
  tooltip?: string,
  tooltipPlacement?: 'bottom' | 'left' | 'right' | 'top',
  tooltipDelay?: number,
  tooltipDisabled?: boolean,
  hideTooltipOnClick?: boolean,
  tooltipGap?: number,
  hideLabel?: boolean,
  className?: string,
} & ({ariaLabel: string, label?: never} | {ariaLabel?: never, label: string})

const IconButtonMap = {
  large: 24,
  medium: 24,
  small: 16,
};

export const IconButton = ({ ariaLabel,
  iconName,
  iconBadge,
  iconDirection = 'up',
  buttonType = 'secondary',
  buttonSize = 'large',
  label,
  labelColor,
  solid,
  tooltip,
  tooltipPlacement = 'top',
  tooltipDelay,
  tooltipGap,
  tooltipDisabled,
  hideTooltipOnClick = false,
  hideLabel,
  className,
  ...props }: IconButtonProps) => {
  const aria = ariaLabel ? { 'aria-label': ariaLabel } : {};
  return (
    <button
      type="button"
      {...props}
      {...aria}
      className={styles.button}
      >
      {label && (<span data-testid="icon-button-label" className={csx(styles.label, hideLabel && styles.hideLabel)} style={{ color: labelColor }}>{label}</span>)}
      <Tooltip gap={tooltipGap} label={tooltip} wrapElement hideOnElementClick={hideTooltipOnClick} placement={tooltipPlacement} delay={tooltipDelay} disabled={tooltipDisabled}>
        <span className={csx(styles.iconButton, styles[buttonType], styles[buttonSize], className)}>
          <Icon name={iconName} size={IconButtonMap[buttonSize]} direction={iconDirection} badge={iconBadge} variant={solid ? 'solid' : 'outline'} />
        </span>
      </Tooltip>
    </button>
  );
};
