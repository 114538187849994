const focusSelector = [
  'a[href]:not([disabled])',
  'textarea:not([disabled])',
  'button:not([disabled])',
  'input:not([disabled])',
  '[tabindex]:not([disabled]):not([tabindex="-1"])',
].join(',');

const dummyEvent = {
  shiftKey: false,
  preventDefault: () => {},
};

function focusable (contextNode: HTMLDivElement | null): HTMLElement[] {
  const elements = Array.from(
    (contextNode || document).querySelectorAll(focusSelector),
  ) as HTMLElement[];
  return elements.filter(elm => (
    elm.offsetWidth ||
    elm.offsetHeight ||
    elm === document.activeElement ||
    elm.getClientRects().length
  ));
}

export function trapfocus (contextNode: HTMLDivElement | null, event = dummyEvent) {
  const elms = focusable(contextNode);
  if (!elms.length) {
    event.preventDefault();
  }
  else {
    const last = elms[elms.length - 1];
    const first = elms[0];
    const currFocus = document.activeElement;
    if (!contextNode?.contains(currFocus)) {
      // focus is somewhere outside of the container
      event.preventDefault();
      first.focus();
    }
    else if (last === currFocus && !event.shiftKey) {
      // last element and tabbing forwards
      event.preventDefault();
      first.focus();
    }
    else if (first === currFocus && event.shiftKey) {
      // first element and tabbing backwards
      event.preventDefault();
      last.focus();
    }
  }
}
