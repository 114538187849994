import { UserGroup } from '@/api/groups';
import { Avatar } from '@/grid-ui/Avatar';
import { IconButton } from '@/grid-ui/IconButton';
import { Tooltip } from '@/grid-ui/Tooltip';

import styles from './members.module.scss';

type MemberProps = {
  onRemove?: () => void,
  name: string,
  subTitle: string,
  username?: string,
  avatar_url?: string,
  isGroupOwner: boolean,
  isCurrentUser: boolean,
}

function getMemberItemTitle (name: string, isGroupOwner: boolean, isCurrentUser: boolean) {
  if (isGroupOwner) {
    return `${name} (owner)`;
  }
  return isCurrentUser ? `${name} (you)` : name;
}

export function MemberItem ({ name, username, avatar_url, onRemove, subTitle, isGroupOwner, isCurrentUser }: MemberProps) {
  const title = getMemberItemTitle(name, isGroupOwner, isCurrentUser);
  return (
    <div className={styles.member} data-testid={`member-${username}`}>
      <Avatar size={24} avatarUrl={avatar_url} className={styles.avatar} name={name} username={username} />
      <div className={styles.text}>
        <div className={styles.title}>{title}</div>
        <div className={styles.subTitle}>{subTitle}</div>
      </div>
      {onRemove && <Tooltip label="Remove"><IconButton buttonType="grayScale" onClick={onRemove} iconName="window-close" buttonSize="small" ariaLabel="Remove user from group" /></Tooltip>}
    </div>
  );
}

type Props = {
  group?: UserGroup,
  currentUserId: string,
  onRemove?: (memberId: string, memberType: 'user' | 'email') => void,
};

export const Members = ({ group, currentUserId, onRemove }: Props) => {
  if (!group) {
    return null;
  }
  const { users = [], invites = [], creator_id }  = group;
  const currentMembers = [ ...users, ...invites ];
  if (!currentMembers.length) {
    return null;
  }
  return (
    <div className={styles.members}>
      {users.sort((a, b) => (a.name < b.name ? -1 : 1)).map(({ avatar_url, id, name, username }) => (
        <MemberItem
          key={id}
          avatar_url={avatar_url}
          name={name}
          subTitle={`@${username}`}
          username={username}
          isGroupOwner={creator_id === id}
          isCurrentUser={currentUserId === id}
          {...(onRemove && currentUserId !== id && { onRemove: () => onRemove(id, 'user') })}
          />
      ))}
      {invites.map(invite => <MemberItem key={invite} name={invite} username={invite} subTitle={invite} isGroupOwner={false} isCurrentUser={false} {...(onRemove && { onRemove: () => onRemove(invite, 'email') })} />)}
    </div>
  );
};
