import * as Sentry from '@sentry/nextjs';

import { BillingService, type GetPlanUsageResponse } from '@/api/billing';
import { UserType } from '@/api/user';
import { isNumber } from '@/grid/utils';
import { ms } from '@/utils/time';

const TRIAL_LENGTH = ms.week;

export function isInTrial (user: UserType) {
  return user.account.subscription_status === 'in_trial';
}

export function hasBeenInTrial (user?: UserType) {
  return (
    user &&
    user.account.subscription_status === 'cancelled' && (
      user.account.trial_end ||
      user.properties?.TRIAL_STARTED
    )
  );
}

export function getDaysUntilTrialEnds (user: UserType) {
  let trialEnd: number | undefined;
  if (user.account.trial_end) {
    trialEnd = Date.parse(user.account.trial_end);
  }
  else {
    const trialStarted = user.properties?.TRIAL_STARTED;
    const timeOfTrial = Date.parse(trialStarted + 'Z');
    trialEnd = timeOfTrial + TRIAL_LENGTH;
  }
  const timeRemaining = trialEnd - Date.now();

  return isFinite(timeRemaining) ? Math.ceil(timeRemaining / ms.day) : 0;
}

export function getOverageTrackingPayload (overages: string[], messagingFormat: 'Banner' | 'Modal') {
  return {
    documentViews: overages.includes('document_views'),
    embedDomains: overages.includes('embed_domains'),
    formSubmissions: overages.includes('form_submissions'),
    seats: overages.includes('seats'),
    messagingFormat: messagingFormat,
  };
}

export function getExceededOverages (usage: GetPlanUsageResponse) {
  const totals = Object.keys(usage.total);
  const overages: string[] = [];
  for (const total of totals) {
    if (
      isNumber(usage.limits[total]) &&
      usage.total[total] > usage.limits[total]
    ) {
      overages.push(total);
    }
  }
  return overages;
}

export function hasExceededUsage (usage: GetPlanUsageResponse) {
  return getExceededOverages(usage).length > 0;
}

export type UsageOverage = {
  period: 'currentMonth' | 'lastMonth',
  usage: GetPlanUsageResponse,
  overages: string[],
}

export async function checkUsageOverage (billing: BillingService, user?: UserType): Promise<UsageOverage | null> {
  try {
    if (!user || user.account?.subscription_status !== 'active') {
      // We only check the usage for users who have an active subscription
      return null;
    }
    // Get the first and last date of the current month
    const today = new Date();
    const currentMonthFirstDay = new Date(today.getFullYear(), today.getMonth(), 1);
    const currentMonthLastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    // Get the first and last date of the last month
    const lastMonthFirstDay = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const lastMonthLastDay = new Date(today.getFullYear(), today.getMonth(), 0);

    // Make API calls for both current and last month
    const currentMonthUsage = await billing.getUsage(currentMonthFirstDay, currentMonthLastDay);
    // Compare the results and return the appropriate status
    const overages = getExceededOverages(currentMonthUsage);
    if (overages.length > 0) {
      return {
        period: 'currentMonth',
        usage: currentMonthUsage,
        overages: overages,
      };
    }
    const lastMonthUsage = await billing.getUsage(lastMonthFirstDay, lastMonthLastDay);
    const lastOverages = getExceededOverages(lastMonthUsage);
    if (lastOverages.length > 0) {
      return {
        period: 'lastMonth',
        usage: lastMonthUsage,
        overages: lastOverages,
      };
    }
    return null;
  }
  catch (error) {
    Sentry.captureException(error);
    // We just ignore these errors for now.
    return null;
  }
}

