import { useEffect, useRef } from 'react';
import { NextRouter } from 'next/router';

import { splitUrl } from '@grid-is/browser-utils';
import { extractId } from '@grid-is/uuid';

import { logEvent } from './tracking';

export function useTrackNavigation ({ router, currentUsername, showingUpgradeBanner }: { router: NextRouter, currentUsername?: string, showingUpgradeBanner?: boolean }) {
  // XXX we could harden this for non-nextjs route changes
  // by using path from document.referrer iff the domain is (in)grid.is
  // without violating our privacy policy
  const referrerRef = useRef<string | null>(null);

  // the effect is triggered for 1) for the initial load, and 2) for all subsequent route changes
  useEffect(() => {
    // only send event for authenticated users
    if (currentUsername == null) {
      return;
    }

    // remove query params from router.asPath (as that's the path shown in the browser)
    const [ path ] = splitUrl(router.asPath);

    // this effect will only be triggered when router.asPath changes but let's guard against firing for the same path
    if (path !== referrerRef.current) {
      logEvent('In App Page Viewed', {
        path: path,
        path_type: mapSlugToType(path, currentUsername),
        referrer: referrerRef.current,
        referrer_type: referrerRef.current != null ? mapSlugToType(referrerRef.current, currentUsername) : null,
        document_id: maybeDocumentId(path),
        showing_upgrade_banner: false,
      });
    }

    referrerRef.current = path;
  }, [ router.asPath, currentUsername, showingUpgradeBanner ]);
}

type PageType = 'Homepage' | 'Another Users Homepage' | 'Document' | 'Account Settings' | 'Integration Pages' | 'Other';

function mapSlugToType (pathname: string, currentUsername?: string): PageType {
  const homepage = pathname.match(/^\/@([^/]+)?\/?$/);
  if (homepage) {
    return homepage[1] === currentUsername ? 'Homepage' : 'Another Users Homepage';
  }
  if (/^\/@[^/]+\/[^/]+/.test(pathname)) {
    return 'Document';
  }
  if (pathname.startsWith('/settings')) {
    return 'Account Settings';
  }
  if (pathname.startsWith('/connect')) {
    return 'Integration Pages';
  }
  return 'Other';
}

function maybeDocumentId (pathname: string): string | null {
  const slug = pathname.match(/^\/@[^/]+\/([^/]+)$/);
  if (slug) {
    return extractId(slug[1]);
  }
  return null;
}
