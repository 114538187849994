export const HeaderIcon = () => {
  return (
    <svg fill="none" width="50" height="40" viewBox="0 0 50 40" xmlns="http://www.w3.org/2000/svg">
      <path d="m10.402 22c-5.8391 0.0323-9.3425-4.4693-10.296-9.8946-0.95357-5.4253 4.6671-12.299 12.182-12.101 5.6963 0.11698 9.7122 4.3201 9.7122 11.121 0 6.8008-4.8771 10.834-11.598 10.875z" fill="#7243BC" />
      <path d="m3.2318 18.559c-0.07981 0-0.14703 0.0403-0.22685 0.0605 0.87836 1.0716 2.0035 1.9331 3.287 2.5168s2.6906 0.8738 4.1106 0.8473c3.5496 0 6.5826-1.1577 8.6577-3.2269-0.2357-0.0913-0.4807-0.1589-0.7309-0.2017-1.9744-0.3469-3.8311-1.0367-4.2008-3.106-0.1238-0.9187-0.1505-1.8472-0.0798-2.7711 0.8407-0.9568 1.3979-2.1132 1.6131-3.348 0.1324 0.01167 0.2658-0.00266 0.3922-0.04211 0.1263-0.03945 0.243-0.10321 0.3429-0.18738 0.0999-0.08416 0.1809-0.18698 0.2381-0.30218 0.0571-0.1152 0.0892-0.24037 0.0944-0.36788 0.0051-0.12751-0.0168-0.2547-0.0645-0.37379-0.0478-0.1191-0.1202-0.22762-0.213-0.31895-0.0929-0.09133-0.2041-0.16356-0.3269-0.21228-0.1228-0.04871-0.2547-0.0729-0.3876-0.07109-0.189-1.803-1.1678-3.2874-2.9027-3.5012-4.1462-0.51228-5.9861 1.1093-5.8433 3.7755v0.12101c-0.44948 0.04034-0.94097 0.16538-1.0502 0.52035-0.16804 0.53648 0.60911 1.0246 1.3064 1.0608 0.29772 1.2446 0.89475 2.405 1.7433 3.3883-0.03208 0.1326-0.05315 0.2675-0.06301 0.4033-0.20163 3.0495-2.7641 4.6912-5.6962 5.3366z" fill="#EFCA9C" />
      <path d="m6.982 7.8703c0.59069-0.17684 1.1392-0.46382 1.6143-0.84451 0.47503-0.38068 0.86719-0.8476 1.154-1.374 0.8948 0.54858 3.3984 0.3469 4.8435-0.80673 0 0 1.2182 1.561 1.1258 2.634 1.1342-1.7506-0.5587-5.748-4.3646-4.8082-4.7931-0.55261-5.7383 2.6421-4.373 5.1994z" fill="#AA8ED7" />
      <path d="m3.0087 18.637 0.10922 0.1251 0.18904 0.2138c0.07561 0.0806 0.15122 0.1573 0.23104 0.2339 0.07981 0.0767 0.18903 0.1896 0.28985 0.2824l0.27305 0.2501 0.21004 0.1734 0.24364 0.1936 0.21845 0.1614 0.27304 0.1855 0.20584 0.1372c0.15543 0.0968 0.31506 0.1895 0.47469 0.2783l0.16803 0.0847 0.36127 0.1775c0.07141 0.0363 0.14282 0.0645 0.21843 0.0968l0.34867 0.1412 0.21424 0.0806c0.13863 0.0484 0.28145 0.0968 0.42008 0.1372l0.15123 0.0484c0.19323 0.0538 0.39066 0.1022 0.5923 0.1452l0.17644 0.0323c0.15123 0.0282 0.30245 0.0564 0.45368 0.0766l0.22264 0.0282c0.15122 0 0.30246 0.0323 0.45788 0.0444h0.20584 0.67634c0.231 0 0.5041 0 0.7519-0.0242h0.2311l0.5125-0.0403 0.252-0.0283 0.4957-0.0645 0.2268-0.0363c0.2227-0.0363 0.4201-0.0766 0.6596-0.125h0.0336c0.2268-0.0484 0.4452-0.1049 0.6637-0.1654l0.2142-0.0605c0.1513-0.0444 0.2983-0.0888 0.4411-0.1412l0.2353-0.0807c0.1428-0.0484 0.2828-0.1008 0.42-0.1573l0.2143-0.0847c0.1806-0.0766 0.357-0.1573 0.5335-0.242l0.0672-0.0323c0.189-0.0928 0.3738-0.1896 0.5587-0.2945l0.1848-0.1129 0.3655-0.2218 0.1974-0.1332c0.1176-0.0806 0.2311-0.1613 0.3403-0.246l0.1764-0.1291c0.147-0.117 0.2899-0.2339 0.4201-0.355l0.0546-0.0484c0.1386-0.121 0.2688-0.25 0.4201-0.3791-0.2353-0.0876-0.4786-0.1538-0.7268-0.1977-0.5853-0.0873-1.1616-0.2236-1.7223-0.4074l-5.2215 2.2185-5.1628-2.9566c-0.92853 0.5345-1.9416 0.92-2.9994 1.1415-0.07561 0.0323-0.13443 0.0565-0.22264 0.0766z" fill="#338BFF" />
      <path d="m33.125 33.996c-8.4929 0.0509-13.583-6.4906-14.971-14.376s6.7873-17.874 17.742-17.615c8.2587 0.19925 14.105 6.3041 14.105 16.186s-7.0922 15.75-16.875 15.805z" fill="#79ADF2" />
      <path d="m43.287 25.08c-2.5055-0.424-4.8607-1.3143-5.3468-3.9343-0.1576-1.1623-0.1932-2.3369-0.106-3.506 1.0679-1.2103 1.7749-2.6752 2.0459-4.2395 0.6496-0.089 1.3256-0.4748 1.2947-1.2379s-0.6849-1.0175-1.1975-1.1319c-0.2386-2.2851-1.4803-4.1674-3.6764-4.4387-5.254-0.6444-7.5871 1.4033-7.4104 4.7821v0.1568c-0.57 0.0509-1.1886 0.2078-1.3256 0.6572-0.2165 0.6783 0.7689 1.2972 1.657 1.3439 0.374 1.5783 1.131 3.0498 2.2094 4.2945-0.0456 0.1697-0.0766 0.3427-0.0928 0.5173-0.2386 3.8155-3.4908 5.9013-7.2026 6.7407-1.2809 0.2594-2.4763 0.8154-3.482 1.6195 2.5408 4.3581 6.721 7.33 12.452 7.2961 6.1642-0.0339 11.255-2.3784 14.184-6.5754-1.1312-1.1701-2.6027-2.1028-4.0034-2.3444z" fill="#EFCA9C" />
      <path d="m38.024 7.2446s0.4728-1.6958 0-2.1918c-0.4728-0.49601-3.0932-0.32219-3.4158 1.416-0.6098-0.8479-2.8589-0.5257-2.8368 0.3985 0.0221 0.92421 6.2526 0.37732 6.2526 0.37732z" fill="#583490" />
      <path d="m39.62 14.482s-0.9677 6.5712-4.4188 7.1478c-3.451 0.5765-4.949-4.2395-5.5986-6.9273 1.5936-0.3798 3.2211-0.614 4.8607-0.6995 1.7323-0.029 3.4624 0.1317 5.1567 0.479z" fill="#583490" />
      <path d="m20.676 26.678c0.0707 0.123 0.1414 0.2417 0.2165 0.3561 0.0751 0.1145 0.1591 0.2714 0.243 0.3986 0.1238 0.1907 0.2519 0.373 0.3845 0.5553 0.1325 0.1823 0.2342 0.3265 0.3579 0.4876s0.2828 0.3688 0.4419 0.5426c0.159 0.1738 0.1944 0.2205 0.2916 0.3307s0.2475 0.2798 0.38 0.4239c0.1326 0.1442 0.2121 0.2163 0.3226 0.3222 0.1105 0.106 0.2607 0.2544 0.3933 0.3731l0.3446 0.301c0.1414 0.1187 0.2828 0.2374 0.4419 0.3519l0.3491 0.2671c0.1591 0.1187 0.327 0.2289 0.4949 0.3391l0.327 0.2205c0.243 0.1484 0.4861 0.2925 0.7379 0.4239l0.2166 0.1103c0.2651 0.1356 0.5346 0.267 0.8086 0.3857l0.3402 0.1315c0.2033 0.0805 0.411 0.1611 0.6231 0.2331 0.1281 0.0467 0.2607 0.0848 0.3933 0.1272l0.6186 0.1738 0.4154 0.0976c0.2165 0.0466 0.4418 0.089 0.654 0.1271 0.1369 0.0255 0.2783 0.0509 0.4153 0.0679 0.2431 0.0381 0.4949 0.0636 0.7424 0.0848l0.3667 0.0339c0.3756 0.0254 0.7601 0.0424 1.1489 0.0381 0.3889-0.0042 0.8617 0 1.2815-0.0424l0.3977-0.0339c0.2872 0 0.5744-0.0466 0.8572-0.0805l0.4419-0.0551c0.2651-0.0396 0.5303-0.082 0.7954-0.1272l0.4419-0.0763c0.2916-0.0552 0.5788-0.123 0.8837-0.1908l0.2917-0.0721c0.38-0.0975 0.7556-0.2077 1.1179-0.3307 0.0751 0 0.1458-0.0508 0.2165-0.0763 0.2946-0.1017 0.5892-0.2077 0.8838-0.3179l0.3358-0.1484c0.2387-0.1018 0.4728-0.2078 0.7026-0.318l0.3403-0.1696c0.2327-0.1243 0.4625-0.2515 0.6893-0.3815l0.2828-0.1611c0.3005-0.1865 0.5965-0.3773 0.8838-0.5808h0.0265c0.3049-0.2162 0.5921-0.424 0.8837-0.6783l0.1812-0.1569c0.2121-0.1865 0.4419-0.3773 0.6231-0.5766 0.0751-0.072 0.1502-0.1441 0.2209-0.2204 0.1856-0.1908 0.3668-0.3858 0.5435-0.5893 0.0663-0.0721 0.1326-0.1484 0.1945-0.2247 0.1944-0.2332 0.38-0.4706 0.5611-0.7165 0.031-0.0466 0.0707-0.089 0.1017-0.1399 0.0309-0.0508 0.0442-0.0678 0.0663-0.1017-1.1445-1.1616-2.616-2.0901-4.0123-2.3317-0.8477-0.1367-1.6774-0.3612-2.4745-0.6698h-14.586c-0.7048 0.2789-1.4318 0.503-2.1741 0.6698-1.274 0.2532-2.4646 0.8003-3.4687 1.594z" fill="#AA8ED7" />
      <circle cx="16.5" cy="29.5" r="10.5" fill="#fff" />
      <mask id="a" x="6" y="19" width="21" height="21" maskUnits="userSpaceOnUse" type="mask-type:alpha">
        <circle cx="16.5" cy="29.5" r="10.5" fill="#C4C4C4" />
      </mask>
      <g mask="url(#a)">
        <path d="m25.958 23.234c-0.096-0.1403-0.1953-0.2787-0.2977-0.4153h-18.001c-0.14089 0.1356-0.27857 0.2769-0.40985 0.4153h18.709z" fill="#7243BC" />
        <path d="m26.468 24.081c-0.0737-0.1412-0.1537-0.2769-0.2338-0.4126h-19.368c-0.11527 0.1357-0.22734 0.2769-0.32019 0.4126h19.922z" fill="#7243BC" />
        <path d="m27.406 26.617c-0.0321-0.1385-0.0673-0.2769-0.1025-0.4098h-22.049c-0.06083 0.1357-0.11847 0.2769-0.1729 0.4098h22.324z" fill="#7243BC" />
        <path d="m24.484 21.539c-0.1601-0.144-0.3202-0.2769-0.4963-0.4126h-14.194c-0.20812 0.1329-0.41625 0.2769-0.61477 0.4126h15.305z" fill="#7243BC" />
        <path d="m26.864 24.925c-0.0577-0.1384-0.1185-0.2769-0.1793-0.4098h-20.473c-0.09606 0.1357-0.18571 0.2769-0.27216 0.4098h20.925z" fill="#7243BC" />
        <path d="m27.174 25.773c-0.0416-0.1412-0.0896-0.2769-0.1408-0.4126h-21.354c-0.07684 0.1357-0.15049 0.2769-0.22093 0.4126h21.715z" fill="#7243BC" />
        <path d="m25.312 22.383c-0.1216-0.1412-0.2465-0.2769-0.3746-0.4126h-16.33c-0.1665 0.1357-0.32019 0.2769-0.48669 0.4126h17.191z" fill="#7243BC" />
        <path d="m23.386 20.692c-0.2242-0.1468-0.4547-0.2769-0.6949-0.4099h-11.351c-0.2754 0.1274-0.5411 0.2769-0.8037 0.4099h12.849z" fill="#7243BC" />
        <path d="m27.696 30.433h-23.054v0.0554c0.02241 0.1191 0.04802 0.2382 0.07364 0.3545h22.926c0.032-0.1329 0.0448-0.2686 0.0544-0.4099z" fill="#7243BC" />
        <path d="m24.179 37.201h-15.942c0.15369 0.144 0.32019 0.2769 0.47708 0.4126h14.908c0.1921-0.1329 0.3779-0.2576 0.5572-0.4126z" fill="#7243BC" />
        <path d="m27.572 27.465c-0.0224-0.1385-0.048-0.2769-0.0736-0.4126h-22.574c-0.04483 0.1385-0.08325 0.2769-0.12168 0.4126h22.769z" fill="#7243BC" />
        <path d="m21.779 19.847c-0.3819-0.1595-0.7755-0.2974-1.1783-0.4126h-6.9066c-0.4441 0.116-0.8803 0.2538-1.3063 0.4126h9.3912z" fill="#7243BC" />
        <path d="m27.037 33.385c0.0512-0.1357 0.1025-0.2769 0.1505-0.4126h-21.805c0.05763 0.1384 0.11207 0.2769 0.1729 0.4126h21.482z" fill="#7243BC" />
        <path d="m24.693 36.769c0.1441-0.1329 0.285-0.2769 0.4195-0.4126h-17.7c0.12167 0.1412 0.24654 0.2769 0.37462 0.4126h16.906z" fill="#7243BC" />
        <path d="m25.506 35.921c0.1152-0.133 0.2241-0.277 0.3201-0.4099h-19.077c0.09926 0.1385 0.20173 0.2769 0.3202 0.4099h18.437z" fill="#7243BC" />
        <path d="m26.147 35.076c0.0896-0.1357 0.1761-0.2769 0.2593-0.4126h-20.201c0.08325 0.1385 0.1665 0.2769 0.25295 0.4126h19.689z" fill="#7243BC" />
        <path d="m27.675 28.31c0-0.1385-0.0288-0.2769-0.0449-0.4098h-22.932c-0.02882 0.1356-0.05123 0.2769-0.07365 0.4098h23.051z" fill="#7243BC" />
        <path d="m19.908 39.294c0.4258-0.1175 0.8437-0.2552 1.252-0.4126h-10.406c0.3309 0.1565 0.6731 0.2943 1.0246 0.4126h8.1297z" fill="#7243BC" />
        <path d="m17.686 39.74h-4.0952c0.6339 0.0981 1.2771 0.1445 1.9211 0.1385 0.7275-0.0034 1.4539-0.0496 2.1741-0.1385z" fill="#7243BC" />
        <path d="m22.166 38.463c0.2658-0.1274 0.5252-0.2631 0.7749-0.4098h-13.656c0.20812 0.144 0.42585 0.2769 0.64038 0.4098h12.241z" fill="#7243BC" />
        <path d="m26.647 34.229c0.0704-0.1357 0.1377-0.2769 0.2017-0.4098h-21.094c0.06724 0.1384 0.13768 0.2769 0.20812 0.4098h20.685z" fill="#7243BC" />
        <path d="m27.725 30.001v-0.4126h-23.169c0 0.1384 1e-5 0.2769 0.02882 0.4126h23.14z" fill="#7243BC" />
        <path d="m27.725 29.156c0-0.1384 0-0.2769-0.0192-0.4125h-23.131c0 0.1384-0.01921 0.2769-0.02241 0.4125h23.172z" fill="#7243BC" />
        <path d="m27.322 32.537c0.0384-0.1357 0.0768-0.2769 0.1089-0.4099h-22.35c0.04483 0.1385 0.08965 0.2769 0.13768 0.4099h22.103z" fill="#7243BC" />
        <path d="m27.527 31.693c0.0288-0.1357 0.0544-0.277 0.0768-0.4126h-22.766c0.03522 0.1384 0.07044 0.2769 0.10886 0.4126h22.58z" fill="#7243BC" />
        <path d="m18.248 29.016h5.6743s0.74-2.0463-1.5451-5.2805c-2.2851-3.2343-8.2259-3.9182-10.36-1.1077-2.368 3.1014-2.1016 6.8617-2.1016 6.8617s3.7059-0.6119 6.0591-0.2963c2.3532 0.3157 2.2733-0.1772 2.2733-0.1772z" fill="#004DB2" />
        <path d="m22.774 34.618c-1.6754-0.2769-3.7947-0.8556-4.1233-2.5641-0.0908-0.582-0.1215-1.1709-0.0918-1.7584 0.0592-0.0471 0.1214-0.0941 0.1836-0.1467 2.7084-2.362 2.3976-7.2217-0.6394-7.5844-3.5135-0.4209-5.0735 0.9137-4.9551 3.1179 0.0948 1.7251 0.888 3.5167 2.0069 4.4914v0.0526c-0.1598 2.4921-2.6877 3.8462-5.1682 4.3917-0.79774 0.161-1.547 0.4864-2.1934 0.9525 1.7286 2.6611 4.4696 4.4471 8.1637 4.4305 3.999-0.0221 7.329-1.451 9.3299-4.0206-0.6797-0.6713-1.5525-1.1444-2.513-1.3624z" fill="#EFCA9C" />
        <path d="m20.948 24.665s-3.256 0.983-7.8055 0.1468c-0.3789-0.3295 0.7903-2.135 1.5303-2.326 0.9669-0.1666 1.9434-0.2794 2.9245-0.3379 0 0 2.9215 0.7671 3.3507 2.5171z" fill="#004DB2" />
        <path d="m7.7905 35.57c0.03256 0.0526 0.07104 0.1052 0.10656 0.1578 0.25181 0.375 0.52863 0.7348 0.8288 1.0772l0.16872 0.1911c0.07992 0.0858 0.1628 0.1689 0.24568 0.252 0.08288 0.083 0.13024 0.1356 0.19832 0.1993l0.2516 0.2271 0.22496 0.1938c0.08288 0.0692 0.16577 0.1385 0.25459 0.2049 0.0888 0.0665 0.1657 0.1274 0.2516 0.1883l0.2545 0.18c0.0977 0.0665 0.1954 0.1246 0.296 0.1883 0.1007 0.0637 0.1569 0.0997 0.2398 0.1468 0.1776 0.1024 0.3582 0.1966 0.5446 0.2769l0.1836 0.0803c0.1361 0.0637 0.2723 0.1246 0.4144 0.18 0.0799 0.0332 0.1628 0.0609 0.2456 0.0913 0.0829 0.0305 0.2487 0.0914 0.3789 0.1302l0.2664 0.0775c0.1273 0.036 0.2575 0.072 0.3907 0.1025l0.2753 0.0609c0.1362 0.0277 0.2753 0.0526 0.4174 0.0748 0.0888 0.0138 0.1776 0.0304 0.2693 0.0415 0.1569 0.0221 0.3168 0.036 0.4766 0.0498 0.0799 0 0.1569 0.0166 0.2368 0.0194 0.2427 0.0166 0.4884 0.0249 0.74 0.0222 0.2516-0.0028 0.5328 0 0.7933-0.0222l0.2605-0.0194c0.1717-0.0138 0.3433-0.0249 0.5121-0.0415l0.296-0.0388 0.444-0.0609 0.3226-0.0554c0.1362-0.0221 0.2723-0.0498 0.4055-0.0775l0.3286-0.072 0.3759-0.0969c0.1125-0.0305 0.222-0.0582 0.3315-0.0914s0.2309-0.072 0.3493-0.1108l0.3286-0.1107 0.3137-0.1219c0.1125-0.0443 0.228-0.0858 0.3375-0.1329l0.1065-0.0498c0.296-0.1329 0.592-0.2769 0.8644-0.4292l0.1924-0.1052c0.1213-0.072 0.2397-0.1468 0.3611-0.2243l0.1953-0.1246c0.1155-0.0776 0.2309-0.1606 0.3434-0.2437l0.1835-0.1357 0.3256-0.2631 0.1687-0.1439c0.1066-0.0942 0.2102-0.1911 0.3138-0.2769l0.1421-0.1413c0.1036-0.1052 0.2072-0.2104 0.296-0.3212l0.1095-0.1246c0.1036-0.1218 0.2102-0.2437 0.296-0.3683-0.6789-0.6683-1.5494-1.1394-2.5071-1.3568-0.958-0.1273-1.8852-0.4084-2.741-0.8307-1.4978 0.252-3.3537 0.8307-4.0552 2.1765-0.5124-1.1871-1.5002-2.14-2.7528-2.6556-0.9818 0.6366-2.0883 1.0854-3.256 1.3209-0.77999 0.1611-1.5126 0.4806-2.146 0.9359z" fill="#338BFF" />
      </g>
    </svg>
  );
};
