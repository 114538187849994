import { useEffect, useMemo } from 'react';

import { UserType } from '@/api/user';
import { useDeps } from '@/bootstrapping/dependencies';

import { TimeToPayModal } from './TimeToPayModal';
import { TrialModal } from './TrialModal';
import { ModalProps } from './types';

type Props = ModalProps & {
  user: UserType,
  initiatedFrom: 'Billing page' | 'In document share button' | 'In document embed button' | 'Document list share button',
}

export const TrialEligibilityModal =  ({ user, onSuccess, onCancel, payWallFeature, initiatedFrom }: Props) => {
  const { userEvents } = useDeps();
  const hasStartedTrial = useMemo(() => {
    return !!user.properties?.TRIAL_STARTED;
  },
  // ignore changes to the user, as we do not want a re-render to re-evaluate what modal is shown
  // eslint-disable-next-line react-hooks/exhaustive-deps
  []);
  useEffect(() => {
    if (!hasStartedTrial) {
      userEvents.startTrialModalShown(initiatedFrom);
    }
    else {
      userEvents.upgradeModalShown(initiatedFrom);
    }
  }, [  hasStartedTrial, userEvents, initiatedFrom ]);

  if (hasStartedTrial) {
    return (<TimeToPayModal
      onCancel={onCancel}
      payWallFeature={payWallFeature}
      />);
  }

  return (
    <TrialModal
      onSuccess={onSuccess}
      onCancel={onCancel}
      payWallFeature={payWallFeature}
      />
  );
};
