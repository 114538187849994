import { joinUrl, splitUrl } from './url';

const enc = (val: unknown): string => {
  if (val == null) {
    return '';
  }
  return encodeURIComponent(String(val))
    .replace(/%40/g, '@')
    .replace(/%2F/g, '/');
};

export function set (url: string, params: Record<string, unknown>): string {
  const [ path, query, hash ] = splitUrl(url);
  const searchParams = new URLSearchParams(query);
  Object.entries(params).forEach(([ key, value ]) => {
    searchParams.set(key, String(value));
  });
  return joinUrl(path, searchParams.toString(), hash);
}

export function remove (url: string, paramName: string) {
  const [ path, query, hash ] = splitUrl(url);
  const searchParams = new URLSearchParams(query);
  searchParams.delete(paramName);
  return joinUrl(path, searchParams.toString(), hash);
}

export const serialize = (params: Record<string, unknown>, includeEmpty = false): string => {
  return Object.keys(params)
    .sort()
    .map(key => {
      const value = params[key];
      if (includeEmpty !== true) {
        if (value == null || value === '') {
          return false;
        }
      }
      return `${enc(key)}=${enc(value)}`;
    })
    .filter(Boolean)
    .join('&');
};

export const parse = (query?: string): Record<string, string> => {
  const params: Record<string, string> = {};
  const q = (query || '').replace(/^[?#]+/, '').split('&')
    .filter(Boolean);
  for (const p of q) {
    const m = /^([^=]*)=(.*)/.exec(p);
    if (m) {
      const v = m[2].trim();
      const key = decodeURIComponent(m[1].trim());
      params[key] = typeof v === 'string' ? decodeURIComponent(v) : v;
    }
  }
  return params;
};
