import React from 'react';

import { colors } from '@grid-is/styles';

type XMoreAvatarProps = {
  count?: string,
}

export const XMoreAvatar: React.VFC<XMoreAvatarProps> = ({ count }) => {
  return count ? (
    <svg data-testid="default-avatar" viewBox="0 0 200 200">
      <rect x="0" y="0" width="200" height="200" fill={colors.lavender} />
      <text fill={colors.squidInk} x="100" y="100" fontWeight="600" fontSize="100" textAnchor="middle" dy=".34em">+{count || '?'}</text>
    </svg>
  ) : null;
};
