import { colors } from '@grid-is/styles';

import { IconBase } from '../../types';

export const RestoreDefaults: IconBase = {
  // Name of icon.
  name: 'restore-defaults',
  // Paths, we only use outline and solid.
  outline: `<path fill-rule="evenodd" clip-rule="evenodd" d="M15.9639 31.125H7.22917V5.91411H17.9997V14.2713H25.1995L25.1999 16.2L26.9999 16.5L26.9997 12.4388C26.9997 12.2195 26.9196 12.0078 26.7746 11.8434L19.9695 4.12955C19.7987 3.93589 19.5529 3.82495 19.2946 3.82495H7.0471C6.21469 3.85053 5.53756 4.57017 5.41849 5.49589C5.40624 5.58703 5.3999 5.6802 5.3999 5.77495V31.125C5.3999 32.2019 6.21889 33.0749 7.22917 33.0749H17.4365L16.5219 32.1C16.2626 31.8296 16.0708 31.4944 15.9639 31.125ZM24.8311 12.5895L19.4312 6.42607V12.5895H24.8311Z" fill="${colors.squidInk}"/> <path d="M23.2985 33.3C25.5295 33.3 27.4309 32.5141 29.0027 30.9422C30.6253 29.3197 31.4365 27.3929 31.4365 25.1619C31.4365 22.931 30.6253 21.0296 29.0027 19.4577C27.4309 17.8859 25.5295 17.1 23.2985 17.1C20.3576 17.1 18.076 18.3422 16.4534 20.8267L14.3999 18.6972V25.1619H20.8647L18.2788 22.576C19.2929 20.5479 20.9661 19.5338 23.2985 19.5338C24.8703 19.5338 26.214 20.0915 27.3295 21.207C28.445 22.2718 29.0027 23.5901 29.0027 25.1619C29.0027 26.7338 28.445 28.0774 27.3295 29.1929C26.214 30.3084 24.8703 30.8662 23.2985 30.8662C21.321 30.8662 19.7745 30.0549 18.6591 28.4324H15.845C16.4534 29.9028 17.4168 31.0943 18.7351 32.007C20.1041 32.869 21.6253 33.3 23.2985 33.3Z" fill="${colors.squidInk}"/>`,
};
