import { IconBase } from '../../types';

export const Stop: IconBase = {
  // Name of icon.
  name: 'stop',
  // Paths, we only use outline and solid.
  outline: '<path d="M30,32H6a2,2,0,0,1-2-2V6A2,2,0,0,1,6,4H30a2,2,0,0,1,2,2V30A2,2,0,0,1,30,32ZM6,6V30H30V6Z"/>',
  solid: '<rect x="3.96" y="4" width="27.99" height="28" rx="2" ry="2"/>',
};

