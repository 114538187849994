import { useEffect, useRef, useState } from 'react';

import { sendEmailVerification } from '@/api/user';
import { TextLink } from '@/grid-ui/TextLink';
import { useAuth } from '@/utils/auth';

import styles from './VerifyEmail.module.scss';

export const VerifyEmail = () => {
  const { user } = useAuth();

  const timer = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);
  const [ loading, setLoading ] = useState(false);
  const [ message, setMessage ] = useState('');

  useEffect(() => {
    return () => {
      timer.current && clearTimeout(timer.current);
    };
  }, []);

  return (
    <div className={styles.verifyEmail}>
      <p>Click the link we sent to <strong>{user?.email}</strong> to verify your email address.</p>
      <p>
        <span>If you are not seeing the link, try checking your junk folder or {' '}</span>
        <TextLink
          href=""
          onClick={e => {
            e.preventDefault();
            setLoading(true);
            setMessage('');
            timer.current && clearTimeout(timer.current);
            timer.current = setTimeout(() => sendEmailVerification().then(res => {
              setLoading(false);
              if (res.verification_email_was_sent) {
                setMessage('Email sent!');
              }
              else {
                setMessage('Hmm, that didn\'t work, please try again.');
              }
            }), 400);
          }}
          >
          resend the link
        </TextLink>
        <span>.</span>
      </p>
      <p className={styles.response}>
        {loading && 'Sending email...'}
        {message}
      </p>
    </div>
  );
};
