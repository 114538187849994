import type { ModelStateArray } from '@/utils/modelState';

import { getRequestOptionsFor } from './document';
import { paths } from './generatedTypes';
import { deleteJSON, postJSON } from './request';

type SubmitFormRequest = paths['/document/{document_id}/form']['post']['requestBody']['content']['application/json'];
export type FormValues = SubmitFormRequest['form_values'];

export function submitForm (
  documentId: string,
  destinations: ('email' | 'hubspot')[],
  form_values: FormValues,
  model_state: { s: string, state: ModelStateArray },
  subject?: string,
  email_addresses?: string[],
  hubspot_integration_id?: string,
) {
  const body = {
    destinations,
    form_values,
    model_state,
    subject,
    email_addresses,
    hubspot_integration_id,
  };
  const opts = getRequestOptionsFor(documentId);
  return postJSON<{}, SubmitFormRequest>(`/document/${documentId}/form`, body, opts);
}

export class FormSubmissionService {
  submit = submitForm;
  delete (submissionIds: string[]) {
    type DeleteRequest = paths['/form-submissions']['delete']['requestBody']['content']['application/json'];
    return deleteJSON<{}, DeleteRequest>('/form-submissions', { form_submission_ids: submissionIds });
  }
}
