import csx from 'classnames';

import { getLoginUrl } from '@grid-is/browser-utils';
import { useMounted } from '@grid-is/custom-hooks';
import { Icon } from '@grid-is/icon';

import { Button } from '@/grid-ui/Button';
import { HomeLogo } from '@/components/Navigation/HomeLogo';
import { NotificationPanel } from '@/components/NotificationPanel/NotificationPanel';
import { isMobile as useMobile } from '@/utils';
import { useAuth } from '@/utils/auth';

import styles from './topNav.module.scss';

type TopNavProps = {
  utmParams?: string | URLSearchParams,
  showMobileSideNav?: () => void,
  hideMenus?: boolean,
}

export const TopNav = ({ utmParams, showMobileSideNav = () => {}, hideMenus }: TopNavProps) => {
  const { isLoggedIn } = useAuth();
  const isMobile = useMobile();
  const isMounted = useMounted();

  if (isMobile) {
    return (
      <nav className={csx(styles.navigation, styles.mobileTopNav)}>
        {isMounted && !isLoggedIn &&
          <>
            <HomeLogo utmParams={utmParams} size={24} />
            {!hideMenus && <a href={getLoginUrl({}, { campaign: 'login' }, utmParams)}><Button buttonType="primary" buttonSize="medium">Log in</Button></a>}
          </>
        }
        {isMounted && isLoggedIn &&
          <>
            {!hideMenus && <button type="button" className={styles.mobileMenuTrigger} onClick={showMobileSideNav}><Icon name="grid-burger" size={24} /></button>}
            <HomeLogo utmParams={utmParams} size={24} />
            {!hideMenus && <div className={styles.notifications}><NotificationPanel /></div>}
          </>
        }
      </nav>
    );
  }
  return null;
};
