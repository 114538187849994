import { useEffect, useMemo } from 'react';
import { useMachine } from '@xstate/react';
import { useRouter } from 'next/router';

import { Icon } from '@grid-is/icon';

import { Button } from '@/grid-ui/Button';
import { Modal } from '@/grid-ui/Modal';

import { ManageGroup } from './ManageGroup';
import { Members } from './Members';
import { Overview } from './Overview';
import { SubtleCTAButton } from './SubtleCTAButton';
import { States } from './types';
import { UserGroupsHeader } from './UserGroupsHeader';
import { UserGroupsMachine } from './UserGroupsMachine';

import styles from './userGroupsModal.module.scss';

type Props = {
  currentUserId: string,
  openModal: boolean,
  setOpenModal?: (shouldOpen: boolean) => void,
}

export const UserGroupsModal = ({ setOpenModal, openModal, currentUserId }: Props) => {
  const router = useRouter();
  const [ state, send ] = useMachine<typeof UserGroupsMachine>(
    UserGroupsMachine,
    { context: { currentUserId, startOpen: router.query?.userGroups === '1' } },
  );

  const { groups, selectedGroup, loadMoreCursor, newGroupName } = state.context;
  const isInitiallyFetching = state.matches(States.fetchGroups);
  const isError = state.matches(States.error);
  const isClosed = state.matches(States.closed);
  const isInOverview = state.matches(States.overview);
  const isLeaving = state.matches(States.leaveGroup);
  const isDeleting = state.matches(States.deleteGroup);
  const isManagingGroup = state.matches(States.manageGroup);
  const isViewingGroup = state.matches(States.viewGroup);
  const isPending = state.hasTag('pending');

  useEffect(() => {
    if (openModal && isClosed) {
      send('OPEN');
    }
  }, [ openModal, send, isClosed ]);

  const { title, subTitle, canGoBack } = useMemo(() => {
    if (isInOverview) {
      return { title: 'My Groups', subTitle: 'Collaborate with people', canGoBack: false };
    }
    else if (isError) {
      return { title: 'Uh oh', canGoBack: false };
    }
    else if (isDeleting) {
      return { title: 'Delete group?', canGoBack: false };
    }
    else if (isLeaving) {
      return { title: 'Leave group?', canGoBack: false };
    }
    return { title: selectedGroup?.name ?? '', canGoBack: true };
  }, [ isDeleting, isError, isInOverview, isLeaving, selectedGroup?.name ]);

  const footer = useMemo(() => {
    if (isDeleting) {
      return (
        <div className={styles.footer}>
          <Button buttonType="secondaryDestructive" onClick={() => send('CANCEL')} disabled={isPending}>Cancel</Button>
          <Button
            buttonType="destructive"
            onClick={() => send('ACCEPT')}
            >Delete
          </Button>
        </div>
      );
    }
    else if (isLeaving) {
      return (
        <div className={styles.footer}>
          <Button onClick={() => send('CANCEL')} disabled={isPending}>Cancel</Button>
          <Button
            buttonType="primary"
            onClick={() => send('ACCEPT')}
            >Leave
          </Button>
        </div>
      );
    }

    return null;
  }, [ isDeleting, isLeaving, send, isPending ]);

  if (isInitiallyFetching) {
    return null;
  }

  function renderBody () {
    if (isInOverview) {
      return (
        <Overview
          currentUserId={currentUserId}
          send={send}
          isPending={isPending}
          groups={groups}
          newGroupName={newGroupName}
          loadMoreCursor={loadMoreCursor}
          />
      );
    }
    else if (isManagingGroup) {
      return <ManageGroup send={send} group={selectedGroup} currentUserId={currentUserId} />;
    }
    else if (isViewingGroup) {
      return (
        <>
          <Members group={selectedGroup} currentUserId={currentUserId} />
          <SubtleCTAButton onClick={() => send('LEAVE_GROUP')}>Leave group</SubtleCTAButton>
        </>
      );
    }
    else if (isError) {
      return (
        <div className={styles.error}>
          <img src="/img/ingrid-in-the-jungle.svg" alt="Something went wrong" />
          <p>Oh no, something went wrong.</p>
          <a
            href="#"
            onClick={e => {
              e.preventDefault();
              send('GO_BACK');
            }}
            >Start over <Icon name="sync" size={16} />
          </a>
        </div>
      );
    }
    else if (isLeaving) {
      return <p>By leaving, you will no longer have access to private projects shared with this group.</p>;
    }
    else if (isDeleting) {
      return (
        <>
          <p>Private projects shared with this group will no longer be accessible to members once the group is deleted.</p>
          <p>Deleting groups can't be undone.</p>
        </>
      );
    }
    return null;
  }

  return (
    openModal ? (
      <Modal
        renderHeader={() => <UserGroupsHeader send={send} subTitle={subTitle} title={title} showBackArrow={canGoBack} />}
        open={!isClosed}
        onClose={() => {
          send('CLOSE');
          setOpenModal && setOpenModal(false);
        }}
        size="small"
        closeButton
        {...(footer !== null && { footer })}
        >
        <div className={styles.body}>
          {renderBody()}
        </div>
      </Modal>
    ) : null
  );
};
