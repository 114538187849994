import { ReactElement, useContext, useEffect, useMemo, useState } from 'react';

import { EventStream } from '@/editor/EditorEventStream';
import { Button } from '@/grid-ui/Button';
import { FieldWrapper } from '@/grid-ui/FieldWrapper';
import { Input } from '@/grid-ui/Input';
import { Modal } from '@/grid-ui/Modal';
import { FLAG_TOGGLER, FlagContext } from '@/utils/flags';

import { NavButton } from '../Navigation/SideNav/NavButton';
import { Flag } from './Flag';

import styles from './FlagToggler.module.scss';

type FlagTogglerProps = {
  location: 'nav' | 'doc-actions',
  children?: ReactElement,
}

const FlagToggler = ({ location = 'nav', children }: FlagTogglerProps) => {
  const [ isOpen, setIsOpen ] = useState(false);
  const { allFlags, set, restoreDefaults } = useContext(FlagContext);
  const [ isHidden, setIsHidden ] = useState(true);
  const [ query, setQuery ] = useState('');

  const showFeatureFlagToggler = allFlags[FLAG_TOGGLER];
  useEffect(() => {
    setIsHidden(!showFeatureFlagToggler);
  }, [ showFeatureFlagToggler ]);

  const flags = useMemo(() => Object.keys(allFlags).sort((a, b) => (a < b ? -1 : 1))
    .filter(key => key !== FLAG_TOGGLER)
    .map(key => {
      return { key, value: allFlags[key] };
    }), [ allFlags ]);

  const experimentalFlags = useMemo(
    () => flags.filter(flag => flag.key.startsWith('ab-') && flag.key.indexOf(query) > -1),
    [ flags, query ]);
  const featureFlags = useMemo(
    () => flags.filter(flag => !flag.key.startsWith('ab-') && flag.key.indexOf(query) > -1),
    [ flags, query ]);

  function closeFlagToggler () {
    setIsOpen(false);
    setQuery('');
  }

  useEffect(() => {
    function onOpenFlagToggler () {
      setIsOpen(true);
    }

    EventStream.on(EventStream.OPEN_FLAG_TOGGLER, onOpenFlagToggler);
    return () => {
      EventStream.off(EventStream.OPEN_FLAG_TOGGLER, onOpenFlagToggler);
    };
  }, []);

  function renderFlag ({ key, value }) {
    return (
      <FieldWrapper id={key} key={key} label={typeof value === 'boolean' ? key : undefined} className={styles.element}>
        <Flag label={key} value={value} set={set} />
      </FieldWrapper>
    );
  }

  if (isHidden) {
    return null;
  }
  return (
    <>
      {location === 'nav' && <div data-testid="flag-toggler"> <NavButton icon="flag" label="Feature flags" onClick={() => setIsOpen(true)} /> </div>}
      {location === 'doc-actions' && children}
      <Modal
        open={isOpen}
        header="Locally overwrite feature flags"
        onClose={closeFlagToggler}
        size="large"
        >
        <div className={styles.flagToggler}>
          <p>
            Here you can see a list of all feature flags and locally overwrite their values. All changes are local to your
            browser on your computer, and do not impact launch darkly at all. This modal is invisible on production, and
            can be hidden until refresh by pressing the primary button below.
          </p>
          <Input name="search-flags" placeholder="Search flags" value={query} onChange={setQuery} />
          <h2>Experiments</h2>
          <div className={styles.flags}>
            {experimentalFlags.map(flag => renderFlag(flag))}
          </div>
          <h2>Features</h2>
          <div className={styles.flags}>
            {featureFlags.map(flag => renderFlag(flag))}
          </div>
          <div className={styles.buttons}>
            <Button
              onClick={() => {
                setIsOpen(false);
                setQuery('');
                setIsHidden(true);
              }}
              iconName="eye-hide"
              buttonType="tertiary"
              >Hide me until refresh
            </Button>
            <Button onClick={restoreDefaults} iconName="refresh" buttonType="tertiary">Restore defaults</Button>
            <div className={styles.flex} />
            <Button buttonType="primary" onClick={closeFlagToggler}>Close window</Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export { FlagToggler };
