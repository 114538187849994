import { AllHTMLAttributes, ReactNode } from 'react';
import csx from 'classnames';
import Link from 'next/link';

import styles from './textLink.module.scss';

type NativeLinkProps = AllHTMLAttributes<HTMLHyperlinkElementUtils>

export type TextLinkProps = NativeLinkProps & {
  children: ReactNode,
  href?: string,
  className?: string,
  linkSize?: 'x-small' | 'small' | 'medium' | 'large',
  isExternalLink?: boolean,
}

export const TextLink = ({ href, children, className, linkSize, isExternalLink, ...props }: TextLinkProps) => {
  const link = (
    <a
      className={csx(styles.link, className, linkSize && styles[linkSize])}
      {... (isExternalLink && { href: href })}
      {...props}
      >
      {children}
    </a>
  );

  if (href && !isExternalLink) {
    return (
      <Link href={href}>
        {link}
      </Link>
    );
  }

  return (
    link
  );
};

