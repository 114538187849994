import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import csx from 'classnames';

import styles from './skeletonLoader.module.scss';

type SkeletonLoaderProps = {
  delay?: number,
  minDuration?: number,
  isLoading?: boolean,
  children: ReactNode,
}

const HIDE_DURATION = 400;

export const SkeletonLoader: FC<SkeletonLoaderProps> = ({ children, delay = 200, minDuration = 600, isLoading }) => {
  const timer = useRef<number | null>(null);
  const [ hide, setHide ] = useState<boolean>(false);
  const [ display, setDisplay ] = useState<string>('block');

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (isLoading && timer.current === null) {
      timer.current = new Date().valueOf();
      setHide(false);
      setDisplay('block');
    }
    if (!isLoading) {
      const now = new Date().valueOf();
      const runtime = now - (timer.current || now);
      // Data was loaded in less time than configured skeleton delay. Show no skeleton.
      if (runtime <= delay) {
        setDisplay('none');
      }
      // Data was loaded while skeleton was running, but before running for the minimum duration.
      else if (runtime > delay && runtime < (delay + minDuration)) {
        timeout = setTimeout(() => {
          setHide(true);
          timer.current = null;
        }, delay + minDuration - runtime);
      }
      // Data was loaded in longer time than delay+minDuration.
      else {
        setHide(true);
        timer.current = null;
      }
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [ isLoading, delay, minDuration ]);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (hide) {
      // Properly hide the skeleton after it has finished animating out.
      timeout = setTimeout(() => {
        setDisplay('none');
      }, HIDE_DURATION);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [ hide ]);

  return (
    <div
      data-testid="skeleton-loader"
      className={csx(styles.skeletonLoader, hide && styles.hide)}
      style={{ display: display }}
      >
      <div
        className={styles.skeletons}
        style={delay ? { animationDelay: `${delay}ms` } : undefined}
        >
        {children}
      </div>
    </div>
  );
};
