/**
 * @template {any[]} Args
 * @template ReturnType
 * @param {(...args: Args) => ReturnType} fx A function or a value that should be wrapped by a function
 * @param {ReturnType | null} [def=null] def A defult value to use when fx is nullish
 * @return {(...args: Args) => ReturnType}
 */
export default function functor (fx, def = null) {
  return typeof fx === 'function' ? fx : () => fx ?? def;
}

