import { paths, schemas } from './generatedTypes';
import { getJSON, postJSON } from './request';

type CheckoutRequest = paths['/subscriptions/chargebee/checkout/start']['post']['requestBody']['content']['application/json'];
type CheckoutResponse = paths['/subscriptions/chargebee/checkout/start']['post']['responses']['200']['content']['application/json'];

export type SubscriptionPlan = Exclude<schemas['Account']['subscription_plan'], undefined>;
export type SubscriptionStatus = Exclude<schemas['Account']['subscription_status'], undefined>;
export type PurchasablePlan = CheckoutRequest['subscription_plan'];
export type PlanCommitment = CheckoutRequest['plan_commitment'];

export function checkout ({ subscription_plan, plan_commitment }: CheckoutRequest) {
  const body = {
    subscription_plan,
    plan_commitment,
  };
  return postJSON<CheckoutResponse, CheckoutRequest>('/subscriptions/chargebee/checkout/start', body);
}

type SyncCheckoutRequest = paths['/subscriptions/chargebee/checkout/sync']['post']['requestBody']['content']['application/json'];
type SyncCheckoutResponse = paths['/subscriptions/chargebee/checkout/sync']['post']['responses']['200']['content']['application/json'];

export function sync_checkout (hosted_page_id: string) {
  return postJSON<SyncCheckoutResponse, SyncCheckoutRequest>('/subscriptions/chargebee/checkout/sync', { hosted_page_id });
}

type PortalResponse = paths['/subscriptions/chargebee/portal_session/create']['get']['responses']['200']['content']['application/json'];

export function portal () {
  return getJSON<PortalResponse>('/subscriptions/chargebee/portal_session/create');
}

export type UserAccountData = schemas['UserAccountData'];

export function getAccounts () {
  return getJSON<UserAccountData>('/user/accounts');
}

type SyncSubscriptionResponse = paths['/subscriptions/chargebee/sync']['post']['responses']['200']['content']['application/json'];

export function sync_subscription () {
  return postJSON<SyncSubscriptionResponse>('/subscriptions/chargebee/sync');
}

export function startTrial () {
  return postJSON('/subscriptions/trial');
}

export type GetPlanUsageResponse = paths['/account/usage/{from_date}/{to_date}']['get']['responses']['200']['content']['application/json'];

function getPlanUsage (fromDate: Date, toDate: Date): Promise<GetPlanUsageResponse> {
  // Convert the dates to ISO strings, without the time component.
  const fromDateOnly = fromDate.toISOString().split('T')[0];
  const toDateOnly = toDate.toISOString().split('T')[0];

  return getJSON<GetPlanUsageResponse>(`/account/usage/${fromDateOnly}/${toDateOnly}`);
}

export type SegnoConfiguration = paths['/integrations/segno']['get']['responses']['200']['content']['application/json'];

function getSegnoConfiguration (): Promise<SegnoConfiguration> {
  return getJSON<SegnoConfiguration>('/integrations/segno');
}

export class BillingService {
  getCancelFlowConfig = getSegnoConfiguration;
  getUsage = getPlanUsage;
}
