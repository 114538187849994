const hasOwnProperty = Object.prototype.hasOwnProperty;

// reads a case-insensitive property value from a table
// example use:
//    enumProp(model.readValue(props.foo), { 'bar': 1, 'baz': 2 }, 'bar');
// will emit 1 or 2, defaulting to 1 if key is not found
export default function enumProp (val, dict, fallback) {
  val = String(val || '').toLowerCase();
  if (hasOwnProperty.call(dict, val)) {
    return dict[val];
  }
  return fallback ? dict[fallback] : null;
}
