import { CSSProperties, useState } from 'react';
import csx from 'classnames';

import { DefaultAvatar } from './DefaultAvatar';
import { XMoreAvatar } from './XMoreAvatar';

import styles from './Avatar.module.scss';

function disc (diameter: number, isGroup?: boolean) {
  const styles = {
    borderRadius: '50%',
    width: diameter + 'px',
    height: diameter + 'px',
  };
  if (isGroup) {
    return {
      ...styles,
      borderRadius: '50% 24% 40% 50%',
    };
  }
  return styles;
}

export type AvatarProps = {
  className?: string,
  username?: string,
  avatarUrl?: string,
  name?: string,
  size?: number,
  style?: CSSProperties,
  isGroup?: boolean,
  isXMore?: boolean,
}

export const Avatar = ({ avatarUrl, className, size = 100, name, username, style = {}, isGroup = false, isXMore = false }: AvatarProps) => {
  const [ error, setError ] = useState(false);
  return (
    <span className={csx(styles.avatar, className)} style={{ ...disc(size, isGroup), ...style }} data-testid="avatar">
      {avatarUrl && !error &&
        <img
          src={avatarUrl}
          width={size * 2}
          alt={'Profile picture for ' + (name || username)}
          onError={() => setError(true)}
          />
      }
      {(!avatarUrl || error) && !isXMore && (
        <DefaultAvatar text={name || username || ''} numChars={isGroup ? 1 : 2} />
      )}
      {isXMore && (
        <XMoreAvatar count={username} />
      )}
    </span>
  );
};
