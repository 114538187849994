import { useCallback, useEffect, useState } from 'react';
import csx from 'classnames';
import { useRouter } from 'next/router';

import { getLoginUrl } from '@grid-is/browser-utils';
import { useDocumentIdFromRouter } from '@grid-is/custom-hooks';
import { Icon } from '@grid-is/icon';
import { tracking } from '@grid-is/tracking';

import { requestAccess } from '@/api/document';
import { Button } from '@/grid-ui/Button';
import { LoadingSpinner } from '@/grid-ui/LoadingSpinner';
import { BasePage } from '@/components/BasePage';
import { HomeLogo } from '@/components/Navigation/HomeLogo';
import { useAuth } from '@/utils/auth';

import styles from './error403.module.scss';

function getLoginUrlWithParams (autoRequestAccess?: boolean) {
  const redirectOpts = { queryParams: { auto_request_access: (!!autoRequestAccess).toString() } };
  const utmParams = { source: 'grid-doc', medium: 'referral', campaign: 'request-access' };
  return getLoginUrl(redirectOpts, utmParams);
}

function getLastRequested (documentId: string, userId: string) {
  const timestamp = localStorage.getItem(`grid-doc-access-request-${userId}-${documentId}`);
  return Number(timestamp) ?? 0;
}

function setLastRequested (documentId: string, userId: string) {
  const timestamp = Date.now();
  return localStorage.setItem(`grid-doc-access-request-${userId}-${documentId}`, timestamp.toString());
}

export const Error403 = props => {
  const documentId = useDocumentIdFromRouter();
  const { pathname, query, push } = useRouter();
  const { user, isLoggedIn } = useAuth();
  const userId = user?.id;
  const isShareBlockError = props.error && props.error.error_code && props.error.error_code === 'sharing_blocked';
  const isEmbed = pathname.startsWith('/embed');

  const [ posted, setPosted ] = useState(false); // Request was sent now to the platform.
  const [ requested, setRequested ] = useState(false); // Access was requested now, or some time in the last 5 minutes.

  const canRequest = useCallback(() => {
    return documentId && userId && (Date.now() - getLastRequested(documentId, userId)) > 1000 * 60 * 5;
  }, [ documentId, userId ]);

  const onRequestAccess = useCallback(() => {
    if (documentId && userId) {
      // To prevent spamming we only allow users to request access once every 5 minutes.
      if (canRequest()) {
        setPosted(true);
        setLastRequested(documentId, userId);
        tracking.logEvent('Document Access Requested', { document_id: documentId, required_authentication_prior_step: query?.auto_request_access === 'true' });
        requestAccess(documentId, 'view')
          .then(() => {
            setRequested(true);
          })
          .catch(() => {
            setRequested(true);
          });
      }
    }
  }, [ documentId, userId, canRequest, query ]);

  useEffect(() => {
    // Prevent spamming.
    if (!canRequest() && isLoggedIn) {
      setRequested(true);
    }
    // Redirect to login if logged out
    if (!isLoggedIn && !isEmbed) {
      const redirectUrl = getLoginUrlWithParams();
      window.location.href = redirectUrl;
    }
  }, [ isLoggedIn, canRequest, query, onRequestAccess, push, isEmbed ]);

  if (pathname === '/embed/[documentId]') {
    const redirectUrl = typeof window !== 'undefined' ? `${location.origin}/enable-embedding/${query.documentId}?onPageLoad=openEmbedModal` : '';
    return (
      <div className={csx(styles.noEmbedAccess, styles.canNotShare)}>
        <a href="https://calculatorstudio.co" target="_blank" rel="noreferrer"><Icon name="grid-full-color" size={40} /></a>
        <p>
          Embedding is not enabled for this project. If you're the author you can enable it in the {' '}
          <a href={redirectUrl} target="_blank" rel="noreferrer">embed menu</a>.
        </p>
      </div>
    );
  }

  if (pathname.startsWith('/embed-notion/') && isShareBlockError) {
    return (
      <div className={csx(styles.noEmbedAccess, styles.canNotShare)}>
        <a href="https://calculatorstudio.co" target="_blank" rel="noreferrer"><Icon name="grid-full-color" size={40} /></a>
        <p>
          Continue using Calculator Studio in Notion by <a href="/settings/billing" target="_blank" rel="noreferrer">upgrading</a> to one of our paid subscriptions.
        </p>
      </div>
    );
  }

  // XXX: There is no guarantee that the user was trying to view a document. This page is shown for all 403 errors.
  if (isLoggedIn) {
    return (
      <BasePage layout="full" title="Private document" hideMenus hideNavigation>
        <div className={styles.requestAccess} data-testid="error-component-403">
          <HomeLogo size={80} />
          <h1>This project is private</h1>
          {!(posted || requested) && <p>You can request access to this project.</p>}
          <div className={styles.actions}>
            {!requested && <Button buttonType="primary" buttonSize="large" onClick={onRequestAccess}>Request access</Button>}
            {(requested || posted) &&
              <div className={styles.requested}>
                <h2><div className={posted ? styles.popIn : undefined}><Icon name="check-circle" size={24} variant="solid" /></div>Access requested</h2>
                <p>You will receive an email when the project owner has granted you access.</p>
              </div>}
            <a data-testid="home-button" href={`/@${user?.username}`}><Button buttonType={requested ? 'primary' : 'tertiary'}>Go to Home</Button></a>
            {posted && <div className={styles.requesting}><LoadingSpinner /></div>}
          </div>
        </div>
      </BasePage>
    );
  }

  return null;
};
