import { ReactNode } from 'react';
import csx from 'classnames';
import Link from 'next/link';

import { Icon, IconTypes } from '@grid-is/icon';

import { User } from '@/api/user';
import { Avatar } from '@/grid-ui/Avatar';
import { getTimeSinceDate } from '@/utils/time';

import { GridNotification } from './types';
import { getCommentText, getMilestoneReachedText } from './utils';

import styles from './notifications.module.scss';

type NotificationContent = {
  avatar: ReactNode,
  text: ReactNode,
  title: string,
}

const getBasicAvatar = (user: User, className: string, iconName: IconTypes) => {
  return (
    <>
      <Avatar
        name={user.name}
        username={user.username}
        avatarUrl={user.avatar_url}
        size={40}
        />
      <div className={csx(styles.badge, className)}><Icon name={iconName} size={16} className={styles.icon} variant="solid" /></div>
    </>
  );
};

const getNotificationContents = (notification: GridNotification): NotificationContent => {
  switch (notification.type) {
    case 'commented_on_document':
    case 'replied_to_your_comment':
    case 'replied_to_a_comment_on_your_document': {
      const { text, title } = getCommentText(notification);

      return {
        avatar: (
          getBasicAvatar(notification.details.user, styles.comment, 'chat-bubble')
        ),
        text,
        title,
      };
    }
    case 'document_milestone_reached': {
      const { text, title } = getMilestoneReachedText(notification);
      return {
        avatar: (
          <>
            <Icon name="document-milestone" size={40} />
            <div className={csx(styles.badge, styles.documentMilestoneReached)}><Icon
              name="grid-mono"
              size={16}
              className={styles.icon}
              />
            </div>
          </>
        ),
        text,
        title,
      };
    }
    case 'document_shared_with_you': {
      const documentTitle = notification.details.document.title || 'Untitled';
      return {
        avatar: (
          getBasicAvatar(notification.details.user, styles.sharedDocument, 'envelope')
        ),
        text: (
          <>
            <strong>{notification.details.user.name}</strong> just shared <strong>{documentTitle}</strong> with you.
          </>
        ),
        title: `${notification.details.user.name} just shared ${documentTitle} with you.`,
      };
    }
    case 'document_shared_with_group': {
      const documentTitle = notification.details.document.title || 'Untitled';
      return {
        avatar: (getBasicAvatar(notification.details.user, styles.sharedDocument, 'envelope')),
        text: (<><strong>{notification.details.user.name}</strong> just shared <strong>{documentTitle}</strong> with <strong>{notification.details.group.name}</strong></>),
        title: `${notification.details.user.name} just shared ${documentTitle} with ${notification.details.group.name}`,
      };
    }
    case 'you_were_granted_edit_access': {
      const documentTitle = notification.details.document.title || 'Untitled';
      return {
        avatar: (
          getBasicAvatar(notification.details.user, styles.sharedDocument, 'envelope')
        ),
        text: (
          <>
            <strong>{notification.details.user.name}</strong> granted you edit access to the document <strong>{documentTitle}</strong>.
          </>
        ),
        title: `${notification.details.user.name} granted you edit access to the document ${documentTitle}.`,
      };
    }
    case 'group_granted_edit_access': {
      const documentTitle = notification.details.document.title || 'Untitled';
      return {
        avatar: (getBasicAvatar(notification.details.user, styles.sharedDocument, 'envelope')),
        text: (<><strong>{notification.details.user.name}</strong> granted <strong>{notification.details.group.name}</strong> edit access to <strong>{documentTitle}</strong>.</>),
        title: `${notification.details.user.name} granted ${notification.details.group.name} edit access to ${documentTitle}.`,
      };
    }
    case 'document_shared_with_domain': {
      const documentTitle = notification.details.document.title || 'Untitled';
      return {
        avatar: (getBasicAvatar(notification.details.user, styles.sharedDocument, 'envelope')),
        text: (<><strong>{notification.details.user.name}</strong> just shared <strong>{documentTitle}</strong> with your team <strong>{notification.details.domain}</strong></>),
        title: `${notification.details.user.name} just shared ${documentTitle} with @${notification.details.domain}`,
      };
    }
    case 'document_liked': {
      const documentTitle = notification.details.document.title || 'Untitled';
      return {
        avatar: (
          <>
            <Avatar
              name={notification.details.user.name}
              username={notification.details.user.username}
              avatarUrl={notification.details.user.avatar_url}
              size={40}
              />
            <div className={csx(styles.badge, styles.likedDocument)}>
              <Icon name="heart" size={16} className={styles.icon} variant="solid" />
            </div>
          </>
        ),
        text: (
          <><strong>{notification.details.user.name}</strong> liked your
            document <strong>{documentTitle}</strong>
          </>
        ),
        title: `${notification.details.user.name} liked your document: ${documentTitle}`,
      };
    }
    case 'user_added_to_group': {
      return {
        avatar: getBasicAvatar(notification.details.user_added, styles.groups, 'users'),
        text: (<><strong>{notification.details.user_added.name}</strong> was added to the group <strong>{notification.details.group.name}</strong></>),
        title: `${notification.details.user_added.name} was added to the group ${notification.details.group.name}`,
      };
    }
    case 'email_invited_to_group': {
      return {
        avatar: (
          <>
            <Icon name="users" size={40} />
            <div className={csx(styles.badge, styles.groups)}><Icon
              name="grid-mono"
              size={16}
              className={styles.icon}
              />
            </div>
          </>
        ),
        text: (<><strong>{notification.details.invited_email}</strong> was invited to the group <strong>{notification.details.group.name}</strong></>),
        title: `${notification.details.invited_email} was invited to the group ${notification.details.group.name}`,
      };
    }
    case 'you_were_added_to_group': {
      return {
        avatar: getBasicAvatar(notification.details.user, styles.groups, 'users'),
        text: (<><strong>{notification.details.user.name}</strong> just added you to their group <strong>{notification.details.group.name}</strong></>),
        title: `${notification.details.user.name} just added you to their group ${notification.details.group.name}`,
      };
    }
    default: {
      return {
        avatar: null,
        text: null,
        title: '',
      };
    }
  }
};

export type Props = {
  notification: GridNotification,
  onClick: (notification: GridNotification, isMetaKey: boolean) => void,
  url?: string,
}

function NotificationItem ({ notification, onClick, url = '' }: Props) {
  const contents = getNotificationContents(notification);
  return (
    <Link href={url}>
      <a
        data-testid="notificationItem"
        href={url}
        className={csx(styles.item, notification.state === 'read' && styles.read)}
        onClick={e => onClick(notification, e.metaKey)}
        >
        <div className={styles.avatar}>
          {contents.avatar}
        </div>
        <div className={styles.contents} title={contents.title}>
          <div className={styles.main}>
            {contents.text}
          </div>
          <div className={styles.date}>{getTimeSinceDate(notification.created)}</div>
        </div>
        <div className={styles.dotContainer}>{notification.state !== 'read' && <span className={styles.dot} data-testid="dot" />}</div>
      </a>
    </Link>
  );
}

export { NotificationItem };
