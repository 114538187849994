import React, { useCallback, useEffect, useState } from 'react';
import * as Sentry from '@sentry/nextjs';
import { useRouter } from 'next/router';

import { token } from '@grid-is/browser-utils';

import { getMyUser, UserType } from '@/api/user';
import { LIMITED_PERMISSIONS } from '@/constants';

import { PrivacyForm } from './PrivacyForm';

type LegalBoundaryProps = {
  children: React.ReactNode,
};

export const LegalBoundary = ({ children }: LegalBoundaryProps) => {
  const router = useRouter();

  const [ user, setUser ] = useState<UserType | undefined>(undefined);

  const isLoggedIn = user !== undefined && !!user.username;

  const fetchMyUser = useCallback(() => {
    if (token.get()) {
      getMyUser()
        .then(d => {
          setUser(d);
        })
        .catch(e => {
          Sentry.captureException(e);
        });
    }
  }, [ ]);

  useEffect(() => {
    fetchMyUser();
  }, [ fetchMyUser ]);

  // Wrapping children in fragment so the component return type is JSX.Element instead of ReactNode
  // eslint-disable-next-line react/jsx-no-useless-fragment
  const childrenElement = <>{children}</>;

  if (!isLoggedIn) {
    // Do not show the legal boundary for anonymous users
    return childrenElement;
  }
  else if ([ '/embed/[documentId]', '/user/signup/[signup_id]' ].includes(router.pathname)) {
    // The following routes should not display the privacy policy
    // 1. embeds: do not display the privacy page for logged in users, when viewing embeds
    // 2. signup page: the user is not ready
    return childrenElement;
  }
  else if (user?.agreements?.some(({ name }) => name === LIMITED_PERMISSIONS)) {
    // if the user has already accepted the limited permissions
    return childrenElement;
  }

  return <PrivacyForm onSubmit={fetchMyUser} />;
};
