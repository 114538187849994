import { ReactNode, useEffect, useState } from 'react';
import csx from 'classnames';

import { startTrial } from '@/api/billing';
import { Button } from '@/grid-ui/Button';
import { LoadingSpinner } from '@/grid-ui/LoadingSpinner';
import { Modal } from '@/grid-ui/Modal';
import { TextLink } from '@/grid-ui/TextLink';
import { VerifyEmail } from '@/components/Settings/Billing/VerifyEmail';
import { useAuth } from '@/utils/auth';

import { PayWallFeature } from '../../BillingContext';
import { ModalProps } from './types';

import styles from './TrialModal.module.scss';

type ModalErrorCode = 'user_has_existing_subscription' | 'user_has_already_claimed_trial' | 'email_verification_required' |'error'

type ModalState = PayWallFeature | 'success' | 'autostart' | ModalErrorCode;

type ModalText = {
  header: string,
  description: ReactNode,
  confirmButton: ReactNode,
  confirmFooter?: ReactNode,
  cancelButton?: ReactNode,
}

const modalTextByState: Record<ModalState, ModalText> = {
  sharing: {
    header: 'Share your work',
    description: (
      <div className={styles.description}>
        <span>Sharing starts your 7 day free trial where you'll also get the chance to try other exclusive features 🥳</span>
        <span className={styles.linkToPricingPlans}>
          <TextLink target="_blank" href="/settings/billing">Learn more</TextLink>
        </span>
      </div>
    ),
    confirmButton: 'Continue to sharing',
  },
  embed: {
    header: 'Embed your work',
    description: (
      <div className={styles.description}>
        <span>Embedding starts your 7 day free trial where you'll also get the chance to try other exclusive features 🥳</span>
        <span className={styles.linkToPricingPlans}>
          <TextLink target="_blank" href="/settings/billing">Learn more</TextLink>
        </span>
      </div>
    ),
    confirmButton: 'Continue to embedding',
  },
  success: {
    header: 'Welcome to your trial 👋',
    description: (
      <div className={styles.description}>
        <span>You've just unlocked 7 days of free sharing, embedding and other exclusive features! </span>
        <span className={styles.linkToPricingPlans}>
          <TextLink target="_blank" href="/settings/billing">Learn more</TextLink>
        </span>
      </div>
    ),
    confirmButton: 'Ok, got it',
  },
  error: {
    header: 'Failed to start trial',
    description: <span>Sorry, something went wrong. Please try again, or if the issue persists, contact us at <TextLink href="mailto:support@calculatorstudio.co">support@calculatorstudio.co</TextLink>.</span>,
    confirmButton: 'Ok, got it',
  },
  user_has_existing_subscription: {
    header: 'Failed to start trial',
    description: <span>You already have an existing subscription. If you have questions or need assistance, please contact support at <TextLink href="mailto:support@calculatorstudio.co">support@calculatorstudio.co</TextLink>.</span>,
    confirmButton: 'Ok, got it',
  },
  user_has_already_claimed_trial: {
    header: 'Failed to start trial',
    description: <span>You have already started the trial. If you believe this is an error, please contact support at <TextLink href="mailto:support@calculatorstudio.co">support@calculatorstudio.co</TextLink>.</span>,
    confirmButton: 'Ok, got it',
  },
  email_verification_required: {
    header: 'Email verification required',
    description: <VerifyEmail />,
    confirmButton: 'Ok, got it',
  },
  autostart: {
    header: '',
    description: '',
    confirmButton: '',
  },
};

// Declared outside to be able to refernce the success state.
modalTextByState.autostart = {
  ...modalTextByState.success,
  header: '',
};

export const TrialModal =  ({ payWallFeature, onSuccess = () => {}, onCancel = () => {}, autoStartTrial }: ModalProps) => {
  const [ isLoading, setIsLoading ] = useState(false);
  const { mutate } = useAuth();
  const [ modalState, setModalState ] = useState<ModalState>(autoStartTrial ? 'autostart' : payWallFeature);

  function requestToStartTrial () {
    setIsLoading(true);
    startTrial()
      .then(() => {
        setIsLoading(false);
        setModalState('success');
      })
      .catch(e => {
        setIsLoading(false);
        let error: ModalErrorCode = 'error';
        if (
          e?.error_code === 'user_has_existing_subscription' ||
          e?.error_code === 'user_has_already_claimed_trial' ||
          e?.error_code === 'email_verification_required'
        ) {
          error = e.error_code;
        }
        setModalState(error);
      });
  }

  function onConfirmButtonClicked () {
    if (modalState === 'success') {
      mutate();
      onSuccess();
    }
    if (modalState === 'sharing' || modalState === 'embed') {
      requestToStartTrial();
    }
    else {
      onCancel();
    }
  }

  useEffect(() => {
    if (modalState === 'autostart') {
      requestToStartTrial();
    }
  }, [ modalState ]);

  const modalText = modalTextByState[modalState];

  return (
    <Modal
      open
      header={modalText.header}
      size="small"
      closeButton
      onClose={onCancel}
      >
      <section className={styles.modal}>
        <div className={styles.description}>
          <span>{modalText.description}</span>
        </div>
        <div className={csx(styles.buttonGroup, modalText.cancelButton && styles.big)}>
          <Button buttonSize="large" buttonType="primary" onClick={onConfirmButtonClicked}>{modalText.confirmButton}</Button>
          {modalText.confirmFooter && <span className={styles.confirmMessage}>{modalText.confirmFooter}</span>}
          {modalText.cancelButton && <Button buttonType="tertiary" buttonSize="medium-large" onClick={() => onCancel()}>{modalText.cancelButton}</Button>}
        </div>
        {isLoading && <div className={csx(styles.loading, modalState === 'autostart' && styles.hideBackdrop)}><LoadingSpinner centered /></div>}
      </section>
    </Modal>
  );
};
