import { useEffect, useRef } from 'react';

export function usePollingEffect (
  asyncCallback: () => Promise<boolean>,
  dependencies: string[] = [],
  interval = 1000,
) {
  const timeoutIdRef = useRef<ReturnType<typeof setTimeout> | undefined>();
  useEffect(() => {
    let _keepPolling = true;
    (async function pollingCallback () {
      try {
        // eslint-disable-next-line require-atomic-updates
        _keepPolling = await asyncCallback();
      }
      finally {
        if (_keepPolling) {
          timeoutIdRef.current = setTimeout(
            pollingCallback,
            interval,
          );
        }
      }
    })();

    return () => {
      _keepPolling = false;
      clearTimeout(timeoutIdRef.current);
    };
  // NOTE: lint is not smart enough to know what we're doing here.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ ...dependencies, interval ]);
}
