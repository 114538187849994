import { IconBase } from '../../types';

export const Display: IconBase = {
  // Name of icon.
  name: 'display',
  // Paths, we only use outline and solid.
  outline: '<path d="M24.9928 30H10.9972C10.4474 30 9.9975 30.45 9.9975 31C9.9975 31.55 10.4474 32 10.9972 32H24.9928C25.5426 32 25.9925 31.55 25.9925 31C25.9925 30.45 25.5426 30 24.9928 30ZM7.59825 9.6H28.1218L29.9313 8H5.99875V24H7.59825V9.6ZM32.4905 4H3.49953C2.66979 4 2 4.67 2 5.5V26.5C2 27.33 2.66979 28 3.49953 28H11.627H13.8063H22.1937H24.373H32.5005C33.3302 28 34 27.33 34 26.5V5.5C34 4.67 33.3302 4 32.5005 4H32.4905ZM31.9906 26H3.99938V6H31.9906V26Z"/>',
  solid: '<path d="M24.9928 30H10.9972C10.4474 30 9.9975 30.45 9.9975 31C9.9975 31.55 10.4474 32 10.9972 32H24.9928C25.5426 32 25.9925 31.55 25.9925 31C25.9925 30.45 25.5426 30 24.9928 30ZM32.4905 4H3.49953C2.66979 4 2 4.67 2 5.5V26.5C2 27.33 2.66979 28 3.49953 28H11.627H13.8063H22.1937H24.373H32.5005C33.3302 28 34 27.33 34 26.5V5.5C34 4.67 33.3302 4 32.5005 4H32.4905ZM29.9913 24H5.99875V8H29.9913V24Z"/>',
};

