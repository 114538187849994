import csx from 'classnames';

import { Badge, Direction, IconStatus, IconTypes } from './types';

import styles from './iconBuilder.module.scss';

type BadgeDotProps = {
  badge: Badge,
};

const BadgeDot = ({ badge }: BadgeDotProps) => {
  if (badge === 'check') {
    return (
      <>
        <g>
          <path
            className={csx(styles.badge, styles[badge])}
            d="M20.5001 28C20.5001 23.8645 23.8646 20.5 28.0001 20.5C32.1356 20.5 35.5001 23.8645 35.5001 28C35.5001 32.1355 32.1356 35.5 28.0001 35.5C23.8646 35.5 20.5001 32.1355 20.5001 28Z"
            />
          <path
            stroke="white"
            strokeWidth="0.75"
            d="M26.7128 31.15L23.5724 28.1968C23.4701 28.0843 23.477 27.9167 23.5882 27.812C23.6994 27.7073 23.8775 27.7008 23.9969 27.7971L26.7068 30.348L32.0031 25.3682C32.1225 25.2719 32.3006 25.2784 32.4118 25.3831C32.523 25.4878 32.5299 25.6554 32.4276 25.7678L26.7128 31.15Z"
            />
        </g>
        <defs>
          <mask id={badge} x="0" y="0" width="1" height="1">
            <rect x="0" y="0" width="36" height="36" fill="white" />
            <circle cx="27.5" cy="27.5" r="9.5" fill="black" />
          </mask>
        </defs>
      </>
    );
  }
  else {
    return (
      <>
        <circle cx="30" cy="6" r="5" className={csx(styles.badge, styles[badge])} />
        <defs>
          <mask id={badge} x="0" y="0" width="1" height="1">
            <rect x="0" y="0" width="36" height="36" fill="white" />
            <circle cx="30" cy="6" r="7.5" fill="black" />
          </mask>
        </defs>
      </>
    );
  }
};

type IconBuilderProps = {
  name: IconTypes,
  path: string,
  className?: string,
  title?: string,
  size?: number,
  direction?: Direction,
  badge?: Badge,
  status?: IconStatus,
}

export const IconBuilder = ({ name, path, badge, className, size = 40, title, direction, status }: IconBuilderProps) => {
  return (
    <div className={csx(styles.icon, className, direction && styles[direction])} aria-label={title || `icon-${name}`} data-testid={`icon-${name}`}>
      <svg className={status && styles[status]} width={size} height={size} viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
        {title && <title>{title}</title>}
        <g dangerouslySetInnerHTML={{ __html: path }} mask={badge && `url(#${badge})`} />
        {badge && <BadgeDot badge={badge} />}
      </svg>
    </div>
  );
};
