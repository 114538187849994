/* globals LAUNCHDARKLY_CLIENT_ID_PROD LAUNCHDARKLY_CLIENT_ID_DEV WITH_MOCKER */
import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { initialize, LDClientBase, LDOptions, LDUser } from 'launchdarkly-js-client-sdk';
import { NextPage } from 'next';

import { isBrowser, localStorage } from '@grid-is/browser-utils';
import { tracking } from '@grid-is/tracking';

import { getBootstrappedFlags } from '@/api/flags';
import { isProdEnvironment } from '@/api/request';
import { AuthEvents, UserType } from '@/api/user';

type BootstrapData = { user: LDUser, state: object };
export type FlagValue = boolean | number | string | Record<string, any>;

type ProviderProps = {
  disabled?: boolean,
  bootstrappedFlags?: BootstrapData,
  children?: ReactNode,
}

export interface FlagContextValue {
  allFlags: Record<string, FlagValue>,
  can: (flag: string) => boolean,
  get: (flag: string) => FlagValue,
  set: (flag: string, value: FlagValue) => void,
  restoreDefaults: () => void,
}

export const FlagContext = createContext<FlagContextValue>({
  allFlags: {},
  can: () => false,
  get: () => false,
  set: () => {},
  restoreDefaults: () => {},
});
const { Consumer, Provider } = FlagContext;

let ldClient: null | LDClientBase = null;

/**
 * This is only intended for tests.
 * XXX: Remove this function, use DI instead to ask for a FlagClient, create a test-double
*/
export function resetLDClient () {
  ldClient = null;
}

function getClientID () {
  return isProdEnvironment() ? LAUNCHDARKLY_CLIENT_ID_PROD : LAUNCHDARKLY_CLIENT_ID_DEV;
}

function trackUserFlags (currentUser: LDUser) {
  if (ldClient) {
    const userId = currentUser.key;
    if (userId != null && userId !== 'anonymous') {
      const flags = ldClient.allFlags();
      tracking.setFeatureFlags(userId, flags);
    }
  }
}

// This is the feature flag key for showing this component
// XXX: ...except it is not! It is the feature flag for showing a different component. 🙃
export const FLAG_TOGGLER = 'flag-toggler';
const LS_KEY = 'GRID_FLAGS';

export const FlagProvider = ({ disabled, bootstrappedFlags, children }: ProviderProps) => {
  const [ allFlags, setAllFlags ] = useState<Record<string, FlagValue>>({});
  // XXX: this component shouldn't be aware of the mocker. Try to remove.
  const isDisabled = !!(WITH_MOCKER || disabled);

  // XXX: this provider should expose save/load mechanism and respect persisted flags, but should be unaware of the toggler
  const isUsingFlagToggler = (): boolean => {
    return allFlags[FLAG_TOGGLER] === true;
  };

  const setLocalOverrides = useCallback(() => {
    const flags = ldClient ? ldClient.allFlags() : {};
    if (flags[FLAG_TOGGLER]) {
      const flagsFromStorage = localStorage.getItem(LS_KEY);
      const localFlags = flagsFromStorage ? JSON.parse(flagsFromStorage) : {};
      Object.assign(flags, localFlags);
    }
    setAllFlags(flags);
  }, [ setAllFlags ]);

  const initializeLaunchDarklyClient = useCallback(({ state, user }: BootstrapData) => {
    const options: LDOptions = { bootstrap: state, sendEventsOnlyForVariation: true };
    ldClient = initialize(getClientID(), user, options);
    trackUserFlags(user);
    setLocalOverrides();
  }, [ setLocalOverrides ]);

  const get = (flag: string): FlagValue => {
    let result: FlagValue;
    if (isDisabled || !ldClient) {
      result = false;
    }
    else if (isUsingFlagToggler()) {
      result = allFlags[flag] ?? false;
    }
    else {
      result = ldClient.variation(flag, false);
    }
    return result;
  };

  const can = (flag: string): boolean => {
    return get(flag) === true;
  };

  const restoreDefaults = () => {
    if (!isUsingFlagToggler()) {
      return;
    }
    localStorage.removeItem(LS_KEY);
    const originalFlags = ldClient ? ldClient.allFlags() : {};
    setAllFlags(originalFlags);
  };

  const set = (flag: string, value: FlagValue): void => {
    if (!isUsingFlagToggler()) {
      return;
    }
    const flagsFromStorage = localStorage.getItem(LS_KEY);
    const localFlags = flagsFromStorage ? JSON.parse(flagsFromStorage) : {};
    const currentFlags = allFlags;
    localFlags[flag] = value;
    localStorage.setItem(LS_KEY, JSON.stringify(localFlags));
    setAllFlags(Object.assign({}, currentFlags, localFlags));
  };

  const reinitializeIfUserChanged = useCallback((newUserKey: string) => {
    if (ldClient) {
      const currentContext = ldClient.getContext();
      if (currentContext && currentContext.key !== newUserKey) {
        // XXX: here we could call ldClient.identify instead, but this is simpler
        const promise = getBootstrappedFlags().then(initializeLaunchDarklyClient);
        if (newUserKey === 'anonymous') {
          // Swallow error from flags request when logging out, else the user
          // may briefly see sheet hitting the fan before navigating away.
          promise.catch(console.warn);
        }
      }
    }
  }, [ initializeLaunchDarklyClient ]);

  useEffect(() => {
    const handleLogin = (user: UserType) => {
      reinitializeIfUserChanged(user.id);
    };

    const handleLogout = () => {
      reinitializeIfUserChanged('anonymous');
    };
    AuthEvents.on(AuthEvents.LOG_IN, handleLogin);
    AuthEvents.on(AuthEvents.LOG_OUT, handleLogout);
    return () => {
      AuthEvents.off(AuthEvents.LOG_IN, handleLogin);
      AuthEvents.off(AuthEvents.LOG_OUT, handleLogout);
    };
  }, [ reinitializeIfUserChanged ]);

  if (ldClient == null && isBrowser() && !isDisabled && bootstrappedFlags) {
    initializeLaunchDarklyClient(bootstrappedFlags);
  }

  return <Provider value={{ allFlags: allFlags, can, get, set, restoreDefaults }}>{children}</Provider>;
};

export function withFlagProvider (WrappedComponent, disabled?: boolean) {
  return <FlagProvider disabled={disabled}>{WrappedComponent}</FlagProvider>;
}

// The use of the NextPage type may seem odd, but it's the recommended type for components that may have a getInitialProps method
// See: https://nextjs.org/docs/api-reference/data-fetching/get-initial-props#typescript
export function withFlags<T extends {flags: FlagContextValue} & Record<string, any>> (WrappedComponent: NextPage<T>) {
  type P = Omit<T, 'flags'>;
  const InnerComponent: NextPage<P> = (props: P) => {
    return (
      <Consumer>
        {
          // @ts-expect-error
          d => <WrappedComponent flags={d} {...props} />
        }
      </Consumer>
    );
  };
  InnerComponent.getInitialProps = WrappedComponent.getInitialProps;

  return InnerComponent;
}

export const useFlags = () => useContext(FlagContext);
