import { IconBase } from '../../types';

export const ElementWaterfall: IconBase = {
  name: 'element-waterfall',
  outline: '<path fill-rule="evenodd" d="' +
    'M32 5H4C3.46957 5 2.96086 5.21071 2.58579 5.58579C2.21071 5.96086 2 6.46957 2 7V29C2 29.5304 2.21071 30.0391 2.58579 30.4142C2.96086 30.7893 3.46957 31 4 31H32C32.5304 31 33.0391 30.7893 33.4142 30.4142C33.7893 30.0391 34 29.5304 34 29V7C34 6.46957 33.7893 5.96086 33.4142 5.58579C33.0391 5.21071 32.5304 5 32 5V5ZM4 29V7H32V29H4Z' +
    'M22.95 9.8999H29.25V26.0999H27.57V11.6466H24.63V26.0999H22.95V9.8999Z' +
    'M6.75 16.1999H13.05V26.0999H11.37V17.9999H8.43V26.0999H6.75V16.1999Z' +
    'M19.53 13.4999H16.47V18.8999H19.53V13.4999ZM14.85 11.6999V20.6999H21.15V11.6999H14.85Z' +
    '" fill="currentColor" />',
};

