import {
  forwardRef,
  Ref,
  useRef,
} from 'react';
import csx from 'classnames';

import { useMergeRefs } from '@grid-is/custom-hooks';

import { InputIcon } from './InputIcon';
import { InputComponentProps, InputProps } from './types';

import styles from './Input.module.scss';

const InputHOC = forwardRef(
  (
    props: Omit<InputComponentProps, 'size' | 'rows'>,
    ref: Ref<HTMLInputElement>,
  ) => <input ref={ref} {...props} />,
);
const TextareaHOC = forwardRef(
  (props: InputComponentProps, ref: Ref<HTMLTextAreaElement>) => (
    <textarea ref={ref} {...props} />
  ),
);

const Input = forwardRef(({
  id,
  size = 'large',
  className,
  disabled,
  required,
  hasError,
  search = false,
  transparent = true,
  placeholder,
  value,
  onChange = () => {},
  onClick = () => {},
  onKeyDown = () => {},
  onBlur = () => {},
  onFocus = () => {},
  onPaste = e => e,
  type = 'text',
  icon,
  iconBefore,
  onIconBeforeClick,
  onIconClick,
  iconDisabled,
  iconBeforeDisabled,
  ariaDescribedBy,
  ariaLabelledBy,
  name,
  autoComplete = 'on',
  spellCheck = 'true',
  autoCorrect = 'on',
  autoCapitalize = 'on',
  autoFocus = false,
  textarea = false,
  readOnly,
  rows,
  testId,
  maxLength,
}: InputProps, ref: Ref<HTMLInputElement | HTMLTextAreaElement>) => {
  const InputComponent = textarea ? TextareaHOC : InputHOC;
  const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null);
  const mergedRefs = useMergeRefs(inputRef, ref || null);
  return (
    <div
      className={csx(
        styles.inputWrapper,
        styles[size],
        disabled && styles.disabled,
        hasError && styles.error,
        icon && styles.afterIcon,
        iconBefore && styles.beforeIcon,
        textarea && styles.textarea,
        !transparent && styles.withBackground,
        className,
        search && styles.search,
      )}
      >
      {iconBefore && <InputIcon onClick={onIconBeforeClick} disabled={iconBeforeDisabled} size={size} iconPlacement="left">{iconBefore}</InputIcon>}
      <InputComponent
        id={id}
        className={csx(
          styles.input,
          textarea && styles.textarea,
          styles[size],
        )}
        ref={mergedRefs}
        value={value}
        onChange={e => onChange(e.target.value, e)}
        onBlur={e => onBlur(e.target.value, e)}
        onFocus={e => onFocus(e.target.value, e)}
        onClick={onClick}
        onPaste={onPaste}
        onKeyDown={onKeyDown}
        name={name}
        disabled={disabled}
        required={required}
        placeholder={placeholder}
        type={type}
        autoComplete={autoComplete}
        autoCorrect={autoCorrect}
        autoCapitalize={autoCapitalize}
        autoFocus={autoFocus}
        spellCheck={spellCheck}
        readOnly={readOnly}
        rows={rows}
        data-testid={testId}
        data-obid={testId}
        maxLength={maxLength}
        {...(ariaDescribedBy && { 'aria-describedby': ariaDescribedBy })}
        {...(ariaLabelledBy && { 'aria-labelledby': ariaLabelledBy })}
        />
      {icon && <InputIcon onClick={onIconClick} disabled={iconDisabled} size={size} iconPlacement="right">{icon}</InputIcon>}
    </div>
  );
});

export { Input };
