import styles from './error403.module.scss';

export function ErrorDomainAccess () {
  const domain = document && document.referrer;

  return (
    <div className={styles.noEmbedAccess}>
      <div>
        <p>
          Sorry, the project you are trying to view isn't accessible from {domain ? <strong>{domain}</strong> : 'this domain'}. If you are the project author you can change this in the sharing settings.
        </p>
        {/* TODO: Add button with link to help article when ready. */}
        {/* <Button primary href="https://grid.is" target="_blank">Learn more about domain access</Button> */}
      </div>
    </div>
  );
}
