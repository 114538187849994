import { customAlphabet } from 'nanoid';
/*
 * We're using 10 chars and a 62 letter alphabet to keep the strings looking neat.
 * The IDs will be exposed in query strings and possibly also shown and used in the UI
 * so what they look like matters! Examples: [ "eciWaCD5sc", "15MT34hcSj" ]
 *
 * We generate only a small number of IDs, and they only need to be unique in the
 * document, not globally. The 62𐄂10 space gives us 1% probability of a collsion
 * after ~15 years at the rate of a 1000 IDs generated per hour.
 */
export default customAlphabet('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz', 10);
