import { ReactNode } from 'react';
import csx from 'classnames';

import { Icon } from '@grid-is/icon';

import styles from './HelperText.module.scss';

type Props = {
  id?: string,
  children: ReactNode,
  disabled?: boolean,
  hasError?: boolean,
}

export const HelperText = ({ id, children, disabled, hasError }: Props) => {
  return (
    <span
      className={csx(
        styles.helperText,
        disabled && styles.disabled,
        hasError && styles.error,
      )}
      id={id}
      >
      {hasError && <Icon className={styles.helperIcon} size={16} name="exclamation-triangle" variant="solid" />}
      {children}
    </span>
  );
};
