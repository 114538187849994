import { useCallback, useEffect, useRef, useState } from 'react';
import csx from 'classnames';

import styles from './dancingSliders.module.scss';

type DancingSlidersProps = {
  size?: number,
  iterationCount?: number | 'infinite',
  shouldAnimate?: boolean,
  onAnimationComplete?: () => void,
}

export const DancingSliders = ({ size = 400, iterationCount = 'infinite', shouldAnimate = true, onAnimationComplete = () => {} }: DancingSlidersProps) => {
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [ animationStartTime, setAnimationStartTime ] = useState<number>();

  const stopAnimation = useCallback(() => {
    setAnimationStartTime(undefined);
    onAnimationComplete();
  }, [ onAnimationComplete ]);

  useEffect(() => {
    return () => {
      timeoutRef.current && clearTimeout(timeoutRef.current);
    };
  }, []);

  useEffect(() => {
    // Start the animation.
    if (!animationStartTime) {
      setAnimationStartTime(Date.now());
      // Stop the animation after n cycles if provided.
      if (iterationCount !== 'infinite') {
        timeoutRef.current && clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(stopAnimation, 2000 * iterationCount);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ ]);

  useEffect(() => {
    // Stop the animation when the current animation cycle has completed.
    if (!shouldAnimate && animationStartTime) {
      timeoutRef.current && clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(stopAnimation, (2000 - (Date.now() - animationStartTime) % 2000));
    }
  }, [ shouldAnimate, animationStartTime, stopAnimation ]);

  return (
    <div className={csx(styles.dancingSliders, animationStartTime && styles.shouldAnimate)} style={{ transform: `scale(${size / 400})` }}>
      <section><div className={styles.wheel} /></section>
      <section><div className={styles.wheel} /></section>
      <section><div className={styles.wheel} /></section>
    </div>
  );
};
