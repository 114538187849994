/* globals SEGMENT_KEY_DEV */
/* globals SEGMENT_KEY_PROD */
import { useEffect } from 'react';

import { isProdEnvironment } from '@/api/request';

type Props = {
  disabled?: boolean,
  isDevProperty?: boolean,
};

export function useSegmentTracker ({ disabled, isDevProperty }: Props) {
  useEffect(
    () => {
      // !window.analytics to prevent double loading
      if (!disabled && !window.analytics) {
        const isProductionProperty = isProdEnvironment() && !isDevProperty;
        const segmentKey = isProductionProperty ? SEGMENT_KEY_PROD : SEGMENT_KEY_DEV;
        const segmentCdnUrl = isProductionProperty ? 'https://cdn-segm.grid.is' : 'https://cdn-segm.ingrid.is';
        const document = window.document;
        const scriptTag = document.createElement('script');
        scriptTag.innerHTML = `!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="${segmentCdnUrl}/segm.js/v1/" + key + "/segm.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="${segmentKey}";analytics.SNIPPET_VERSION="4.13.2";
        analytics.load('${segmentKey}', {integrations: {Amplitude: false}});
        }}();`;
        if (document.head) {
          document.head.appendChild(scriptTag);
        }
        else {
          document.body.appendChild(scriptTag);
        }
      }
    },
    [ disabled, isDevProperty ],
  );
}
