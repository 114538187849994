import localCache from '@borgar/localcache';
import EventEmitter from 'component-emitter';

import { deleteCookie, localStorage, query as queryUtils, token } from '@grid-is/browser-utils';
import { tracking } from '@grid-is/tracking';

import { integrations } from '@/api/integrations';

import { invalidateAuth } from '../utils/auth';
import { paths, schemas } from './generatedTypes';
import { getJSON, postJSON, putJSON, type RequestOptions } from './request';

// XXX: many function in this file aren't really GRID-api related. They pertain to authentication and the identity of the current user. Separate!!

export type SignUpInfo = schemas['Signup'];

export type AnalyticsContext = {
  has_redirect_parameter: boolean,
  user_signup_utm_campaign?: string,
  user_signup_utm_firstpage?: string,
  user_signup_utm_grid_doc_id?: string,
  user_signup_utm_medium?: string,
  user_signup_utm_seq?: string,
  user_signup_utm_source?: string,
  user_signup_utm_term?: string,
  username_suggestion_available: boolean,
  username_suggestion_used: boolean,
}

export type Agreement = 'beta-terms' | 'beta-privacy-policy' | 'limited-permissions' | 'extended-permissions'

export type AccountFeatures = schemas['AccountFeatures'];

export type User = schemas['User'];

export type UserSearchResult = schemas['UserSearchResult'];

export type SlimUser = schemas['SlimUser']

type TimeStamp = ReturnType<typeof Date.prototype.toISOString>

export type UserProperties = {
  notion_signup?: boolean,
  mobile_signup?: TimeStamp,
  first_tutorial_option_selected?: 'charts-and-reports' | 'calculators' | 'analysis' | 'not-sure',
  template_gallery_home_tile_clicked?: boolean,
  tutorials_home_tile_clicked?: boolean,
  build_a_calculator_home_tile_clicked?: boolean,
  connecting_notion_data_v2_home_tile_clicked?: boolean,
  aggregating_data_v2_home_tile_clicked?: boolean,
  getting_started_v3_home_tile_clicked?: boolean,
  my_groups_home_tile_clicked?: boolean,
  examples_home_tile_clicked?: boolean,
  next_time_to_show_get_help_creating_docs_tip?: TimeStamp | 'never',
  tutorial_opened?: boolean,
  tutorial_completed?: boolean,
  in_product_trial_expired_modal_shown?: TimeStamp,
  TRIAL_STARTED?: TimeStamp,
  tutorials_completed_celebration_shown?: TimeStamp,
  tutorial_savings_calculator_completed?: TimeStamp,
  tutorial_savings_calculator_with_toolbar_completed?: TimeStamp,
  tutorial_simple_charts_completed?: TimeStamp,
  tutorial_chart_builder_completed?: TimeStamp,
}

// XXX: in the GRID-api schema, this is called 'MyUser'. Consider keeping those names in sync.
export type UserType = Omit<schemas['MyUser'], 'properties'> & { properties?: UserProperties};

type AdditionalAnalytics = {
  properties: AnalyticsContext,
}

export type NotificationSettings = schemas['NotificationSettings'];

const eventEmitter = new EventEmitter();
export const AuthEvents = {
  on: (event: string, fn: Function) => eventEmitter.on(event, fn),
  off: (event: string | undefined, fn: Function | undefined) => eventEmitter.off(event, fn),
  emit: (event: string, payload: any) => eventEmitter.emit(event, payload),
  LOG_IN: 'LOG_IN',
  LOG_OUT: 'LOG_OUT',
};

// XXX: get rid of this function altogether
export function whois (username?: string): string {
  if (username) {
    localStorage.setItem('username', username);
    return username;
  }
  const tok = token.get();
  const user = tok ? localStorage.getItem('username') || '' : '';
  return user;
}

type GetUserResponse = paths['/user']['get']['responses']['200']['content']['application/json'];

// XXX: localcache is untyped. Consider using another LRU/memoization lib.
// localcached user call for all non-current users
const _cachedInfo = localCache(function (username: string) {
  return getJSON<GetUserResponse>('/user/' + username);
});

export const getMyUser = (authToken?: string): Promise<UserType> => {
  return getJSON<UserType>('/user', getRequestOptions(authToken));
};

function getRequestOptions (authToken?: string) {
  const opts: RequestOptions = { headers: {} };
  if (authToken) {
    opts.headers!.Authorization = `Bearer ${authToken}`;
  }
  return opts;
}

export const getUser = function (username: string): Promise<UserType> {
  return _cachedInfo(username);
};

export function logIn (data: {token: string, user: UserType}) {
  token.set(data.token);
  whois(data.user.username);
  AuthEvents.emit(AuthEvents.LOG_IN, data.user);
}

export function generateLogoutQueryParameters (query: Record<string, any> = {}) {
  const params: Record<string, any> = {};
  const { next, post_logout_redirect_uri } = query;
  if (typeof next === 'string' && !next.includes('user/logout')) {
    params.state = JSON.stringify({ next });
  }
  if (post_logout_redirect_uri) {
    params.post_logout_redirect_uri = post_logout_redirect_uri;
  }
  return queryUtils.serialize(params);
}

export async function logOut (params?: Record<string, any>) {
  const { fusionauth } = await integrations();
  clearPersistentUserData();
  tracking.logOut();
  AuthEvents.emit(AuthEvents.LOG_OUT, null);
  let logoutUrl = fusionauth.logout_url;
  if (params) {
    const sep = /\?/.test(logoutUrl) ? '&' : '?';
    logoutUrl = `${logoutUrl}${sep}${generateLogoutQueryParameters(params)}`;
  }
  // @ts-expect-error
  window.location = logoutUrl;
}

export function clearPersistentUserData () {
  // XXX: check if these localStorage items are still used. If not, stop clearing them.
  deleteCookie('username');
  localStorage.removeItem('username');
  token.clear();
  deleteCookie('userId');
  localStorage.removeItem('userId');
  invalidateAuth();
}

export function isLoggedIn () {
  return !!token.get();
}

type GetSignupResponse = paths['/signup/{signup_id}']['get']['responses']['200']['content']['application/json'];

export function getSignup (signup_id: string) {
  return getJSON<GetSignupResponse>('/signup/' + signup_id);
}

type CreateUserRequest = paths['/signup/{signup_id}']['post']['requestBody']['content']['application/json'];
type CreateUserResponse = paths['/signup/{signup_id}']['post']['responses']['200']['content']['application/json'];

export function create (signup_id: string, props: CreateUserRequest, additionalAnalytics: AdditionalAnalytics, autologin = true) {
  const payload = {
    name: props.name,
    given_name: props.given_name,
    family_name: props.family_name,
    username: props.username,
    bio: props.bio || '',
    avatar_url: props.avatar_url || '',
  };
  return postJSON<CreateUserResponse, CreateUserRequest>('/signup/' + signup_id, payload, { additionalAnalytics })
    .then(d => {
      if (autologin) {
        logIn(d);
      }
      return d;
    });
}

type SettingsRequest = paths['/settings']['post']['requestBody']['content']['application/json'];
type SettingsResponse = paths['/settings']['post']['responses']['200']['content']['application/json'];

export async function settings ({ name, bio, avatar_url }: SettingsRequest) {
  const payload = {
    name: name,
    bio: bio,
    avatar_url: avatar_url,
  };
  return postJSON<SettingsResponse, SettingsRequest>('/settings', payload);
}

type IsAvailableResponse = paths['/username/{username}/available']['get']['responses']['200']['content']['application/json'];

export function isAvailable (username: string) {
  return getJSON<IsAvailableResponse>(`/username/${username}/available`);
}

type GetUserLikesRequest = paths['/user/{username}/likes']['post']['requestBody']['content']['application/json'];
type GetUserLikesResponse = paths['/user/{username}/likes']['post']['responses']['200']['content']['application/json'];

export async function getUserLikes (documents: GetUserLikesRequest): Promise<string[]> {
  // XXX: we need an endpoint which doesn't require a username...or alternatively, include the is_liked boolean directly on the document responses
  const username = whois();
  if (!username) {
    return [];
  }
  return postJSON<GetUserLikesResponse, GetUserLikesRequest>(`/user/${username}/likes`, documents);
}

type UpdateAgreementsRequest = paths['/settings/agreements']['post']['requestBody']['content']['application/json'];
type UpdateAgreementsResponse = paths['/settings/agreements']['post']['responses']['200']['content']['application/json'];

export async function updateAgreements (agreements: {add: Agreement[], remove?: Agreement[]}) {
  const payload = {
    agreements_to_add: agreements.add || [],
    agreements_to_remove: agreements.remove || [],
    client_build: BUILD_ID,
  };
  return postJSON<UpdateAgreementsResponse, UpdateAgreementsRequest>('/settings/agreements', payload);
}

type UpdateNotificationSettingsRequest = paths['/settings/notifications']['post']['requestBody']['content']['application/json'];

export function updateNotificationSettings (notificationsSettings: UpdateNotificationSettingsRequest) {
  return postJSON<{}, UpdateNotificationSettingsRequest>('/settings/notifications', notificationsSettings);
}

type GetNotificationSettingsResponse = paths['/settings/notifications']['get']['responses']['200']['content']['application/json'];

export function getNotificationSettings () {
  return getJSON<GetNotificationSettingsResponse>('/settings/notifications');
}

export function getLoginURL () {
  if (typeof location !== 'undefined') {
    return `${location.origin}/user/login`;
  }
  return 'https://grid.is/user/login';
}

type SearchForUsersResponse = paths['/users/search']['get']['responses']['200']['content']['application/json'];

export function searchForUsers (searchQuery: string) {
  searchQuery = encodeURIComponent(searchQuery);
  return getJSON<SearchForUsersResponse>(`/users/search?query=${searchQuery}`);
}

type SendEmailVerificationResponse = paths['/users/resend-email-verification']['post']['responses']['200']['content']['application/json'];

export function sendEmailVerification () {
  return postJSON<SendEmailVerificationResponse>('/users/resend-email-verification');
}

type UserPropertiesRequest = paths['/user_properties']['put']['requestBody']['content']['application/json'];
type UserPropertiesResponse = paths['/user_properties']['put']['responses']['200']['content']['application/json'];

/**
 * Sets a single user property
 * @param name The name of the property
 * @param value The value to assign to the user property
 * @returns `true` if the property didn't already have the supplied value, otherwise `false`.
 */
export async function setUserProperty<K extends keyof UserProperties> (name: K, value: UserProperties[K]): Promise<boolean> {
  const body: { properties: UserProperties} = {
    properties: { },
  };
  body.properties[name] = value;
  const response = await putJSON<UserPropertiesResponse, UserPropertiesRequest>('/user_properties', body);
  return response.properties[name].status !== 'unchanged';
}

/**
 * Sets multiple user properties
 * @returns An object indicating which properties were created, changed or unchanged
 */
export async function setUserProperties (properties: UserProperties) {
  const body = {
    properties,
  };
  return putJSON<UserPropertiesResponse, UserPropertiesRequest>('/user_properties', body);
}
