import { colors } from '@grid-is/styles';

import { IconBase } from '../../types';

export const NewDocument: IconBase = {
  // Name of icon.
  name: 'new-document',
  // Paths, we only use outline and solid.
  outline: `<path fill-rule="evenodd" clip-rule="evenodd" d="M21.7964 4.80719C21.6078 4.61093 21.3474 4.5 21.0753 4.5H8.84741C7.91998 4.52617 7.1853 5.34346 7.20042 6.33217V29.5269C7.1853 30.5156 7.91998 31.3329 8.84741 31.359H27.1532C28.0806 31.3329 28.8153 30.5156 28.8002 29.5269V12.4998C28.8002 12.2415 28.7003 11.9933 28.5213 11.807L21.7964 4.80719ZM21.2313 6.88838L26.6313 12.548H21.2313V6.88838ZM9.00029 6.41844V29.4405H27.0001V14.0924H19.8002V6.41844H9.00029Z" fill="${colors.squidInk}"/> <path d="M13.8999 25.4C13.8999 30.2601 17.8398 34.2001 22.7 34.2001C25.0339 34.2001 27.2722 33.2729 28.9225 31.6226C30.5729 29.9723 31.5 27.7339 31.5 25.4C31.5 20.5399 27.5601 16.6 22.7 16.6C17.8398 16.6 13.8999 20.5399 13.8999 25.4Z" fill="${colors.squidInk}" stroke="white"/> <path d="M27.5429 24.807H23.2921V20.5562C23.2921 20.2288 23.0267 19.9634 22.6992 19.9634C22.3718 19.9634 22.1064 20.2288 22.1064 20.5562V24.807H17.8556C17.5281 24.807 17.2627 25.0725 17.2627 25.3999C17.2627 25.7273 17.5281 25.9928 17.8556 25.9928H22.1064V30.2436C22.1064 30.571 22.3718 30.8364 22.6992 30.8364C23.0267 30.8364 23.2921 30.571 23.2921 30.2436V25.9928H27.5429C27.8703 25.9928 28.1358 25.7273 28.1358 25.3999C28.1358 25.0725 27.8703 24.807 27.5429 24.807Z" fill="white" stroke="white" stroke-width="0.3"/>`,
};
