// FIXME: when Apiary is factored out of the project, import from it
// this is a modified copy of the Excel = operator code
export default function compareValues (a, b) {
  // null === undefined === ""
  if ((a == null && b == null) ||
      (a === '' && b == null) ||
      (a == null && b === '')) {
    return true;
  }
  // identical things are equal
  if (a === b) {
    return true;
  }
  const t_a = typeof a;
  const t_b = typeof b;
  // not the same type? not equal
  if (t_a !== t_b) {
    return false;
  }
  // strings are compared case-insensitively
  if (t_a === 'string' && t_a === t_b) {
    return a.toLowerCase() === b.toLowerCase();
  }
  // everything else needs to be identical
  return false;
}
