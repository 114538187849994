import { useContext, useEffect } from 'react';
import csx from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { Icon } from '@grid-is/icon';

import { useDeps } from '@/bootstrapping/dependencies';
import { isMobile } from '@/utils';
import { useFlags } from '@/utils/flags';

import { BannerType, BillingContext } from './BillingContext';

import styles from './Banner.module.scss';

export const BANNER_ID = 'top-banner-anchor';

export const Banner = () => {
  const flags = useFlags();
  const { banner: billingBanner } = useContext(BillingContext);
  const router = useRouter();
  const inDocumentView = router?.route?.includes('rewrites_document');
  const { userEvents } = useDeps();

  // Banner is set via LaunchDarkly
  const temporaryBanner = flags.get('temporary-banner') as BannerType;
  const banner = temporaryBanner.description ? temporaryBanner : billingBanner;

  useEffect(() => {
    const trackWhenOpened = billingBanner?.tracking?.onOpen;
    if (trackWhenOpened) {
      trackWhenOpened();
    }
  }, [ billingBanner ]);

  if (banner) {
    return (
      <div id={BANNER_ID} className={styles.container}>
        <div className={csx(styles.upgradeBanner, (inDocumentView || isMobile()) && styles.fullWidth, banner.type === 'danger' && styles.danger)}>
          <div className={styles.content}>
            <Icon name="exclamation-triangle" size={24} variant="solid" />
            <span>{banner.description}
              {banner.confirmButton && banner.url &&
                <Link href={banner.url}>
                  <a
                    onClick={() => {
                      userEvents.bannerClicked(inDocumentView, banner.description, banner.confirmButton, banner.url);
                      const trackWhenConfirm = billingBanner?.tracking?.onConfirm;
                      if (trackWhenConfirm) {
                        trackWhenConfirm();
                      }
                    }}
                    target={banner.urlShouldOpenInNewTab ? '_blank' : undefined}
                    >{banner.confirmButton}
                  </a>
                </Link>}
              {banner.secondaryButton && banner.secondaryUrl &&
                <>
                  <span> or</span>
                  <Link href={banner.secondaryUrl}>
                    <a
                      onClick={() => userEvents.bannerClicked(inDocumentView, banner.description, banner.secondaryButton, banner.secondaryUrl)}
                      target={banner.secondaryUrlShouldOpenInNewTab ? '_blank' : undefined}
                      >{banner.secondaryButton}
                    </a>
                  </Link>
                </>}
            </span>
          </div>
        </div>
      </div>
    );
  }

  return null;
};
