import { Button } from '@/grid-ui/Button';
import { Modal } from '@/grid-ui/Modal';

import { PayWallFeature } from '../../BillingContext';
import { ModalProps } from './types';

import styles from './TrialModal.module.scss';

type ModalText = {
  header: string,
  description: string,
  confirmButton: string,
}

const modalTextByFeature: Record<Extract<PayWallFeature, ''>, ModalText> = {
  sharing: {
    header: 'Upgrade to share',
    description: 'Upgrade to one of our paid plans to share your document & unlock other exclusive features.',
    confirmButton: 'Upgrade',
  },
  embed: {
    header: 'Upgrade to embed',
    description: 'Upgrade to one of our paid plans to embed your document & unlock other exclusive features.',
    confirmButton: 'Upgrade',
  },
};

export const TimeToPayModal =  ({ payWallFeature, onCancel }: ModalProps) => {
  const modalText = modalTextByFeature[payWallFeature];
  return (
    <Modal
      open
      header={modalText.header}
      size="small"
      onClose={onCancel}
      >
      <section>
        <div className={styles.description}>
          <span>{modalText.description}</span>
        </div>
        <div className={styles.buttonGroup}>
          <Button
            onClick={() => {
              window.location.href = '/settings/billing';
            }}
            buttonType="primary"
            buttonSize="large"
            >{modalText.confirmButton}
          </Button>
        </div>
      </section>
    </Modal>
  );
};
