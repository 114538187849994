import React, { CSSProperties } from 'react';
import ReactDOM from 'react-dom';

import { Menu } from './Menu';

type ContextMenuProps = {
  actions: {
    onClick: (e: React.MouseEvent) => void,
    label: string,
  }[],
  pos: [x: number, y: number],
  onClose: () => void,
}

export class ContextMenu extends React.Component<ContextMenuProps> {
  constructor (props: ContextMenuProps) {
    super(props);
    this.elm = document.createElement('div');
    this.elm.setAttribute('role', 'menu');
  }

  componentDidMount () {
    document.body.appendChild(this.elm);
  }

  componentWillUnmount () {
    document.body.removeChild(this.elm);
  }

  elm: HTMLDivElement;

  renderMenu = () => {
    const { pos } = this.props;
    const drawerStyle: CSSProperties = {
      top: `${pos[1]}px`,
      left: `${pos[0]}px`,
      position: 'absolute',
    };
    return (
      <div style={drawerStyle}>
        <Menu open {...this.props} />
      </div>
    );
  };

  render () {
    return ReactDOM.createPortal(
      this.renderMenu(),
      this.elm,
    );
  }
}
