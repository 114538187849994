import { useRef } from 'react';
import { SelectInstance } from 'react-select';

import { tracking } from '@grid-is/tracking';

import { UserGroup } from '@/api/groups';
import { SelectOptionType } from '@/grid-ui/Select/types';

import { PeoplePicker } from '../PeoplePicker/PeoplePicker';
import { Members } from './Members';
import { SubtleCTAButton } from './SubtleCTAButton';
import { SendEvent } from './types';

type Props = {
  send: SendEvent,
  group?: UserGroup,
  currentUserId: string,
};

export const ManageGroup = ({ send, group, currentUserId }: Props) => {
  const selectRef = useRef<SelectInstance<SelectOptionType>>(null);
  if (!group) {
    return null;
  }
  const { users = [] }  = group;

  function handleRemove (id, memberType: 'user' | 'email') {
    if (id !== currentUserId) {
      send('REMOVE_MEMBER', { id, memberType });
    }
  }

  return (
    <>
      <PeoplePicker
        buttonLabel="Add"
        getErrorMessage={({ newUsers, newInvites }) => {
          const tooManyUsers = users.length + newUsers.length + newInvites.length > 15;
          if (tooManyUsers) {
            tracking.logEvent('User Group Size Limit Message Displayed', {
              user_group_id: group.id,
              group_owner_id: group.creator_id,
              limit: 15,
            });
            return 'You have reached the 15 person limit';
          }
          return undefined;
        }}
        filterOption={option => {
          if (typeof option === 'string') {
            return users.map(u => u.id).indexOf(option) === -1;
          }
          return users.map(u => u.id).indexOf(option.id) === -1;
        }}
        onAdd={newPeople => send('ADD_MEMBERS', newPeople)}
        noOptionsMessage="Please select an existing user."
        placeholder="Add by username or email"
        title="Add people"
        canAddEmails
        ref={selectRef}
        />
      <Members group={group} currentUserId={currentUserId} onRemove={handleRemove} />
      <SubtleCTAButton onClick={() => send('DELETE_GROUP')}>Delete group</SubtleCTAButton>
    </>
  );
};
