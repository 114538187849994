import csx from 'classnames';

import styles from './LoadingSpinner.module.scss';

export type LoadingSpinnerProps = {
  size?: number,
  color?: string,
  delayed?: boolean,
  centered?: boolean,
  className?: string,
}

export const LoadingSpinner = ({ size: fontSize = 8, color: background, delayed = false, centered = false, className }: LoadingSpinnerProps) => (
  <div
    className={csx(styles.spinner, delayed && styles.delayed, centered && styles.centered, className)}
    style={{ fontSize }}
    data-testid="spinner"
    >
    <div style={{ background }} />
    <div style={{ background }} />
    <div style={{ background }} />
    <div style={{ background }} />
  </div>
);
