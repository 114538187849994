import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import csx from 'classnames';

import { EventStream } from '@/editor/EditorEventStream';
import { Toast } from '@/grid-ui/Toast';
import { NavButton } from '@/components/Navigation/SideNav/NavButton';
import { isMobile } from '@/utils';

import { ContactSupportModal } from './ContactSupportModal';
import { HelpCenter } from './HelpCenter';
import { HelpDocsTitle } from './helpDocs';

import styles from './HelpActions.module.scss';

export type THelpActions = {
  documentId?: string,
  isOwner?: boolean,
  location: 'sideNav' | 'inDocument',
  getFocusElement?: () => (string | undefined),
}

export function HelpActions ({ documentId, isOwner, location, getFocusElement = () => undefined }: THelpActions) {
  const [ showHelpPopover, setShowHelpPopover ] = useState(false);
  const [ showContactSupport, setShowContactSupport ] = useState(false);
  const [ contextSpecificHelp, setContextSpecificHelp ] = useState<HelpDocsTitle | undefined>(undefined);
  const [ requestSubmit, setRequestSubmit ] = useState(false);
  const [ notification, setNotification ] = useState('');
  const container = location === 'inDocument' && typeof document !== 'undefined' ? document.getElementById('help-portal') : null;

  useEffect(() => {
    EventStream.on(EventStream.OPEN_HELP_CENTER, onHelpOpened);

    return () => {
      EventStream.off(EventStream.OPEN_HELP_CENTER, onHelpOpened);
    };
  }, []);

  if (isMobile()) {
    return null;
  }

  function onHelpOpened (props: { helpDoc?: HelpDocsTitle }) {
    const { helpDoc } = props;
    setShowHelpPopover(true);
    setContextSpecificHelp(helpDoc);
  }

  function onHelpClose () {
    setContextSpecificHelp(undefined);
    setShowHelpPopover(false);
  }

  function onHelpClick () {
    setShowHelpPopover(true);
  }

  function getInitiatedFrom () {
    if (contextSpecificHelp === 'Sharing your project') {
      return 'Share Menu';
    }
    else if (documentId) {
      return 'In Document';
    }
    return 'Home Sidebar';
  }

  function renderHelp () {
    return (
      <>
        {location !== 'inDocument' &&
          <NavButton
            icon="question-mark"
            label="Help center"
            onClick={onHelpClick}
            />
            }
        <div
          data-testid="help-actions"
          className={csx(location === 'inDocument' ? styles.helperWrapperInDocument : styles.helperWrapperInSideNav)}
          >
          <HelpCenter
            open={showHelpPopover}
            onClose={onHelpClose}
            contactSupport={() => setShowContactSupport(true)}
            displayContactSupport={location === 'inDocument'}
            focusElement={showHelpPopover ? getFocusElement() : undefined}
            initiatedFrom={getInitiatedFrom()}
            contextSpecificHelp={contextSpecificHelp}
            />
          {location === 'inDocument' && documentId && isOwner && (
            <ContactSupportModal
              onRequestSubmit={() => setRequestSubmit(true)}
              open={showContactSupport}
              onClose={() => setShowContactSupport(false)}
              documentId={documentId}
              setNotification={message => setNotification(message)}
              />
          )}
        </div>
        <Toast
          open={requestSubmit}
          duration={7000}
          onClose={() => setRequestSubmit(false)}
          onClick={() => setRequestSubmit(false)}
          message={<span>{notification}</span>}
          />
      </>
    );
  }

  if (container) {
    return ReactDOM.createPortal(renderHelp(), container);
  }
  else {
    return renderHelp();
  }
}
