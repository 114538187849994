export interface EnvironmentConfig {
  AMPLITUDE_KEY: string,
  API_URL: string,
  DYNAMIC_RENDERING: boolean,
  GIPHY_API_KEY: string,
  LAUNCH_DARKLY_KEY: string,
  PRUNE_WORKBOOKS: boolean,
  RELEASE_STAGE: string,
  SEGMENT_KEY: string,
  SENTRY_ENV: string,
  SLACK_APP_ID: string,
}

export function toBool (value?: string): boolean {
  let result = false;
  if (typeof value === 'string') {
    try {
      result = !!JSON.parse(value.trim().toLowerCase());
    }
    catch {
      // ignore
    }
  }
  return result;
}

export function getConfig (): EnvironmentConfig | null {
  let config: EnvironmentConfig | null = null;
  if (typeof window !== 'undefined' && (<any>window).config) {
    config = (<any>window).config as EnvironmentConfig;
  }
  else if (typeof process !== 'undefined' && process.env) {
    config = {
      AMPLITUDE_KEY: process.env.AMPLITUDE_KEY || '',
      API_URL: process.env.API_URL || '',
      DYNAMIC_RENDERING: process.env.DYNAMIC_RENDERING !== '0',  // this is true, unless explicitly turned off
      GIPHY_API_KEY: process.env.GIPHY_API_KEY || '',
      LAUNCH_DARKLY_KEY: process.env.LAUNCH_DARKLY_KEY || '',
      PRUNE_WORKBOOKS: toBool(process.env.PRUNE_WORKBOOKS),
      RELEASE_STAGE: process.env.RELEASE_STAGE || 'local',
      SEGMENT_KEY: process.env.SEGMENT_KEY || '',
      SENTRY_ENV: process.env.SENTRY_ENV || 'localdev',
      SLACK_APP_ID: process.env.SLACK_APP_ID || '',
    };
  }
  return config;
}
