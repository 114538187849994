import useSWR, { SWRConfiguration } from 'swr';

import { token } from '@grid-is/browser-utils';

import { AuthorizationRequiredError } from '@/api/errors';
import { getJSON } from '@/api/request';
import { UserType } from '@/api/user';

/**
 * Incremented each time the cached authentication response is invalidated
 * via `invalidateAuth` (presumably because of a logout event).
 */
let authInvalidations = 0;

function userInfo (): Promise<UserType> {
  if (!token.get()) {
    return Promise.reject('UnAuthorized');
  }
  return getJSON('/user').catch(err => {
    if (err instanceof AuthorizationRequiredError) {
      // XXX: clean up this error handling; this currently just emulates the above
      // Promise.reject call and that made the crash go away
      // eslint-disable-next-line no-throw-literal
      throw 'UnAuthorized';
    }
    else {
      throw err;
    }
  });
}

// XXX: simplify. Both isLoggedIn and whois can be deduced from the user.
export type AuthProps = {
  isLoggedIn: boolean,
  isValidating: boolean,
  whois: () => string | undefined,
  mutate: (user?: UserType) => void,
  user?: UserType,
};

export function useAuth (options?: SWRConfiguration): AuthProps {
  const { data, mutate, isValidating, error } = useSWR<UserType>([ 'api/user', authInvalidations ], userInfo, options);
  const whois = () => data?.username;
  const isLoggedIn = !!data?.username && !!token.get();

  return {
    isLoggedIn,
    isValidating: isValidating && !error,
    whois,
    mutate,
    user: data,
  };
}

export function invalidateAuth () {
  authInvalidations += 1;
}
