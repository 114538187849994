import React from 'react';
import csx from 'classnames';
import Link from 'next/link';
import PropTypes from 'prop-types';

import { Icon } from '@grid-is/icon';
import { uuid } from '@grid-is/uuid';

import { Tooltip } from '@/grid-ui/Tooltip';
import { renderPortal } from '@/utils/portal';

import { OptionMenu } from './OptionMenu';

import styles from './menu.module.scss';

function assignValueToAction (action) {
  if (action.value == null) {
    if (typeof action.label === 'string') {
      action.value = action.label?.replace(' ', '-').trim();
    }
    else {
      action.value = uuid();
    }
  }
  return action;
}

function addActionValues (actions) {
  return actions.map(action => {
    if (Array.isArray(action)) {
      return action.map(assignValueToAction);
    }
    return assignValueToAction(action);
  });
}

export class Menu extends React.Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    actions: PropTypes.arrayOf(PropTypes.shape({
      onClick: PropTypes.func,
      icon: PropTypes.oneOfType([ PropTypes.string, PropTypes.element ]),
      to: PropTypes.string,
      label: PropTypes.node.isRequired,
      disabled: PropTypes.bool,
      id: PropTypes.string,
      href: PropTypes.string,
      className: PropTypes.string,
    })).isRequired,
    children: PropTypes.node,
    drawerClassName: PropTypes.string,
    arrow: PropTypes.bool,
    header: PropTypes.node,
    portalTarget: PropTypes.object,
  };

  static defaultProps = {
    arrow: false,
  };

  constructor (props) {
    super(props);
    this.state = {
      cursor: -1,
      actions: addActionValues(props.actions),
    };
    this.input = React.createRef();
    this.container = React.createRef();
    this.drawer = React.createRef();
  }

  componentDidMount = () => {
    document.addEventListener('click', this.onClickOutside);
    document.addEventListener('contextmenu', this.onClickOutside);
    window.addEventListener('resize', this.setDirection);

    this.setDirection();
  };

  componentDidUpdate = () => {
    this.setDirection();

    if (this.props.open) {
      if (this.input.current) {
        this.input.current.focus();
      }
    }
  };

  static getDerivedStateFromProps (props, prevState) {
    if (props.actions !== prevState.actions || !prevState.actions) {
      return {
        actions: addActionValues(props.actions),
      };
    }
  }

  componentWillUnmount = () => {
    document.removeEventListener('click', this.onClickOutside);
    document.removeEventListener('contextmenu', this.onClickOutside);
    window.removeEventListener('resize', this.setDirection);
  };

  setDirection = () => {
    const rect = this.drawer && this.drawer.current && this.drawer.current.getBoundingClientRect();
    if (rect && this.container.current) {
      this.container.current.classList.toggle(styles.upwards, this.isUpwards(rect.height));
      this.container.current.classList.toggle(styles.left, this.openToLeft());
      this.drawer.current.classList.toggle(styles.left, this.openToLeft());
    }
    if (this.props.portalTarget) {
      this.setPortalPos();
    }
  };

  setPortalPos = () => {
    if (this.drawer.current && this.portal) {
      const drawerRect = this.drawer.current.getBoundingClientRect();
      const containerRect = this.container.current.getBoundingClientRect();
      const portalTargetRect = this.props.portalTarget.getBoundingClientRect();
      if (this.isUpwards(drawerRect.height)) {
        this.portal.style.top = portalTargetRect.y - drawerRect.height - 8 + 'px';
      }
      else {
        this.portal.style.top = containerRect.y + 4 + 'px';
      }
      this.portal.style.left = containerRect.x - drawerRect.width + containerRect.width + 'px';
      this.portal.style.width = drawerRect.width + 'px';
    }
  };

  onClickOutside = e => {
    if (this.drawer && this.drawer.current && this.drawer.current.contains(e.target)) {
      return;
    }

    const container = this.container;
    if (container && container.current) {
      if (container.current.parentNode.contains(e.target)) {
        return;
      }

      if (this.props.open && !container.current.contains(e.target)) {
        this.close();
      }
    }
  };

  // Tests fail unless we prevent the onClick to be called for a disabled option
  onClick = action => {
    if (action && !action.disabled && action.onClick) {
      action.onClick(action);
    }
    this.close();
  };

  onKeyDown = e => {
    const { cursor } = this.state;
    const { open } = this.props;
    if (open) {
      const code = e.keyCode;
      if (code === 27) { // <ESC> closes
        e.preventDefault();
        this.close();
      }
      else if (code === 38) { // <UP>
        e.preventDefault();
        this.moveCursor(-1);
      }
      else if (code === 40) { // <DOWN>
        e.preventDefault();
        this.moveCursor(1);
      }
      else if (code === 13) { // <ENTER>
        e.preventDefault();
        this.selectByCursor(e, cursor);
      }
      else if (code === 9) { // <TAB>
        this.close();
      }
    }
  };

  isUpwards = elementHeight => {
    const container = this.container;
    if (container && container.current && container.current.parentNode) {
      const rect = container.current.parentNode.getBoundingClientRect();
      const innerHeight = (window.innerHeight || document.documentElement.clientHeight);
      const distToBottom = innerHeight - rect.bottom;
      const distToTop = rect.top;
      if (distToBottom < elementHeight && distToTop > elementHeight) {
        return true;
      }
    }
    return false;
  };

  openToLeft = () => {
    const container = this.container;
    if (container && container.current && container.current.parentNode) {
      const parent = container.current.parentNode.getBoundingClientRect();
      const innerWidth = (window.innerWidth || document.documentElement.clientWidth);
      const left = parent.left;
      const right = parent.right;
      const pos = left + ((right - left) / 2);
      if (pos < innerWidth - pos) {
        return false;
      }
    }
    return true;
  };

  selectByCursor = (e, pos) => {
    if (pos > -1) {
      const action = this.props.actions[pos];
      if (action) {
        this.setState({ cursor: -1 }, () => {
          this.onClick(action);
        });
      }
    }
  };

  moveCursor = offs => {
    if (this.props.open) {
      const newPos = this.state.cursor + offs;
      if (newPos >= 0 && newPos < this.props.actions.length) {
        this.setState({ cursor: newPos });
      }
    }
  };

  close = () => {
    this.setState({ cursor: -1 });
    this.props.onClose();
  };

  renderIcon = icon => {
    return (
      <span className={styles.icon}>
        {typeof icon === 'string' ? <Icon
          size={24}
          name={/** @type {import('@grid-is/icon').IconTypes} */ (icon)}
          /> : icon}
      </span>
    );
  };

  renderOption = (option, selected, focused) => {
    const classNames = csx(
      styles.action,
      option.className,
      option.icon && styles.hasIcon,
      option.disabled && styles.disabled,
      focused && styles.focused);

    const props = {
      'key': option.id,
      'className': classNames,
      'data-id': option.id,
      'data-open': option.open,
      'onClick': () => this.onClick(option),
      'target': option.target,
    };

    const optionContent = (
      <>
        {option.icon && this.renderIcon(option.icon)}
        {option.label}
      </>
    );

    let item;
    if (option.to) {
      const to = option.to || '/';
      item = (
        <Link
          href={option.query ? {
            pathname: to,
            query: option.query,
          } : to}
          as={to}
          >
          <a {...props}>
            {optionContent}
          </a>
        </Link>
      );
    }

    else if (option.href) {
      item = (
        <a
          {...props}
          href={option.href || '#'}
          >
          {optionContent}
        </a>
      );
    }
    else {
      item = (
        <span {...props}>
          {optionContent}
        </span>
      );
    }

    if (option.tooltip) {
      return (
        <Tooltip
          label={option.tooltip}
          wrapElement
          >
          {item}
        </Tooltip>);
    }
    else {
      return item;
    }
  };

  renderMenu = () => {
    const { children, arrow, drawerClassName, portalTarget } = this.props;
    const menu = (
      <>
        <div
          data-open
          ref={this.drawer}
          className={csx(
            styles.drawer,
            drawerClassName,
            arrow && styles.arrow,
          )}
          >
          {this.props.header}
          <OptionMenu
            className={styles.actionsWrapper}
            options={this.state.actions}
            cursor={this.state.cursor}
            renderOption={this.renderOption}
            onSelect={this.onClick}
            />
          {children}
        </div>
        <div className={styles.input}>
          <input
            ref={this.input}
            id="menu-input"
            onKeyDown={this.onKeyDown}
            readOnly
            />
        </div>
      </>
    );
    return portalTarget ? renderPortal(
      <div
        className={styles.menuPortal}
        ref={elm => {
          this.portal = elm;
        }}
        >
        {menu}
      </div>, document.body) : menu;
  };

  render () {
    const { open } = this.props;
    return (
      <div
        ref={this.container}
        className={csx(
          styles.drawerWrapper,
        )}
        style={{ display: this.props.open ? '' : 'none' }}
        >
        {open
          ? this.renderMenu()
          : null}
      </div>
    );
  }
}
