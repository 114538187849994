import { useEffect } from 'react';
import csx from 'classnames';

import { Icon } from '@grid-is/icon';
import { tracking } from '@grid-is/tracking';

import { UserGroup } from '@/api/groups';
import { Button } from '@/grid-ui/Button';
import { HelperText } from '@/grid-ui/FieldWrapper/HelperText';
import { Input } from '@/grid-ui/Input';
import { LoadingSpinner } from '@/grid-ui/LoadingSpinner';

import { SubtleCTAButton } from './SubtleCTAButton';
import { SendEvent } from './types';
import { areAnyGroupsWithTheSameName } from './userGroupsUtils';

import styles from './overview.module.scss';

function Group ({ group, isOwner, onClick }: {group: UserGroup, isOwner?: boolean, onClick: (groupId: string) => void}) {
  const memberCount = group.users.length + (group.invites ? group.invites.length : 0);
  const subTitle = memberCount === 1 ? 'member' : 'members';
  return (
    <div
      role="button"
      tabIndex={0}
      className={styles.group}
      onClick={() => onClick(group.id)}
      onKeyDown={e => e.key === 'Enter' && onClick(group.id)}
      >
      <div className={styles.contents}>
        <div className={styles.text}>
          <div className={styles.title}>{group.name}</div>
          <div className={styles.subTitle}>{'\u00a0'}({memberCount} {subTitle})</div>
        </div>
        {!isOwner && <div className={styles.subTitle}>@{group.namespace?.user.username}</div>}
      </div>
      <Icon name="angle" direction="right" size={16} />
    </div>
  );
}

type Props = {
  send: SendEvent,
  newGroupName: string,
  isPending?: boolean,
  loadMoreCursor?: string,
  groups: UserGroup[],
  currentUserId: string,
};

export const Overview = ({ send, isPending, loadMoreCursor, newGroupName, groups, currentUserId }: Props) => {
  const duplicateName = areAnyGroupsWithTheSameName(newGroupName, groups, currentUserId);
  useEffect(() => {
    if (duplicateName) {
      tracking.logEvent('User Group Creation Error Shown', { initiated_from: 'User Group Panel' });
    }
  }, [ duplicateName ]);
  return (
    <>
      <div>
        <h5 key="contentTitle">Create a group</h5>
        <div className={styles.overviewControls}>
          <div className={styles.groupName}>
            <Input
              autoFocus
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              name="newGroup"
              value={newGroupName}
              placeholder="Type a name for your group"
              onChange={newValue => send('SET_NEW_GROUP_NAME', { newGroupName: newValue })}
              onKeyDown={e => {
                if (newGroupName.length > 0 && e.key === 'Enter') {
                  send('CREATE_GROUP');
                }
              }}
              variant="lite"
              />
            {duplicateName && <HelperText hasError>You already own a group with this name</HelperText>}
          </div>
          <Button
            buttonType="primary"
            disabled={newGroupName.length === 0 || duplicateName}
            onClick={() => send('CREATE_GROUP')}
            >Create
          </Button>
        </div>
      </div>
      {groups.length ? (
        <div className={csx(styles.items, loadMoreCursor && styles.includeBorder)}>
          {groups.map(group => {
            const isOwner = group.creator_id === currentUserId;
            return (
              <Group
                key={group.id}
                group={group}
                isOwner={isOwner}
                onClick={groupId => send(isOwner ? 'MANAGE_GROUP' : 'VIEW_GROUP', { groupId })}
                />
            );
          })}
        </div>
      ) : null}
      {loadMoreCursor && (
        <SubtleCTAButton onClick={() => send('LOAD_MORE')} loadMore>{isPending ? <LoadingSpinner /> : 'Load more'}</SubtleCTAButton>
      )}
    </>
  );
};
