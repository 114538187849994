import { createMonthOption } from '@/components/Settings/Usage/options';

import { UsageOverage } from '../../utils';

export function getLastMonth () {
  const lastMonthDate  = new Date();
  lastMonthDate.setDate(0);
  const option = createMonthOption(lastMonthDate);
  return option.value;
}

type ModalText = {
  header: string,
  description: string,
  confirmButton: string,
  url: string,
}

export const modalTextByPeriod: Record<UsageOverage['period'], ModalText> = {
  currentMonth: {
    header: 'You\'re ready to level up ✈️',
    description: 'You are exceeding your plan\'s limits. Upgrade now to avoid disruption.',
    confirmButton: 'Learn more',
    url: '/settings/usage',
  },
  lastMonth: {
    header: 'You\'re ready to level up ✈️',
    description: 'Last month you exceeded your plan\'s limits. Upgrade now to avoid disruption.',
    confirmButton: 'Learn more',
    url: `/settings/usage?month=${getLastMonth()}`,
  },
};

