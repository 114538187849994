import { IconBase } from '../../types';

export const GridView: IconBase = {
  // Name of icon.
  name: 'grid-view',
  // Paths, we only use outline and solid.
  outline: '<path d="M14,4H6A2,2,0,0,0,4,6v8a2,2,0,0,0,2,2h8a2,2,0,0,0,2-2V6A2,2,0,0,0,14,4ZM6,14V6h8v8Z"/><path d="M30,4H22a2,2,0,0,0-2,2v8a2,2,0,0,0,2,2h8a2,2,0,0,0,2-2V6A2,2,0,0,0,30,4ZM22,14V6h8v8Z"/><path d="M14,20H6a2,2,0,0,0-2,2v8a2,2,0,0,0,2,2h8a2,2,0,0,0,2-2V22A2,2,0,0,0,14,20ZM6,30V22h8v8Z"/><path d="M30,20H22a2,2,0,0,0-2,2v8a2,2,0,0,0,2,2h8a2,2,0,0,0,2-2V22A2,2,0,0,0,30,20ZM22,30V22h8v8Z"/>',
  solid: '<rect x="4" y="4" width="12" height="12" rx="2" ry="2"/><rect x="20" y="4" width="12" height="12" rx="2" ry="2"/><rect x="4" y="20" width="12" height="12" rx="2" ry="2"/><rect x="20" y="20" width="12" height="12" rx="2" ry="2"/>',
};

