import { IconBase } from '../../types';

export const PaintRoller: IconBase = {
  // Name of icon.
  name: 'paint-roller',
  // Paths, we only use outline and solid.
  outline: '<path d="M31 10V4a2 2 0 0 0-2-2H6a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h23a2 2 0 0 0 2-2ZM6 4h23v6H6Z" class="clr-i-outline clr-i-outline-path-1"/><path d="M33 6h-1v6.29l-13.3 4.25a1 1 0 0 0-.7 1V19h-2v14a2 2 0 0 0 2 2h2a2 2 0 0 0 2-2V19h-2v-.73L33.3 14a1 1 0 0 0 .7-1V7a1 1 0 0 0-1-1ZM20 33h-2V21h2Z" class="clr-i-outline clr-i-outline-path-2"/><path fill="none" d="M0 0h36v36H0z"/>',
  solid: '<rect x="4" y="2" width="27" height="10" rx="1" ry="1" class="clr-i-solid clr-i-solid-path-1"/><path d="M33 6h-1v6.24l-13.29 4.21a1 1 0 0 0-.71 1V19h-2v15a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1V19h-2v-.82L33.29 14a1 1 0 0 0 .71-1V7a1 1 0 0 0-1-1Z" class="clr-i-solid clr-i-solid-path-2"/><path fill="none" d="M0 0h36v36H0z"/>',
};

