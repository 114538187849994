import { CSSProperties, FC, ReactNode, useEffect, useRef } from 'react';

type Props = {
  onClickOutside: () => void,
  id?: string,
  testId?: string,
  className?: string,
  style?: CSSProperties,
  children: ReactNode,
}

export const OutsideClickHandler: FC<Props> =  ({ onClickOutside, id, testId, children, className, style }) => {
  const ref = useRef<HTMLDivElement>(null);
  const active = useRef<boolean>(false);
  useEffect(() => {
    const handleClickOutside = event => {
      if (!event.target.closest('[data-ignoreoutsideclick]') && ref.current && !ref.current.contains(event.target)) {
        active.current = true;
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    return function cleanup () {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ ref ]);

  useEffect(() => {
    const handleClickOutside = () => {
      if (active.current) {
        onClickOutside();
        active.current = false;
      }
    };
    document.addEventListener('mouseup', handleClickOutside);

    return function cleanup () {
      document.removeEventListener('mouseup', handleClickOutside);
    };
  }, [ onClickOutside ]);

  return (
    <div ref={ref} id={id} data-testid={testId} className={className} style={style}>
      {children}
    </div>
  );
};
