import { FunctionComponent, ReactNode, useCallback, useEffect, useRef } from 'react';
import { useMeasure } from 'react-use';
import csx from 'classnames';

import { getViewport } from '@grid-is/browser-utils';
import { OutsideClickHandler } from '@grid-is/outside-click-handler';

import { Modal, ModalProps } from '@/grid-ui/Modal';
import { trapfocus } from '@/grid-ui/Modal/focustrap';

import styles from './PopOver.module.scss';

type Props = {
  content: ReactNode,
  isOpen: boolean,
  onClose: () => void,
  name: string,
  children: ReactNode,
  shouldAnimate?: boolean,
  contentWidth?: number,
  positionRight?: boolean,
  className?: string,
  useModal?: boolean,
  animationDirection?: 'fromLeft',
  animateHeight?: boolean,
  modalProps?: Partial<ModalProps>,
  disableCloseOnClickOutside?: boolean,
}

export const PopOver: FunctionComponent<Props> = ({ name, modalProps, isOpen, onClose, content, animationDirection, animateHeight = false, contentWidth = 392, shouldAnimate, positionRight, className, children, useModal = false, disableCloseOnClickOutside }) => {
  const dialogRef = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  const [ contentRef, { height: contentHeight } ] = useMeasure<HTMLDivElement>();
  const prevHeight = useRef(0);

  const onKey = useCallback((e: KeyboardEvent) => {
    if (isOpen) {
      if (e.key === 'Escape') {
        if (!e.defaultPrevented && onClose) {
          onClose();
        }
      }
      if (e.key === 'Tab') {
        trapfocus(dialogRef.current, e);
      }
    }
  }, [ isOpen, onClose ]);

  useEffect(() => {
    document.addEventListener('keydown', onKey, false);

    return () => {
      document.removeEventListener('keydown', onKey, false);
    };
  }, [ onKey ]);

  useEffect(() => {
    if (scrollRef.current && animateHeight) {
      const minimumPadding = 24;
      const popOverTop = scrollRef.current?.getBoundingClientRect().top ?? 0;
      const viewportHeight = getViewport().height;
      const availableSpace = viewportHeight - popOverTop - minimumPadding;

      // We don't want to animate height from zero, i.e. when the pop over opens f.x.
      scrollRef.current.style.transition = prevHeight.current === 0 ? 'none' : 'height 200ms ease-out';

      // Updated height, if exceeding viewport then clamp and enable scrolling.
      if (availableSpace - contentHeight < 0) {
        scrollRef.current.style.overflow = 'auto';
        scrollRef.current.style.height = `${availableSpace}px`;
      }
      else {
        scrollRef.current.style.overflow = 'hidden';
        scrollRef.current.style.height = `${contentHeight}px`;
      }
    }
    prevHeight.current = contentHeight;
  }, [ contentHeight, animateHeight ]);

  // The PopOver only breaks out into a modal if the screen width is not enough for its width.
  const shouldUseModal = useModal || getViewport().width - contentWidth < 32;
  const showPopUp = isOpen && !shouldUseModal;
  return (
    <div className={csx(styles.popOver, shouldAnimate && styles.shouldAnimate, positionRight && styles.positionRight, animationDirection === 'fromLeft' && styles.fromLeft)} data-testid="pop-over">
      {(isOpen && shouldUseModal) ? (
        <>
          {children}
          <Modal
            open={isOpen}
            onClose={onClose}
            header={name}
            {...modalProps}
            >
            {content}
          </Modal>
        </>
      ) : (
        <OutsideClickHandler onClickOutside={() => isOpen && !disableCloseOnClickOutside && onClose()}>
          {children}
          {showPopUp &&
            <div className={csx(styles.outer, className, animateHeight && styles.animateHeight)} ref={scrollRef}>
              <div className={styles.dialog} role="dialog" ref={dialogRef} style={{ width: `${contentWidth}px` }}>
                <div ref={contentRef} className={styles.content}>{content}</div>
              </div>
            </div>
        }
        </OutsideClickHandler>
      )}
    </div>
  );
};
