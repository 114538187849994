import { FormEvent, useEffect, useRef, useState } from 'react';
import csx from 'classnames';
import { useRouter } from 'next/router';

import { getLoginUrl } from '@grid-is/browser-utils';

import { getDocumentPassword, setDocumentPassword } from '@/api/document';
import { Button } from '@/grid-ui/Button';
import { FieldWrapper } from '@/grid-ui/FieldWrapper';
import { Modal } from '@/grid-ui/Modal';
import { Password } from '@/grid-ui/Password';
import { BasePage } from '@/components/BasePage';
import { useAuth } from '@/utils/auth';

import styles from './error.module.scss';

export function ErrorPassword () {
  const router = useRouter();
  const { documentId } = router.query;
  const [ password, setPassword ] = useState<string>('');
  const [ isShaking, setIsShaking ] = useState<boolean>(false);
  const timeoutIdRef = useRef<ReturnType<typeof setTimeout> | undefined>();

  const shake = () => {
    timeoutIdRef.current && clearTimeout(timeoutIdRef.current);
    setIsShaking(true);
    timeoutIdRef.current = setTimeout(() => {
      setIsShaking(false);
    }, 800);
  };

  useEffect(() => {
    if (documentId) {
      const previouslyEnteredPassword = getDocumentPassword(String(documentId));
      if (previouslyEnteredPassword) {
        setPassword(previouslyEnteredPassword);
        shake();
      }
    }
  }, [ documentId ]);

  useEffect(() => {
    return () => timeoutIdRef.current && clearTimeout(timeoutIdRef.current);
  }, []);

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (password) {
      // push the password into history state and refresh the view
      setDocumentPassword(String(documentId), password);
      location.reload();
    }
    else {
      shake();
    }
  };

  const embed = router.pathname === '/embed/[documentId]';
  const isPassword = password && password.length > 0;
  const { isLoggedIn: loggedIn, isValidating } = useAuth();
  const isLoggedIn = loggedIn || isValidating || embed;
  return (
    <BasePage layout="narrow" title="Authorization Required" hideNavigation={embed}>
      <Modal
        open
        closeButton={false}
        header="Password Required"
        size="small"
        footer={isLoggedIn
          ? null
          : <p className={styles.signIn}>Is this your document? <a href={getLoginUrl()}>Log in</a></p>
          }
        >
        <form
          onSubmit={onSubmit}
          className={csx(styles.passwordLock, isShaking && styles.shake)}
          >
          <p>
            The document you are trying to access is password protected.
            If the creator of the document has shared the password with you, enter it here:
          </p>
          <div>
            <FieldWrapper label="Document password">
              <Password
                value={password}
                onChange={setPassword}
                autoComplete="off"
                autoFocus
                placeholder="Type a password"
                />
            </FieldWrapper>
            <span className={styles.button}>
              <Button buttonType="primary" type="submit" disabled={!isPassword}>View Document</Button>
            </span>
          </div>
        </form>
      </Modal>
    </BasePage>
  );
}
