// Keys are used as labels as well
// Values are the name of the Help guide on app.calculatorstudio.co
export const docs = {
  // All help guides
  'All help guides': 'help-guides-overview-ROR_CRebSoiGRwNV0f5M9Q',
  // Basics
  'What is Calculator Studio?': 'what-is-calculator-studio-yBYeOyirSSKBlknj25F98A',
  'Text formatting': 'basic-text-editing-in-calculator-studio-hb17zQOOR4msa_CcxQFSbQ',
  'Layout': 'layout-ZlpgtNIXT:WthPWKor9svw',
  'Keyboard shortcuts': 'keyboard-shortcuts-USPd0mqHSliBhPbIRlPPVQ',
  'Browser support': 'browser-support-_fCiqXsjTCKxQpdqKbg9DA',
  // Data sources
  'Project sheet (built-in spreadsheet)': 'project-sheet-built-in-spreadsheet-b5FVdQGfQka9ygcw_aeHyA',
  'Adding a data source to your project': 'adding-a-data-source-to-your-project-Rdwytwz_RsyRKEtZ6t0dpw',
  'Data preparation for Notion users': 'data-preparation-for-notion-users-Pn14QzbIR_eTNL4F4ik8vQ',
  'Connecting Notion databases': 'connecting-notion-databases-y:LUZlNgQ3ivR6TwoOroXw',
  'Connecting Airtable databases': 'connecting-airtable-databases-y72axIpMRcOXm7_53ZdB:g',
  'Connecting Smartsheet sheets': 'connecting-smartsheet-sheets-3lkZETulSm2R:RWndpBUMQ',
  // Sharing & publishing
  'Sharing your project': 'sharing-your-project-2IFplN9pSQ6GB5AD7ZpFBg',
  'Embedding your project': 'embedding-your-project-Bm0Ti0BzRReNeC10d4hQZQ',
  'Partial embedding': 'partial-embedding-MLw_wmQ0R6i7WC9tmxwMJg',
  'Setting domain access': 'setting-domain-access-wixDZ3syQteSPvVoFphugg',
  'Embedding in Squarespace': 'embedding-in-squarespace-via-html-iframe-p7zMB3JZR3CijUs5d_6DrA',
  'Embedding in Wix': 'embedding-in-wix-via-html-iframe-wDKCcOzQRDCNuaAypab5GQ/edit',
  'Embedding in Wordpress': 'embedding-in-wordpress-via-html-iframe-LazGC3EmTZKvhcaoDIJj7w',
  'Embedding in Notion': 'embedding-in-notion-7QxmIXXaSL6UG0DlcodkKw',
  'Project statistics': 'document-statistics-lV_nBnk1RXOgH4A34ruR0g',
  'Export to PDF': 'export-to-pdf-SLStncTPTeuldPxTEGK9Jw',
  'Customizing a PDF': 'customizing-a-pdf-RiNlWru4TbC0f9U_B3JyjA',
  'Scenarios': 'scenarios-KjpYqCSxSnu06X8uBTb_RA',
  'Using URL parameters to create scenarios': 'using-url-parameters-to-create-scenarios-qx:dPm7YSCyxNpwj1JMk1A',
  'How to use URL parameters to carry values between projects': 'how-to-use-url-parameters-to-carry-values-between-projects-OyahFeIuT4SG6Iq8_da44Q',
  'User groups': 'user-groups-syU4nA4UR7qK0qYT_1F3WQ',
  'Allow others to copy your project': 'allow-others-to-copy-your-project-ftzwatbMS4mAD5C:v1qDuA',
  'Presentation mode & slide breaks': 'presentation-mode-slide-breaks-JJHjFRwKT921fr6DyUS6Sg',
  'Collaborative editing': 'collaborative-editing-R:ZtxdCKR2G4mn90Th4ZHA',
  'Controlling visibility': 'controlling-visibility-ya_M022ESGGsGfHqhwwVxQ',
  'Project design - customizing colors and fonts': 'document-appearance-customizing-colors-and-fonts-qyNYdvzmRgmqK1ho52QHGQ',
  // Formulas & functions
  'Formulas': 'formulas-9KQH7kacROK2okYWDDg6Gw',
  'Summarize data with the QUERY function': 'summarize-data-with-the-query-function-InXxO_7vS6KNkV6tYScx3Q',
  'Formula separator & locale': 'formula-separator-locale-rE2TO0GUQsiu4g1dEvrs0g',
  'How to use the IF function': 'how-to-use-the-if-function-pY6c3yg7RHO_7ZhcKSUZGQ',
  'Using non-adjacent ranges': 'using-non-adjacent-ranges-QOjbn_tQSFmOjlbkU8xjCw',
  'Entering values directly': 'entering-values-directly-RJEMk0kITJez4kFtQSuzJw',
  'Resilient references': 'resilient-references-UwhrV0hCQ4q7uOUgXG2stw',
  // Spreadsheet engine
  'Supported functions': 'supported-spreadsheet-functions-in-grid-xmEKxuFiSsq3Mlrxq9vefw',
  'Supported spreadsheet operators': 'supported-spreadsheet-operators-in-grid-F2PiVv6MRgG4VWXZCKl4zQ',
  'Known limitations in calculations': 'current-limitations-in-calculations-43GlEubRTf:O4iJx_jpdfQ',
  'Improving operating speed': 'improving-operating-speed-of-projects-FZZqhVmBQYiNzpey9Q:z7w',
  // Elements, Display
  'KPI': 'kpi-element-cmDxlcSKSOCkdj6lwkPMxA',
  'Area chart': 'area-chart-MsRzFLfjROekOyfxzsTpjA',
  'Bar chart': 'bar-chart-YoI9IdBdRWCYc_y1GHbwtQ',
  'Column chart': 'column-chart-9KID4AQfTcSDavaJTLTv2Q',
  'Combo chart': 'combo-chart-s94AdSfsQrq_jbD:JQfQdw',
  'Line chart': 'line-chart-ZL5dMFEETeKsswK8kHGVQA',
  'Pie chart': 'pie-chart-18muD9atTDmQCV_dYQHwLA',
  'Scatter plot and Bubble chart': 'scatter-plot-and-bubble-chart-C7bYyitRTaess270B4teiA',
  'Waterfall chart': 'waterfall-chart-yoEp6Jt4TdG0Pfv:lPKzng',
  'Sheet': 'sheet-t39pzLRmTem_9E3ymOBfLg',
  'Table': 'table-POuts1WCTN2F10Y8BG9M6Q',
  'Formula output': 'formula-output-G2IuQvM7Teqa9eF5ggObLw',
  // Elements, Interaction
  'Button': 'button-RUxmaDXPRV27jRlyTeNSww',
  'Checkbox': 'check-box-vNqi0cFCT0S_nmjOk89BxQ',
  'Dropdown': 'dropdown-selection-fDLTFqVYTnuz3:s0808L0Q',
  'Input field': 'input-field-68QA3B7uRleCGKQndm2FWA',
  'Radio buttons': 'radio-selection-mE:iFGkpRkyHPf4UjAjn0Q',
  'Slider': 'slider-_mIXkr3pSGC9_5IOBPyNKg',
  'Interactive value': 'interactive-value-Fe3cYtIkRECZoWczKomOUA',
  'Reset (action button)': 'reset-action-button-rwPXT2shRmivg1yddRKO5A',
  'Recalculate (action button)': 'recalculate-action-button-GSx6DozISSO5FP_9eelkkQ',
  'Goal seek (action button)': 'goal-seek-action-button-cZqsbbs5QIaAE3Cbw0avGg',
  'Timer': 'timer-BOv33ZEcRjereXmrb3dZNQ',
  'Save to PDF (action button)': 'save-to-pdf-action-button-E49WeRV1RoeP6DIOR0nfMw',
  'Open URL (action button)': 'open-url-action-button-x1g9xF_3Q4OazBOfZE93TQ',
  'Copy URL (action button)': 'copy-url-action-button-tOBgrrx3RcaATDX0UNbdBw',
  'Form submissions (lead captures))': 'form-submissions-lead-captures-QCRE4yE4StS4uVKBwTBH9w',
  // Elements, Other
  'Source link': 'source-link-5eoBUFZHSyO6s7JIoyp3:w',
  'Image': 'images-JtlfPZrXQmSUGINzICOdLw',
  'Video': 'video-SHvTuBiBRhKynDX3_H3nxg',
  'Equation': 'latex-math-equation-4KH5jcVIQe:kt1OUW4eiVg',
  'Slide breaks': 'presentation-mode-slide-breaks-JJHjFRwKT921fr6DyUS6Sg',
  'Tooltip': 'tooltip-0kUOg1flRcuJT8eROYa5oQ',
  // Elements, Layout
  'Layout element': 'layout-element-n4RKi5VITrmBoDnTIX_9Tg',
  // Elements, Options
  'Chart axes': 'chart-axes-S65QHMGiTkiiA03YSoOhVA',
  'Element options': 'other-options-dfROT2grRuKAjgkwIP6Paw',
  // Integrations
  'Hubspot integration for lead capture': 'hubspot-integration-for-lead-capture-22VaTZWnQ0WbHPEvIJDZxw',
  'Zapier integration': 'how-to-set-up-zapier-integration-KQbWcbXbTGqv2Ko1Pn7K_A',
  // AI Features
  'Formula assistant': 'formula-assistant-7I:2wlh7TTOoq3epDBesKw',
  'Chart builder and AI chart assistant': 'chart-builder-and-ai-chart-assistant-wbFE80FKRbqEUpIu5Quvhg',
  // Administration
  'Cancel subscription': 'cancel-subscription-F22jPBo_QiuZRY1pCSYcFw',
  'Delete your account': 'delete-your-account-3yw7eqJlSAm0H79kzT6QJg',
  'Security': 'security-MJ:N2lskSQ6I51_0auM87g',
};

export type HelpDocsTitle = keyof typeof docs;

export const quickHelp = [
  'What is Calculator Studio?',
  'Adding a data source to your project',
  'Sharing your project',
  'Embedding your project',
  'Form submissions (lead captures)',
];

export const getContextSpecificHelp = (focusElement: string | undefined): HelpDocsTitle | null => {
  switch (focusElement) {
    case 'area': return 'Area chart';
    case 'bar': return 'Bar chart';
    case 'column': return 'Column chart';
    case 'combo': return 'Combo chart';
    case 'line': return 'Line chart';
    case 'pie': return 'Pie chart';
    case 'scatter': return 'Scatter plot and Bubble chart';
    case 'sheet': return 'Sheet';
    case 'table': return 'Table';
    case 'text': return 'Formula output';
    case 'button': return 'Button';
    case 'checkbox': return 'Checkbox';
    case 'dropdown': return 'Dropdown';
    case 'input': return 'Input field';
    case 'radio': return 'Radio buttons';
    case 'recalcbutton': return 'Recalculate (action button)';
    case 'resetbutton': return 'Reset (action button)';
    case 'slider': return 'Slider';
    case 'source': return 'Source link';
    case 'image': return 'Image';
    case 'embed': return 'Video';
    case 'kpi': return 'KPI';
    case 'latex': return 'Equation';
    case 'submitbutton': return 'Form submissions (lead captures))';
    case 'slidebreak': return 'Slide breaks';
    case 'tooltip': return 'Tooltip';
    default: return null;
  }
};
