import { IconButton } from '@/grid-ui/IconButton';

import { HeaderIcon } from './HeaderIcon';
import { SendEvent } from './types';

import styles from './userGroupsHeader.module.scss';

type Props = {
  send: SendEvent,
  title: string,
  subTitle?: string,
  showBackArrow?: boolean,
}

export const UserGroupsHeader = ({ send, subTitle, title, showBackArrow }: Props) => {
  return (
    <div className={styles.header}>
      {title === 'Groups' && <HeaderIcon />}
      {showBackArrow && <span className={styles.goBack}><IconButton data-testid="user-groups-go-back" buttonSize="small" buttonType="grayScale" onClick={() => send('GO_BACK')} iconName="angle" iconDirection="left" ariaLabel="Go back" /></span>}
      <div className={styles.text}>
        <div className={styles.title}>{title}</div>
        {subTitle && <div className={styles.subTitle}>{subTitle}</div>}
      </div>
    </div>
  );
};
