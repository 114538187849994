import type { Descendant, Element as SlateElement, Node as SlateNode } from 'slate';

import type { Range, Reference } from '@grid-is/apiary';

import { GridElementTypes } from '@/grid';
import { Doc } from '@/grid/Doc';
import { DocumentSettings } from '@/components/Document/EditableDocument/machine/types';
import type { TemplateType } from '@/components/Document/EditorPanel/templates/types';
import { HelpDocsTitle } from '@/components/DocumentActionsOld/HelpActions/helpDocs';
import { ChartBuilderInitiatedFrom, OptionChangeOrigin } from '@/instrumentation/UserEvents';
import { TypedEmitter } from '@/utils/events';

import type { QueryDefinition, QueryUICompatibleElement, QueryUIContext } from './components/QueryUI';
import type { EditableElementTypes } from './types/elements';
import type { BlockType, GridBlockElement } from './types/elements/block';
import type { GridInlineElement } from './types/elements/inline';
import type { ColumnElement, ColumnWidth, RowElement } from './types/elements/layout';
import type { ElementData } from './types/elements/shared';

export type OptionChangeTrigger = 'change' | 'focus' | 'blur' | 'itemAdded' | 'itemRemoved';

export type ActionType = 'Elements' | 'Design' | 'Layout' | 'Forms' | 'Full screen' | 'Scenarios' | 'Statistics' | 'Help center' | 'Feature flags';

export type ReferenceRenaming = {
  from: string,
  to: string,
  includeUnprefixed: boolean,
};

export type Events = {
  FORMULA_FIELD_FOCUS: undefined,
  ELEMENT_ADD: {
    id?: string,
    data: ElementData & { type: EditableElementTypes },
    element?: Partial<SlateElement>,
  },
  ELEMENT_EDIT: {
    id: string,
    data: ElementData,
    type?: string,
    element: Partial<SlateElement>,
    optionToAutoFocus?: string,
    tabToSelect?: string,
    isEditorPanelDisabled?: boolean,
  },
  ELEMENT_SELECT: {
    id?: string,
    name?: string,
    data?: ElementData | null,
    type?: string,
    element?: Partial<SlateElement>,
    isEditorPanelDisabled?: boolean,
  },
  ELEMENT_DELETE: {
    cb: (editor: Record<string, any>, element: Descendant, trackingOptions: Record<string, any>) => void,
    elementId?: string,
  },
  ON_DOCUMENT_SETTINGS_CLICK: { data: Doc },
  ON_DOCUMENT_APPEARANCE_CLICK: { data: Doc },
  ON_LAYOUT_SELECTION: { element: ColumnElement | RowElement },
  OPEN_DOCUMENT_SETTINGS: DocumentSettings,
  OPEN_ELEMENT_MENU: {
    rect: { x: number, y: number },
    element: Partial<SlateElement>,
    withSlash?: boolean,
  },
  OPEN_HELP_CENTER: {
    helpDoc?: HelpDocsTitle,
  },
  OPTION_CHANGE: {
    option: string,
    value: any,
    prevValue: string,
    panelTemplateType?: TemplateType,
    label: string,
    elementId?: string,
    elementType?: BlockType | 'grid:inline' | 'document',
    arrayElementPath?: [string, number],
    trigger: OptionChangeTrigger,
    updatedFrom: OptionChangeOrigin,
  },
  QUERY_UI_CHANGED: {
    elementId: string,
    elementType: GridElementTypes,
    queryDefinition: QueryDefinition,
    wbName?: string,
    sheetName?: string,
    tableName?: string,
    range?: Range,
  },
  UPDATE_ELEMENT_DATA: {
    id: string,
    data: ElementData,
  },
  SCROLL_TO_SELECTED_ELEMENT: undefined,
  SHEET_PANEL_RESIZE: {
    currSize: number,
    newSize: number,
    isDrag?: boolean,
    isFullscreen?: boolean,
  },
  SELECT_RANGE: { ref: Reference | string },
  CANCEL_SELECT_RANGE: undefined,
  SHEET_SELECT: {
    sheetName: string,
    workbookName: string,
  },
  FORMULA_FIELD_UPDATE: {
    option: string,
    elementType: GridElementTypes,
    value: string | Record<string, any>[],
  },
  FORMULA_FIELD_KEYDOWN: { event: KeyboardEvent},
  CLICKED_COMMENTS: undefined,
  CLOSE_ELEMENT_OPTIONS: undefined,
  EDITOR_PANEL_DID_CLOSE: undefined,
  ELEMENT_HERO_COMPLETED: undefined,
  DOCUMENT_META_UPDATE: {
    option: string,
    elementType: GridElementTypes,
    value: string | Record<string, any>[],
  },
  DOCUMENT_RESET_APPEARANCE: undefined,
  DOCUMENT_TITLE_UPDATE: { title: string },
  DRAFT_DISCARD: { dom: SlateNode[] },
  ON_DOCUMENT_WORKBOOK_RENAMED: {
    prevName: string,
    newName: string,
    // specification of also renaming a singleton sheet in the workbook
    alsoSheet?: ReferenceRenaming,
    // specification of also renaming a singleton table in the workbook
    alsoTable?: ReferenceRenaming,
  },
  ON_DOCUMENT_SHEET_RENAMED: {
    prevName: string,
    newName: string,
    workbookName: string,
  },
  ON_COMMENT_ACTIVATION: string | undefined,
  ON_COMMENT_ACTIVATION_WITH_RIGHT_CLICK: string | undefined,
  ON_COMMENT_FOCUS: string | undefined,
  TOGGLE_CONVERSATIONS: boolean,
  OPEN_FLAG_TOGGLER: undefined,
  UPDATE_BLOCK_CONTROLS: SlateElement & { domNode?: HTMLElement | null, data: ElementData},
  TOGGLE_RICH_TEXT_MENU: boolean,
  COLLABORATORS_UPDATE: undefined,
  FOCUS_EDITOR: undefined,
  RESIZE_INLINE_ELEMENT: { element: GridInlineElement },
  MODEL_RECALC: undefined,
  ADD_ELEMENT_FROM_SPREADSHEET_PANEL: {
    selection: [[number, number], [number, number]], // XXX: convert to Range
    sheetName: string,
    workbookName: string,
    type: EditableElementTypes,
  },
  CHART_BUILDING_CLOSED: QueryUIContext & {tableName?: string},
  CHART_BUILDING_INITIATED: {
    elementType: QueryUICompatibleElement,
    elementId?: string,
    workbookName?: string,
    sheetName?: string,
    initiatedFrom: ChartBuilderInitiatedFrom,
    range?: Range,
  },
  PASTE_TABULAR_DATA: GridBlockElement<'table'>[],
  AUTO_SELECT_DATA: { elementId: string, trigger: 'Element Placeholder' | 'Try again button' },
  OPEN_SHEET_PANEL: undefined,
  OPEN_EDITOR_PANEL: undefined,
  REWRITE_FORMULAS: { rewriteFormula: (formula: string) => string },
  SELECT_WORKBOOK: { workbookId: string, origin: 'dataPanel' | 'editorPanel' },
  SELECT_CELLS: { cellsToSelect: Reference},
  SELECT_ELEMENT: Element | null,
  INSERT_COLUMN_LAYOUT: [ColumnWidth, ColumnWidth],
  INSERT_PARAGRAPH: { elements?: ElementData[], data: ElementData },
  STARTED_DRAGGING: { id: string },
  STOPPED_DRAGGING: { id: string },
  CHART_ASSISTANT_BUTTON_CLICKED: {
    workbookName: string,
    sheetName: string,
    initiatedFrom: ChartBuilderInitiatedFrom,
  },
  REPLACE_CHART_ASSISTANT_WITH_CHART: {id: string, data: ElementData | null},
  SHOW_ACTIVE_BLOCK_DRAG_HANDLE: { show: boolean },
  REPEATER_BATCH_UPDATE_CLICKED: {
    elementId: string,
    elementType: GridElementTypes,
  },
  SPREADSHEET_DRAWING_BUTTON_CLICKED: {chartId: string, workbookName: string},
  OPEN_FORM_BUILDER: { addedFrom: string },
  CLOSE_DOCUMENT_ACTIONS: undefined,
  DOCUMENT_ACTION_CLOSED: undefined,
  DOCUMENT_STATISTICS_CLICKED: undefined,
  SCENARIOS_OPENED: undefined,
  ON_ELEMENT_MENU_TOGGLED: {
    isOpen: boolean,
  },
  ON_OPEN_ELEMENTS: undefined,
  EDITOR_WIDTH_DID_RESIZE: { rect: DOMRect},
}

class EventStream extends TypedEmitter<Events> {
  FORMULA_FIELD_FOCUS = 'FORMULA_FIELD_FOCUS' as const;
  // Emitted when a new element is added
  ELEMENT_ADD = 'ELEMENT_ADD' as const;
  // Emitted when "going into edit mode" on a focused element
  // (command-e, double click, clicking edit button)
  ELEMENT_EDIT = 'ELEMENT_EDIT' as const;
  // Emitted when a non-focused element is selected
  // (by clicking it, using arrows)
  ELEMENT_SELECT = 'ELEMENT_SELECT' as const;
  ELEMENT_DELETE = 'ELEMENT_DELETE' as const;
  ON_DOCUMENT_SETTINGS_CLICK = 'ON_DOCUMENT_SETTINGS_CLICK' as const;
  ON_DOCUMENT_APPEARANCE_CLICK = 'ON_DOCUMENT_APPEARANCE_CLICK' as const;
  // Emitted when editing layout
  ON_LAYOUT_SELECTION = 'ON_LAYOUT_SELECTION' as const;
  OPEN_DOCUMENT_SETTINGS = 'OPEN_DOCUMENT_SETTINGS' as const;
  OPEN_DOCUMENT_APPEARANCE = 'OPEN_DOCUMENT_APPEARANCE' as const;
  // Emitted when clicking element menu or using slash in editor
  OPEN_ELEMENT_MENU = 'OPEN_ELEMENT_MENU' as const;
  // Emitted when clicking "Need help?" in sharing flow
  OPEN_HELP_CENTER = 'OPEN_HELP_CENTER' as const;
  OPTION_CHANGE = 'OPTION_CHANGE' as const;
  // Emitted when using chart/table builder
  QUERY_UI_CHANGED = 'QUERY_UI_CHANGED' as const;
  UPDATE_ELEMENT_DATA = 'UPDATE_ELEMENT_DATA' as const;
  SCROLL_TO_SELECTED_ELEMENT = 'SCROLL_TO_SELECTED_ELEMENT' as const;
  SHEET_PANEL_RESIZE = 'SHEET_PANEL_RESIZE' as const;

  SELECT_RANGE = 'SELECT_RANGE' as const;
  CANCEL_SELECT_RANGE = 'CANCEL_SELECT_RANGE' as const;
  SHEET_SELECT = 'SHEET_SELECT' as const;
  // Emitted when formula field is focused, blurred or updated and when spreadsheet icon is clicked
  FORMULA_FIELD_UPDATE = 'FORMULA_FIELD_UPDATE' as const;
  // Emitted on key down in the formula field in the Editor panel
  FORMULA_FIELD_KEYDOWN = 'FORMULA_FIELD_KEYDOWN' as const;

  // Emitted when comments are clicked
  CLICKED_COMMENTS = 'CLICKED_COMMENTS' as const;
  // Emitted to close the editor panel from outside
  CLOSE_ELEMENT_OPTIONS = 'CLOSE_ELEMENT_OPTIONS' as const;
  // Emitted when the editor panel is closed
  EDITOR_PANEL_DID_CLOSE = 'EDITOR_PANEL_DID_CLOSE' as const;
  // Emitted when the element hero is completed, i.e. when a required field is filled.
  ELEMENT_HERO_COMPLETED = 'ELEMENT_HERO_COMPLETED' as const;
  // Emitted when document description, language, or theme is updated
  DOCUMENT_META_UPDATE = 'DOCUMENT_META_UPDATE' as const;
  // Emitted when document appearance is reset back to defaults
  DOCUMENT_RESET_APPEARANCE = 'DOCUMENT_RESET_APPEARANCE' as const;
  // Emitted when document title or visibility is updated
  DOCUMENT_TITLE_UPDATE = 'DOCUMENT_TITLE_UPDATE' as const;
  DRAFT_DISCARD = 'DRAFT_DISCARD' as const;
  // Emitted when a workbook is renamed, or replaced with another one with a different name
  ON_DOCUMENT_WORKBOOK_RENAMED = 'ON_DOCUMENT_WORKBOOK_RENAMED' as const;
  // Emitted when a native workbook sheet is renamed.
  ON_DOCUMENT_SHEET_RENAMED = 'ON_DOCUMENT_SHEET_RENAMED' as const;
  // Emitted when comment box is activated or focused for a particular element
  ON_COMMENT_ACTIVATION = 'ON_COMMENT_ACTIVATION' as const;
  ON_COMMENT_ACTIVATION_WITH_RIGHT_CLICK = 'ON_COMMENT_ACTIVATION_WITH_RIGHT_CLICK' as const;
  ON_COMMENT_FOCUS = 'ON_COMMENT_FOCUS' as const;
  TOGGLE_CONVERSATIONS = 'TOGGLE_CONVERSATIONS' as const;
  OPEN_FLAG_TOGGLER = 'OPEN_FLAG_TOGGLER' as const;
  UPDATE_BLOCK_CONTROLS = 'UPDATE_BLOCK_CONTROLS' as const;
  TOGGLE_RICH_TEXT_MENU = 'TOGGLE_RICH_TEXT_MENU' as const;
  COLLABORATORS_UPDATE = 'COLLABORATORS_UPDATE' as const;
  FOCUS_EDITOR = 'FOCUS_EDITOR' as const;
  RESIZE_INLINE_ELEMENT = 'RESIZE_INLINE_ELEMENT' as const;
  MODEL_RECALC = 'MODEL_RECALC' as const;
  ADD_ELEMENT_FROM_SPREADSHEET_PANEL = 'ADD_ELEMENT_FROM_SPREADSHEET_PANEL' as const;
  CHART_BUILDING_CLOSED = 'CHART_BUILDING_CLOSED' as const;
  CHART_BUILDING_INITIATED = 'CHART_BUILDING_INITIATED' as const;
  PASTE_TABULAR_DATA = 'PASTE_TABULAR_DATA' as const;
  AUTO_SELECT_DATA = 'AUTO_SELECT_DATA' as const;
  OPEN_SHEET_PANEL = 'OPEN_SHEET_PANEL' as const;
  OPEN_EDITOR_PANEL = 'OPEN_EDITOR_PANEL' as const;
  REWRITE_FORMULAS = 'REWRITE_FORMULAS' as const;
  SELECT_WORKBOOK = 'SELECT_WORKBOOK' as const;
  SELECT_CELLS = 'SELECT_CELLS' as const;
  // Command used by our tutorials
  SELECT_ELEMENT = 'SELECT_ELEMENT' as const;
  INSERT_COLUMN_LAYOUT = 'INSERT_COLUMN_LAYOUT' as const;
  INSERT_PARAGRAPH = 'INSERT_PARAGRAPH' as const;

  STARTED_DRAGGING = 'STARTED_DRAGGING' as const;
  STOPPED_DRAGGING = 'STOPPED_DRAGGING' as const;
  CHART_ASSISTANT_BUTTON_CLICKED = 'CHART_ASSISTANT_BUTTON_CLICKED' as const;
  REPLACE_CHART_ASSISTANT_WITH_CHART = 'REPLACE_CHART_ASSISTANT_WITH_CHART' as const;
  SHOW_ACTIVE_BLOCK_DRAG_HANDLE = 'SHOW_ACTIVE_BLOCK_DRAG_HANDLE' as const;
  REPEATER_BATCH_UPDATE_CLICKED = 'REPEATER_BATCH_UPDATE_CLICKED' as const;
  SPREADSHEET_DRAWING_BUTTON_CLICKED = 'SPREADSHEET_DRAWING_BUTTON_CLICKED' as const;
  OPEN_FORM_BUILDER = 'OPEN_FORM_BUILDER' as const;
  CLOSE_DOCUMENT_ACTIONS = 'CLOSE_DOCUMENT_ACTIONS' as const;
  DOCUMENT_ACTION_CLOSED = 'DOCUMENT_ACTION_CLOSED' as const;
  DOCUMENT_STATISTICS_CLICKED = 'DOCUMENT_STATISTICS_CLICKED' as const;
  SCENARIOS_OPENED = 'SCENARIOS_OPENED' as const;
  ON_ELEMENT_MENU_TOGGLED = 'ON_ELEMENT_MENU_TOGGLED' as const;
  ON_OPEN_ELEMENTS = 'ON_OPEN_ELEMENTS' as const;
  EDITOR_WIDTH_DID_RESIZE = 'EDITOR_WIDTH_DID_RESIZE' as const;
}

const singleton = new EventStream();
export { singleton as EventStream };
