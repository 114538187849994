import { tracking } from '@grid-is/tracking';

import { PurchasablePlan } from '@/api/billing';
import { DocumentType, ViewAccess } from '@/api/document';
import { DevicePreviewTypes } from '@/editor/components/DevicePreviewToggle';
import { EditableElementTypes } from '@/editor/types/elements';
import { ColumnWidth } from '@/editor/types/elements/layout';
import { isEqual } from '@/grid/utils';
import { TutorialExitPaths } from '@/components/DocumentTutorial/DocumentTutorial';

interface Emitter {
  emit: typeof tracking.logEvent,
}

export type ChartBuilderInitiatedFrom = 'Element placeholder' | 'Chart selector prompt' | 'Build chart button' | 'Element menu' | 'Chart assistant button';
export type OptionChangeOrigin = 'Editor panel' | 'Editor popover - Chart builder';

/**
 * Tracking properties used to be snake case. However, values in tracking should
 * now use Sentence case. First letter in first word is uppercase.
 */
export function toLegacyTrackingCasing (value: string) {
  value = value.trim();
  value = value.toLocaleLowerCase();
  return value.replace(/\s/g, '_');
}

// XXX: sort methods alphabetically
export class UserEvents {
  private _emitter: Emitter;
  constructor ({ emitter = { emit: tracking.logEvent } }) {
    this._emitter = emitter;
  }

  addDataMenuAccessed (docId: string, initiatedFrom: string): void {
    this._emitter.emit('Add Data Menu Accessed', {
      document_id: docId,
      initiated_from: initiatedFrom,
    });
  }

  addWorkbookButtonClicked (docId: string, docType: DocumentType, initiatedFrom?: string): void {
    this._emitter.emit('Add Workbook Button Clicked', {
      document_id: docId,
      document_type: docType,
      initiated_from: initiatedFrom,
    });
  }

  bannerClicked (bannerInDocument: boolean, bannerDescription: string, bannerButtonText?: string, bannerButtonUrl?: string): void {
    this._emitter.emit('Banner Clicked', {
      banner_in_document: bannerInDocument,
      banner_description: bannerDescription,
      banner_button_text: bannerButtonText,
      banner_button_url: bannerButtonUrl,
    });
  }

  bookACallPageShown (initiatedFrom: string): void {
    this._emitter.emit('Book a Call Page Shown', {
      initiated_from: initiatedFrom,
    });
  }

  bookACallContinueToGridClicked (): void {
    this._emitter.emit('Book a Call Continue to GRID Clicked', {
    });
  }

  chartBuilderClosed (
    elementId: string,
    isCategoryAxisPopulated: boolean,
    numSeries: number,
    numAggSum: number,
    numAggMax: number,
    numAggMin: number,
    numAggAvg: number,
    numAggCount: number,
    numAggCountUnique: number,
    numFilters: number,
    numFiltersAbsolute: number,
    numFiltersRelative: number,
    isSplitBy: boolean,
    hasNonBlankCellValue: boolean,
    hasAtLeastOneNonZeroNumber: boolean,
    tutorialName: string,
    docType: string,
  ) {
    this._emitter.emit('Chart Builder Closed', {
      element_id: elementId,
      is_category_axis_populated: isCategoryAxisPopulated,
      num_series: numSeries,
      num_agg_sum: numAggSum,
      num_agg_max: numAggMax,
      num_agg_min: numAggMin,
      num_agg_avg: numAggAvg,
      num_agg_count: numAggCount,
      num_agg_count_unique: numAggCountUnique,
      num_filters: numFilters,
      num_filters_absolute: numFiltersAbsolute,
      num_filters_relative: numFiltersRelative,
      is_split_by: isSplitBy,
      has_non_blank_cell_value: hasNonBlankCellValue,
      has_at_least_one_non_zero_number: hasAtLeastOneNonZeroNumber,
      document_type: docType,
      tutorial_name: tutorialName,
    });
  }

  chartBuilderStarted (elementId: string, initiatedFrom: ChartBuilderInitiatedFrom, tutorialName: string, docType: string) {
    this._emitter.emit('Chart Builder Started', { initiated_from: initiatedFrom, element_id: elementId, tutorial_name: tutorialName, document_type: docType });
  }

  chartSelectorPromptDismissed (docId: string, workbookType: WorkbookType) {
    this._emitter.emit('Chart Selector Prompt Dismissed', { document_id: docId, connected_workbook_type: workbookType });
  }

  chartSelectorPromptDisplayed (docId: string, workbookType: WorkbookType) {
    this._emitter.emit('Chart Selector Prompt Displayed', { document_id: docId, connected_workbook_type: workbookType });
  }

  chartAssistantActivated (elementId: string, initiatedFrom: 'Chart selector prompt' | 'Build chart button') {
    this._emitter.emit('Chart Assistant Activated', { element_id: elementId, initiated_from: initiatedFrom });
  }

  chartAssistantQueried (elementId: string, prompt: string, isChart: boolean, responseRaw?: string, hasNonBlankCellValue?: boolean) {
    this._emitter.emit('Chart Assistant Queried', { element_id: elementId, chart_assistant_prompt: prompt, chart_assistant_response_is_chart: isChart, chart_assistant_response_raw: responseRaw, has_non_blank_cell_value: hasNonBlankCellValue });
  }

  columnWidthSet (columnWidth: ColumnWidth, numberOfColumns: number, documentId: string) {
    this._emitter.emit('Column Width Set', {
      column_width: columnWidth,
      number_of_columns: numberOfColumns,
      document_id: documentId,
    });
  }

  DocumentCreationServiceTipShown (documentId: string) {
    this._emitter.emit('Document Creation Service Tip Shown', {
      document_id: documentId,
    });
  }

  DocumentCreationServiceTipDismissed (documentId: string, dismissOption: 'Remind me later' | 'Don\'t show again') {
    this._emitter.emit('Document Creation Service Tip Dismissed', {
      document_id: documentId,
      dismiss_option: dismissOption,
    });
  }

  layoutWidthSet (layoutWidth: string, documentId: string, elementId: string) {
    this._emitter.emit('Layout Width Set', {
      width: layoutWidth || 'default',
      document_id: documentId,
      element_id: elementId,
    });
  }

  layoutElementButtonClicked (documentId: string, elementId: string) {
    this._emitter.emit('Layout Element Button Clicked', {
      document_id: documentId,
      element_id: elementId,
    });
  }

  documentLinkCopied (docId: string, inputState: 'Your Input' | 'Original', initiatedFrom: 'Inside Share Menu' | 'Inside Share Menu Toast', viewAccess: ViewAccess) {
    this._emitter.emit('Document Link Copied', { document_id: docId,
      document_input_state: inputState,
      copy_link_initiated_from: initiatedFrom,
      view_access: viewAccess });
  }

  elementAdded (docId: string, elementId: string, docType: DocumentType, elementType: string, addedFrom: string, tutorialName?: string): void {
    this._emitter.emit('Element Added', {
      document_id: docId,
      element_id: elementId,
      document_type: docType,
      element_type: elementType,
      added_from: addedFrom,
      tutorial_name: tutorialName,
    });
  }

  formBuilderOpened (docId: string, addedFrom: 'Document Actions' | 'Element menu'): void {
    this._emitter.emit('Form Builder Opened', {
      document_id: docId,
      initiated_from: addedFrom,
    });
  }

  formBuildSubmitted (docId: string, numInputElements: number, numDropdownElements: number, numCheckboxElements: number, numRadioElements: number /** numElementsFromDocument: number */): void {
    this._emitter.emit('Form Added', {
      document_id: docId,
      num_input_elements: numInputElements,
      num_dropdown_elements: numDropdownElements,
      num_checkbox_elements: numCheckboxElements,
      num_radio_elements: numRadioElements,
      // num_elements_from_document: numElementsFromDocument,
    });
  }

  documentActionClicked (actionName: string): void {
    this._emitter.emit('Document Action Clicked', {
      action_name: actionName,
    });
  }

  editModeEntered (docId: string, docType: DocumentType, docOwnerId: string, isDocOwner: boolean): void {
    this._emitter.emit('Edit Mode Entered',
      {
        document_id: docId,
        document_type: docType,
        document_owner_id: docOwnerId,
        is_document_owner: isDocOwner,
      },
    );
  }

  fullscreenModeEntered (docId: string, docType: DocumentType): void {
    this._emitter.emit('Fullscreen Mode Entered', { document_id: docId, document_type: docType });
  }

  fullscreenModeExited (docId: string, docType: DocumentType, duration: number): void {
    const duration_seconds = Math.round(duration / 1000);
    const duration_minutes = Math.round(duration_seconds / 60 * 100) / 100;
    this._emitter.emit('Fullscreen Mode Exited', { document_id: docId, document_type: docType, duration_seconds, duration_minutes });
  }

  elementOptionsAutomaticallySet (
    docId: string,
    docType: DocumentType,
    elementId: string,
    elementType: string,
    trigger: string,
    tutorialName?: string,
  ): void {
    this._emitter.emit('Element Options Automatically Set', {
      document_id: docId,
      document_type: docType,
      element_id: elementId,
      element_type: elementType,
      options_auto_set_trigger: trigger,
      tutorial_name: tutorialName,
    });
  }

  elementOptionUpdated (
    docId: string,
    docType: DocumentType,
    tutorialName: string,
    optionLabel: string,
    isValidFormula: boolean,
    elementType: string,
    elementId: string,
    dataSource: string,
    hasReferences: boolean,
    optionName: string,
    hasNonBlankCellValue: boolean,
    docOwnerId: string | undefined,
    isDocOwner: boolean,
    hasAtLeastOneNonZeroNumber: boolean,
    updatedFrom: 'Editor panel' | 'Editor popover - Select data' | 'Editor popover - Chart builder',
    itemIndex?: number,
  ): void {
    this._emitter.emit('Element Option Updated', {
      document_id: docId,
      document_type: docType,
      tutorial_name: tutorialName,
      option_label: optionLabel,
      is_valid_formula: isValidFormula,
      element_type: elementType,
      element_id: elementId,
      data_source: dataSource,
      has_references: hasReferences,
      option_name: optionName,
      has_non_blank_cell_value: hasNonBlankCellValue,
      has_at_least_one_non_zero_number: hasAtLeastOneNonZeroNumber,
      document_owner_id: docOwnerId,
      is_document_owner: isDocOwner,
      updated_from: updatedFrom,
      item_index: itemIndex,
    });
  }

  gridWorkbookUpdated (
    docId: string,
    docType: DocumentType,
    docOwnerId: string | undefined,
    isDocOwner: boolean,
    workbookId: string,
    sheetName: string,
    updateAction: string, // XXX: there's drift between Avo and the GRIDSheetUpdatedPayload.action type definition
    updatedFrom: 'Cell' | 'Formula Bar' | 'Paste to Document' | 'Toolbar' | 'Row Header Menu' | 'Column Header Menu',
    toolbarOptionLabel?: 'Bold' | 'Data type' | 'Italic' | 'Decimals' | 'Currency',
  ): void {
    this._emitter.emit('GRID Workbook Updated', {
      document_id: docId,
      document_type: docType,
      document_owner_id: docOwnerId,
      is_document_owner: isDocOwner,
      workbook_id: workbookId,
      sheet_id: sheetName,
      grid_sheet_update_action: updateAction,
      grid_sheet_updated_from: updatedFrom,
      grid_sheet_toolbar_option_label: toolbarOptionLabel,
    });
  }

  helpCenterSizeToggled (maximized: boolean): void {
    this._emitter.emit('Help Center Size Toggled', {
      new_size: maximized ? 'small' : 'large',
      prev_size: maximized ? 'large' : 'small',
    });
  }

  plusButtonClicked (docId: string, docType: DocumentType, elementTypeSelected?: string): void {
    this._emitter.emit('Plus Button Clicked', {
      document_id: docId,
      document_type: docType,
      element_type_selected: elementTypeSelected,
    });
  }

  viewModeEntered (docId: string, docType: DocumentType, docOwnerId: string, isDocOwner: boolean): void {
    this._emitter.emit('View Mode Entered',
      {
        document_id: docId,
        document_type: docType,
        document_owner_id: docOwnerId,
        is_document_owner: isDocOwner,
      },
    );
  }

  documentPreviewed (docId: string, docType: DocumentType, docOwnerId: string, previewMode: DevicePreviewTypes): void {
    this._emitter.emit('Document Previewed',
      {
        document_id: docId,
        document_type: docType,
        document_owner_id: docOwnerId,
        preview_mode: previewMode,
      },
    );
  }

  gifPickerOpened (docId: string, initiatedFrom: 'Image Placeholder'): void {
    this._emitter.emit('GIF Picker Opened', {
      document_id: docId,
      initiated_from: initiatedFrom,
    });
  }

  gifSelected (docId: string): void {
    this._emitter.emit('GIF Selected', { document_id: docId });
  }

  elementMenuSearched (searchTerm: string): void {
    this._emitter.emit('Element Menu Searched', { element_menu_search_term: searchTerm });
  }

  emojiPickerOpened (docId: string, initiatedFrom: 'Inline'): void {
    this._emitter.emit('Emoji Picker Opened', {
      document_id: docId,
      initiated_from: initiatedFrom,
    });
  }

  emojiSelected (docId: string, emoji: string, emojiName: string): void {
    this._emitter.emit('Emoji Selected', {
      document_id: docId,
      emoji: emoji,
      emoji_name: emojiName,
    });
  }

  dataPanelResized (docId: string, initiatedFrom: string): void {
    this._emitter.emit('Data Panel Resized',
      {
        document_id: docId,
        initiated_from: initiatedFrom,
      },
    );
  }

  planCommitmentModalShown (initiatedFrom: 'Active plan' | 'Pricing table', subscriptionPlan: PurchasablePlan) {
    this._emitter.emit('Plan Commitment Modal Shown', { initiated_from: initiatedFrom, subscription_plan: subscriptionPlan });
  }

  spreadsheetPanelToggled (
    visibility: 'Open' | 'Closed',
    trigger: 'New Element Added' | 'Editor Panel' | 'Element Popover' | 'Minimize Button' | 'Spreadsheet Button',
    elementType?: EditableElementTypes,
    elementOption?: string,
  ) {
    this._emitter.emit('Spreadsheet Panel Toggled',
      {
        panel_visibility: visibility,
        toggle_trigger: trigger,
        editor_panel_trigger: elementOption,
        element_type: elementType,
      },
    );
  }

  loginToIntegration (integrationName: 'Google Slides' | 'Miro'): void {
    this._emitter.emit('Log In to Integration', {
      integration_name: integrationName,
    });
  }

  snapshotAddedToGoogleSlides (docId: string, docAuthorUserId: string, performedByAuthor: boolean): void {
    this._emitter.emit('Snapshot Added to Google Slides',
      {
        document_id: docId,
        document_author_user_id: docAuthorUserId,
        performed_by_author: performedByAuthor,
      },
    );
  }

  documentEmbeddedInMiro (docId: string, docAuthorUserId: string, performedByAuthor: boolean): void {
    this._emitter.emit('Document embedded in Miro',
      {
        document_id: docId,
        document_author_user_id: docAuthorUserId,
        performed_by_author: performedByAuthor,
      },
    );
  }

  blockGrabbed (docId: string, elementGroupName: string): void {
    this._emitter.emit('Block Grabbed',
      {
        document_id: docId,
        element_group_name: elementGroupName,
      },
    );
  }

  blockGrabCancelled (docId: string, elementGroupName: string): void {
    this._emitter.emit('Block Grab Cancelled',
      {
        document_id: docId,
        element_group_name: elementGroupName,
      },
    );
  }

  snapshotUpdatedInGoogleSlides (docId: string): void {
    this._emitter.emit('Snapshot Updated in Google Slides',
      {
        document_id: docId,
      },
    );
  }

  blockDropped (docId: string, elementGroupName: string, validDropzone = true, hasMoved = true): void {
    this._emitter.emit('Block Dropped',
      {
        document_id: docId,
        element_group_name: elementGroupName,
        valid_dropzone: validDropzone,
        is_drop_indicator_in_original_location: !hasMoved,
      },
    );
  }

  colorPaletteSelected (docId: string, paletteLabel: string): void {
    this._emitter.emit('Color Palette Selected',
      {
        document_id: docId,
        color_palette_label: paletteLabel,
      },
    );
  }

  colorTileConfigurationUpdated (docId: string, tile: number, updateType: 'Color Tile Added' | 'Color Tile Removed' | 'Color Tile Updated' | 'Color Tile Moved'): void {
    this._emitter.emit('Color Tile Configuration Updated',
      {
        document_id: docId,
        color_tile_index: tile,
        color_tile_update_type: updateType,
      },
    );
  }

  upgradeStarted (subscriptionPlanRaw: string, subscriptionPlan: 'Professional' | 'Premium' | 'Unlimited', planCommitmentRaw: string, planCommitment: 'monthly' | 'annual'): void {
    this._emitter.emit('Upgrade Started',
      {
        subscription_plan_raw: subscriptionPlanRaw,
        subscription_plan: subscriptionPlan,
        plan_commitment_raw: planCommitmentRaw,
        plan_commitment: planCommitment,
      },
    );
  }

  startTrialModalShown (initiatedFrom: 'Billing page' | 'In document share button' | 'In document embed button' | 'Document list share button') {
    this._emitter.emit('Start Trial Modal Shown', { initiated_from: initiatedFrom });
  }

  subscriptionCheckoutProgressed (subscriptionPlanRaw: string, subscriptionPlan: 'Professional' | 'Premium' | 'Unlimited', planCommitmentRaw: string, planCommitment: 'monthly' | 'annual', checkoutStep: number): void {
    this._emitter.emit('Subscription Checkout Progressed',
      {
        subscription_plan_raw: subscriptionPlanRaw,
        subscription_plan: subscriptionPlan,
        plan_commitment_raw: planCommitmentRaw,
        plan_commitment: planCommitment,
        checkout_step: checkoutStep,
      },
    );
  }

  overageMessagingShown = (documentViews: boolean, embedDomains: boolean, formSubmissions: boolean, seats: boolean, messagingFormat: 'Banner' | 'Modal',
  ) => {
    this._emitter.emit('Overage Messaging Shown',
      {
        document_views_overage: documentViews,
        embed_domains_overage: embedDomains,
        form_submissions_overage: formSubmissions,
        seats_overage: seats,
        messaging_format: messagingFormat,
      },
    );
  };

  overageMessagingClicked = (documentViews: boolean, embedDomains: boolean, formSubmissions: boolean, seats: boolean, messagingFormat: 'Banner' | 'Modal',
  ) => {
    this._emitter.emit('Overage Messaging Clicked',
      {
        document_views_overage: documentViews,
        embed_domains_overage: embedDomains,
        form_submissions_overage: formSubmissions,
        seats_overage: seats,
        messaging_format: messagingFormat,
      },
    );
  };

  upgradeModalShown (initiatedFrom: 'Billing page' | 'In document share button' | 'In document embed button' | 'Document list share button'): void {
    this._emitter.emit('Upgrade Modal Shown',
      {
        initiated_from: initiatedFrom,
      },
    );
  }

  upgradeInterestIndicated (initiatedFrom: string, interestSource: 'product', plan?: 'Professional' | 'Premium' | 'Unlimited'): void {
    this._emitter.emit('Upgrade Interest Indicated',
      {
        initiated_from: initiatedFrom,
        interest_source: interestSource,
        plan: plan,
      },
    );
  }

  allTutorialsCompleted (numTutorials: number) {
    this._emitter.emit('All Tutorials Completed', {
      number_of_tutorials_completed: numTutorials,
    });
  }

  tutorialGalleryAccessed (tutorialGalleryAccessedFrom: string) {
    this._emitter.emit('Tutorial Gallery Accessed', {
      tutorial_gallery_accessed_from: tutorialGalleryAccessedFrom,
    });
  }

  tutorialOptionSelected (initiatedFrom: string, optionSelected: string): void {
    this._emitter.emit('Tutorial Option Selected',
      {
        initiated_from: initiatedFrom,
        option_selected: optionSelected,
      },
    );
  }

  postTutorialOptionSelected (variant: 'abort' | 'success', optionSelected: TutorialExitPaths) {
    this._emitter.emit('Post Tutorial Option Selected', {
      post_tutorial_option_variant: variant,
      post_tutorial_option_selected: optionSelected,
    });
  }

  popoverDisplayed (
    initiatedFrom: 'Plus button' | 'Edit icon' | 'Editor panel',
    elementId: string,
    tab: 'Chart Builder',
    numNotionWorkbooks: number,
    numAirtableWorkbooks: number,
  ): void {
    this._emitter.emit('Editor Popover Displayed',
      {
        initiated_from: initiatedFrom,
        element_id: elementId,
        preselected_tab: tab,
        num_notion_workbooks: numNotionWorkbooks,
        num_airtable_workbooks: numAirtableWorkbooks,
      },
    );
  }

  editorPanelAccessed (
    initiatedFrom: 'Editor popover - Chart builder' | 'Direct',
    elementId: string,
    editType?: 'Pencil' | 'Roller',
  ): void {
    this._emitter.emit('Editor Panel Accessed',
      {
        initiated_from: initiatedFrom,
        element_id: elementId,
        edit_type: editType,
      },
    );
  }

  referenceSuggestionsShown (documentId: string, elementType: string, numSuggestions: number): void {
    this._emitter.emit('Reference Suggestions Shown',
      {
        document_id: documentId,
        element_type: elementType,
        num_suggestions: numSuggestions,
      },
    );
  }

  referenceSuggestionPicked (documentId: string, elementType: string, rank: number): void {
    this._emitter.emit('Reference Suggestion Picked',
      {
        document_id: documentId,
        element_type: elementType,
        rank,
      },
    );
  }
}

interface CapturedEvent {event: string, data: Record<string, any>}

// Test doubles below.
// These should fall out via tree-shaking during the build, and thus not impact the bundle size
// To verify:
// - make clean
// - npm run build
// - grep -r 'FakeUserEvents' .next/static/chunks/*
class FakeEmitter implements Emitter {
  public captured: CapturedEvent[] = [];

  emit (event: string, options?: Record<string, any>): void {
    this.captured.push({ event, data: options || {} });
  }
}

export class FakeUserEvents extends UserEvents {
  private _fakeEmitter: FakeEmitter;
  constructor () {
    const emitter = new FakeEmitter();
    super({ emitter });
    this._fakeEmitter = emitter;
  }

  get captured () {
    return this._fakeEmitter.captured;
  }

  /**
   * Accepts either the event name or the full event object.
   * if you pass the full event object, it will do a deep comparison
   * otherwise it will just check if an event with that name was captured
   */
  countMatches (expectedEvent: CapturedEvent | string): number {
    const matches = this.captured.filter(e => {
      if (expectedEvent instanceof Object) {
        return isEqual(e, expectedEvent);
      }
      return e.event === expectedEvent;
    });
    return matches.length;
  }
}
