export function isChrome () {
  if (typeof window === 'undefined') {
    return false;
  }
  const ua = navigator.userAgent;
  return /\bChrom(e|ium)\/./.test(ua) && !/\bEdge\//.test(ua);
}

export function isBrowser () {
  return typeof window !== 'undefined';
}

export function prefersReducedMotion () {
  if (isBrowser()) {
    return window.matchMedia('(prefers-reduced-motion: reduce)').matches;
  }
  return false;
}

export function hasResizeObserver () {
  return isBrowser() && typeof window.ResizeObserver !== 'undefined';
}

export function getRunTimeConfigurations () {
  if (typeof window !== 'undefined' && window.navigator.userAgent.includes('GRID e2e tests')) {
    return {
      browserWarning: false,
      tracking: false,
      tests: true,
    };
  }
  return { browserWarning: !isChrome(), tracking: true };
}
