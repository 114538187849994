export function getTimeSinceDate (isoDate: string): string {
  const date = Date.parse(isoDate);
  const now = Date.now();
  const ageInSeconds = (now - date) / 1000;
  if (ageInSeconds < 60) {
    return 'Just now';
  }
  else if (ageInSeconds < 120) {
    return '1 minute ago';
  }
  else if (ageInSeconds < 3600) {
    return `${Math.floor(ageInSeconds / 60)} minutes ago`;
  }
  else if (ageInSeconds < 7200) {
    return '1 hour ago';
  }
  else if (ageInSeconds < 3600 * 24) {
    return `${Math.floor(ageInSeconds / 3600)} hours ago`;
  }
  else if (ageInSeconds < 2 * 3600 * 24) {
    return '1 day ago';
  }
  return `${Math.floor(ageInSeconds / (3600 * 24))} days ago`;
}

/**
 * @description A collection of milliseconds constants for use in time calculations.
 */
export const ms = Object.freeze({
  second: 1000,
  minute: 60 * 1000,
  hour: 60 * 60 * 1000,
  day: 24 * 60 * 60 * 1000,
  week: 7 * 24 * 60 * 60 * 1000,
});
