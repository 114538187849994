import { cloneElement, ReactElement } from 'react';
import { ClearIndicatorProps,
  components,
  ControlProps,
  DropdownIndicatorProps,
  GroupBase,
  GroupHeadingProps,
  GroupProps,
  InputProps,
  MenuListProps,
  MenuProps,
  MultiValueGenericProps, MultiValueProps,
  MultiValueRemoveProps,
  NoticeProps,
  OptionProps,
  PlaceholderProps,
  SingleValueProps,
  ValueContainerProps } from 'react-select';
import csx from 'classnames';

import { Icon } from '@grid-is/icon';

import { Tooltip } from '@/grid-ui/Tooltip';

import { Pill } from '../Pill';
import { GroupedSelectOptionType, SelectOptionType, SelectPropTypes } from './types';

import styles from './Select.module.scss';

type withSelectProps<T> = T & {selectProps: SelectPropTypes}

const ClearIndicator = (props: withSelectProps<ClearIndicatorProps<SelectOptionType, boolean, GroupedSelectOptionType>>) => {
  return (
    <components.ClearIndicator
      className={csx(styles.clearIndicator)}
      {...props}
      >
      <Icon
        name="window-close"
        size={16}
        />
    </components.ClearIndicator>
  );
};

const DropdownIndicator = (props: withSelectProps<DropdownIndicatorProps<SelectOptionType, boolean, GroupedSelectOptionType>>) => {
  const { menuIsOpen, isMulti, isSearchBox } = props.selectProps;
  if (isMulti || isSearchBox) {
    // we do not want an indicator with multi selects or in the search box
    return null;
  }
  return (
    <components.DropdownIndicator
      className={csx(styles.dropdownIndicator, menuIsOpen && styles.openDropdownIndicator)}
      {...props}
      >
      <Icon
        name="angle"
        direction="down"
        size={16}
        />
    </components.DropdownIndicator>
  );
};

const Group = (props: withSelectProps<GroupProps<SelectOptionType, boolean, GroupedSelectOptionType>>) => (
  <components.Group className={csx(styles.group, styles[props.selectProps.size || 'medium'])} {...props} />
);

const GroupHeading = (props: withSelectProps<GroupHeadingProps<SelectOptionType, boolean, GroupedSelectOptionType>>) => {
  return (
    <components.GroupHeading className={csx(styles.groupHeading, styles[props.selectProps.size || 'medium'])} {...props}>
      <span className={styles.textContent}>
        {props.children}
      </span>
    </components.GroupHeading>);
};
const Input = (props: withSelectProps<InputProps>) => {
  const { isSearchBox } = props.selectProps;
  const testId = `input-${props.selectProps.testId ?? props.selectProps.name}`;
  return (
    <components.Input className={csx(isSearchBox && styles.searchBoxInput)} {...props} data-select-input="true" data-testid={testId} data-obid={testId} />
  );
};

const MenuList = (props: MenuListProps<SelectOptionType, boolean, GroupedSelectOptionType>) => {
  const { menuPlacement, id } = props.selectProps;
  return (
    <components.MenuList
      className={csx(styles.menuList, menuPlacement === 'bottom' && styles.bottom)}
      {...props}
      >
      <div
        data-ignoreoutsideclick
        id={`${id}-menuList`}
        >{props.children}
      </div>
    </components.MenuList>);
};

const MultiValueLabel = (props: MultiValueProps<SelectOptionType>) => {
  const { data, isFocused } = props;

  return (
    <Pill
      className={styles.multiValuePill}
      isFocused={isFocused}
      text={data.label}
      icon={data.icon || undefined}
      />
  );
};

const MultiValueRemove = (props: MultiValueRemoveProps<SelectOptionType, boolean, GroupedSelectOptionType>) => {
  return (
    <components.MultiValueRemove {...props}>
      <Icon size={14} name="window-close" />
    </components.MultiValueRemove>
  );
};

const MultiValueContainer = (props: MultiValueGenericProps<SelectOptionType>) => {
  return (
    <components.MultiValueContainer {...props} />
  );
};

const Option = (props: OptionProps<SelectOptionType, boolean, GroupedSelectOptionType>) => {
  const size = props.selectProps.size || 'medium';
  const { icon, description, hideBorders, disabled, disabledFeature, tooltip } = props.data;
  const { isSearchBox } = props.selectProps;
  const obid = props.label || '';
  const inner = (
    <>
      {icon && (
        <div className={csx(styles.icon, styles[size])}>
          {cloneElement(icon as ReactElement, { size: 24 })}
        </div>
      )}
      <div
        className={csx(styles.optionTextWrapper, icon && styles.withIcon, disabled && styles.disabled)}
        data-obid={obid}
        data-focused={props.isFocused}
        data-testid="select-option"
        data-disabled={disabled}
        >
        <div className={styles.optionText}>{props.children}</div>
        {description &&
          <div className={csx(styles.optionDescription, styles[size])} title={description}>{description}</div>}
      </div>
    </>
  );
  let contents = inner;
  if (tooltip) {
    contents = (
      <Tooltip
        disabled={!disabled}
        label={tooltip}
        wrapElement
        wrapClassName={styles.tooltip}
        >
        {inner}
      </Tooltip>
    );
  }
  return (
    <components.Option
      className={csx(styles.option, styles[size], isSearchBox && styles.isSearchBox, (disabled || disabledFeature) && styles.disabled, disabledFeature && styles.disabledFeature, hideBorders && styles.hideBorders)}
      {...props}
      >
      {contents}
    </components.Option>
  );
};

const Placeholder = (props: PlaceholderProps<SelectOptionType, boolean, GroupedSelectOptionType>) => {
  return (
    <components.Placeholder className={styles.placeholderWrapper} {...props}>
      <span className={styles.placeholder}>
        {props.children}
      </span>
    </components.Placeholder>
  );
};

const SingleValue = (props: SingleValueProps<SelectOptionType, boolean, GroupedSelectOptionType>) => {
  const { selectProps, data } = props;
  const size = selectProps.size || 'medium';
  const testId = `singleValue-${props.selectProps.testId ?? props.selectProps.name}`;
  const { icon } = data;
  // TODO if we want to empty the select while editing and there is an icon over the input cursor
  // if (data.icon && selectProps.menuIsOpen && selectProps.inputValue === '') { return null; }
  return (
    <components.SingleValue
      className={csx(styles.singleValue, 'singleValue', typeof props.children !== 'string' && styles.fullWidth)}
      {...props}
      >
      {icon && <div className={csx(styles.icon, styles[size])}>{cloneElement((icon as ReactElement), { size: 24 })}</div>}
      <div data-testid={testId}>{props.children}</div>
    </components.SingleValue>
  );
};

const ValueContainer = (props: ValueContainerProps<SelectOptionType, boolean, GroupedSelectOptionType>) => {
  return (
    <components.ValueContainer
      className={csx(styles.valueContainer, styles[props.selectProps.size || 'medium'], props.selectProps.variant && styles[props.selectProps.variant])}
      {...props}
      >
      {props.children}
    </components.ValueContainer>
  );
};

const LoadingMessage = (props: NoticeProps<SelectOptionType, boolean, GroupBase<SelectOptionType>>) => {
  return <components.LoadingMessage className={styles.noOptionsMessage} {...props} />;
};

const NoOptionsMessage = (props: NoticeProps<SelectOptionType, boolean, GroupBase<SelectOptionType>>) => {
  return (
    <components.NoOptionsMessage className={styles.noOptionsMessage} {...props} />
  );
};

const Control = (props: ControlProps<SelectOptionType, boolean, GroupedSelectOptionType>) => {
  const { hideInput, size, isSearchBox, menuPlacement } = props.selectProps;

  if (isSearchBox && !hideInput) {
    return (
      <div className={csx(styles.searchBox, menuPlacement === 'bottom' && styles.bottom)} data-obid="search-box">
        <div>

          <components.Control {...props} className={styles.control}>
            <Icon name="search" size={16} className={csx(styles.icon, styles[size || 'medium'])} />
            {props.children}
          </components.Control>
        </div>
      </div>
    );
  }
  return <components.Control {...props} className={csx(styles.control, hideInput && styles.hideInput, props.selectProps.size && styles[props.selectProps.size])} />;
};

const Menu = (props: MenuProps<SelectOptionType, boolean, GroupedSelectOptionType>) => {
  const { hideInput, isSearchBox, menuPlacement } = props.selectProps;
  return (
    <components.Menu
      {...props}
      className={csx(styles.menu, isSearchBox && styles.isSearchBox, hideInput && styles.hideInput, menuPlacement === 'bottom' && styles.bottom)}
      >
      {props.children}
    </components.Menu>);
};

export const SelectComponents = {
  ClearIndicator,
  DropdownIndicator,
  Group,
  GroupHeading,
  Input,
  ValueContainer,
  Menu,
  MenuList,
  MultiValueLabel,
  MultiValueRemove,
  MultiValueContainer,
  Option,
  Control,
  SingleValue,
  Placeholder,
  NoOptionsMessage,
  LoadingMessage,
};
