export default function isEqual (a, b) {
  if (a && b && typeof a === 'object' && typeof b === 'object') {
    if (a.constructor !== b.constructor) {
      return false;
    }

    let length, i;
    if (Array.isArray(a)) {
      length = a.length;
      if (length !== b.length) {
        return false;
      }
      for (i = length; i-- !== 0;) {
        if (!isEqual(a[i], b[i])) {
          return false;
        }
      }
      return true;
    }

    if (a.constructor === Set) {
      if (a.size !== b.size) {
        return false;
      }
      // XXX: do we need to have a looser comparison here for objects?
      for (const val of a) {
        if (!b.has(val)) {
          return false;
        }
      }
      return true;
    }

    if (a.constructor === Map) {
      if (a.size !== b.size) {
        return false;
      }
      for (const [ key, val ] of a) {
        if (!b.has(key) || !isEqual(val, b.get(key))) {
          return false;
        }
      }
      return true;
    }

    if (a.constructor === RegExp) {
      return a.source === b.source && a.flags === b.flags;
    }

    if (a.valueOf && a.valueOf !== Object.prototype.valueOf) {
      if (a.valueOf() !== b.valueOf()) {
        return false;
      }
    }

    const keys = Object.keys(a);
    length = keys.length;
    if (length !== Object.keys(b).length) {
      return false;
    }

    for (i = length; i-- !== 0;) {
      if (!Object.prototype.hasOwnProperty.call(b, keys[i])) {
        return false;
      }
    }

    for (i = length; i-- !== 0;) {
      const key = keys[i];
      if (!isEqual(a[key], b[key])) {
        return false;
      }
    }

    return true;
  }

  // Because functions can have closures they are not safe to treat like
  // this in many cases. When a closure changes we would like the function
  // to be a new instance. So this specialized function comparison is disabled
  // for now, prehaps to be made optional:
  // if (typeof a === 'function' && typeof b === 'function') {
  //   if (String(a) !== String(b)) {
  //     return false;
  //   }
  //   if (String(a) !== String(b)) {
  //     return false;
  //   }
  //   return true;
  // }

  if (a === b) {
    return true;
  }

  // true if both NaN, false otherwise
  // eslint-disable-next-line
  return a !== a && b !== b;
}
