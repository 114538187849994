import { BasePage } from '@/components/BasePage';
import { Profilelink } from '@/components/ProfileLink';
import { useAuth } from '@/utils/auth';

export function Error404 () {
  const { isLoggedIn, user } = useAuth();
  return (
    <BasePage layout="narrow" title="Page not found">
      <h1>Page not found</h1>
      <p>
        Well, this is awkward.
        We don't seem to have the page you requested.
      </p>
      <p>
        It could be that there is an error in the URL, or maybe it has been removed?
        We suggest the following instead:
      </p>

      <ul>
        {isLoggedIn ? <li><Profilelink username={user?.username}><a>Your profile</a></Profilelink></li> : null}
        <li><a href="https://grid.is/?noRedirect=1">The front page</a></li>
        <li><a href="/help">Help</a></li>
      </ul>
    </BasePage>
  );
}
