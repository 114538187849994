import cxs from 'classnames';
import Link from 'next/link';

import { Icon, IconTypes } from '@grid-is/icon';

import styles from './sideNav.module.scss';

type NavButtonProps = {
  external?: boolean,
  icon: IconTypes,
  label: string,
  selected?: boolean,
  href?: string,
  testid?: string,
  onClick?: () => void,
  className?: string,
}

export const NavButton = ({ external, icon, label, selected, href, testid, onClick = () => {}, className }: NavButtonProps) => {
  return (
    href ? (
      <div className={cxs(styles.navButton, className)} onClick={onClick}>
        {external &&
          (
            <a href={href} target="_blank" rel="noreferrer" tabIndex={-1} data-testid={testid}>
              <button type="button" className={cxs(selected && styles.selected)}>
                <Icon name={icon} title={label} size={24} />
                <span>{label}</span>
              </button>
            </a>
          )}
        {!external && (
          <Link href={href}>
            <a tabIndex={-1} data-testid={testid}>
              <button type="button" className={cxs(selected && styles.selected)}>
                <Icon name={icon} title={label} size={24} />
                <span>{label}</span>
              </button>
            </a>
          </Link>)}

      </div>
    ) : (
      <div className={cxs(styles.navButton, className)}>
        <button type="button" className={cxs(selected && styles.selected)} onClick={onClick}>
          <Icon name={icon} title={label} size={24} />
          <span>{label}</span>
        </button>
      </div>
    )
  );
};
