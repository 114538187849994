import { useState } from 'react';
import { RemoveScroll } from 'react-remove-scroll';
import csx from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { Icon } from '@grid-is/icon';
import { tracking } from '@grid-is/tracking';

import { Avatar } from '@/grid-ui/Avatar';
import { Button } from '@/grid-ui/Button';
import { HelpActions } from '@/components/DocumentActionsOld/HelpActions/HelpActions';
import { FlagToggler } from '@/components/FlagToggler';
import { HomeLogo } from '@/components/Navigation/HomeLogo';
import { NotificationPanel } from '@/components/NotificationPanel/NotificationPanel';
import { UserGroups } from '@/components/UserGroups/UserGroups';
import { UserMenu } from '@/components/UserMenu/UserMenu';
import { isMobile as useMobile } from '@/utils';
import { useAuth } from '@/utils/auth';

import { NavButton } from './NavButton';

import styles from './sideNav.module.scss';

type SideNavProps = {
  utmParams?: string | URLSearchParams,
  showMobileSideNav?: boolean,
  hideMobileSideNav?: () => void,
  hideMenus?: boolean,
}

export const SideNav = ({ utmParams, showMobileSideNav, hideMobileSideNav = () => {}, hideMenus }: SideNavProps) => {
  const { whois, user, isLoggedIn, isValidating } = useAuth();
  const isMobile = useMobile();
  const router = useRouter();
  const [ openModal, setOpenModal ] = useState(false);

  function openUserGroup () {
    hideMobileSideNav();
    tracking.logEvent('User Group Panel Accessed', { initiated_from: 'Home Sidebar' });
    setOpenModal(true);
  }

  if (isMobile) {
    return (
      <>
        {user?.id && (<UserGroups currentUserId={user.id} openModal={openModal} setOpenModal={setOpenModal} />)}
        <RemoveScroll enabled={showMobileSideNav}>
          <nav className={csx(styles.navigation, styles.mobileSideNav, showMobileSideNav && styles.show)} data-testid="mobile-side-nav">
            <button className={styles.close} type="button" onClick={hideMobileSideNav}><Icon name="window-close" size={24} /></button>
            <div className={styles.flagToggler} onClick={hideMobileSideNav}><FlagToggler location="nav" /></div>
            <Avatar
              size={104}
              avatarUrl={user?.avatar_url}
              username={user?.username}
              className={styles.avatar}
              />
            <p className={styles.name}>{user?.name}</p>
            <Button buttonType="secondary" iconName="users" onClick={openUserGroup}>My Groups</Button>
            <div className={styles.links}>
              <Link href={`/@${whois()}`}><a onClick={hideMobileSideNav}>Home</a></Link>
              <Link href="/settings/profile"><a onClick={hideMobileSideNav} data-testid="menu-item-my-account">Account</a></Link>
              <a href="/forum" target="_blank" rel="noreferrer" onClick={() => tracking.logEvent('Community Forum Clicked', { click_source: 'Mobile Menu' })}>Community Forum</a>
              <a href="/@grid/grid-intro-ROR_CRebSoiGRwNV0f5M9Q?utm_campaign=menu-help&utm_seq=1" onClick={() => tracking.logEvent('Help Accessed', { initiated_from: 'Mobile Menu' })} target="_blank" rel="noreferrer">Help</a>
            </div>
            <div className={styles.flex} />
            <Link href="/user/logout"><a><Button buttonType="secondary" iconName="logout">Log out</Button></a></Link>
          </nav>
        </RemoveScroll>
      </>
    );
  }
  else {
    return (
      <nav className={csx(styles.navigation, styles.sideNav, hideMenus && styles.hideMenus)}>
        {user?.id && (
          <UserGroups
            currentUserId={user.id}
            openModal={openModal}
            setOpenModal={setOpenModal}
            />
        )}
        <div className={styles.logo}>
          <HomeLogo useLargeLogo utmParams={utmParams} size={32} />
        </div>
        {!hideMenus && (!isValidating || user) &&
          <>
            <div className={styles.horizontalSeperator} />
            <div className={styles.menu} data-testid="side-menu">
              {isLoggedIn &&
                <>
                  <NavButton icon="home" label="Home" href={`/@${whois()}`} selected={router?.asPath === `/${router.query.user}`} />
                  <NavButton icon="cog" label="Account" href="/settings/profile" selected={router?.pathname === '/settings/[settings]'} testid="menu-item-my-account" />
                  <div className={styles.groupHeading}>Resources</div>
                  <NavButton icon="file-group" label="Examples" href={`/@${user?.username}?examples=all&accessed_from=Home`} selected={!!router.query.examples} />
                  <NavButton icon="launchpad" label="Interactive tutorials" href={`/@${user?.username}?tutorials=all&accessed_from=Home`} selected={!!router.query.tutorials} />
                  <NavButton icon="talk-bubbles" label="Community forum" href="/forum" onClick={() => tracking.logEvent('Community Forum Clicked', { click_source: 'Home Sidebar' })} external />
                  <HelpActions isOwner location="sideNav" />
                  <div className={styles.flagToggler}><FlagToggler location="nav" /></div>
                </>}
            </div>
            {isLoggedIn && <div className={styles.flex} />}
            <div className={csx(styles.userMenu, !isLoggedIn && styles.notLoggedIn)}>
              <UserMenu analyticsPageName="Home" utmParams={utmParams} isLoggedIn={isLoggedIn} iconSize={24} />
              {isLoggedIn && <div className={styles.notifications}><NotificationPanel /></div>}
            </div>
          </>
        }
      </nav>
    );
  }
};
