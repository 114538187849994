import { IconBase } from '../../types';

export const DocumentSettings: IconBase = {
  name: 'document-settings',
  outline: '' +
    '<path d="M33.83,23.43a1.13,1.13,0,0,0-.18-.67,1.16,1.16,0,0,0-.53-.45l-1.68-.5c-.09-.24-.18-.48-.29-.71l.78-1.44a1.12,1.12,0,0,0,.12-.72,1.16,1.16,0,0,0-.33-.65L30.3,16.88a1.15,1.15,0,0,0-1.37-.2l-1.45.76a7.19,7.19,0,0,0-.76-.32l-.48-1.58a1.16,1.16,0,0,0-.43-.57,1.18,1.18,0,0,0-.68-.2h-2a1.14,1.14,0,0,0-.69.23,1.1,1.1,0,0,0-.42.59l-.47,1.54a7.35,7.35,0,0,0-.77.32l-1.42-.76a1.1,1.1,0,0,0-.71-.12,1.15,1.15,0,0,0-.65.32l-1.45,1.4a1.21,1.21,0,0,0-.34.65,1.23,1.23,0,0,0,.13.73L17.08,21a6.81,6.81,0,0,0-.31.74l-1.58.47a1.16,1.16,0,0,0-.6.42,1.14,1.14,0,0,0-.23.69v2a1.14,1.14,0,0,0,.83,1.1l1.59.47a5.78,5.78,0,0,0,.31.72l-.78,1.46a1.12,1.12,0,0,0-.12.72,1.16,1.16,0,0,0,.33.65l1.42,1.4a1.16,1.16,0,0,0,.65.33,1.12,1.12,0,0,0,.72-.12l1.48-.78a7.72,7.72,0,0,0,.72.29L22,33.18a1.14,1.14,0,0,0,.42.59,1.2,1.2,0,0,0,.69.22h2a1.14,1.14,0,0,0,.69-.23,1.1,1.1,0,0,0,.42-.59l.47-1.58a6.07,6.07,0,0,0,.7-.29l1.5.79a1.1,1.1,0,0,0,.71.12,1.15,1.15,0,0,0,.65-.32l1.42-1.4a1.21,1.21,0,0,0,.34-.65,1.23,1.23,0,0,0-.13-.73l-.79-1.45c.11-.23.2-.46.29-.69L33,26.5a1.16,1.16,0,0,0,.6-.42,1.14,1.14,0,0,0,.23-.69Zm-1.6,1.63-2.11.62L30,26.1a5.89,5.89,0,0,1-.5,1.19l-.21.38,1,1.91-1,1-2-1-.37.2a6.3,6.3,0,0,1-1.2.49l-.42.12-.63,2.09H23.42l-.63-2.08-.42-.12a5.79,5.79,0,0,1-1.21-.49l-.37-.2-1.94,1-1-1,1-1.94-.22-.38A5.67,5.67,0,0,1,18.17,26L18,25.63,16,25V23.69l2-.61.13-.41a6.09,6.09,0,0,1,.53-1.23L18.9,21l-1-1.85,1-.94,1.89,1,.38-.21a6.31,6.31,0,0,1,1.26-.52l.41-.12.63-2h1.38l.62,2,.41.12A6.22,6.22,0,0,1,27.1,19l.38.21,1.92-1,1,1-1,1.89.21.38a5.82,5.82,0,0,1,.5,1.21l.12.42,2.06.61Z" />' +
    '<path d="M24.12,20.35a4,4,0,0,0-3.64,2.52,4,4,0,0,0-.19,2.31A4,4,0,0,0,25.75,28a4,4,0,0,0,2.45-3.69,4.06,4.06,0,0,0-4.08-4Zm0,6.46a2.48,2.48,0,0,1-1.34-.43,2.53,2.53,0,0,1-.87-1.1,2.4,2.4,0,0,1-.12-1.4,2.49,2.49,0,0,1,.68-1.24A2.45,2.45,0,0,1,23.71,22a2.4,2.4,0,0,1,1.4.15,2.43,2.43,0,0,1,1.08.9,2.35,2.35,0,0,1,.41,1.34,2.54,2.54,0,0,1-.19.94,2.47,2.47,0,0,1-1.35,1.32,2.53,2.53,0,0,1-.94.18Z" />' +
    '<path d="M14.49,31H6V5H26v7.89a3.35,3.35,0,0,1,1.19.64A3.3,3.3,0,0,1,28,14.61V5a2,2,0,0,0-2-2H6A2,2,0,0,0,4,5V31a2,2,0,0,0,2,2H16.23l-1.1-1.08A3.24,3.24,0,0,1,14.49,31Z" />' +
    '<rect width="36" height="36" fill="none"/>',
};

