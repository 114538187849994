// Use this function instead of window.open to prevent tabnabbing security vulnerability  https://app.clickup.com/t/2559602/CLIENT-521
// https://cheatsheetseries.owasp.org/cheatsheets/HTML5_Security_Cheat_Sheet.html#tabnabbing
export function openLink (url: string, name?: string, windowFeatures = '') {
  const newWindow = typeof window !== 'undefined' && window.open(url, name, 'noopener,noreferrer,' + windowFeatures);
  if (newWindow) {
    newWindow.opener = null;
  }
}

export function isFullscreenAvailable () {
  if (typeof window === 'undefined') {
    return false;
  }
  return !!(
    document.fullscreenEnabled ||
    document.webkitFullscreenEnabled ||
    document.mozFullScreenEnabled ||
    document.msFullscreenEnabled
  );
}
