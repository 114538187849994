import React from 'react';
import PropTypes from 'prop-types';

import { tracking } from '@grid-is/tracking';

import { Button } from '@/grid-ui/Button';
import { BasePage } from '@/components/BasePage';

import styles from './error.module.scss';

export function Error500 (props) {
  const { error } = props;
  tracking.logEvent('CriticalErrorPage Displayed', { message: String(error ? error.message || '' : '') });
  return (
    <BasePage layout="narrow" title="Oops! Something's wrong.">
      <div className={styles.fan} data-testid="generic-error-component">
        <img className={styles.blades} src="/img/fan-blades.svg" />
        <img className={styles.faint} src="/img/fan-faint.svg" />
        <img className={styles.base} src="/img/fan-base.svg" />
        <img className={styles.mouth} src="/img/fan-mouth.svg" />
        <img className={styles.shreds} src="/img/fan-shreds.svg" />
      </div>
      <div className={styles.info}>
        <h3>The sheet has hit the fan!</h3>
        <p>Sorry, it seems like something went wrong.
          Please try refreshing the page — if the problem persists, contact <a href="mailto:support@calculatorstudio.co">support@calculatorstudio.co</a>
        </p>
        <Button buttonType="primary" buttonSize="large" onClick={() => window.location.reload()}>Refresh page</Button>
      </div>
    </BasePage>
  );
}

Error500.propTypes = {
  error: PropTypes.object,
};
