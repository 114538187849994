// FIXME: when Apiary is factored out of the project, import from it
// this is a modified copy of the Excel collation code
const typeWeight = { boolean: 3, string: 2, number: 1 };

export default function collate (a, b) {
  // null is strange
  if (a == null && !b || b == null && !a) {
    // null = [0, "", FALSE, null]
    return 0;
  }
  const t_a_w = typeWeight[typeof a] || 0;
  const t_b_w = typeWeight[typeof b] || 0;
  // unknown types
  if (!t_a_w || !t_b_w) {
    return Math.sign(t_b_w - t_a_w);
  }
  // known types that are not the same
  if (t_a_w !== t_b_w) {
    // weight of type wins
    return (t_a_w < t_b_w) ? -1 : 1;
  }
  // known types are the same
  else if (t_a_w === 3) {
    // boolean
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  }
  else if (t_a_w === 2) {
    if (a === b) {
      return 0;
    }
    return (a < b) ? -1 : 1;
  }
  else if (t_a_w === 1) {
    if (a === b) {
      return 0;
    }
    return (a < b) ? -1 : 1;
  }
  return 0;
}
