import { IconBase } from '../../types';

export const DragHandle: IconBase = {
  // Name of icon.
  name: 'drag-handle',
  // Paths, we only use outline and solid.
  outline: `
  <path d="M23.6666 14.8333C23.6666 15.8459 24.4874 16.6667 25.4999 16.6667C26.5124 16.6667 27.3333 15.8459 27.3333 14.8333C27.3333 13.8208 26.5124 13 25.4999 13C24.4874 13 23.6666 13.8208 23.6666 14.8333Z" />
  <path d="M9.00033 14.8333C9.00033 15.8459 9.82114 16.6667 10.8337 16.6667C11.8462 16.6667 12.667 15.8459 12.667 14.8333C12.667 13.8208 11.8462 13 10.8337 13C9.82114 13 9.00033 13.8208 9.00033 14.8333Z" />
  <path d="M23.6666 22.1668C23.6666 23.1794 24.4874 24.0002 25.4999 24.0002C26.5124 24.0002 27.3333 23.1794 27.3333 22.1668C27.3333 21.1543 26.5124 20.3335 25.4999 20.3335C24.4874 20.3335 23.6666 21.1543 23.6666 22.1668Z" />
  <path d="M9.00033 22.1668C9.00033 23.1794 9.82114 24.0002 10.8337 24.0002C11.8462 24.0002 12.667 23.1794 12.667 22.1668C12.667 21.1543 11.8462 20.3335 10.8337 20.3335C9.82114 20.3335 9.00033 21.1543 9.00033 22.1668Z" />
  <path d="M16.3333 22.1668C16.3333 23.1794 17.1541 24.0002 18.1667 24.0002C19.1792 24.0002 20 23.1794 20 22.1668C20 21.1543 19.1792 20.3335 18.1667 20.3335C17.1541 20.3335 16.3333 21.1543 16.3333 22.1668Z" />
  <path d="M16.3333 14.8333C16.3333 15.8459 17.1541 16.6667 18.1667 16.6667C19.1792 16.6667 20 15.8459 20 14.8333C20 13.8208 19.1792 13 18.1667 13C17.1541 13 16.3333 13.8208 16.3333 14.8333Z" />
  `,
};

