import { useEffect, useRef, useState } from 'react';
import csx from 'classnames';

import { getElementTop, getIsIntegrationTests } from '@grid-is/browser-utils';
import { Icon } from '@grid-is/icon';
import { tracking } from '@grid-is/tracking';

import { Button } from '@/grid-ui/Button';
import { IconButton } from '@/grid-ui/IconButton';
import { Input } from '@/grid-ui/Input';
import { PopOver } from '@/grid-ui/PopOver';
import { useDeps } from '@/bootstrapping/dependencies';
import { SkeletonLoader } from '@/components/SkeletonLoader';
import { executionDelay } from '@/utils/executionDelay';

import { docs, getContextSpecificHelp, HelpDocsTitle, quickHelp } from './helpDocs';

import styles from './helpCenter.module.scss';

type THelpPopOverActions = {
  open: boolean,
  onClose: () => void,
  contactSupport: () => void,
  displayContactSupport: boolean,
  focusElement: string | undefined,
  initiatedFrom: 'In Document' | 'Home Sidebar' | 'Share Menu',
  contextSpecificHelp?: HelpDocsTitle,
}

export const HelpCenter = ({ open, onClose, contactSupport, displayContactSupport, focusElement, initiatedFrom, contextSpecificHelp }: THelpPopOverActions) => {
  const popoverRef = useRef<HTMLDivElement>(null);
  const { userEvents } = useDeps();
  const [ loading, setLoading ] = useState(false);
  const [ selected, setSelected ] = useState<HelpDocsTitle>();
  const [ transitioning, setTransitioning ] = useState(false);
  const [ search, setSearch ] = useState('');
  const [ showBackToHome, setShowBackToHome ] = useState(false);
  const [ maximized, setMaximized ] = useState(false);
  const showContextSpecificHelpInIntegrationTests = getIsIntegrationTests() && contextSpecificHelp;
  const url = selected ? `https://grid.is/embed/${docs[selected]}?width=full&scale_to_fit=true` : '';

  function closeHelpCenter () {
    setSearch('');
    setSelected(undefined);
    setMaximized(false);
    setShowBackToHome(false);
    onClose();
  }

  function goBackToHome (initiatedFrom: 'back-button' | 'need-help-with-something-else?') {
    tracking.logEvent('Help Center Home Page Returned To', {
      return_initiated_from: initiatedFrom,
      help_guide_name_returned_from: selected,
      element_in_focus: focusElement || 'none',
    });
    setSelected(undefined);
    setMaximized(false);
    setShowBackToHome(false);
  }

  function setHelp (selection: HelpDocsTitle) {
    setTransitioning(true);
    setTimeout(() => {
      setSelected(selection);
      setLoading(true);
    }, 200);
    setTimeout(() => {
      setTransitioning(false);
    }, 400);
  }

  function onSearch (value) {
    setSearch(value);
  }

  function onSearchKeyDown (e) {
    if (e.key === 'Escape' && search !== '') {
      e.preventDefault();
      e.stopPropagation();
      setSearch('');
    }
  }

  function onContactSupport () {
    contactSupport();
    onClose();
  }

  function getPopoverDimensions () {
    const contentTop = initiatedFrom === 'Home Sidebar' ? getElementTop('base-page-main') : getElementTop('doc-wrap');
    const popoverBottom = popoverRef.current?.getBoundingClientRect().bottom ?? 0;
    const popoverHeight = maximized ? Math.min(popoverBottom - contentTop, 800) : 512;
    const popoverWidth = popoverHeight * 0.8;
    return { popoverHeight, popoverWidth };
  }

  function togglePopoverSize () {
    setMaximized(!maximized);
    userEvents.helpCenterSizeToggled(maximized);
  }

  let links;
  const keys = Object.keys(docs);
  if (search.length > 1) {
    links = keys.filter(key => key.toLowerCase().includes(search.toLowerCase()));
  }
  else {
    links = keys;
  }

  useEffect(() => {
    if (search !== '') {
      executionDelay(() => {
        tracking.logEvent('Help Center Searched', {
          help_center_search_term: search,
          num_help_center_search_results: links.length,
        });
      }, 1000);
    }
  }, [ search, links.length ]);

  useEffect(() => {
    if (open) {
      const contextSpecificHelpDoc = contextSpecificHelp || getContextSpecificHelp(focusElement);
      tracking.logEvent('Help Accessed', {
        initiated_from: initiatedFrom,
        element_in_focus: focusElement || 'none',
        context_specific_help_guide_name: contextSpecificHelpDoc || 'none',
      });
      if (contextSpecificHelpDoc) {
        setHelp(contextSpecificHelpDoc);
        setShowBackToHome(true);
      }
    }
  // NOTE: We don't want to run this effect when the focusElement changes
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ open, initiatedFrom, contextSpecificHelp ]);

  const cypressContent = (
    <div data-testId="context-specific-help-cypress">
      <div>{selected}</div>
      <div>{url}</div>
    </div>
  );

  const { popoverHeight, popoverWidth } = getPopoverDimensions();

  return (
    <PopOver
      name="Help center"
      isOpen={open}
      onClose={closeHelpCenter}
      disableCloseOnClickOutside
      contentWidth={popoverWidth}
      shouldAnimate
      animationDirection={displayContactSupport ? undefined : 'fromLeft'}
      className={styles.helpCenter}
      content={
        // Hack we show different content in cypress to be able to test that the help center was opened with the correct context
        // Since the help context relies on documents that only exist on grid.is and we end in a redirect loop
        showContextSpecificHelpInIntegrationTests ? (cypressContent)
          : (
            <div className={csx(styles.popover, selected && styles.taller)} ref={popoverRef} style={{ height: `${popoverHeight}px` }}>
              {!selected &&
                <div className={csx(styles.main, transitioning && styles.swipeLeft)}>
                  <h4>Help Center</h4>
                  <div className={styles.menu}>
                    <div className={styles.searchInput}>
                      <Input
                        name="Help search"
                        placeholder="Type to search for topic ..."
                        autoFocus
                        value={search}
                        onChange={onSearch}
                        onKeyDown={onSearchKeyDown}
                        icon={search ? <Icon name="window-close" size={16} title="Clear search" /> : undefined}
                        onIconClick={() => setSearch('')}
                        autoCorrect="off"
                        autoCapitalize="off"
                        autoComplete="off"
                        />
                    </div>
                    {!search
                      ? (
                        <>
                          <div className={styles.commonHelp}>
                            <p>Quick guides</p>
                            <ul>
                              {quickHelp.map(key => (
                                <li key={key}>
                                  <button
                                    type="button"
                                    aria-label={key}
                                    onClick={() => {
                                      tracking.logEvent('Help Center Link Clicked', {
                                        help_link_to_guide_initiated_from: 'Quick guides',
                                        help_guide_name: key,
                                      });
                                      setHelp(key as HelpDocsTitle);
                                    }}
                                    >
                                    <span>{key}</span>
                                    <Icon name="angle" size={16} direction="right" />
                                  </button>
                                </li>
                              ))}
                            </ul>
                            <div className={styles.allHelpButton}>
                              <Button
                                buttonType="primary"
                                onClick={() => {
                                  tracking.logEvent('Help Center Link Clicked', {
                                    help_link_to_guide_initiated_from: 'See all button',
                                    help_guide_name: 'All help',
                                    help_center_search_term: search,
                                  });
                                  setHelp('All help guides');
                                }}
                                >
                                See all guides
                              </Button>
                            </div>
                          </div>
                          <div className={styles.flex} />
                          <div className={styles.bottomButtons}>
                            {displayContactSupport && <Button buttonType="tertiary" onClick={onContactSupport}>Contact support</Button>}
                          </div>
                        </>
                      )
                      : (
                        <div className={styles.searchResults}>
                          {links.length > 0 && <p>Get help for ...</p>}
                          {links.length === 0 && <p className={styles.light}>We couldn't find any matches. Try browsing help guides.</p>}
                          <ul>
                            {links && links.map(key => (
                              <li key={key}>
                                <button
                                  type="button"
                                  aria-label={key}
                                  onClick={() => {
                                    tracking.logEvent('Help Center Link Clicked', {
                                      help_link_to_guide_initiated_from: 'Search',
                                      help_guide_name: key,
                                      help_center_search_term: search,
                                    });
                                    setHelp(key);
                                  }}
                                  >
                                  <span>{key}</span>
                                  <Icon name="angle" size={16} direction="right" />
                                </button>
                              </li>
                            ))}
                          </ul>
                          {links.length === 0 &&
                            <div className={styles.allHelpButton}>
                              <Button
                                buttonType="primary"
                                onClick={() => {
                                  tracking.logEvent('Help Center Link Clicked', {
                                    help_link_to_guide_initiated_from: 'See all button',
                                    help_guide_name: 'All help',
                                    help_center_search_term: search,
                                  });
                                  setHelp('All help guides');
                                }}
                                >
                                See all guides
                              </Button>
                            </div>
                        }
                        </div>
                      )}
                  </div>
                </div>}
              {selected &&
                <div className={styles.instruction}>
                  <div className={styles.backButton}>
                    <IconButton
                      onClick={() => goBackToHome('back-button')}
                      iconName="angle"
                      iconDirection="left"
                      buttonType="grayScale"
                      ariaLabel="close"
                      buttonSize="medium"
                      />
                  </div>
                  {selected &&
                    <div className={styles.iframe}>
                      <SkeletonLoader delay={0} isLoading={loading}>
                        <div className={styles.skeleton}>
                          <section style={{ width: '100%', height: '32px' }} />
                          <section style={{ width: '100%', height: '64px' }} />
                          <section style={{ width: '75%', height: '32px' }} />
                          <section style={{ width: '100%', height: '64px' }} />
                          <section style={{ width: '100%', height: '32px' }} />
                          <section style={{ width: '100%', height: '32px' }} />
                          <section style={{ width: '75%', height: '32px' }} />
                        </div>
                      </SkeletonLoader>
                      <iframe
                        src={url}
                        onLoad={() => setTimeout(() => {
                          setLoading(false);
                          setMaximized(true);
                        }, 400)}
                        />
                    </div>}
                </div>}
              <div className={styles.actions}>
                {selected && <IconButton
                  onClick={() => {
                    window.open(`https://grid.is/@grid/${docs[selected]}`);
                  }}
                  iconName="pop-out"
                  buttonType="grayScale"
                  ariaLabel="open in new tab"
                  buttonSize="medium"
                  tooltip="Open in new tab"
                  tooltipDelay={0}
                  tooltipPlacement="bottom"
                  />}
                {selected && <IconButton
                  onClick={togglePopoverSize}
                  iconName={maximized ? 'shrink' : 'resize'}
                  buttonType="grayScale"
                  ariaLabel="Open in new tab"
                  buttonSize="medium"
                  tooltip="Resize window"
                  tooltipDelay={0}
                  tooltipPlacement="bottom"
                  />}
                <IconButton
                  onClick={closeHelpCenter}
                  iconName="window-close"
                  buttonType="grayScale"
                  ariaLabel="close"
                  buttonSize="medium"
                  tooltip="Close Help Center"
                  tooltipDelay={0}
                  tooltipPlacement="bottom"
                  />
              </div>
              {showBackToHome && <div className={styles.backToHomeButton}><Button onClick={() => goBackToHome('need-help-with-something-else?')} buttonType="primary">Need help with something else?</Button></div>}
            </div>)}
      ><div />
    </PopOver>
  );
};
