import { Ref, useCallback } from 'react';

function setRefs<T> (ref: Ref<T>, value: T) {
  if (typeof ref === 'function') {
    ref(value);
  }
  else if (ref) {
    (ref as any).current = value;
  }
}

export function useMergeRefs<ForwardRef, LocalRef extends ForwardRef> (
  forwardedRef: Ref<ForwardRef>,
  localRef: Ref<LocalRef>,
): (instance: LocalRef | null) => void {
  return useCallback(
    value => {
      setRefs(forwardedRef, value);
      setRefs(localRef, value);
    },
    [ forwardedRef, localRef ],
  );
}
