/* eslint-disable react/button-has-type */
import { AllHTMLAttributes, FC } from 'react';
import csx from 'classnames';

import { Direction, Icon, IconTypes } from '@grid-is/icon';

import styles from './Button.module.scss';

type NativeButtonProps = AllHTMLAttributes<HTMLButtonElement>

export type ButtonProps = NativeButtonProps & {
  buttonSize?: 'large'| 'medium-large' | 'medium' | 'small',
  iconName?: IconTypes,
  iconDirection?: Direction,
  iconAfterText?: boolean,
  type?: 'submit' | 'reset' | 'button',
} & ({
  buttonType?: 'primary' | 'tertiary' | 'destructive' | 'secondaryDestructive',
  color?: never,
} | {
  buttonType?: 'secondary',
  color?: 'yellow' | 'purple',
})

const IconButtonMap = {
  'large': 24,
  'medium': 16,
  'medium-large': 20,
  'small': 16,
};

export const Button: FC<ButtonProps> = ({ buttonType = 'secondary', buttonSize = 'medium', iconName, iconDirection, children, iconAfterText, type = 'button', color, ...props }) => (
  <button
    {...props}
    className={csx(styles.button, styles[buttonType], styles[buttonSize], color && styles[color])}
    type={type}
    >
    {iconName ? (
      <>
        {iconName && !iconAfterText && (
          <span className={styles.icon}>
            <Icon name={iconName} direction={iconDirection} size={IconButtonMap[buttonSize]} />
          </span>
        )}
        <span> {children}</span>
        {iconName && iconAfterText && (<span className={csx(styles.icon, styles.iconAfter)}><Icon name={iconName} direction={iconDirection} size={IconButtonMap[buttonSize]} /></span>)}
      </>
    ) : children}
  </button>
);
