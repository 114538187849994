import csx from 'classnames';

import { InputIconProps } from './types';

import styles from './InputIcon.module.scss';

export function InputIcon ({ children, size, onClick, disabled = false, iconPlacement = 'right', preventFocus = false }: InputIconProps) {
  if (onClick) {
    return (
      <button
        disabled={disabled}
        type="button"
        className={csx(
          styles.button,
          styles[size],
          disabled && styles.disabled,
          iconPlacement === 'left' && styles.leftIcon)}
        onClick={onClick}
        onMouseDown={e => {
          if (preventFocus) {
            e.preventDefault();
          }
        }}
        data-testid="input-icon-button"
        >
        <span className={styles.icon}>{children}</span>
      </button>
    );
  }

  return (
    <span
      className={csx(
        styles.icon,
        styles.withoutAction,
        iconPlacement === 'left' && styles.leftIcon,
        disabled && styles.disabled,
        styles[size])}
      >
      {children}
    </span>
  );
}

