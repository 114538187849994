import React from 'react';

import { TextLink } from '@/grid-ui/TextLink';

type ExtendedPolicyProps = {
  className?: string,
}

export function ExtendedPolicy ({ className }: ExtendedPolicyProps) {
  return (
    <div className={className}>
      <p>In order to help us improve your experience and further develop our product, we ask for your consent to monitor your use of Calculator Studio. This information will help us improve the product and provide you with better support when you need it, as well as for product improvement and development. It is our aim that the use of your data will be mutually beneficial. We use software such as FullStory to track your use of Calculator Studio. When you request support, our customer care team might also view your related projects and/or spreadsheets. We will never share the information outside our organization.</p>
      <p>You can withdraw your consent at any given time on your account page. Withdrawal of consent will not affect the legitimacy of the processing of your personal data prior to your withdrawal. Further information on our processing of personal data can be found in the <TextLink href="https://calculatorstudio.co/privacy-policy" target="_blank" rel="noreferrer">privacy policy.</TextLink></p>
    </div>
  );
}
