import React from 'react';
import csx from 'classnames';
import PropTypes from 'prop-types';

import { IconButton } from '@/grid-ui/IconButton';
import { Tooltip } from '@/grid-ui/Tooltip';

import { Menu } from './Menu';

import styles from './buttonMenu.module.scss';

export class ButtonMenu extends React.Component {
  static propTypes = {
    actions: PropTypes.array,
    onClick: PropTypes.func,
    className: PropTypes.string,
    icon: PropTypes.string,
    button: PropTypes.node,
    size: PropTypes.oneOf([ 'small', 'medium', 'large' ]),
    label: PropTypes.node,
    disabled: PropTypes.bool,
    open: PropTypes.bool,
    buttonClassName: PropTypes.string,
    tooltip: PropTypes.string,
    testId: PropTypes.string,
    usePortal: PropTypes.bool,
    onClose: PropTypes.func,
  };

  constructor (props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  static getDerivedStateFromProps (props, state) {
    if (props.open !== undefined && props.open !== state.open) {
      return { open: props.open };
    }

    return null;
  }

  componentDidUpdate (prevProps, prevState) {
    if (!this.state.open && prevState.open && this.props.onClose) {
      this.props.onClose();
    }
  }

  onClick = e => {
    e.stopPropagation();
    const isOpen = !this.state.open;
    this.setState({ open: isOpen });
    this.props.onClick && this.props.onClick(e, isOpen);
  };

  contains = elm => {
    return this.domRef?.contains(elm);
  };

  renderButton = () => {
    const { button, icon, buttonClassName, label, disabled, size, testId } = this.props;

    if (button) {
      return (
        <div
          className={csx(styles.button, buttonClassName, disabled && styles.disabled)}
          onClick={disabled ? undefined : this.onClick}
          data-testid={testId}
          >
          {button}
        </div>);
    }

    return (
      <IconButton
        buttonSize={size || 'medium'}
        buttonType="grayScale"
        type="button"
        ariaLabel={label}
        iconName={icon || 'ellipsis-vertical'}
        onClick={this.onClick}
        disabled={disabled}
        className={buttonClassName}
        data-testid={testId || `icon-button-${icon || 'ellipsis-vertical'}`}
        />
    );
  };

  render () {
    const actions = (this.props.actions || []).filter(d => typeof d === 'object');
    if (!React.Children.count(this.props.children) && !actions.length) {
      return null;
    }
    const { className, tooltip, usePortal } = this.props;
    const { open } = this.state;
    return (
      <div
        className={csx(styles.container, className)}
        ref={elm => {
          this.domRef = elm;
        }}
        >

        <Tooltip label={tooltip} disabled={!tooltip || open} hideOnElementClick>
          {this.renderButton()}
        </Tooltip>
        <Menu
          {...this.props}
          actions={actions}
          open={open}
          onClose={() => this.setState({ open: false })}
          portalTarget={usePortal ? this.domRef : undefined}
          />
      </div>
    );
  }
}
