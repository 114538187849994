import { MouseEvent, ReactNode } from 'react';
import csx from 'classnames';

import { Icon } from '@grid-is/icon';

import styles from './Pill.module.scss';

export type PillProps = {
  className?: string,
  text?: string | ReactNode,
  icon?: ReactNode,
  disabled?: boolean,
  onClick?: (event: MouseEvent) => void,
  onDismiss?: (event: MouseEvent) => void,
  isFocused?: boolean,
}

export function Pill ({
  className,
  disabled = false,
  icon,
  isFocused = false,
  onClick = () => {},
  onDismiss,
  text,
}: PillProps) {
  return (
    <div className={csx(styles.pill, disabled && styles.disabled, isFocused && styles.isFocused, className)} onClick={onClick}>
      {icon && <div className={styles.icon}>{icon}</div>}
      {text && <span>{text}</span>}
      {(!disabled && onDismiss) &&
        <button
          type="button"
          data-testid="pill-dismiss"
          onClick={e => {
            e.stopPropagation();
            onDismiss(e);
          }}
          >
          <Icon className={styles.close} size={16} name="close" variant="solid" />
        </button>
      }
    </div>
  );
}
