// generates a RFC4122 version 4 compliant UUID
const template = [
  1, 1, 1, 1, 1, 1, 1, 1,
  '-',
  1, 1, 1, 1,
  '-',
  '4', 1, 1, 1,
  '-',
  2, 1, 1, 1,
  '-',
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
];
export function uuid (): string {
  return template.reduce<string>((a, c) => {
    if ((c + '') === c) {
      return a + c;
    }
    const r = Math.random() * 16 | 0;
    const v = (c === 1) ? r : (r & 0x3 | 0x8);
    return a + v.toString(16);
  }, '');
}

const parseTemplate = [ 0, 0, 0, 0, '-', 0, 0,  '-', 0, 0, '-', 0, 0, '-', 0, 0, 0, 0, 0, 0 ];
export function uuidFromBytes (array: number[]): string {
  let i = 0;
  return parseTemplate.reduce<string>((a, c) => {
    if (c === '-') {
      return a + c;
    }
    const byte = array[i++] || 0;
    return a + (byte < 16 ? '0' : '') + byte.toString(16);
  }, '');
}
