import vars from './src/_vars.module.scss';

const {
  designsMobile,
  designsTablet,
  designsDesktop,

  easeOutExpo,
  ...colors
} = vars;

const breakpoints = {
  designsMobile,
  designsTablet,
  designsDesktop,
};

const easing = {
  easeOutExpo,
};

export { breakpoints, colors, easing };
