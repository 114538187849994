/**
 *
 * @param {string} pathname: The path of the current page
 * @returns {(string|null)}: Returns the pathname of the current page but replaces slugs with hardcoded
 * values to reduce sentry transaction cardinality. e.g. /@hjalli => /<user>
 */
export function mapSlugToRoute (pathname) {
  if (/^\/@[^/]+?\/?$/.test(pathname)) {
    return '/<user>';
  }
  if (/^\/@[^/]+\/[^/]+$/.test(pathname)) {
    return '/<user>/<document>';
  }
  if (/^\/@[^/]+\/[^/]+\/edit$/.test(pathname)) {
    return '/<user>/<document>/edit';
  }
  if (/^\/embed\/[^/]+$/.test(pathname)) {
    return '/embed/<document>';
  }
  if (/^\/user\/signup\/[0-9a-f-]+/.test(pathname)) {
    return '/user/signup/<signup_id>';
  }
  if (/^\/@[^/]+\/[^/]+\/preview$/.test(pathname)) {
    return '/<user>/<document>/preview';
  }
  return null;
}
