import { IconBase } from '../../types';

export const TwoWayArrows: IconBase = {
  // Name of icon.
  name: 'two-way-arrows',
  // Paths, we only use outline and solid.
  outline: '<path d="M23.43,16.83A1,1,0,0,0,22,18.24L25.72,22H7.83a1,1,0,0,0,0,2H25.72L22,27.7a1,1,0,1,0,1.42,1.41L29.53,23Z" className="clr-i-outline clr-i-outline-path-1"></path><path d="M13.24,18.45a1,1,0,0,0,.71-1.71L10.24,13H28.12a1,1,0,0,0,0-2H10.24l3.71-3.73a1,1,0,0,0-1.42-1.41L6.42,12l6.11,6.14A1,1,0,0,0,13.24,18.45Z" className="clr-i-outline clr-i-outline-path-2"></path>',
  solid: '<path d="M23.43,16.83A1,1,0,0,0,22,18.24L25.72,22H7.83a1,1,0,0,0,0,2H25.72L22,27.7a1,1,0,1,0,1.42,1.41L29.53,23Z" className="clr-i-outline clr-i-outline-path-1"></path><path d="M13.24,18.45a1,1,0,0,0,.71-1.71L10.24,13H28.12a1,1,0,0,0,0-2H10.24l3.71-3.73a1,1,0,0,0-1.42-1.41L6.42,12l6.11,6.14A1,1,0,0,0,13.24,18.45Z" className="clr-i-outline clr-i-outline-path-2"></path>',
};

