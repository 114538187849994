import { paths, schemas } from './generatedTypes';
import { getJSON, postJSON } from './request';

export type NotificationTypes = schemas['Notification']['type'];

type GetNotificationsResponse = paths['/notifications']['get']['responses']['200']['content']['application/json'];

export function notifications () {
  return getJSON<GetNotificationsResponse>('/notifications');
}

export function markNotificationsAsSeen (notification_ids: string[]) {
  return setNotificationState({ notification_ids, state: 'seen' });
}

export function markNotificationsAsRead (notification_ids: string[]) {
  return setNotificationState({ notification_ids, state: 'read' });
}

export function markNotificationsAsUnseen (notification_ids: string[]) {
  return setNotificationState({ notification_ids, state: 'unseen' });
}

type NotificationStateRequest = paths['/notifications/state']['post']['requestBody']['content']['application/json'];

function setNotificationState (payload: NotificationStateRequest) {
  return postJSON<{}, NotificationStateRequest>('/notifications/state', payload);
}
