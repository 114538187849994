/* globals FULLSTORY_ID */
import { useEffect } from 'react';
import * as FullStory from '@fullstory/browser';

import { EXTENDED_PERMISSIONS } from '@/constants';
import { useAuth } from '@/utils/auth';
const fullstoryOrgId = FULLSTORY_ID;

type Props = {
  disabled?: boolean,
};

export function useFullStory ({ disabled }: Props) {
  const { user } = useAuth();
  useEffect(() => {
    const hasExtendedPermissions = user && user.agreements?.some(agreement => agreement.name === EXTENDED_PERMISSIONS);
    if (hasExtendedPermissions) {
      if (window._FS) {
        window.FS = window._FS;
        window._FS = undefined;
        FullStory.restart();
      }
      else {
        FullStory.init({ orgId: fullstoryOrgId });
      }
      FullStory.identify(user.id, { displayName: user.username });
    }
    else {
      try {
        FullStory.shutdown();
        if (window.FS) {
          window._FS = window.FS;
          window.FS = undefined;
        }
      }
      catch (e) {
        // Fullstory throws an error if you try to stop recordings
        // if the API has not been initialized but does not seem to
        // expose a way to evaluate if fullstory is running.
      }
    }
  }, [ disabled, user ]);
}
