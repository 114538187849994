import { Controller, useForm } from 'react-hook-form';

import { requestSupport } from '@/api/document';
import { Button } from '@/grid-ui/Button';
import { Checkbox } from '@/grid-ui/Checkbox';
import { FieldWrapper } from '@/grid-ui/FieldWrapper';
import { Input } from '@/grid-ui/Input';
import { Modal } from '@/grid-ui/Modal';

import styles from './HelpActions.module.scss';

type TContactSupportModal = {
  open: boolean,
  onClose: () => void,
  documentId: string,
  onRequestSubmit: () => void,
  setNotification: (message: string) => void,
};

const defaultValues = {
  description: '',
  shareWithSupport: true,
};

export const ContactSupportModal = ({ open, onClose, documentId, onRequestSubmit, setNotification }: TContactSupportModal) => {
  const { handleSubmit, control, reset } = useForm({ defaultValues });

  function onSubmit (data) {
    requestSupport(documentId, data.description, data.shareWithSupport)
      .then(() => {
        onCloseModal();
        onRequestSubmit();
        setNotification('Thank you for contacting support. We\'ve received your request and will get back to you shortly.');
      })
      .catch(err => {
        setNotification('Uh oh, something went wrong. Please try submitting again.');
        onRequestSubmit();
        console.error(err);
      });
  }

  function onCloseModal () {
    reset(defaultValues);
    onClose();
  }

  return (
    <Modal
      open={open}
      header="Contact support"
      size="medium"
      closeButton
      onClose={onCloseModal}
      >
      <form onSubmit={handleSubmit(onSubmit)} className={styles.contentWrapper}>
        <FieldWrapper label="Tell us a little about what’s going on">
          <Controller
            name="description"
            control={control}
            defaultValue={defaultValues.description}
            render={({ field }) => (
              <Input
                textarea
                rows={5}
                autoFocus
                {...field}
                />)}
            />
        </FieldWrapper>
        <Controller
          name="shareWithSupport"
          control={control}
          defaultValue={defaultValues.shareWithSupport}
          render={({ field }) => (
            <Checkbox
              label="Share my project and workbook with support staff. All information shared remains strictly confidential."
              checked={field.value}
              {...field}
              ref={field.ref}
              />
          )}
          />
        <div className={styles.footerWrapper}>
          <Button
            buttonType="primary"
            buttonSize="large"
            type="submit"
            >
            Submit
          </Button>
        </div>
      </form>
    </Modal>
  );
};
