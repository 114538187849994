const classMap = {
  'x-small': 'xsmall',
  'small': 'small',
  'medium': 'medium',
  'large': 'large',
  'x-large': 'xlarge',
  'full': 'full',
};

/**
 * @param {string} [width]
 * @param {''|'row'|'col'|'text'|'true'|'false'|boolean} [inlineMode='']
 * @return {string}
 */
export default function widthClass (width, inlineMode = '') {
  let prefix = 'col'; // ...or "false"
  const mode = String(inlineMode);
  if (mode === 'row') {
    prefix = 'row';
  }
  else if (mode === 'text' || mode === 'true') {
    prefix = 'txt';
  }
  if (width || mode === 'row') {
    const widthClass = classMap[width] || 'medium';
    return `${prefix}_mode w_${widthClass}`;
  }
  return `${prefix}_mode`;
}
