/* globals process */
import { format } from 'numfmt';

/**
 * Formats a spreadsheet cell value as a string.
 *
 * @param {object | null} cell The cell
 * @param {string | null} [fmtStr] The format string
 * @param {string | null} [locale] The locale
 * @return {string} The formatted value
 */
export default function printCell (cell, fmtStr = undefined, locale = undefined) {
  if (!cell || cell.v == null) {
    return '';
  }
  const z = fmtStr || cell.z || 'General';
  const v = (cell.v instanceof Error) ? String(cell.v) : cell.v;
  try {
    return format(z, v, { locale: (locale || undefined), nbsp: true });
  }
  catch (err) {
    if (process.env.NODE_ENV !== 'production') {
      console.warn(err);
    }
  }
  return '######';
}
