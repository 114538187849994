import React from 'react';

import { getViewport } from '@grid-is/browser-utils';

import { TextLink } from '@/grid-ui/TextLink';

// UI element
export const DOCUMENT_SIZE = 768;
export const EDITOR_PANEL_WIDTH = 328;
export const EDITOR_PANEL_HEADER_HEIGHT = 40;
export const ELEMENT_POPOVER_WIDTH = 328;
export const SPREADSHEET_RIBBON_HEIGHT = 41;
export const SPREADSHEET_PANEL_HEIGHT = getViewport().height !== 0 ? (getViewport().height / 3) : 200;
export const COMMENT_WIDTH = 312;
export const COMMENT_BUTTON_SPACING = 64;
export const CONVERSATIONS_WIDTH = COMMENT_WIDTH + COMMENT_BUTTON_SPACING;

// Editor behavior
export const MAX_LAYOUT_COLUMNS = 6;

// IDs
export const EDITOR_ID = 'editor';
export const EDITOR_COMMENT_CONTAINER_ID = 'editor-comments';
export const DOCUMENT_AREA_ID = 'editor-document-area';
export const HEADER_PORTAL_ID = 'header-menus';
export const CONVERSATIONS_TOGGLE_PORTAL_ID = 'conversations-toggle-portal';
export const ELEMENT_MENU_PORTAL_ID = 'element-menu-portal';
export const DATA_BUTTON_PORTAL_ID = 'data-button-portal';
export const UNDO_ACTION_PORTAL_ID = 'undo-action-portal';
export const FORM_BUILDER_PORTAL_ID = 'form-builder-portal';
export const MANAGE_DRIVES_PORTAL_ID = 'manage-drives-portal';
export const ELEMENT_QUICK_ACTIONS_PORTAL_ID = 'element-quick-actions-portal';
export const ELEMENT_QUICK_MENU_ID = 'element-quick-menu';
export const HUBSPOT_CONVERSATIONS_ID = 'hubspot-conversations';

// File specs
export const MAX_SOURCE_SIZE = 26214400;

// User agreements
export const BETA_TERMS = 'beta-terms';
export const BETA_PRIVACY_POLICY = 'beta-privacy-policy';
export const LIMITED_PERMISSIONS = 'limited-permissions';
export const EXTENDED_PERMISSIONS = 'extended-permissions';

// Formats
export const DATE_FORMAT = 'MMM D, YYYY';
export const DATETIME_FORMAT = 'MMM D, YYYY, HH:MM';
export const TIME_FORMAT = 'HH:MM';
export const TIME_FORMAT_WITH_SEC = 'HH:MM:SS';
export const SHORT_DATE_FORMAT_MONTH_FIRST = 'MM/DD/YYYY';
export const SHORT_DATE_FORMAT_DAY_FIRST = 'DD/MM/YYYY';

// Errors
export const SOURCE_ERRORS  = {
  cannot_fetch_from_remote: {
    title: 'Unable to connect to spreadsheet in cloud drive',
    body: 'We encountered an error when attempting to connect to the spreadsheet in its source cloud drive. Try connecting again — if the problem persists, please contact support@calculatorstudio.co.',
  },
  conversion_error: {
    title: 'Unable to process file',
    body: 'We encountered an unexpected error while trying to process the file.',
  },
  processing_timeout: {
    title: 'We ran out of time!',
    body: 'We encountered a timeout while processing the file. Please try again — if the problem persists, contact support@calculatorstudio.co.',
  },
  converted_workbook_too_big: {
    title: 'Unable to convert file',
    body: 'Converting this spreadsheet will result in a file that is too large to handle smoothly. Try reducing conversion size by removing complex backgrounds, borders, and unnecessary sheets, columns, and rows.',
  },
  too_big: {
    title: 'File is too large',
    body: 'Your spreadsheet exceeds GRID\'s current 25 MB size limit. Please try again with a smaller file.',
  },
  unrecognized_format: {
    title: 'Cannot read file',
    body: 'We supports .xlsx and .csv files. Try saving your file in one of these supported formats and upload again. Please ensure that .xlsx files are not password protected.',
  },
  // the http_error error only exists in the client
  http_error: {
    title: 'Connection error',
    body: 'Oops! We encountered an error while trying to connect to the spreadsheet needed by this document. Try reloading the page to resolve the issue.',
  },
  // the duplicate_names error only exists in the client
  duplicate_names: {
    title: 'Duplicate filenames',
    body: 'A project can\'t have two spreadsheets with the same filename. Please ensure that your filenames are distinct, and try again.',
  },
  fetch_timed_out: {
    title: 'An error occurred',
    body: 'Whoops! We\'ve encountered an error trying to fetch a spreadsheet requested by this document.',
  },
  upload_failed: {
    title: 'Unable to upload file',
    body: 'Oops! We encountered an error while trying to upload your file.',
  },
  error_fetching_from_url: {
    title: 'Error fetching from URL',
    body: 'We were unable to fetch your spreadsheet from the given URL. Review the URL and make sure it works.',
  },
  file_type_not_supported: {
    title: 'Unsupported file type',
    body: 'The URL must point to a CSV or XLSX file.',
  },
  default: {
    title: 'Failed to connect spreadsheet',
    body: 'We encountered a new kind of error while trying to connect your spreadsheet. Humans have been notified and will investigate!',
  },
};
SOURCE_ERRORS.max_file_size_exceeded = SOURCE_ERRORS.too_big;

export const CLOUD_DRIVE_SYNC_ERRORS = {
  source_not_refreshable: {
    title: 'Unable to sync data source',
    body: 'Oops. We can\'t sync your data source without access to your cloud drive. Please reconnect your cloud drive to get up to date.',
    footer: 'Once reconnected, try refreshing the data source. If the problem persists, reconnect the data source by choosing “Replace" and selecting the file in your cloud drive.',
  },
  cloud_drive_disconnected: {
    title: 'Unable to sync data source',
    body: 'Oops. We can\'t sync your data source without access to your cloud drive. Please reconnect your cloud drive to get up to date.',
    footer: 'Once reconnected, try refreshing the data source. If the problem persists, reconnect the data source by choosing “Replace" and selecting the file in your cloud drive.',
  },
  cloud_object_not_found: {
    title: 'Unable to sync data source',
    body: 'The cloud drive claims the file or object this source points to no longer exists.  It may have been removed or you have lost access to it.',
    footer: 'If the issue persists, reconnect the data source by choosing “Replace" and selecting the object in your cloud drive.',
  },
  default: {
    title: 'Unable to sync data source',
    body:  'Oops. We\'ve encountered an error while trying to sync your file. Please try again – if the issue persists, please contact support@calculatorstudio.co.',
  },
};

export const CLOUD_DRIVE_AUTOMATIC_REFRESH_NOTICE = {
  delayed: {
    title: 'Delayed refresh',
    body: 'Spreadsheets with read-only access need a bit more time – it may take up to 3 minutes to refresh.',
    footer: (<span>Read <TextLink href="https://grid.is/@grid/cloud-provider-updates-vZfExz9QRDCBBmiltLeuPw" target="_blank" rel="noreferrer">more</TextLink> about how we update data from cloud sources.</span>),
  },
  unavailable: {
    title: 'Keeping your data up to date',
    body: 'This data source won’t update automatically. Sync your data source with your document in the Data panel to ensure that the latest updates to the document are reflected.',
    footer: (<span>Read <TextLink href="https://grid.is/@grid/cloud-provider-updates-vZfExz9QRDCBBmiltLeuPw" target="_blank" rel="noreferrer">more</TextLink> about how we update data from cloud sources.</span>),
  },
};

export const LOCK_ERROR = {
  title: 'You\'ve got company 👋 ',
  body: (
    <>
      <p>Someone else is currently editing this document.</p>
      <p>You can try again later, or if you know who is editing, contact them directly.</p>
    </>
  ),
};

// providers
export const providers = {
  airtable: 'Airtable',
  google: 'Google Drive',
  dropbox: 'Dropbox',
  onedrive: 'OneDrive',
  notion: 'Notion',
  slack: 'Slack',
};

// providers icons
export const providerIcons = {
  airtable: 'airtable',
  google: 'google-sheets',
  dropbox: 'dropbox',
  onedrive: 'one-drive',
  notion: 'notion',
  slack: 'slack',
};

// modes
export const VIEW = 'view';
export const EDIT = 'edit';
export const COMMENT = 'comment';

export const API_FEATURE_BRANCH_COOKIE = 'api_host';
export const CLIENT_FEATURE_BRANCH_COOKIE = 'client_host';
export const DUMMY_UPLOAD_ID = 'DUMMY_UPLOAD_ID';
