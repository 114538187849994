import { schemas } from './generatedTypes';

export class AuthorizationRequiredError extends Error {
  status: 401;

  constructor (message: string) {
    super(message);
    this.name = 'AuthorizationRequiredError';
    this.status = 401;
  }
}

export class NotFoundError extends Error {}

export type APIError = schemas['Error'];
