import React from 'react';
import csx from 'classnames';
import PropTypes from 'prop-types';

import styles from './separator.module.scss';

export function Separator (props) {
  return <div className={csx(styles.separator, props.className)} />;
}

Separator.propTypes = {
  className: PropTypes.string,
};
