import { Children, cloneElement, FC, ReactElement, ReactNode } from 'react';
import csx from 'classnames';

import { uuid } from '@grid-is/uuid';

import { Label } from '@/grid-ui/Label';

import { HelperText } from './HelperText';

import styles from './FieldWrapper.module.scss';

export type FieldWrapperProps = {
  children: ReactElement,
  className?: string,
  label?: string,
  helperText?: ReactNode,
  id?: string,
  required?: boolean,
  disabled?: boolean,
  hasError?: boolean,
  tooltip?: string | ReactNode,
};

export const FieldWrapper: FC<FieldWrapperProps> = ({
  children,
  className,
  disabled,
  hasError,
  helperText,
  id,
  label,
  required,
  tooltip,
}) => {
  const inputId = id || `input-${uuid()}`;
  const helperId = 'helper-' + inputId;
  const child = Children.only(children);
  const childProps = { id: inputId, ...(helperText && { ariaDescribedBy: helperId }) };
  return (
    <div>
      <div className={csx(styles.container, className && className, disabled && styles.disabled, hasError && styles.error)}>
        {child && cloneElement(child, childProps)}
        {label && <Label label={label} id={inputId} disabled={disabled} required={required} hasError={hasError} tooltip={tooltip} />}
      </div>
      {helperText && <HelperText id={helperId} disabled={disabled} hasError={hasError}>{helperText}</HelperText>}
    </div>
  );
};
