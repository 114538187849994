import { colors } from '@grid-is/styles';

const palette = [
  colors.pinkElephant,
  colors.coolEmber,
  colors.sunflower,
  colors.neonLights,
];

// only allow chars that are space, number or have upper/lower case forms
function cleanStr (str: string) {
  return (str || '')
    .trim()
    .toUpperCase()
    .split('')
    .filter(d => /^[ \d]$/.test(d) || d !== d.toLowerCase())
    .join('');
}

type DefaultAvatarProps = {
  text: string,
  numChars?: 1 | 2,
}

export function DefaultAvatar ({ text, numChars = 2 }: DefaultAvatarProps) {
  const cleanText = cleanStr(text || '');
  let color = colors.ash;
  if (cleanText) {
    const c = cleanText.split('').reduce((a, d) => a + d.charCodeAt(0), 0);
    color = palette[c % palette.length];
  }
  const names = cleanText.split(/\s+/);
  const chrs = names[0].slice(0, 1) + (numChars > 1 && names.length > 1 ? names[names.length - 1].slice(0, 1) : '');

  return (
    <svg data-testid="default-avatar" viewBox="0 0 200 200">
      <rect x="0" y="0" width="200" height="200" fill={color} />
      <text fill="white" x="100" y="100" fontWeight="600" fontSize="100" textAnchor="middle" dy=".34em">{chrs || '?'}</text>
    </svg>
  );
}
