import { IconBase } from '../../types';

export const Bell: IconBase = {
  // Name of icon.
  name: 'bell',
  // Paths, we only use outline and solid.
  outline: (
    '<path d="M30.797 26.6625C29.9547 25.9028 29.2172 25.0319 28.6044 24.0733C27.9355 22.75 27.5345 21.3048 27.4251 19.8227V15.4572C27.4309 13.1292 26.5961 10.8792 25.0776 9.12987C23.5592 7.38058 21.4615 6.25244 19.1788 5.95741V4.81744C19.1788 4.50455 19.0559 4.20448 18.8372 3.98323C18.6185 3.76199 18.3219 3.6377 18.0126 3.6377C17.7033 3.6377 17.4066 3.76199 17.1879 3.98323C16.9692 4.20448 16.8464 4.50455 16.8464 4.81744V5.97508C14.5841 6.29138 12.5118 7.42634 11.0132 9.16975C9.51471 10.9132 8.69151 13.1469 8.69612 15.4572V19.8227C8.58671 21.3048 8.18577 22.75 7.51682 24.0733C6.91484 25.0297 6.18919 25.9005 5.35914 26.6625C5.26596 26.7453 5.19128 26.8473 5.14007 26.9615C5.08886 27.0758 5.06229 27.1998 5.06213 27.3253V28.5271C5.06213 28.7615 5.15417 28.9863 5.31799 29.152C5.48181 29.3177 5.70401 29.4108 5.93569 29.4108H30.2205C30.4522 29.4108 30.6744 29.3177 30.8382 29.152C31.002 28.9863 31.094 28.7615 31.094 28.5271V27.3253C31.0939 27.1998 31.0673 27.0758 31.0161 26.9615C30.9649 26.8473 30.8902 26.7453 30.797 26.6625ZM6.87913 27.6434C7.69189 26.8491 8.40749 25.9591 9.0106 24.9923C9.85324 23.3941 10.3449 21.6307 10.452 19.8227V15.4572C10.4173 14.4215 10.589 13.3894 10.9569 12.4223C11.3248 11.4551 11.8813 10.5728 12.5933 9.82775C13.3052 9.08272 14.1581 8.49024 15.1012 8.0856C16.0442 7.68096 17.0581 7.47243 18.0825 7.47243C19.1068 7.47243 20.1207 7.68096 21.0637 8.0856C22.0068 8.49024 22.8597 9.08272 23.5716 9.82775C24.2836 10.5728 24.8401 11.4551 25.208 12.4223C25.5759 13.3894 25.7476 14.4215 25.7129 15.4572V19.8227C25.82 21.6307 26.3117 23.3941 27.1543 24.9923C27.7574 25.9591 28.473 26.8491 29.2858 27.6434H6.87913Z" fill="currentColor"/>' +
    '<path d="M18.1215 32.3623C18.6718 32.3495 19.1998 32.1402 19.6123 31.7715C20.0248 31.4027 20.295 30.8983 20.3753 30.3475H15.7804C15.8629 30.9133 16.1458 31.4296 16.5764 31.8002C17.0071 32.1708 17.5562 32.3706 18.1215 32.3623Z" fill="currentColor"/>'
  ),
  outlineBadged: (
    '<path d="M25.1899 15.2727C27.6752 15.2727 29.6899 13.258 29.6899 10.7727C29.6899 8.28742 27.6752 6.27271 25.1899 6.27271C22.7047 6.27271 20.6899 8.28742 20.6899 10.7727C20.6899 13.258 22.7047 15.2727 25.1899 15.2727Z" fill="currentColor"/>' +
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M28.6044 24.0733C29.2172 25.0319 29.9547 25.9028 30.797 26.6625C30.8902 26.7453 30.9649 26.8473 31.0161 26.9615C31.0673 27.0758 31.0939 27.1998 31.094 27.3253V28.5271C31.094 28.7615 31.002 28.9863 30.8382 29.152C30.6744 29.3177 30.4522 29.4108 30.2205 29.4108H5.93569C5.70401 29.4108 5.48181 29.3177 5.31799 29.152C5.15417 28.9863 5.06213 28.7615 5.06213 28.5271V27.3253C5.06229 27.1998 5.08886 27.0758 5.14007 26.9615C5.19128 26.8473 5.26596 26.7453 5.35914 26.6625C6.18919 25.9005 6.91484 25.0297 7.51682 24.0733C8.18577 22.75 8.58671 21.3048 8.69612 19.8227V15.4572C8.69151 13.1469 9.51471 10.9132 11.0132 9.16975C12.5118 7.42634 14.5841 6.29138 16.8464 5.97508V4.81744C16.8464 4.50455 16.9692 4.20448 17.1879 3.98323C17.4066 3.76199 17.7033 3.6377 18.0126 3.6377C18.3219 3.6377 18.6185 3.76199 18.8372 3.98323C19.0559 4.20448 19.1788 4.50455 19.1788 4.81744V5.95741C19.8285 6.04139 20.4633 6.19288 21.074 6.40698C20.6641 6.79358 20.3086 7.23728 20.0203 7.72536C19.3892 7.55783 18.738 7.47243 18.0825 7.47243C17.0581 7.47243 16.0442 7.68096 15.1012 8.0856C14.1581 8.49024 13.3052 9.08272 12.5933 9.82775C11.8813 10.5728 11.3248 11.4551 10.9569 12.4223C10.589 13.3894 10.4173 14.4215 10.452 15.4572V19.8227C10.3449 21.6307 9.85324 23.3941 9.0106 24.9923C8.40749 25.9591 7.69189 26.8491 6.87913 27.6434H29.2858C28.473 26.8491 27.7574 25.9591 27.1543 24.9923C26.3117 23.3941 25.82 21.6307 25.7129 19.8227V16.7502C26.3133 16.6984 26.8884 16.5581 27.4251 16.3425V19.8227C27.5345 21.3048 27.9355 22.75 28.6044 24.0733ZM19.6123 31.7715C19.1998 32.1402 18.6718 32.3495 18.1215 32.3623C17.5562 32.3706 17.0071 32.1708 16.5764 31.8002C16.1458 31.4296 15.8629 30.9133 15.7804 30.3475H20.3753C20.295 30.8983 20.0248 31.4028 19.6123 31.7715Z" fill="currentColor"/>'
  ),
};

