import { getCookie } from './cookies';
import { parse } from './query';
import { isBrowser } from './runtimeContext';

export function getApiFeatureBranchName () {
  if (!isBrowser()) {
    return null;
  }
  const apiHost = getCookie('api_host') || null;
  if (!apiHost) {
    return null;
  }
  return apiHost.replace('-api.ingrid.is', '');
}

export function getClientFeatureBranchName () {
  if (!isBrowser()) {
    return null;
  }
  const clientHost = getCookie('client_host') || null;
  if (!clientHost) {
    return null;
  }
  return clientHost
    .replace('grid-client-git-', '')
    .replace('-grid-is.vercel.app', '');
}

export function isSearchEngineBot (useragent = '') {
  return /\b(?:googlebot|bingbot|yandex|baiduspider|duckduckbot|slurp)\b/i.test(useragent);
}

export function getIsThumbnailMode () {
  if (isBrowser()) {
    if (parse(window.location.search).preview || /^\/@[^/]+\/[^/]+\/preview$/.test(window.location.pathname)) {
      return true;
    }
  }
  return false;
}

export function getIsIntegrationTests () {
  return typeof window !== 'undefined' && window.navigator.userAgent.includes('GRID e2e tests');
}

export function isInIframe () {
  return isBrowser() && window.location !== window.parent.location;
}
