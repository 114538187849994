import { chain } from '@grid-is/iterators';

import { UserType } from '@/api/user';
import { SelectOptionType } from '@/grid-ui/Select/types';

function generateMonthYearLabel (month: number, year: number): string {
  const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December',
  ];
  return `${monthNames[month - 1]} ${year}`;
}

export function determineSignupDayFromAgreements (agreements?: UserType['agreements']): Date {
  const signupDate = chain(agreements || [])
    .map(agreement => new Date(agreement.when))
    .min();

  if (!signupDate) {
    return new Date();
  }
  return signupDate;
}

export function createMonthOption (date: Date) {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const monthString = month < 10 ? `0${month}` : `${month}`;
  return {
    value: `${year}-${monthString}`,
    label: generateMonthYearLabel(month, year),
  };
}

export function getMonthOptions (signupDate: Date): SelectOptionType[] {
  let currentDate = new Date();
  const options: { value: string, label: string }[] = [];

  const maxMonthsToShow = 12;
  let i = 0;

  while (i < maxMonthsToShow && currentDate > signupDate) {
    options.push(createMonthOption(currentDate));

    // Move to the previous month
    currentDate = new Date(currentDate.setMonth(currentDate.getMonth() - 1));
    i++;
  }

  return options;
}

export function deconstructOptionValue (optionValue: string): { fromDate: Date, toDate: Date } {
  const startDate = new Date(optionValue);

  // Calculate the end date by adding one month to the start date and subtracting one day.
  const endDate = new Date(startDate);
  endDate.setMonth(startDate.getMonth() + 1);
  endDate.setDate(endDate.getDate() - 1);

  return {
    fromDate: startDate,
    toDate: endDate,
  };
}

