import { useEffect, useRef } from 'react';

export function fullScreenElement () {
  if (typeof document === 'undefined') {
    return null;
  }
  return (
    document.fullscreenElement || // @ts-expect-error
    document.webkitFullscreenElement || // @ts-expect-error
    document.mozFullScreenElement || // @ts-expect-error
    document.msFullscreenElement
  );
}

let _promiseTimeout;

/**
 * @param {HTMLElement} elm
 * @returns {Promise<void>}
 */
export function requestFullscreen (elm) {
  let r = null;
  try {
    if (elm.requestFullscreen) {
      r = elm.requestFullscreen();
    }
    else if ('webkitRequestFullscreen' in elm && typeof elm.webkitRequestFullscreen === 'function') {
      r = elm.webkitRequestFullscreen();
    }
    else if ('mozRequestFullScreen' in elm && typeof elm.mozRequestFullScreen === 'function') {
      r = elm.mozRequestFullScreen();
    }
    else if ('msRequestFullscreen' in elm && typeof elm.msRequestFullscreen === 'function') {
      r = elm.msRequestFullscreen();
    }
  }
  catch (err) {
    return Promise.reject();
  }
  if (r && r.then) {
    return r;
  }
  // if the API does not return a promise, wait until we have a fullScreenElement
  return new Promise(resolve => {
    const haveFS = () => {
      clearTimeout(_promiseTimeout);
      const fsElm = fullScreenElement();
      if (fsElm) {
        resolve(undefined);
      }
      _promiseTimeout = setTimeout(haveFS, 100);
    };
    haveFS();
  });
}

/**
 * @param {{
 *   element: React.MutableRefObject<HTMLElement | null>;
 *   onOpen: () => void;
 *   onClose: (duration: number) => void;
 * }} options
 */
export function useFullScreen (options) {
  /** @type {React.MutableRefObject<number | undefined>} */
  const timestampRef = useRef();
  const { element, onOpen, onClose } = options;
  const enterFullScreen = () => {
    requestFullscreen(element?.current || document.documentElement)
      .then(onOpen)
      .catch(() => onClose(0));
  };

  const exitFullscreen = () => {
    const elm = document;
    if (elm.exitFullscreen) {
      return elm.exitFullscreen();
    }
    else if ('webkitExitFullscreen' in elm && typeof elm.webkitExitFullscreen === 'function') {
      return elm.webkitExitFullscreen();
    }
    else if ('mozCancelFullScreen' in elm && typeof elm.mozCancelFullScreen === 'function') {
      return elm.mozCancelFullScreen();
    }
    else if ('msExitFullscreen' in elm && typeof elm.msExitFullscreen === 'function') {
      return elm.msExitFullscreen();
    }
  };

  useEffect(() => {
    const onFullscreen = () => {
      if (!fullScreenElement()) {
        // Calculate duration of presentation using the start timestamp.
        const now = new Date().getTime();
        const then = timestampRef.current || now;
        const duration = now - then;
        // ensure we're not still waiting for a promise to complete
        clearTimeout(_promiseTimeout);
        onClose(duration);
      }
      else {
        // Timestamp beginning of presentation.
        timestampRef.current = new Date().getTime();
      }
    };

    document.addEventListener('webkitfullscreenchange', onFullscreen, false);
    document.addEventListener('mozfullscreenchange', onFullscreen, false);
    document.addEventListener('msfullscreenchange', onFullscreen, false);
    document.addEventListener('MSFullscreenChange', onFullscreen, false);
    document.addEventListener('fullscreenchange', onFullscreen, false);

    return () => {
      document.removeEventListener('webkitfullscreenchange', onFullscreen);
      document.removeEventListener('mozfullscreenchange', onFullscreen);
      document.removeEventListener('msfullscreenchange', onFullscreen);
      document.removeEventListener('MSFullscreenChange', onFullscreen);
      document.removeEventListener('fullscreenchange', onFullscreen);
    };
  }, [ element, onClose ]);

  return {
    open: enterFullScreen,
    close: exitFullscreen,
  };
}
