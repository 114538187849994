import { StateSchema } from 'xstate';

import { UserGroup } from '@/api/groups';
import { User } from '@/api/user';

export interface UserGroupsContext {
  currentUserId: string,
  groups: UserGroup[],
  selectedGroup?: UserGroup,
  newGroupName: string,
  error?: string,
  loadMoreCursor?: string,
  startOpen: boolean,
  fallbackUsers: User[],
  fallbackInvites: string[],
}

export type AddMembersEvent = {type: 'ADD_MEMBERS', newUsers: User[], newInvites: string[] }
export type RemoveMemberEvent = { type: 'REMOVE_MEMBER', id: string, memberType: 'user' | 'email'};
export type ViewGroupEvent = { type: 'VIEW_GROUP', groupId: string };
export type ManageGroupEvent = { type: 'MANAGE_GROUP', groupId: string };
export type SetNewGroupNameEvent = { type: 'SET_NEW_GROUP_NAME', newGroupName: string };
export type UserGroupsEvents =
  {type: 'ACCEPT' } |
  {type: 'CANCEL' } |
  {type: 'CLOSE' } |
  {type: 'DELETE_GROUP' } |
  {type: 'GO_BACK' } |
  {type: 'LEAVE_GROUP' } |
  {type: 'LOAD_MORE' } |
  {type: 'OPEN' } |
  {type: 'CREATE_GROUP' } |
  AddMembersEvent |
  RemoveMemberEvent |
  ManageGroupEvent |
  SetNewGroupNameEvent |
  ViewGroupEvent;

export enum States {
  fetchGroups = 'fetchGroups',
  closed = 'closed',
  deleteGroup = 'deleteGroup',
  error = 'error',
  leaveGroup = 'leaveGroup',
  manageGroup = 'manageGroup',
  overview = 'overview',
  viewGroup = 'viewGroup',
}

export interface ManageGroupsStates extends StateSchema<UserGroupsContext> {
  states: {
    createGroup: {},
    pendingGroup: {},
    ready: {},
    pendingAddMembers: {},
    pendingRemoveMember: {},
  },
}

export interface OverviewStates extends StateSchema<UserGroupsContext> {
  states: {
    pendingGroups: {},
    ready: {},
  },
}

export interface ViewGroupStates extends StateSchema<UserGroupsContext> {
  states: {
    pendingGroup: {},
    ready: {},
  },
}

export interface DeleteGroupStates extends StateSchema<UserGroupsContext> {
  states: {
    delete: {},
    pendingDelete: {},
  },
}

export interface LeaveGroupStates extends StateSchema<UserGroupsContext> {
  states: {
    leave: {},
    pendingLeave: {},
  },
}

export interface UserGroupsStates extends StateSchema<UserGroupsContext> {
  states: {
    [States.fetchGroups]: {},
    [States.closed]: {},
    [States.deleteGroup]: DeleteGroupStates,
    [States.error]: {},
    [States.leaveGroup]: LeaveGroupStates,
    [States.manageGroup]: ManageGroupsStates,
    [States.overview]: OverviewStates,
    [States.viewGroup]: ViewGroupStates,
  },
}

export type SendEvent = (event: UserGroupsEvents['type'], options?: Record<string, any>) => void;
