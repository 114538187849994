import { useEffect } from 'react';

import { logEvent } from './tracking';

export function useAppcues () {
  useEffect(() => {
    // Adding Appcues events in our warehouse.
    const onAppcuesEvent = (eventName, eventObject) => {
      const eventObjectWithSnakeKeys = {};
      const toSnakeCase = camel => {
        return camel.replace(/([A-Z])/g, '_$1').toLowerCase();
      };
      for (const key in eventObject) {
        eventObjectWithSnakeKeys[toSnakeCase(key)] = eventObject[key];
      }
      logEvent(`Appcues ${eventObject.name}`, eventObjectWithSnakeKeys, { skipAppcues: true });
    };
    window.Appcues && window.Appcues.on('all', onAppcuesEvent);
    return () => {
      window.Appcues && window.Appcues.off('all', onAppcuesEvent);
    };
  }, []);
}
