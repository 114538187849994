export default function leastSignificant (value) {
  if (!value || typeof value !== 'number' || !isFinite(value)) {
    return 0;
  }
  // round the value to 15 significant digits to reduce likelyhood
  // of floating point errors...
  const d = Math.ceil(Math.log10(value < 0 ? -value : value));
  const magnitude = 10 ** (15 - Math.floor(d));
  const shifted = Math.round(value * magnitude);
  // convert to string
  const numstr = String(Math.abs(shifted / magnitude));
  let haveNonZero = false;
  let s = '';
  // convert all but last digit to zero: (123 => 003, 1.123 => 0.001)
  for (let i = numstr.length - 1; i >= 0; i--) {
    const digit = numstr[i];
    if (digit === '.') {
      s = '.' + s;
    }
    else if (digit === '0' || haveNonZero) {
      s = '0' + s;
    }
    else {
      s = digit + s;
      haveNonZero = true;
    }
  }
  // return significance of converted number
  return Math.floor(Math.log10(parseFloat(s)));
}
