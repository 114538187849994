import { BillingService } from './billing';
import { DocumentService } from './document';
import { FormSubmissionService } from './formSubmission';
import { IntegrationService } from './integrations';

export class Api {
  billing: BillingService;
  documents: DocumentService;
  formSubmissions: FormSubmissionService;
  integrations: IntegrationService;

  constructor ({
    billing = new BillingService(),
    documents = new DocumentService(),
    formSubmissions = new FormSubmissionService(),
    integrations = new IntegrationService(),
  }) {
    this.billing = billing;
    this.documents = documents;
    this.formSubmissions = formSubmissions;
    this.integrations = integrations;
  }
}
