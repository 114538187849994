import type { NextPageContext } from 'next';

import { deleteCookie, getCookie, setCookie } from './cookies';
import { isBrowser } from './runtimeContext';

const DAY = 86400; // one day in seconds
const COOKIE = 'access_token';
// _user_token is used as a hack to get around Google Slides not maintaining cookies.
const allowWindowMember = true;

// XXX: this doesn't really belong in a browser utils package. This is auth-related.

export const token = {
  get (ctx?: Pick<NextPageContext, 'req'>): string {
    if (allowWindowMember && isBrowser() && window._user_token) {
      return window._user_token;
    }
    return getCookie(COOKIE, ctx) || '';
  },

  set (value: string): void {
    if (allowWindowMember && isBrowser()) {
      window._user_token = value;
    }
    setCookie(COOKIE, value, DAY * 30);
  },

  clear (): void {
    if (allowWindowMember && isBrowser()) {
      delete window._user_token;
    }
    deleteCookie(COOKIE);
  },
};
