import { MouseEventHandler, useState } from 'react';
import csx from 'classnames';

import { Icon, IconTypes } from '@grid-is/icon';

import styles from './ToggleButton.module.scss';

export type ToggleButtonProps = {
  value?: boolean,
  onChange?: (newValue: boolean) => void,
  onMouseDown?: MouseEventHandler<HTMLSpanElement>,
  id?: string,
  testId?: string,
  obid?: string,
  name?: string,
  disabled?: boolean,
  locked?: boolean,
  size?: 'small' | 'large',
  icons?: [ IconTypes, IconTypes],
  tabIndex?: number,
}

const iconSizeMap = {
  small: 10,
  large: 14,
};

export const ToggleButton = ({ id, name, value = true, onMouseDown = () => {}, onChange = () => {}, size, disabled, locked, testId = 'toggle', obid, icons, tabIndex }: ToggleButtonProps) => {
  const [ nudge, setNudge ] = useState(false);

  const onClick: MouseEventHandler<HTMLSpanElement> = e => {
    if (locked) {
      e.preventDefault();
      setNudge(false);
      setTimeout(() => {
        setNudge(true);
      }, 1);
    }
    else if (!disabled && (e.target as HTMLElement).tagName === 'SPAN') {
      onChange(!value);
    }
  };

  return (
    <span
      className={csx(styles.switch, size === 'small' && styles.small, locked && styles.locked, nudge && styles.nudge)}
      onClick={onClick}
      onMouseDown={onMouseDown}
      data-testid={testId}
      data-obid={obid}
      >
      <input
        type="checkbox"
        aria-checked={value}
        checked={value}
        name={name}
        onChange={e => !disabled && !locked && onChange(e.target.checked)}
        disabled={disabled}
        data-testid={`input-${testId}`}
        id={id}
        tabIndex={tabIndex}
        />
      <span className={csx(styles.slider, size === 'small' && styles.small, disabled && styles.disabled, value ? styles.on : styles.off)}>
        {
          icons?.map(name => {
            const iconSize = size ? iconSizeMap[size] : iconSizeMap.large;
            return (
              <Icon className={styles.icon} size={iconSize} key={name} name={name} />
            );
          })
        }
      </span>
    </span>
  );
};
