import { isBrowser } from './runtimeContext';

function getNativeLocalStorage () {
  if (!isBrowser()) {
    return null;
  }
  try {
    const ls = window.localStorage;
    ls.setItem('_grid_', '_grid_');
    ls.removeItem('_grid_');
    return ls;
  }
  catch (e) {
    return null;
  }
}

export const localStorage = getNativeLocalStorage() || {
  setItem (id, obj) {
    localStorage[id] = String(obj);
  },
  getItem (id) {
    return localStorage[id] || null;
  },
  removeItem (id) {
    delete localStorage[id];
  },
};
