import { ReactNode, useEffect, useRef, useState } from 'react';
import csx from 'classnames';
import Head from 'next/head';

import { useMounted } from '@grid-is/custom-hooks';
import { colors } from '@grid-is/styles';

import { ApiFeatureBranchBanner, ClientFeatureBranchBanner } from '@/components/FeatureBranchBanners';
import { SideNav } from '@/components/Navigation/SideNav';
import { TopNav } from '@/components/Navigation/TopNav';

import { Banner } from '../BillingBoundary/Banner';

import styles from './basepage.module.scss';

type BasePageProps = {
  children?: ReactNode,
  title: string,
  layout?: 'narrow' | 'wide' | 'document' | 'normal' | 'full' | 'home',
  hideMenus?: boolean,
  hideNavigation?: boolean,
  isEmbed?: boolean,
  // A property to control if the layout page should be statically generated or server side rendered.
  ssr?: boolean,
  utmParams?: string | URLSearchParams,
  disableContentAutoOverflow?: boolean,
  contentBgColor?: string,
};

const layoutSize = {
  narrow: [ styles.col4 ],
  normal: [ styles.col4, styles.col6 ],
  wide: [ styles.col4, styles.col6, styles.col8 ],
  full: [ styles.full ],
  document: [ styles.col4, styles.col6 ],
  home: [ styles.home ],
};

export const BasePage = ({
  children,
  layout = 'wide',
  hideNavigation,
  hideMenus,
  ssr = false,
  title,
  isEmbed,
  utmParams,
  disableContentAutoOverflow,
  contentBgColor,
}: BasePageProps) => {
  const [ showMobileSideNav, setShowMobileSideNav ] = useState(false);

  const contentRef = useRef<HTMLDivElement>(null);
  const homeRef = useRef<HTMLDivElement>(null);

  const mounted = useMounted();
  const pageTitle = title ? title + ' – Calculator Studio' : 'Calculator Studio';

  function onClick (e) {
    if (e.target.matches('button')) {
      e.target.focus();
    }
  }

  useEffect(() => {
    document.addEventListener('click', onClick, { capture: true });

    return () => {
      document.removeEventListener('click', onClick);
    };
  }, []);

  const contentTop = contentRef.current?.getBoundingClientRect().top || 0;
  const homeTop = homeRef.current?.getBoundingClientRect().top || 0;

  if (!mounted && !ssr) {
    return null;
  }
  return (
    <div className={styles.basePage} style={{ ...(isEmbed && { position: 'static' }) }} data-testid="base-page">
      <Head>
        <title>{pageTitle}</title>
        <meta name="theme-color" content={colors.white} />
        <meta name="referrer" content="unsafe-url" />
        <meta name="twitter:site" content="@GRID_hq" />
        <meta name="title" content={pageTitle} key="metaTitle" />
        <meta property="og:site_name" content="GRID" />
        <meta property="og:title" content={pageTitle} key="ogTitle" />
        <meta property="twitter:title" content={pageTitle} key="twitterTitle" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <meta name="pinterest" content="nopin" />
      </Head>
      <Banner />
      <div className={styles.site}>
        {!hideNavigation &&
          <SideNav
            utmParams={utmParams}
            showMobileSideNav={showMobileSideNav}
            hideMobileSideNav={() => setShowMobileSideNav(false)}
            hideMenus={hideMenus}
            />}
        <div
          id="content"
          className={csx(styles.content, disableContentAutoOverflow && styles.disableOverflowAuto)}
          ref={contentRef}
          style={{ ...contentBgColor && { background: contentBgColor } }}
          >
          {!hideNavigation &&
            <TopNav
              utmParams={utmParams}
              showMobileSideNav={() => setShowMobileSideNav(true)}
              hideMenus={hideMenus}
              />}
          <div
            id="base-page-main"
            className={csx(styles.main, ...layoutSize[layout], isEmbed && styles.embedded)}
            ref={homeRef}
            style={{ ...(layout === 'home' && { minHeight: `calc(100% - ${homeTop - contentTop}px)` }) }}
            >
            {children}
          </div>
        </div>
      </div>
      <div id="confirmation-root" />
      <ApiFeatureBranchBanner />
      <ClientFeatureBranchBanner />
      {ssr && <div style={{ display: 'none' }} data-testid="ssr-status">true</div>}
    </div>
  );
};
