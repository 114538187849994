export default function transpose (array) {
  if (!array || !Array.isArray(array[0])) {
    return null;
  }
  const rows = array.length;
  const cols = array.reduce((a, c) => Math.max(a, c.length), 0);
  const output = [];
  for (let j = 0; j < cols; j++) {
    const r = output[j] || (output[j] = []);
    for (let i = 0; i < rows; i++) {
      r[i] = array[i][j];
    }
  }
  return output;
}

