import { IconBase } from '../../types';

const path = 'M32.5 5H4.5C3.96957 5 3.46086 5.21071 3.08579 5.58579C2.71071 5.96086 2.5 6.46957 2.5 7V29C2.5 29.5304 2.71071 30.0391 3.08579 30.4142C3.46086 30.7893 3.96957 31 4.5 31H32.5C33.0304 31 33.5391 30.7893 33.9142 30.4142C34.2893 30.0391 34.5 29.5304 34.5 29V7C34.5 6.46957 34.2893 5.96086 33.9142 5.58579C33.5391 5.21071 33.0304 5 32.5 5V5ZM4.5 29V7H32.5V29H4.5Z' +
  'M15.5 19H21.5V26H19.9V20.6H17.1V26H15.5V19Z' +
  'M23.5 16.0001H29.5V26.0001H27.9V17.6001H25.1V26.0001H23.5V16.0001Z' +
  'M13.5 13.1549V10H7.50001V17.6922L9.10001 16.4823V11.6H11.9V14.3649L13.5 13.1549Z' +
  'M9.10001 20.4315L7.50001 21.6414V26H9.10001V20.4315Z' +
  'M11.9 18.3141L13.5 17.1042V26H11.9V18.3141Z' +
  'M30.0433 10.1941L21.2094 16.4561L15.8426 14.1561L7.06903 20.7907L6.25476 19.7139L15.6537 12.6064L21.0458 14.9173L29.2626 9.09277L30.0433 10.1941Z';

export const ElementCombo: IconBase = {
  name: 'element-combo',
  outline: '<path d="' + path + '" fill="currentColor" />',
};

