import EventEmitter from 'component-emitter';

export class TypedEmitter<T extends Record<string, any>> {
  emitter = new EventEmitter();

  on<K extends keyof T> (event: K, listener: (...args: T[K] extends undefined ? [] : [ T[K] ]) => void) {
    this.emitter.on(String(event), listener);
  }

  off<K extends keyof T> (event: K, listener: (...args: T[K] extends undefined ? [] : [ T[K] ]) => void) {
    this.emitter.off(String(event), listener);
  }

  emit<K extends keyof T> (event: K, ...args: T[K] extends undefined ? [] : [ T[K] ]) {
    this.emitter.emit(String(event), ...args);
  }
}
