import { colors } from '@grid-is/styles';

import { IconBase } from '../../types';

export const Template: IconBase = {
  // Name of icon.
  name: 'template',
  // Paths, we only use outline and solid.
  outline: '' +
  `<path d="M33.28 6.3C33.3914 6.3 33.4982 6.34425 33.577 6.42302C33.6557 6.50178 33.7 6.60861 33.7 6.72V33.28C33.7 33.3914 33.6557 33.4982 33.577 33.577C33.4982 33.6557 33.3914 33.7 33.28 33.7H6.72C6.60861 33.7 6.50178 33.6557 6.42302 33.577C6.34425 33.4982 6.3 33.3914 6.3 33.28V6.72C6.3 6.60861 6.34425 6.50178 6.42302 6.42302C6.50178 6.34425 6.60861 6.3 6.72 6.3H33.28ZM33.28 4.5H6.72C6.13203 4.50263 5.56889 4.73737 5.15313 5.15313C4.73737 5.56889 4.50263 6.13203 4.5 6.72V33.28C4.50263 33.868 4.73737 34.4311 5.15313 34.8469C5.56889 35.2626 6.13203 35.4974 6.72 35.5H33.28C33.868 35.4974 34.4311 35.2626 34.8469 34.8469C35.2626 34.4311 35.4974 33.868 35.5 33.28V6.72C35.4974 6.13203 35.2626 5.56889 34.8469 5.15313C34.4311 4.73737 33.868 4.50263 33.28 4.5V4.5Z" fill="${colors.squidInk}"/>` +
  `<path d="M16.7 10.8V15.2H10.8V10.8H16.7ZM17.53 9H10C9.73478 9 9.48043 9.10536 9.29289 9.29289C9.10536 9.48043 9 9.73478 9 10V16C9 16.2652 9.10536 16.5196 9.29289 16.7071C9.48043 16.8946 9.73478 17 10 17H17.56C17.8252 17 18.0796 16.8946 18.2671 16.7071C18.4546 16.5196 18.56 16.2652 18.56 16V10C18.56 9.73478 18.4546 9.48043 18.2671 9.29289C18.0796 9.10536 17.8252 9 17.56 9H17.53Z" fill="${colors.squidInk}"/>` +
  `<path d="M16.7 21.8V29.2H10.8V21.8H16.7ZM17.56 20H9.94C9.6907 20 9.4516 20.099 9.27532 20.2753C9.09904 20.4516 9 20.6907 9 20.94V30.06C9 30.3093 9.09904 30.5484 9.27532 30.7247C9.4516 30.901 9.6907 31 9.94 31H17.56C17.8093 31 18.0484 30.901 18.2247 30.7247C18.401 30.5484 18.5 30.3093 18.5 30.06V20.94C18.5 20.6907 18.401 20.4516 18.2247 20.2753C18.0484 20.099 17.8093 20 17.56 20Z" fill="${colors.squidInk}"/>` +
  `<path d="M29.2002 10.8V29.2H23.3002V10.8H29.2002ZM30.0002 9H22.4702C22.205 9 21.9506 9.10536 21.7631 9.29289C21.5756 9.48043 21.4702 9.73478 21.4702 10V30C21.4702 30.2652 21.5756 30.5196 21.7631 30.7071C21.9506 30.8946 22.205 31 22.4702 31H30.0002C30.2654 31 30.5198 30.8946 30.7073 30.7071C30.8949 30.5196 31.0002 30.2652 31.0002 30V10C31.0002 9.73478 30.8949 9.48043 30.7073 9.29289C30.5198 9.10536 30.2654 9 30.0002 9Z" fill="${colors.squidInk}"/>`
  ,
};
