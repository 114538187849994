import { IconBase } from '../../types';

export const AddData: IconBase = {
  // Name of icon.
  name: 'add-data',
  // Paths, we only use outline and solid.
  outline: `<path d="M14.89 7.99996C15.4423 7.99996 15.89 8.44768 15.89 8.99996C15.89 9.55225 15.4423 9.99996 14.89 9.99996H10V15.21C10 15.7622 9.5523 16.21 9.00001 16.21C8.44773 16.21 8.00001 15.7622 8.00001 15.21V9.99996H2.89001C2.33773 9.99996 1.89001 9.55225 1.89001 8.99996C1.89001 8.44768 2.33773 7.99996 2.89001 7.99996H8.00001V3.20996C8.00001 2.65768 8.44773 2.20996 9.00001 2.20996C9.5523 2.20996 10 2.65768 10 3.20996V7.99996H14.89Z" />
  <path fill-rule="evenodd" clip-rule="evenodd" d="M14 15.25V16H13.1C12.4925 16 12 16.4477 12 17C12 17.5523 12.4925 18 13.1 18H14L14 32.75C14 33.4404 14.4477 34 15 34C15.5523 34 16 33.4404 16 32.75V30H21V32.875C21 33.4963 21.4477 34 22 34C22.5523 34 23 33.4963 23 32.875V30H28V32.875C28 33.4963 28.4477 34 29 34C29.5523 34 30 33.4963 30 32.875V30H32.75C33.4404 30 34 29.5523 34 29C34 28.4477 33.4404 28 32.75 28H30V24H33C33.5523 24 34 23.5523 34 23C34 22.4477 33.5523 22 33 22H30V18H32.9C33.5075 18 34 17.5523 34 17C34 16.4477 33.5075 16 32.9 16H16V15.25C16 14.5596 15.5523 14 15 14C14.4477 14 14 14.5596 14 15.25ZM28 24V28H23V24H28ZM28 18V22H23V18H28ZM21 18H16V22H21V18ZM21 24H16V28H21V24Z" />
  </svg>`,
};

