import { createContext } from 'react';

export type PayWallFeature = 'sharing' | 'embed'

export type PayWallGate = {
  feature: PayWallFeature,
  onSuccess: () => void,
  initiatedFrom: 'In document share button' | 'In document embed button' | 'Document list share button',
}

export type BannerType = {
  description: string,
  confirmButton?: string,
  secondaryButton?: string,
  url?: string,
  urlShouldOpenInNewTab?: boolean,
  secondaryUrl?: string,
  secondaryUrlShouldOpenInNewTab?: boolean,
  type?: 'warning' | 'danger',
  tracking?: {
    onConfirm: () => void,
    onOpen: () => void,
  },
}

type BillingContextValue = {
  ensureCanUseFeature: (feature: PayWallGate['feature'], onSuccess: PayWallGate['onSuccess'], initiatedFrom: PayWallGate['initiatedFrom']) => void,
  banner?: BannerType,
}

export const BillingContext = createContext<BillingContextValue>({
  ensureCanUseFeature: () => {},
});
