import { ReactNode } from 'react';

import { query } from '@grid-is/browser-utils';

import { UserType } from '@/api/user';

import { CommentDetails, DocumentMilestoneReachedNotification, GridNotification, GridNotificationBase } from './types';

export function constructNotificationUrl (notification: GridNotification, loggedInUser: UserType, currentPath: string = '', isMobile = false): string | null {
  switch (notification.type) {
    case 'commented_on_document':
    case 'replied_to_your_comment':
    case 'replied_to_a_comment_on_your_document':
      return `/@${notification.details.user.username}/${notification.details.document.slug}?ref=notification&notification_channel=in_app&notification_type=${notification.type}`;
    case 'document_shared_with_you':
    case 'document_shared_with_group':
    case 'document_shared_with_domain':
    case 'you_were_granted_edit_access':
    case 'group_granted_edit_access':
      return `/@${notification.details.user.username}/${notification.details.document.slug}?ref=notification&notification_channel=in_app&notification_type=${notification.type}`;
    case 'document_liked':
      return `/@${loggedInUser.username}/${notification.details.document.slug}?ref=notification&notification_channel=in_app&notification_type=${notification.type}`;
    case 'document_milestone_reached':
      return `/@${loggedInUser.username}/${notification.details.document.slug}?stats=1&ref=notification&notification_channel=in_app&notification_type=${notification.type}`;
    case 'user_added_to_group':
    case 'you_were_added_to_group':
      // XXX: what about 'email_invited_to_group'?
      if (isMobile) {
        return `/@${loggedInUser.username}?userGroups=1`;
      }
      return query.set(currentPath, { userGroups: 1 });
    default:
      return null;
  }
}

export function getMilestoneReachedText (notification: DocumentMilestoneReachedNotification): {text: ReactNode, title: string} {
  const { milestone, document } = notification.details;
  if (milestone === 1) {
    return {
      text: (
        <>
          <strong>{document.title}</strong> was viewed for the first time!
        </>
      ),
      title: `${document.title} was viewed for the first time!`,
    };
  }
  return {
    text: (
      <>
        <strong>{document.title}</strong> just reached <strong>{milestone}</strong> views!
      </>
    ),
    title: `${document.title} just reached ${milestone} views!`,
  };
}

export function getCommentText (notification: GridNotificationBase<CommentDetails>): {text: ReactNode, title: string} {
  const message = {
    commented_on_document: 'started a conversation in',
    replied_to_your_comment: 'has replied to your conversation in',
    replied_to_a_comment_on_your_document: 'has replied to a conversation in',
  };

  const documentTitle = notification.details.document.title || 'Untitled';
  return {
    text: (
      <>
        <strong>{notification.details.user.name}</strong>
        {message[notification.type]}
        <strong>{documentTitle}</strong>
      </>
    ),
    title: `${notification.details.user.name} ${message[notification.type]} ${documentTitle}: ${notification.details.comment.body}`,
  };
}
