import { colors } from '@grid-is/styles';

import { IconBase } from '../../types';

export const DocumentStatistics: IconBase = {
  // Name of icon.
  name: 'document-statistics',
  // Paths, we only use outline and solid.
  outline: `<path fill-rule="evenodd" clip-rule="evenodd" d="M18.0055 6.41184L17.9139 6.41213H18.0971L18.0055 6.41184ZM6.89286 11.6885C9.33372 8.69892 12.957 6.71267 17.0493 6.44322V9.27989H18.9611V6.44318C23.1601 6.71946 26.8654 8.80324 29.3062 11.9238L27.0866 13.2469L28.0655 14.8891L30.3831 13.5076C31.6297 15.6332 32.3443 18.1085 32.3443 20.7506C32.3443 21.072 32.3338 21.3909 32.313 21.707H29.4768V23.6188H32.0576C31.6151 25.7989 30.6781 27.7989 29.3683 29.4977C29.0459 29.9158 29.0808 30.5181 29.4766 30.8674C29.8724 31.2168 30.4789 31.1808 30.8045 30.7652C32.9665 28.0057 34.2562 24.5278 34.2562 20.7506C34.2562 11.7757 26.9805 4.5 18.0055 4.5C9.03054 4.5 1.75488 11.7757 1.75488 20.7506C1.75488 24.5278 3.04459 28.0057 5.20656 30.7652C5.53216 31.1808 6.13867 31.2168 6.53448 30.8674C6.9303 30.5181 6.96517 29.9158 6.6428 29.4977C5.33293 27.7989 4.39591 25.7989 3.95348 23.6188H6.53426V21.707H3.69808C3.67729 21.3909 3.66672 21.072 3.66672 20.7506C3.66672 17.9997 4.4414 15.4297 5.78449 13.2469L7.88914 14.8212L9.03429 13.2903L6.89286 11.6885ZM16.893 28.4461C18.3906 28.9618 20.0226 28.1658 20.5382 26.6683C20.8024 25.9009 21.1054 22.7081 21.4008 19.5947C21.6819 16.6324 21.9561 13.7419 22.1837 13.0809C22.0477 13.476 20.7779 15.4798 19.3487 17.7352C17.5091 20.6382 15.4054 23.9582 15.1152 24.801C14.5995 26.2985 15.3955 27.9305 16.893 28.4461Z" fill=fill=${colors.squidInk}/>`,
};
