import React from 'react';
import Link from 'next/link';
import PropTypes from 'prop-types';

export function Profilelink (props) {
  return (
    <Link href={'/@' + (props.username || 'grid')}>
      {props.children}
    </Link>
  );
}

Profilelink.propTypes = {
  username: PropTypes.string,
  children: PropTypes.node,
};
