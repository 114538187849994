import { IconBase } from '../../types';

export const Close: IconBase = {
  // Name of icon.
  name: 'close',
  // Paths, we only use outline and solid.
  outline: '<path fill-rule="evenodd" clip-rule="evenodd" d="M18 4.8C10.7231 4.8 4.8 10.7231 4.8 18C4.8 25.2769 10.7231 31.2 18 31.2C25.2769 31.2 31.2 25.2769 31.2 18C31.2 10.7231 25.2769 4.8 18 4.8ZM3 18C3 9.729 9.729 3 18 3C26.271 3 33 9.729 33 18C33 26.271 26.271 33 18 33C9.729 33 3 26.271 3 18Z"/><path d="M24.1725 22.0515C24.759 22.638 24.759 23.586 24.1725 24.1725C23.88 24.465 23.496 24.612 23.112 24.612C22.728 24.612 22.344 24.465 22.0515 24.1725L18 20.121L13.9485 24.1725C13.656 24.465 13.272 24.612 12.888 24.612C12.504 24.612 12.12 24.465 11.8275 24.1725C11.241 23.586 11.241 22.638 11.8275 22.0515L15.879 18L11.8275 13.9485C11.241 13.362 11.241 12.414 11.8275 11.8275C12.414 11.241 13.362 11.241 13.9485 11.8275L18 15.879L22.0515 11.8275C22.638 11.241 23.586 11.241 24.1725 11.8275C24.759 12.414 24.759 13.362 24.1725 13.9485L20.121 18L24.1725 22.0515Z" />',
  solid: '<path d="M18 3C9.729 3 3 9.729 3 18C3 26.271 9.729 33 18 33C26.271 33 33 26.271 33 18C33 9.729 26.271 3 18 3ZM24.1725 22.0515C24.759 22.638 24.759 23.586 24.1725 24.1725C23.88 24.465 23.496 24.612 23.112 24.612C22.728 24.612 22.344 24.465 22.0515 24.1725L18 20.121L13.9485 24.1725C13.656 24.465 13.272 24.612 12.888 24.612C12.504 24.612 12.12 24.465 11.8275 24.1725C11.241 23.586 11.241 22.638 11.8275 22.0515L15.879 18L11.8275 13.9485C11.241 13.362 11.241 12.414 11.8275 11.8275C12.414 11.241 13.362 11.241 13.9485 11.8275L18 15.879L22.0515 11.8275C22.638 11.241 23.586 11.241 24.1725 11.8275C24.759 12.414 24.759 13.362 24.1725 13.9485L20.121 18L24.1725 22.0515Z" />',
};

