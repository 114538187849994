/* eslint-disable handle-callback-err */
import React from 'react';
import * as Sentry from '@sentry/nextjs';
import { withRouter } from 'next/router';
import PropTypes from 'prop-types';

import { token } from '@grid-is/browser-utils';
import { tracking } from '@grid-is/tracking';

import { Error403 } from './Error403';
import { Error404 } from './Error404';
import { Error500 } from './Error500';
import { ErrorDomainAccess } from './ErrorDomainAccess';
import { ErrorPassword } from './ErrorPassword';

// XXX: needs moving conditions down to render
function getErrorCode (error) {
  if (error) {
    const code = error.status;
    if (code === 403 && error.error_code === 'document_password_required') {
      // This HTTP code does not exist, we use it here to signify password required
      return 444;
    }
    if (code === 403 && error.error_code === 'not_allowed_for_domain') {
      // This HTTP code does not exist, we use it here to signify domain access restriction.
      return 455;
    }
    else if (code === 401) {
      return 401;
    }
    else if (code === 403) {
      return 403;
    }
    else if (code === 404 || code === 400) {
      return 404;
    }
    return 500;
  }
  return 200;
}

class ErrorBoundaryInternal extends React.Component {
  static propTypes = {
    router: PropTypes.object,
  };

  static getDerivedStateFromProps (nextProps, prevState) {
    // it is important to use asPath so the error boundary cleans the error when navigating between documents
    const nextPath = nextProps.router.asPath;
    if (nextPath !== prevState.path) {
      return { error: null, path: nextPath, prevPath: prevState.path };
    }
    return null;
  }

  static getDerivedStateFromError (error) {
    return { error };
  }

  constructor (props) {
    super(props);
    this.state = { error: null };
  }

  componentDidMount () {
    // Catch unhandled promise rejections (componentDidCatch only catches errors thrown during rendering)
    window.addEventListener('unhandledrejection', this.promiseRejectionHandler);
    window.addEventListener('error', this.errorHandler);
  }

  componentDidCatch (error, info) {
    const code = getErrorCode(error);
    if (code === 401) {
      this.clearAuthToken();
    }
    if (code >= 500) {
      Sentry.withScope(scope => {
        if (info) {
          scope.setExtras(info);
        }
        Sentry.captureException(error);
      });
    }
    else if (code !== 200) {
      tracking.logEvent('Page Not Found Displayed', {
        error_code: code,
        current_page: this.state.path,
        page_of_origin_path: this.state.prevPath,
      });
    }
  }

  componentWillUnmount () {
    window.removeEventListener('unhandledrejection', this.promiseRejectionHandler);
    window.removeEventListener('error', this.errorHandler);
  }

  clearAuthToken () {
    // If we get a 401 error back it normally means that the auth token
    // has expired. In this case we clear it, so it's not sitting around
    // in a cache, and so other code is not misled into thinking we are still logged in.
    token.clear();
  }

  errorHandler = event => {
    const status = event.error && event.error.status;
    if (typeof status === 'number' && status >= 400 && status < 500) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  promiseRejectionHandler = event => {
    event.preventDefault();
    event.stopImmediatePropagation();
    if (getErrorCode(event.reason) === 401) {
      this.clearAuthToken();
    }
    this.setState({ error: event.reason });
  };

  render () {
    const code = getErrorCode(this.state.error);
    if (code === 444) {
      return <ErrorPassword />;
    }
    if (code === 455) {
      return <ErrorDomainAccess />;
    }
    else if (code === 403 || code === 401) {
      return <Error403 error={this.state.error} />;
    }
    else if (code === 404) {
      return <Error404 />;
    }
    else if (code >= 400) {
      return <Error500 error={this.state.error} />;
    }
    return this.props.children;
  }
}

export const ErrorBoundary = withRouter(ErrorBoundaryInternal);
