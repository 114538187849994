import { createContext, useContext } from 'react';
import lodashDebounce from 'lodash.debounce';
import { NextPage } from 'next';

import { Api } from '@/api';
import { UserEvents } from '@/instrumentation/UserEvents';

// See doc/adr/0014-abandon-mocks.md for why and when to leverage Dependency Injection (DI)

export interface Dependencies {
  userEvents: UserEvents,
  debounce: typeof lodashDebounce,
  api: Api,
}

export function createContainer ({ userEvents = new UserEvents({}), debounce = lodashDebounce, api = new Api({}) }): Dependencies {
  return {
    userEvents,
    debounce,
    api,
  };
}

// React convenience enablers below
const DependencyContext = createContext<Dependencies>(createContainer({}));

export function useDeps () {
  return useContext(DependencyContext);
}

export function DependencyProvider ({ children, dependencies }) {
  return (
    <DependencyContext.Provider value={dependencies}>
      {children}
    </DependencyContext.Provider>
  );
}

// The use of the NextPage type may seem odd, but it's the recommended type for components that may have a getInitialProps method
// See: https://nextjs.org/docs/api-reference/data-fetching/get-initial-props#typescript
export function withDeps<T extends {deps: Dependencies} & Record<string, any>> (WrappedComponent: NextPage<T>) {
  type P = Omit<T, 'deps'>;
  const InnerComponent: NextPage<P> = (props: P) => {
    return (
      <DependencyContext.Consumer>
        {
          // @ts-expect-error
          d => <WrappedComponent deps={d} {...props} />
        }
      </DependencyContext.Consumer>
    );
  };
  InnerComponent.getInitialProps = WrappedComponent.getInitialProps;
  return InnerComponent;
}
