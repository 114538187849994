/* globals AMPLITUDE_KEY_DEV */
/* globals AMPLITUDE_KEY_PROD */
import { useEffect } from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';

import { isProdEnvironment } from '@/api/request';
import { EXTENDED_PERMISSIONS } from '@/constants';

import { useAuth } from '../auth';

type Props = {
  disabled?: boolean,
  isDevProperty?: boolean,
};

export function useAmplitudeTracker ({ disabled, isDevProperty }: Props) {
  const { user } = useAuth();
  const hasExtendedPermissions = user && user.agreements?.some(agreement => agreement.name === EXTENDED_PERMISSIONS);

  useEffect(
    () => {
      if (!disabled) {
        const isProduction = isProdEnvironment() && !isDevProperty;
        const amplitudeKey = isProduction ? AMPLITUDE_KEY_PROD : AMPLITUDE_KEY_DEV;
        const amplitudeApiUrl = isProduction ? 'https://api-ampl2.grid.is/2/httpapi' : 'https://api-ampl2.ingrid.is/2/httpapi';

        amplitude.init(amplitudeKey, {
          serverUrl: amplitudeApiUrl,
          identityStorage: 'localStorage',
          defaultTracking: {
            // GRID-client has it's own In App Page Viewed event
            pageViews: false,
          },
        });
      }
    },
    [ disabled, isDevProperty ],
  );

  useEffect(
    () => {
      if (!disabled && hasExtendedPermissions) {
        const sessionReplay = sessionReplayPlugin({
          sampleRate: 1,
        });
        amplitude.add(sessionReplay);
      }
    },
    [ disabled, hasExtendedPermissions ],
  );
}
