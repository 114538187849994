import { useRouter } from 'next/router';

import { getLoginUrl, getSignupUrl, query } from '@grid-is/browser-utils';
import { tracking } from '@grid-is/tracking';

import { Avatar } from '@/grid-ui/Avatar';
import { Button } from '@/grid-ui/Button';
import { ButtonMenu } from '@/components/Menu';
import { isMobile } from '@/utils';
import { useAuth } from '@/utils/auth';

import styles from './userMenu.module.scss';

interface Props {
  utmParams?: string | URLSearchParams,
  analyticsPageName: string,
  documentId?: string,
  isLoggedIn?: boolean,
  iconSize?: number,
}

export function UserMenu ({ utmParams, analyticsPageName, documentId, isLoggedIn, iconSize = 32 }: Props) {
  const { whois, isValidating, user } = useAuth();
  const router = useRouter();
  if (isValidating && !whois()) {
    return <span data-testid="usermenu-loading" className={styles.loadingAvatar} />;
  }
  if (isLoggedIn && isMobile()) {
    return null;
  }
  if (isLoggedIn) {
    const userGroupsPath = query.set(router.asPath, { userGroups: 1 });
    return (
      <ButtonMenu
        id="user-menu"
        testId="userMenu"
        button={<button type="button" className={styles.resetButton}><Avatar username={user?.username} name={user?.name} avatarUrl={user?.avatar_url} size={iconSize} /></button>}
        className={styles.userMenu}
        tooltip="My workspace"
        actions={[
          !isMobile() && {
            label: 'New project',
            to: '/new',
            query: analyticsPageName ? {
              source: `${analyticsPageName} - My Workspace Menu`,
            } : null,
            onClick: () => {
              tracking.logEvent('New Document Clicked', { click_source: 'menu' });
            },
            icon: 'new-document',
            className: styles.hideOnSmallScreens,
          },
          {
            label: 'Home',
            to: '/me',
            icon: 'home',
            separator: true,
          },
          {
            label: 'Groups',
            to: userGroupsPath,
            icon: 'users',
            onClick: () => {
              tracking.logEvent('User Group Panel Accessed', { initiated_from: 'User Menu' });
            },
          },
          {
            label: 'Account',
            to: '/settings/profile',
            icon: 'cog',
          },
          {
            label: 'Log out',
            to: '/user/logout',
            icon: 'logout',
            separator: true,
          },
        ]}
        />
    );
  }
  return (
    <div className={styles.buttonWrapper}>
      <a
        href={getLoginUrl({}, { campaign: 'login' }, utmParams)}
        className={styles.loginHref}
        data-testid="userMenu"
        >
        <Button className={styles.button} buttonType="secondary">Log in</Button>
      </a>
      <a
        href={getSignupUrl({}, { source: 'grid-doc', medium: 'referral', campaign: 'header-signup', grid_doc: documentId })}
        className={styles.loginHref}
        data-testid="signup"
        >
        <Button className={styles.button} buttonType="primary">Sign up</Button>
      </a>
    </div>
  );
}
