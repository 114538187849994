/* globals WITH_MOCKER  */
/* globals process  */
import 'focus-visible';

import React, { useEffect, useRef, useState } from 'react';
import * as Sentry from '@sentry/nextjs';
import App from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import PropTypes from 'prop-types';
import { SWRConfig } from 'swr';

import { getIsIntegrationTests, getIsThumbnailMode, isBrowser, token } from '@grid-is/browser-utils';
import { getConfig } from '@grid-is/environment-config';
import { tracking, useAppcues, useSendPageEventsToAppcues, useTrackNavigation } from '@grid-is/tracking';

import { AuthorizationRequiredError } from '@/api/errors';
import { getBootstrappedFlags } from '@/api/flags';
import { fetchForSWR } from '@/api/request';
import { createContainer, DependencyProvider } from '@/bootstrapping/dependencies';
import { BillingBoundary } from '@/components/BillingBoundary';
import { ErrorBoundary } from '@/components/Error/ErrorBoundary';
import { LegalBoundary } from '@/components/Legal/LegalBoundary';
import { WelcomeBoundary } from '@/components/WelcomeBoundary';
import { useAuth } from '@/utils/auth';
import { FlagProvider } from '@/utils/flags';
import { useAmplitudeTracker } from '@/utils/hooks/useAmplitudeTracker';
import { useFullStory } from '@/utils/hooks/useFullStory';
import { useSegmentTracker } from '@/utils/hooks/useSegmentTracker';

import '@grid-is/styles/src/global.scss';
import '@grid-is/styles/src/print.scss';
import '@/grid/styles/document.scss';
import '@/global.scss';

let isLocalhost = false;
if (isBrowser()) {
  const host = window.location.hostname;
  if (host === 'localhost') {
    isLocalhost = true;
  }
  if (WITH_MOCKER) {
    isLocalhost = true;
  }
}

function MyApp ({ Component, pageProps, bootstrappedFlags }) {
  const router = useRouter();

  // used for providing additional analytics across page navigation
  const [ additionalAnalytics, setAdditionalAnalytics ] = useState({});
  const { user, isLoggedIn } = useAuth();
  const dependencies = useRef(createContainer({}));
  // only load analytics SDKs if the user is authenticated
  // and if the following conditions are met:
  // - not running against mocker
  // - not creating a thumbnail of a GRID doc
  // - not running e2e tests
  // - NEXT_PUBLIC_DISABLE_TRACKING=true is not in .env.local
  const disableTracking = !!WITH_MOCKER || process.env.NEXT_PUBLIC_DISABLE_TRACKING === 'true' || getIsThumbnailMode();
  const isIntegrationTests = getIsIntegrationTests();

  const isUnauthenticated = !token.get() && !isLoggedIn;

  useFullStory({ disabled: isLocalhost || disableTracking || isIntegrationTests });
  useSegmentTracker({ disabled: isUnauthenticated || disableTracking, isDevProperty: isIntegrationTests });
  useAmplitudeTracker({ disabled: isUnauthenticated || disableTracking, isDevProperty: isIntegrationTests });
  useAppcues();

  useSendPageEventsToAppcues({ router });
  useTrackNavigation({ router, currentUsername: user?.username });
  useEffect(() => {
    // Call tracking login, whenever the login status of the user changes to
    // logged-in
    if (!isLoggedIn || !user?.id) {
      return;
    }
    tracking.login(String(user.id), user.email_domain === 'grid.is');
  }, [ isLoggedIn, user?.id, user?.email_domain ]);

  // XXX: extract the favicon logic into a separate component
  return (
    <DependencyProvider dependencies={dependencies.current}>
      <ErrorBoundary>
        <Script src="/api/front/config" strategy="beforeInteractive" />
        {getConfig()?.RELEASE_STAGE === 'local' && (
          <Head>
            <link rel="shortcut icon" href="/favicon-dev.ico" />
          </Head>
        )}
        {getConfig()?.RELEASE_STAGE === 'development' && (
          <Head>
            <link rel="shortcut icon" href="/favicon-ingrid.ico" />
          </Head>
        )}
        <FlagProvider disabled={!!WITH_MOCKER} bootstrappedFlags={bootstrappedFlags}>
          <SWRConfig
            value={{ fetcher: fetchForSWR,
              onError: error => {
                if (error instanceof AuthorizationRequiredError) {
                  // Throw this particular error so ErrorBoundary can catch it and redirect the user if necessary
                  throw error;
                }
              } }}
            >
            <LegalBoundary>
              <WelcomeBoundary />
              <BillingBoundary user={user}>
                <Component
                  setAdditionalAnalytics={setAdditionalAnalytics}
                  additionalAnalytics={additionalAnalytics}
                  {...pageProps}
                  />
              </BillingBoundary>
            </LegalBoundary>
          </SWRConfig>
        </FlagProvider>
      </ErrorBoundary>
    </DependencyProvider>
  );
}

MyApp.propTypes = {
  Component: PropTypes.elementType,
  pageProps: PropTypes.object,
  bootstrappedFlags: PropTypes.object,
};

async function addFeatureFlagsToProps (context, props) {
  const ctx = context.ctx || context;
  try {
    const bootstrappedFlags = await getBootstrappedFlags(ctx);
    // HACK: disable all flags, except the flag toggler, when running cypress tests, to isolate them from changes in LD
    if (ctx?.req?.headers['user-agent'] === 'GRID e2e tests') {
      for (const [ key, value ] of Object.entries(bootstrappedFlags.state)) {
        if (typeof value === 'boolean') {
          bootstrappedFlags.state[key] = false;
        }
        else if (typeof value === 'string') {
          bootstrappedFlags.state[key] = '';
        }
      }
      bootstrappedFlags.state['flag-toggler'] = true;
    }
    props = Object.assign(props, { bootstrappedFlags });
  }
  catch (e) {
    console.warn('Failed to fetch feature flags');
    Sentry.captureException(e);
  }
  return props;
}

MyApp.getInitialProps = async context => {
  let props = await App.getInitialProps(context);
  if (!isBrowser()) {
    props = await addFeatureFlagsToProps(context, props);
  }
  return props;
};

export default MyApp;
