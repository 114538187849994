import Link from 'next/link';

import { utm } from '@grid-is/browser-utils';
import { useMounted } from '@grid-is/custom-hooks';
import { Icon } from '@grid-is/icon';

import { useAuth } from '@/utils/auth';

type HomeLogoProps = {
  utmParams?: string | URLSearchParams,
  size: number,
  useLargeLogo?: boolean,
}

import styles from './HomeLogo.module.scss';

export const HomeLogo = ({ utmParams, size, useLargeLogo = false }: HomeLogoProps) => {
  const { whois, isLoggedIn } = useAuth();
  const isMounted = useMounted();
  const icon = useLargeLogo
    ? <img src="/img/grid-logo.svg" alt="Logo" height={32} />
    : <Icon name="grid-full-color" size={size} />;

  function getLogoRedirectUri () {
    if (!isMounted) {
      return '';
    }
    if (isLoggedIn) {
      return `/@${whois()}`;
    }
    else {
      return utm.url('/', { campaign: 'header-logo' }, utmParams);
    }
  }

  if (isLoggedIn) {
    return (
      <Link href={getLogoRedirectUri()} passHref>
        <a className={styles.link} data-testid="home-logo" data-obid="home-logo">{icon}</a>
      </Link>
    );
  }
  return (
    <a
      className={styles.link}
      style={{ height: `${size}px` }}
      href={getLogoRedirectUri()}
      data-testid="home-logo"
      data-obid="home-logo"
      >
      {icon}
    </a>
  );
};
