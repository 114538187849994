import { ForwardedRef, forwardRef, useCallback, useMemo, useState } from 'react';
import { SelectInstance } from 'react-select';
import csx from 'classnames';

import { getGroups, UserGroup } from '@/api/groups';
import { searchForUsers, User } from '@/api/user';
import { Avatar } from '@/grid-ui/Avatar';
import { Button } from '@/grid-ui/Button';
import { HelperText } from '@/grid-ui/FieldWrapper/HelperText';
import { Pill } from '@/grid-ui/Pill';
import { Select } from '@/grid-ui/Select';
import { GroupedSelectOptionType, SelectOptionType } from '@/grid-ui/Select/types';
import { useAuth } from '@/utils/auth';
import { isEmail } from '@/utils/email';

import styles from './peoplePicker.module.scss';

export type People = {newUsers: User[], newInvites: string[], newGroups: UserGroup[], newEmailDomain?: string};

export type PeoplePickerProps = {
  hideButton?: boolean,
  buttonLabel?: string,
  canAddEmails?: boolean,
  canAddEmailDomain?: boolean,
  emailDomain?: string,
  canAddGroups?: boolean,
  getErrorMessage?: (currentState: {newUsers: User[], newInvites: string[], newGroups: UserGroup[]}) => string | undefined,
  filterOption?: (option: User | UserGroup | string) => boolean,
  onAdd?: (people: People) => void,
  onChange?: (people: People) => void,
  noOptionsMessage?: string,
  placeholder?: string,
  title?: string,
  useModal?: boolean,
  initialValue?: People,
}

async function queryGroups (query: string, newGroups: UserGroup[], filterOption: PeoplePickerProps['filterOption'] = () => true) {
  const alreadyPickedGroups = newGroups.map(g => g.id);
  return getGroups({ query }).then(results => {
    return results.items
      .filter(d => !alreadyPickedGroups.includes(d.id) && filterOption(d))
      .map(d => ({
        meta: d,
        description: `${d.users.length + (d.invites ? d.invites.length : 0)} members`,
        label: d.name,
        value: d.id,
        icon: <Avatar username={d.name} size={24} isGroup />,
      }));
  })
    .catch(() => []);
}

async function queryUsers (query: string, newUsers: User[], currentUser?: User, filterOption: PeoplePickerProps['filterOption'] = () => true) {
  return searchForUsers(query).then(async results => {
    const alreadyPickedUsers = newUsers.map(u => u.id);
    return results
      .filter(d => d.id !== currentUser?.id && !alreadyPickedUsers.includes(d.id) && filterOption(d))
      .map(d => ({
        meta: d,
        description: `@${d.username} - ${d.email}`,
        label: d.name,
        value: d.username,
        icon: <Avatar name={d.name} username={d.username} avatarUrl={d.avatar_url} size={24} />,
      }));
  })
    .catch(() => []);
}

export const PeoplePicker = forwardRef(({
  buttonLabel = 'Add',
  hideButton = false,
  canAddEmailDomain,
  canAddEmails,
  canAddGroups,
  emailDomain,
  getErrorMessage = () => undefined,
  filterOption = () => true,
  onAdd = () => undefined,
  onChange = () => undefined,
  useModal,
  noOptionsMessage = 'Please select an existing user.',
  placeholder = 'Add by username',
  title,
  initialValue,
}: PeoplePickerProps, ref: ForwardedRef<SelectInstance<SelectOptionType>>) => {
  const [ newUsers, setNewUsers ] = useState<User[]>(initialValue?.newUsers || []);
  const [ newInvites, setNewInvites ] = useState<string[]>(initialValue?.newInvites || []);
  const [ newGroups, setNewGroups ] = useState<UserGroup[]>(initialValue?.newGroups || []);
  const [ newEmailDomain, setNewEmailDomain ] = useState<string | undefined>(initialValue?.newEmailDomain);
  const { user } = useAuth();
  const errorMessage = getErrorMessage({ newUsers, newInvites, newGroups });
  const hasPills = newUsers.length > 0 || newInvites.length > 0 || newGroups.length > 0;
  const showEmailDomainOption = canAddEmailDomain && !!emailDomain && !newEmailDomain;

  const emailDomainOption = useMemo(() => ({
    label: <strong>Anyone @{emailDomain}</strong>,
    description: `Give access to anyone with an @${emailDomain} email.`,
    value: emailDomain ?? '',
    icon: <Avatar name={emailDomain} username={emailDomain} avatarUrl={`https://logo.clearbit.com/${emailDomain}?size=20`} size={20} />,
    meta: { isEmailDomain: true },
  }), [ emailDomain ]);

  const loadOptions = useCallback(async (query: string): Promise<(SelectOptionType | GroupedSelectOptionType)[]> => {
    const value = (query || '').toLowerCase();
    if (value.length === 0) {
      return [];
    }
    else {
      const shouldAddEmailDomainOption = showEmailDomainOption &&
        (emailDomain?.toLowerCase().startsWith(value.trim()) || `@${emailDomain?.toLowerCase()}`.startsWith(value.trim()));
      if (canAddGroups) {
        return Promise.all([
          queryGroups(query, newGroups, filterOption),
          queryUsers(query, newUsers, user, filterOption),
        ]).then(([ groupOptions, userOptions ]) => {
          return [
            ...(shouldAddEmailDomainOption ? [ emailDomainOption ] : []),
            { label: 'My Groups', options: groupOptions },
            { label: 'People', options: userOptions },
          ];
        });
      }
      return queryUsers(query, newUsers, user, filterOption).then(users => {
        return [
          ...(shouldAddEmailDomainOption ? [ emailDomainOption ] : []),
          ...users,
        ];
      });
    }
  }, [ canAddGroups, newUsers, filterOption, newGroups, showEmailDomainOption, emailDomain, emailDomainOption, user ]);

  const defaultOptions: SelectOptionType[] | undefined = showEmailDomainOption ? [
    emailDomainOption,
  ] : undefined;

  const onSubmit = useCallback(() => {
    onAdd({ newUsers, newInvites, newGroups, newEmailDomain });
    if (!hideButton) {
      setNewGroups([]);
      setNewInvites([]);
      setNewUsers([]);
      setNewEmailDomain(undefined);
    }
  }, [ hideButton, newEmailDomain, newGroups, newInvites, newUsers, onAdd ]);

  return (
    <div>
      {title && <h5 key="contentTitle" className={styles.title}>{title}</h5>}
      <div className={csx(styles.peoplePicker, !hasPills && styles.emptyPicker)}>
        <div className={csx(styles.pills, !hasPills && styles.noPills)} data-testid="peoplePills">
          {newGroups.map(group => (
            <div key={group.id} className={styles.pillWrapper}>
              <Pill
                text={group.name}
                onDismiss={() => {
                  const groups = newGroups.filter(g => g.id !== group.id);
                  setNewGroups(groups);
                  onChange({ newUsers, newInvites, newGroups: groups, newEmailDomain });
                }}
                icon={<Avatar username={group.name} size={16} />}
                />
            </div>
          ))}
          {newUsers.map(user => (
            <div key={user.id} className={styles.pillWrapper}>
              <Pill
                text={user.name}
                onDismiss={() => {
                  const users = newUsers.filter(u => u.id !== user.id);
                  setNewUsers(users);
                  onChange({ newUsers: users, newInvites, newGroups, newEmailDomain });
                }}
                icon={<Avatar name={user.name} username={user.username} avatarUrl={user.avatar_url} size={16} />}
                />
            </div>
          ))}
          {newInvites.map(invite => (
            <div key={invite} className={styles.pillWrapper}>
              <Pill
                text={invite}
                onDismiss={() => {
                  const invites = newInvites.filter(i => i !== invite);
                  setNewInvites(invites);
                  onChange({ newUsers, newInvites: invites, newGroups, newEmailDomain });
                }}
                />
            </div>
          ))}
          {newEmailDomain && (
            <div key={newEmailDomain} className={styles.pillWrapper}>
              <Pill
                text={`Anyone @${newEmailDomain}`}
                onDismiss={() => {
                  setNewEmailDomain(undefined);
                  onChange({ newUsers, newInvites, newGroups, newEmailDomain: undefined });
                }}
                icon={<Avatar name={emailDomain} username={emailDomain} avatarUrl={`https://logo.clearbit.com/${emailDomain}?size=16`} size={16} />}
                />
            </div>
          )}
        </div>
        <div className={csx(styles.pickerContainer, hasPills && styles.hasPills)}>
          <div className={csx(!hasPills && styles.emptyPicker)}>
            <Select
              isTransparent
              placeholder={placeholder}
              id="addPeople"
              {...(canAddEmails && {
                isCreatable: true,
                isValidNewOption: inputValue => isEmail(inputValue) && filterOption(inputValue),
                onCreateOption: newValidEmail => {
                  const invites = [ ...newInvites, newValidEmail ];
                  setNewInvites(invites);
                  onChange({ newInvites: invites, newUsers, newGroups, newEmailDomain });
                },
              })}
              defaultOptions={defaultOptions}
              loadOptions={loadOptions}
              menuPortalTarget={document.body}
              name="addPeople"
              size="small"
              noOptionsMessage={() => noOptionsMessage}
              // if the picker is opened immediately on focus and inside a modal, the picker menu can be displaced due to the animation
              // therefore we will not open the menu on focus inside the Home view. Only in the document itself.
              openMenuOnFocus={showEmailDomainOption && !useModal}
              onKeyDown={e => {
                // TODO after updating react-select to newest version, the types for the ref should be correct
                // @ts-expect-error
                if (e.key === 'Enter' && ref && !ref.current?.state.inputValue?.length) {
                  onSubmit();
                }
              }}
              onChange={option => {
                const typedOption = option as SelectOptionType;
                if (typedOption === null) {
                  setNewUsers([]);
                  onChange({ newUsers: [], newInvites, newGroups, newEmailDomain });
                }
                else if (typedOption.meta.username) {
                  const users = [ ...newUsers, (typedOption as SelectOptionType).meta ];
                  setNewUsers(users);
                  onChange({ newUsers: users, newInvites, newGroups, newEmailDomain });
                }
                else if (canAddEmailDomain && typedOption.meta.isEmailDomain) {
                  setNewEmailDomain(emailDomain);
                  onChange({ newUsers: newUsers, newInvites, newGroups, newEmailDomain: emailDomain });
                }
                else if (canAddGroups) {
                  const groups = [ ...newGroups, (typedOption as SelectOptionType).meta ];
                  setNewGroups(groups);
                  onChange({ newUsers: newUsers, newInvites, newGroups: groups, newEmailDomain });
                }
              }}
              ref={ref}
              value={null}
              variant="lite"
              />
            {errorMessage && <HelperText hasError>{errorMessage}</HelperText>}
          </div>
          {!hideButton &&
          (
            <div className={csx(styles.addMembers, !hasPills && styles.hasPills)}>
              <Button
                disabled={newEmailDomain === undefined && newUsers.length === 0 && newInvites.length === 0 && newGroups.length === 0 || errorMessage !== undefined}
                buttonSize="medium"
                onClick={onSubmit}
                >{buttonLabel}
              </Button>
            </div>
          )
         }
        </div>
      </div>
    </div>
  );
});
