import { useEffect } from 'react';
import { useRouter } from 'next/router';

import { query } from '@grid-is/browser-utils';

import { UserGroupsModal } from './UserGroupsModal';

type Props = {
  currentUserId: string,
  openModal: boolean,
  setOpenModal?: (shouldOpen: boolean) => void,
}

export const UserGroups = ({ currentUserId, openModal, setOpenModal }: Props) => {
  const router = useRouter();

  useEffect(() => {
    if (router.query?.userGroups === '1') {
      setOpenModal && setOpenModal(true);
      const url = query.remove(router.asPath, 'userGroups');
      router.replace(url);
    }
  }, [ router, setOpenModal ]);

  return (
    openModal ? <UserGroupsModal currentUserId={currentUserId} openModal={openModal} setOpenModal={setOpenModal} /> : null
  );
};

