import { useEffect } from 'react';

import { page } from './tracking';

export function useSendPageEventsToAppcues ({ router }) {
  useEffect(() => {
    // Single page apps need to report to tracking providers when a page changes.
    router.events.on('routeChangeComplete', page);
    return () => {
      router.events.off('routeChangeComplete', page);
    };
  }, [ router ]);
}
