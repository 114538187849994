import { ReactNode } from 'react';
import csx from 'classnames';

import { Icon } from '@grid-is/icon';

import { Tooltip } from '@/grid-ui/Tooltip';

import styles from './Label.module.scss';

export type LabelProps = {
  disabled?: boolean,
  id?: string,
  label?: ReactNode,
  required?: boolean,
  hasError?: boolean,
  tooltip?: ReactNode,
  tooltipVariety?: 'dark' | 'light',
  labelId?: string,
  testid?: string,
};

export function Label ({ disabled, id, label, required, hasError, tooltip, tooltipVariety, labelId, testid }: LabelProps) {
  return (
    <label
      htmlFor={id}
      className={csx(
        styles.label,
        disabled && styles.disabled,
        hasError && styles.error,
      )}
      id={labelId}
      data-testid={testid}
      >
      {label}
      {tooltip &&
        <span className={styles.tooltipWrapper}>
          <Tooltip
            variety={tooltipVariety}
            label={tooltip}
            >
            <Icon className={styles.tooltipIcon} name="help" size={12} />
          </Tooltip>
        </span>}
      {required && ' (required)'}
    </label>
  );
}
