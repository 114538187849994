import { forwardRef, ReactNode } from 'react';

import { Icon } from '@grid-is/icon';

import styles from './Checkbox.module.scss';

export type CheckboxProps = {
  name: string,
  id?: string,
  onChange?: (newValue: boolean) => void,
  onBlur?: (newValue: boolean) => void,
  checked?: boolean,
  disabled?: boolean,
  label?: string | ReactNode,
  testId?: string,
  required?: boolean,
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(({
  name,
  id = name,
  onChange = () => {},
  onBlur = () => {},
  checked,
  disabled,
  label,
  required,
  testId = name,
}: CheckboxProps, ref) => {
  return (
    <div className={styles.wrapper}>
      <input
        onChange={e => onChange(e.target.checked)}
        onBlur={e => onBlur(e.target.checked)}
        name={name}
        type="checkbox"
        checked={checked}
        disabled={disabled}
        id={id}
        className={styles.input}
        data-testid={testId}
        ref={ref}
        required={required}
        />
      <label
        htmlFor={id}
        className={styles.label}
        >
        {label}
        <Icon name="tick" size={16} />
      </label>
    </div>
  );
});
