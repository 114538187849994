import { paths, schemas } from './generatedTypes';
import { deleteJSON, getJSON, postJSON, request } from './request';

export type UserGroup = schemas['UserGroup'];

type GetGroupsResponse = paths['/groups']['get']['responses']['200']['content']['application/json'];

export function getGroups ({ query, cursor }: {query?: string, cursor?: string}) {
  if (query || cursor) {
    let url = '/groups?';
    // FIXME: query and cursor are not mutually exclusive. Use new URLSearchParams({query, cursor}).toString() or similar solution.
    if (query) {
      url += `query=${encodeURIComponent(query)}`;
    }
    if (cursor) {
      url += `cursor=${encodeURIComponent(cursor)}`;
    }
    return getJSON<GetGroupsResponse>(url);
  }
  return getJSON<GetGroupsResponse>('/groups');
}

type CreateGroupRequest = paths['/groups']['post']['requestBody']['content']['application/json'];
type CreateGroupResponse = paths['/groups']['post']['responses']['200']['content']['application/json'];

export function createGroup (name: string) {
  return postJSON<CreateGroupResponse, CreateGroupRequest>('/groups', { name });
}

export function deleteGroup (groupId: string): Promise<{}> {
  return request(`/groups/${groupId}`, { method: 'DELETE' });
}

type GetGroupResponse = paths['/groups/{group_id}']['get']['responses']['200']['content']['application/json'];

export function getGroup (groupId: string) {
  return getJSON<GetGroupResponse>(`/groups/${groupId}`);
}

type InviteRequest = paths['/groups/{group_id}/invite']['post']['requestBody']['content']['application/json'];

export function inviteToGroupViaEmail (groupId: string, emails: string[]) {
  return postJSON<{}, InviteRequest>(`/groups/${groupId}/invite`, { emails });
}

export function removeUserFromGroup (groupId: string, userId: string): Promise<{}> {
  return request(`/groups/${groupId}/user/${userId}`, { method: 'DELETE' });
}

type RemoveEmailRequest = paths['/groups/{group_id}/invite']['delete']['requestBody']['content']['application/json'];

export function removeEmailFromGroup (groupId: string, email?: string) {
  return deleteJSON<{}, RemoveEmailRequest>(`/groups/${groupId}/invite`, { email });
}

type AddUserRequest = paths['/groups/{group_id}/user']['post']['requestBody']['content']['application/json'];

export function addUsersToGroup (groupId: string, userIds: string[]) {
  return postJSON<{}, AddUserRequest>(`/groups/${groupId}/user`, { user_ids: userIds });
}
