import { forwardRef, useState } from 'react';

import { Icon } from '@grid-is/icon';

import { Input } from '@/grid-ui/Input';
import { InputProps } from '@/grid-ui/Input/types';

export interface PasswordProps extends Pick<InputProps, 'onChange' | 'onFocus' | 'onBlur'|'value'|'placeholder'|'required'|'autoComplete' | 'autoFocus' > { }

export const Password = forwardRef<HTMLInputElement, PasswordProps>(({
  onChange,
  onFocus,
  onBlur,
  value,
  placeholder,
  required,
  autoComplete,
  autoFocus = false,
}, ref) => {
  const [ showPass, setShowPass ] = useState(false);
  return (
    <Input
      ref={ref}
      testId="password-field"
      name="password-field"
      type={showPass ? 'text' : 'password'}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      autoFocus={autoFocus}
      onFocus={onFocus}
      onBlur={onBlur}
      required={required}
      autoComplete={autoComplete}
      icon={<Icon variant="solid" name={showPass ? 'eye' : 'eye-hide'} size={18} />}
      onIconClick={e => {
        e.preventDefault();
        setShowPass(!showPass);
      }}
      />
  );
});
