import { getViewport, isBrowser } from '@grid-is/browser-utils';
import { breakpoints } from '@grid-is/styles';

export function isMobile () {
  if (isBrowser()) {
    // Special case if user is on ipad and safari
    if (/Mac/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2) {
      return true;
    }

    return /iPhone|iPad|iPod|Android|BlackBerry|Opera Mini|IEMobile/i.test(navigator.userAgent);
  }
  return false;
}

export function isMobileWidth () {
  if (isBrowser()) {
    return getViewport().width < parseInt(breakpoints.designsMobile, 10);
  }
  return false;
}
