import * as amplitude from '@amplitude/analytics-browser';

import { getIsIntegrationTests, isBrowser, token } from '@grid-is/browser-utils';

export const logOut = () => {
  if (isBrowser()) {
    if (window.analytics) {
      window.analytics.reset();
    }
    amplitude.reset();
  }
};

export const logEvent = (name: string, props: Record<string, any>, options = { skipAppcues: false }) => {
  if (isBrowser() && token.get()) {
    if (window.analytics) {
      window.analytics.track(name, props);
    }
    if (window.Appcues && options.skipAppcues !== true) {
      // We don't want to send Appcues their own events.
      window.Appcues.track(name, props);
    }
    amplitude.track(name, props);
    if (window.FS) {
      window.FS.event(name, props);
    }
  }
};

// Used for Appcues exclusive events that are fired to trigger onboarding flows.
export const logAppcuesEvent = (name: string, props: Record<string, any> = {}) => {
  if (isBrowser() && window.Appcues && token.get()) {
    window.Appcues.track(name, props);
  }
};

export const page = () => {
  // Appcues
  if (window.Appcues) {
    window.Appcues.page();
  }
};

export const login = (id: string, hasGridEmail: boolean = false) => {
  if (isBrowser()) {
    const traits = { has_grid_email: hasGridEmail };
    if (window.analytics) {
      window.analytics.identify(id, traits);
    }
    if (window.Appcues) {
      window.Appcues.identify(id);
    }
    const identify = new amplitude.Identify();
    amplitude.setUserId(id);
    Object.entries(traits).forEach(([ k, v ]) => {
      identify.set(k, v);
    });
    amplitude.identify(identify);
  }
};

export const setFeatureFlags = (userId: string, flags: Record<string, any>) => {
  if (isBrowser() && !getIsIntegrationTests()) {
    const prefix = 'feature_';
    const prefixedFlags = Object.fromEntries(
      Object.entries(flags).map(([ k, v ]) => [ `${prefix}${k}`, v ]),
    );
    if (window.analytics) {
      window.analytics.identify(userId, prefixedFlags);
    }
    const identify = new amplitude.Identify();
    Object.entries(prefixedFlags).forEach(([ k, v ]) => {
      identify.set(k, v);
    });
    amplitude.identify(identify);
  }
};
