import { ForwardedRef, forwardRef } from 'react';
import ReactSelect, { SelectInstance } from 'react-select';
import AsyncSelect from 'react-select/async';
import AsyncCreatable from 'react-select/async-creatable';
import Creatable from 'react-select/creatable';
import ctx from 'classnames';

import { colors } from '@grid-is/styles';

import { SelectComponents } from './SelectComponents';
import { GroupedSelectOptionType, SelectOptionType, SelectPropTypes } from './types';

import styles from './Select.module.scss';

const { lupine, fossil, wiseOwl, concrete, mist, candyApple, white, layerTooltip, cloud, transparent } = colors;

const customStyles = {
  indicatorSeparator: () => ({}),
  control: (provided, { isFocused, selectProps }) => {
    // It is impossible to overwrite border styles in scss for this component so we need to use
    // the css-in-js approach provided by react-select
    const border = isFocused ? lupine : concrete;
    const hoveredBorder = isFocused ? lupine : fossil;
    const errorBorder = candyApple;
    const { isDisabled, isMulti, hasError, size, variant, isSearchBox, isTransparent } = selectProps;
    let height: string | number = 32;
    if (isMulti) {
      height = 'auto';
    }
    else if (size !== 'small' && !isSearchBox) {
      height = 40;
    }
    const borderColor = hasError ? errorBorder : border;
    let backgroundColor = isDisabled ? mist : provided.backgroundColor;
    if (isTransparent) {
      backgroundColor = transparent;
    }
    const isLite = variant === 'lite';
    return {
      ...provided,
      'height': height,
      'cursor': 'pointer',
      'borderWidth': isFocused && !isLite ? 2 : 1,
      'padding': isFocused && !isLite ? 0 : 1,
      'borderColor': borderColor,
      'backgroundColor': !isLite ? backgroundColor : 'transparent',
      'outline': 'none',
      '&:hover': {
        borderColor: hasError ? errorBorder : hoveredBorder,
      },
      ...(isLite && {
        boxShadow: 'unset',
        borderTop: 'unset',
        borderRight: 'unset',
        borderLeft: 'unset',
        borderBottom: `1px solid ${borderColor}`,
        borderRadius: 0,
        cursor: 'text',
      }),
    };
  },

  multiValue: base => ({
    ...base,
    margin: '0 2px',
    height: '30px',
    background: white,
    border: `1px solid ${mist}`,
    borderLeft: 'none',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '4px',
  }),
  multiValueRemove: base => ({
    ...base,
    'height': '14px',
    'width': '14px',
    'background': wiseOwl,
    'borderRadius': '50%',
    'color': mist,
    'fontWeight': 'bold',
    'display': 'flex',
    'alignItems': 'center',
    'justifyContent': 'center',
    'cursor': 'pointer',
    'marginRight': '8px',
    'marginLeft': '-4px',
    'padding': 0,
    '&:hover': {
      background: wiseOwl,
      color: mist,
    },
  }),
  menuPortal: provided => {
    return { ...provided, zIndex: layerTooltip };
  },
};

export const Select = forwardRef(({
  autoFocus = false,
  defaultOptions,
  defaultValue,
  disabled,
  isClearable = false,
  isCreatable = false,
  isMulti = false,
  isSearchBox = false,
  isValidNewOption,
  hasError = false,
  hideInput = false,
  filterOption,
  onChange = () => {},
  onInputChange = () => {},
  onKeyDown,
  onFocus = () => {},
  onBlur = () => {},
  onCreateOption,
  options,
  label,
  loadOptions,
  menuPortalTarget,
  menuIsOpen,
  maxMenuHeight,
  menuPlacement = 'auto',
  name,
  openMenuOnFocus,
  id = name,
  noOptionsMessage = () => 'No options',
  placeholder = '',
  required,
  size = 'medium',
  testId = `select-${name}`,
  value,
  variant = 'default',
  isTransparent = false,
}: SelectPropTypes, ref: ForwardedRef<SelectInstance<SelectOptionType>>) => {
  const isAsync = loadOptions !== undefined;
  let SelectComponent: unknown = ReactSelect;

  if (isAsync && isCreatable) {
    SelectComponent = AsyncCreatable<SelectOptionType, boolean, GroupedSelectOptionType>;
  }
  else if (isAsync) {
    SelectComponent = AsyncSelect<SelectOptionType, boolean, GroupedSelectOptionType>;
  }
  else if (isCreatable) {
    SelectComponent = Creatable<SelectOptionType, boolean>;
  }
  return (
    <div
      className={ctx(styles.wrapper, styles[size], isMulti && styles.isMulti, isSearchBox && styles.searchBox, isTransparent && styles.transparent)}
      data-testid={testId}
      data-obid={testId}
      // @ts-expect-error unable to figure this out
      ><SelectComponent
        autoFocus={autoFocus}
        components={SelectComponents}
        defaultValue={defaultValue}
        defaultOptions={defaultOptions}
        filterOption={filterOption}
        hasError={hasError}
        hideInput={hideInput}
        onFocus={onFocus}
        onBlur={onBlur}
        id={`select-${id}`}
        inputId={id}
        instanceId={id}
        isClearable={isClearable}
        isDisabled={disabled}
        isMulti={isMulti}
        isSearchBox={isSearchBox}
        isValidNewOption={isValidNewOption}
        isOptionDisabled={o => o.disabled}
        isTransparent={isTransparent}
        menuPortalTarget={menuPortalTarget}
        label={label}
        loadOptions={loadOptions}
        menuPlacement={menuPlacement}
        menuIsOpen={menuIsOpen}
        openMenuOnFocus={openMenuOnFocus}
        maxMenuHeight={maxMenuHeight}
        name={name}
        noOptionsMessage={noOptionsMessage}
        onChange={onChange}
        onCreateOption={onCreateOption}
        onKeyDown={onKeyDown}
        onInputChange={onInputChange}
        options={options}
        formatCreateLabel={s => `Add ${s}`}
        placeholder={placeholder}
        ref={ref}
        required={required}
        size={size}
        styles={customStyles}
        theme={theme => ({
          ...theme,
          spacing: {
            ...theme.spacing,
            menuGutter: 0,
          },
          colors: {
            ...theme.colors,
            primary: wiseOwl,
            primary75: mist,
            primary50: mist,
            primary25: cloud,
          },

        })}
        value={value}
        variant={variant}
        />
    </div>
  );
});
