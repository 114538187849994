import { IconBase } from '../../types';

export const FontColor: IconBase = {
  name: 'font-color',
  outline: '' +
    '<g transform="translate(5.2,5)scale(1.05)">' +
      '<path' +
        ' d="M12.98 2.56001C12.916 2.36381 12.7909 2.19325 12.6229 2.07329C12.455 1.95333 12.253 1.89027 12.0467 1.89334H12C11.7914 1.88738 11.5865 1.94909 11.4158 2.06926C11.2452 2.18942 11.118 2.36158 11.0534 2.56001L5.61335 14.3733C5.55282 14.4957 5.5209 14.6302 5.52002 14.7667C5.52002 14.9435 5.59026 15.1131 5.71528 15.2381C5.84031 15.3631 6.00988 15.4333 6.18669 15.4333C6.34597 15.4367 6.50207 15.3885 6.63179 15.296C6.76151 15.2036 6.85792 15.0717 6.90669 14.92L8.29335 11.82H15.6267L17.0134 14.88C17.068 15.0365 17.1684 15.1729 17.3016 15.2715C17.4347 15.3702 17.5944 15.4266 17.76 15.4333C17.8546 15.4333 17.9482 15.4147 18.0356 15.3785C18.1229 15.3423 18.2023 15.2893 18.2691 15.2225C18.336 15.1556 18.389 15.0762 18.4252 14.9889C18.4614 14.9015 18.48 14.8079 18.48 14.7133C18.4728 14.582 18.4411 14.4531 18.3867 14.3333L12.98 2.56001ZM8.90669 10.4733L12 3.66001L15.0667 10.4733H8.90669Z"' +
        ' fill="currentColor"' +
        ' stroke-width=".3"' +
        ' stroke="currentColor"' +
        ' />' +
    '</g>' +
    '<rect class="swatch" x="4" y="25" width="28" height="8" fill="red" rx="2" />',
};

