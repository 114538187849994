import { assign, createMachine, MachineConfig } from 'xstate';

import { tracking } from '@grid-is/tracking';

import {
  addUsersToGroup,
  createGroup,
  deleteGroup,
  getGroup,
  getGroups,
  inviteToGroupViaEmail,
  removeEmailFromGroup,
  removeUserFromGroup,
  UserGroup,
} from '@/api/groups';

import {
  AddMembersEvent,
  RemoveMemberEvent,
  States,
  UserGroupsContext,
  UserGroupsEvents,
  UserGroupsStates,
} from './types';
import { areAnyGroupsWithTheSameName } from './userGroupsUtils';

const key = 'userGroups';

function trackMembershipAddition (users, selectedGroup: UserGroup, memberType: 'Email' | 'GRID User') {
  const memberCount = selectedGroup.users.length + (selectedGroup.invites ? selectedGroup.invites.length : 0);

  users.forEach(user => {
    tracking.logEvent('User Group Membership Updated', {
      user_group_id: selectedGroup.id,
      group_owner_id: selectedGroup.creator_id,
      num_users_in_group: memberCount,
      update_type: 'Addition',
      updater_type: 'Group Owner',
      member_type: memberType,
      invited_member_user_id: memberType === 'GRID User' ? user.id : null,
    });
  });
}

function resetFallbacks (context: UserGroupsContext) {
  return {
    ...context,
    fallbackInvites: [],
    fallbackUsers: [],
  };
}
function handleMemberManagementError (context: UserGroupsContext) {
  if (!context.selectedGroup) {
    return context;
  }
  return {
    ...context,
    selectedGroup: {
      ...context.selectedGroup,
      invites: context.fallbackInvites,
      users: context.fallbackUsers,
    },
    fallbackInvites: [],
    fallbackUsers: [],
  };
}
function selectGroup (context, event) {
  return { ...context, selectedGroup: context.groups.find(({ id }) => id === event.groupId) };
}
const stateMachineConfig: MachineConfig<UserGroupsContext, UserGroupsStates, UserGroupsEvents> = {
  key,
  predictableActionArguments: true,
  initial: States.fetchGroups,
  context: {
    fallbackInvites: [],
    fallbackUsers: [],
    groups: [],
    newGroupName: '',
    startOpen: false,
    currentUserId: '',
  },
  states: {
    fetchGroups: {
      invoke: {
        src: () => getGroups({}),
        onDone: {
          actions: assign({
            groups: (context, event) => event.data.items,
          }),
          target: States.closed,
        },
        onError: States.closed,
      },
    },
    closed: {
      entry: assign(context => ({ ...context, newGroupName: '' })),
      on: { OPEN: States.overview },
      always: [ { target: States.overview, cond: context => context.startOpen } ],
    },
    error: {
      on: {
        CLOSE: States.closed,
        GO_BACK: {
          actions: assign(context => {
            return { ...context, error: undefined };
          }),
          target: States.overview,
        },
      },
    },
    overview: {
      entry: assign(context => ({ ...context, selectedGroup: undefined, startOpen: false })),
      initial: 'pendingGroups',
      states: {
        pendingGroups: {
          invoke: {
            src: (context, event) => getGroups(event.type === 'LOAD_MORE' ? { cursor: context.loadMoreCursor } : {}),
            onDone: {
              actions: assign({
                groups: (context, event) => {
                  if (context.loadMoreCursor) {
                    const groupMap: Record<string, UserGroup> = {};
                    context.groups.forEach(group => {
                      groupMap[group.id] = group;
                    });
                    event.data.items.forEach(group => {
                      groupMap[group.id] = group;
                    });
                    return Object.values(groupMap);
                  }
                  return event.data.items;
                },
                loadMoreCursor: (context, event) => event.data.response_metadata.next_cursor,
              }),
              target: 'ready',
            },
            onError: {
              actions: assign({ error: (context, event) => event.data }),
              target: 'ready',
            },
          },
          tags: 'pending',
        },
        ready: {
          on: {
            LOAD_MORE: 'pendingGroups',
            MANAGE_GROUP: {
              actions: assign(selectGroup),
              target: `#${key}.${States.manageGroup}.pendingGroup`,
            },
            VIEW_GROUP: {
              actions: assign(selectGroup),
              target: `#${key}.${States.viewGroup}`,
            },
            CREATE_GROUP: {
              cond: ({ newGroupName, groups, currentUserId }) => newGroupName.length > 0 && !areAnyGroupsWithTheSameName(newGroupName, groups, currentUserId),
              target: `#${key}.${States.manageGroup}.createGroup`,
            },
          },
        },
      },
      on: {
        CLOSE: States.closed,
        SET_NEW_GROUP_NAME: { actions: assign((context, { newGroupName }) => ({ ...context, newGroupName })) },
      },
    },
    viewGroup: {
      initial: 'pendingGroup',
      states: {
        pendingGroup: {
          invoke: {
            src: context => {
              return context.selectedGroup ? getGroup(context.selectedGroup.id) : Promise.reject('No group selected');
            },
            onDone: {
              actions: assign({ selectedGroup: (context, event) => event.data }),
              target: 'ready',
            },
            onError: {
              actions: assign({ error: (context, event) => event.data }),
              target: `#${key}.${States.error}`,
            },
          },
          tags: 'pending',
        },
        ready: {
          on: {
            LEAVE_GROUP: `#${key}.${States.leaveGroup}`,
          },
        },
      },
      on: {
        GO_BACK: States.overview,
        CLOSE: States.closed,
      },
    },
    leaveGroup: {
      initial: 'leave',
      states: {
        leave: {
          on: {
            ACCEPT: 'pendingLeave',
          },
        },
        pendingLeave: {
          invoke: {
            src: context => {
              const { selectedGroup, currentUserId } = context;
              if (!selectedGroup) {
                return Promise.reject('No group selected');
              }

              const memberCount = selectedGroup.users.length + (selectedGroup.invites ? selectedGroup.invites.length : 0);
              tracking.logEvent('User Group Membership Updated', {
                user_group_id: selectedGroup.id,
                group_owner_id: selectedGroup.creator_id,
                num_users_in_group: memberCount,
                update_type: 'Removal',
                updater_type: 'Self',
                member_type: 'GRID user',
              });
              return removeUserFromGroup(selectedGroup.id, currentUserId);
            },
            onDone: {
              actions: assign({ groups: context => context.groups.filter(({ id }) => id !== context.selectedGroup?.id) }),
              target: `#${key}.${States.overview}`,
            },
            onError: {
              actions: assign({ error: (context, event) => event.data }),
              target: `#${key}.${States.error}`,
            },
          },
          tags: 'pending',
        },
      },
      on: {
        CANCEL: `${States.viewGroup}.ready`,
        GO_BACK: `${States.viewGroup}.ready`,
        CLOSE: States.closed,
      },
    },
    manageGroup: {
      initial: 'ready',
      states: {
        createGroup: {
          entry: assign(context => ({ ...context,
            selectedGroup: { name: context.newGroupName,
              users: [],
              creator_id: context.currentUserId,
              id: 'pending',
              namespace: { user: { id: context.currentUserId, name: '', username: '' } } } })), // TODO: fill in names?
          invoke: {
            src: context => createGroup(context.newGroupName),
            onDone: {
              actions: assign({
                groups: (context: UserGroupsContext, event) => {
                  const newGroup = event.data;
                  return [ newGroup, ...context.groups ];
                },
                selectedGroup: (context, event) => {
                  tracking.logEvent('User Group Created', { user_group_id: event.data.id });
                  return event.data;
                },
                newGroupName: '',
              }),
              target: 'ready',
            },
            onError: {
              actions: assign((context, event) => {
                tracking.logEvent('User Group Creation Error Shown', { initiated_from: 'User Group Panel' });
                return { ...context, error: event.data };
              }),
              target: `#${key}.${States.error}`,
            },
          },
          tags: 'pending',
        },
        pendingGroup: {
          invoke: {
            src: context => {
              return context.selectedGroup ? getGroup(context.selectedGroup.id) : Promise.reject('No group selected');
            },
            onDone: {
              actions: assign({ selectedGroup: (context, event) => event.data }),
              target: 'ready',
            },
            onError: {
              actions: assign({ error: (context, event) => event.data }),
              target: `#${key}.${States.error}`,
            },
          },
          tags: 'pending',
        },
        ready: {
          on: {
            ADD_MEMBERS: {
              cond: context => (context.selectedGroup?.users?.length ?? 0) + (context.selectedGroup?.invites?.length ?? 0) < 15,
              actions: assign(function addMembers (context, event) {
                const { selectedGroup } = context;
                if (!selectedGroup) {
                  return context;
                }
                const { newUsers, newInvites } = event;
                const currentUsers = selectedGroup.users ?? [];
                const currentInvites = selectedGroup.invites ?? [];
                return {
                  ...context,
                  selectedGroup: {
                    ...selectedGroup,
                    users: [ ...newUsers, ...currentUsers ],
                    invites: [ ...newInvites, ...currentInvites ],
                  },
                  fallbackInvites: currentInvites,
                  fallbackUsers: currentUsers,
                };
              }),
              target: 'pendingAddMembers',
            },
            REMOVE_MEMBER: {
              actions: assign(function removeMemberAction (context, event) {
                const { selectedGroup } = context;
                if (!selectedGroup) {
                  return context;
                }
                const { id } = event;
                const currentUsers = selectedGroup.users ?? [];
                const currentInvites = selectedGroup.invites ?? [];

                return {
                  ...context,
                  selectedGroup: {
                    ...selectedGroup,
                    users: currentUsers.filter(user => user.id !== id),
                    invites: currentInvites.filter(email => email !== id),
                  },
                  fallbackInvites: currentInvites,
                  fallbackUsers: currentUsers,
                };
              }),
              target: 'pendingRemoveMember',
            },
            DELETE_GROUP: `#${key}.${States.deleteGroup}`,
          },
        },
        pendingAddMembers: {
          invoke: {
            src: (context, event) => {
              const typedEvent = event as AddMembersEvent;
              const { selectedGroup } = context;
              if (!selectedGroup) {
                return Promise.reject('No group selected');
              }
              const { newUsers, newInvites } = typedEvent;
              const promises: Promise<unknown>[] = [];

              if (newUsers.length) {
                trackMembershipAddition(newUsers, selectedGroup, 'GRID User');
                promises.push(addUsersToGroup(selectedGroup.id, newUsers.map(x => x.id)));
              }
              if (newInvites.length) {
                trackMembershipAddition(newInvites, selectedGroup, 'Email');
                promises.push(inviteToGroupViaEmail(selectedGroup.id, newInvites));
              }

              return Promise.all(promises);
            },
            onDone: {
              actions: assign(resetFallbacks),
              target: 'ready',
            },
            onError: {
              actions: assign(handleMemberManagementError),
              target: `#${key}.${States.error}`,
            },
          },
        },
        pendingRemoveMember: {
          invoke: {
            src: (context, event) => {
              const typedEvent = event as RemoveMemberEvent;
              const { selectedGroup } = context;
              if (!selectedGroup) {
                return Promise.reject('No group selected');
              }
              const { id } = typedEvent;
              const memberCount = selectedGroup.users.length + (selectedGroup.invites ? selectedGroup.invites.length : 0);
              tracking.logEvent('User Group Membership Updated', {
                user_group_id: selectedGroup.id,
                group_owner_id: selectedGroup.creator_id,
                num_users_in_group: memberCount,
                update_type: 'Removal',
                updater_type: 'Group Owner',
                member_type: typedEvent.memberType === 'user' ? 'GRID user' : 'Email',
              });
              if (typedEvent.memberType === 'user') {
                return removeUserFromGroup(selectedGroup.id, id);
              }
              else {
                return removeEmailFromGroup(selectedGroup.id, id);
              }
            },
            onDone: {
              actions: assign(resetFallbacks),
              target: 'ready',
            },
            onError: {
              actions: assign(handleMemberManagementError),
              target: `#${key}.${States.error}`,
            },
          },
        },
      },
      on: {
        CLOSE: States.closed,
        GO_BACK: States.overview,
      },
    },
    deleteGroup: {
      initial: 'delete',
      states: {
        delete: {
          on: { ACCEPT: 'pendingDelete' },
        },
        pendingDelete: {
          invoke: {
            src: context => {
              if (!context.selectedGroup) {
                return Promise.reject('No group selected');
              }
              tracking.logEvent('User Group Deleted', { user_group_id: context.selectedGroup.id });
              return deleteGroup(context.selectedGroup.id);
            },
            onDone: {
              actions: assign({ groups: context => context.groups.filter(({ id }) => id !== context.selectedGroup?.id) }),
              target: `#${key}.${States.overview}`,
            },
            onError: {
              actions: assign({ error: (context, event) => event.data }),
              target: `#${key}.${States.error}`,
            },
          },
          tags: 'pending',
        },
      },
      on: {
        CANCEL: States.manageGroup,
        GO_BACK: States.manageGroup,
        CLOSE: States.closed,
      },
    },
  },
};

export const UserGroupsMachine = createMachine<UserGroupsContext, UserGroupsEvents>(stateMachineConfig);
