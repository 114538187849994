/* globals BUILD_ID */
import { isBrowser, localStorage, token as accessToken } from '@grid-is/browser-utils';
import { getConfig } from '@grid-is/environment-config';
import { assert } from '@grid-is/validation';

import { AuthorizationRequiredError } from '@/api/errors';

import { sessionId } from './session';

export interface RequestOptions {
  headers?: Record<string, string>,
  additionalAnalytics?: Record<string, any>,
  body?: BodyInit,
  method?: 'GET' | 'PUT' | 'POST' | 'DELETE',
  signal?: AbortSignal,
}

const defaultOptions: RequestOptions = {
  // credentials: 'same-origin',
  headers: {
    'Accept': 'application/json; charset=UTF-8',
    'X-Client-Build': BUILD_ID,
  },
};

const isURI = /^(https?:)?\/\//;

// API_URL can be set via an environment variable, but defaults to /api
function defaultApiUrl (): string {
  let result = '/api';
  const config = getConfig();
  if (config?.API_URL) {
    result = config.API_URL;
  }
  else if (isBrowser()) {
    result = `${location.protocol}//${location.host}/api`;
  }
  return result;
}

export function frontApiUrl (): string {
  return `${location.protocol}//${location.host}/api/front`;
}

export function getApiUrl (): string {
  return localStorage.getItem('API_URL') || defaultApiUrl();
}

export function isProdEnvironment (): boolean {
  return getApiUrl() === 'https://api.calculatorstudio.co' || getApiUrl() === 'https://grid.is/api' || getApiUrl() === 'https://api.grid.is';
}

function autoJSON (options: RequestOptions): RequestOptions {
  // pull out the payload
  const body = options.body;
  // if there is no payload, we don't need to do anything
  if (!body || typeof body === 'string' || body instanceof Blob) {
    return options;
  }
  // determine if there is a content-type header (might be case insensitive)
  if (options.headers && Object.keys(options.headers).filter(h => h.toLowerCase() === 'content-type').length) {
    // if content-type is already set, leave this alone
    return options;
  }
  // if there is no content-type, we are free to jsonify
  if (Array.isArray(body) || typeof body === 'object') {
    return Object.assign({}, options, {
      body: JSON.stringify(body),
      headers: Object.assign({}, options.headers || {}, {
        'Content-Type': 'application/json; charset=UTF-8',
      }),
    });
  }
  return options;
}

export async function unJSON (res: Response) {
  const isJson = /^application\/json\b/.test(res.headers.get('content-type') || '');
  const bodyPromise = isJson ? res.json() : res.text();
  const body = await bodyPromise;
  if (res.ok) {
    return body;
  }
  else if (typeof body !== 'string') {
    body.status = res.status;
  }
  throw body; // FIXME: instead, throw an Error wrapping the body, to include message and stack trace
}

function autoAuth (options: RequestOptions, url: string): RequestOptions {
  if (url && isURI.test(url)) {
    // don't send access tokens to 3rd parties
    return options;
  }
  const token = accessToken.get();
  if (token) {
    if (!options.headers) {
      options.headers = {};
    }
    if (!options.headers.Authorization) {
      options.headers.Authorization = `Bearer ${token}`;
    }
  }
  return options;
}

// IE Edge is very sensitive about how headers are sent, this fixes that
function fixHeaders (options: RequestOptions): RequestOptions {
  if (typeof Headers !== 'undefined' && options.headers) {
    const headers = new Headers();
    for (const key of Object.keys(options.headers)) {
      if (options.headers[key]) {
        headers.set(key, options.headers[key]);
      }
    }
    return Object.assign({}, options, { headers: headers });
  }
  return options;
}

/**
 * Checks whether the given URL path points to the front API, which is part of the GRID-client project
 * */
function isFrontApiPath (path: string): boolean {
  return path.startsWith('/front/') || path.startsWith('front/');
}

/**
 * Checks whether client is being browsed on localhost
 */
function hostNameIsLocalHost (): boolean {
  return location && location.hostname === 'localhost';
}

function resolvePath (path: string): string {
  // don't prepend things that are already URLs
  if (isURI.test(path)) {
    return path;
  }
  let apiUrl = getApiUrl();

  // Ensure that requests to the "front" api get routed to localhost when the client is being run locally
  if (isFrontApiPath(path) && hostNameIsLocalHost()) {
    apiUrl = location.origin + '/api';
  }

  return path.replace(/^\/?/, apiUrl + '/');
}

export function gridfetch (url: string, opts: RequestOptions = {}, magicJSON = false): Promise<any> {
  let options = Object.assign({}, defaultOptions, opts);
  options.headers = Object.assign({ 'x-grid-session-id': sessionId }, options.headers);
  if (magicJSON) {
    options = autoJSON(options);
  }
  options = autoAuth(options, url);

  // check if additionalAnalytics is set for the API call and enrich the request with a header
  if (options.additionalAnalytics) {
    assert(options.headers);
    options.headers['additional-analytics'] = encodeURIComponent(JSON.stringify(options.additionalAnalytics));
  }

  options = fixHeaders(options);
  return fetch(resolvePath(url), options)
    .then(res => {
      // XXX: throw appropriate errors (e.g. NotFound, AccessDenied, LockConflict) when response is not OK.
      // HTTP concerns should be encapsulated within this module and users of it should use structured error handling.

      if (res.status === 401) {
        throw new AuthorizationRequiredError(`401 status code returned from ${url}`);
      }
      else {
        const updatedAuthToken = res.headers.get('X-Grid-Token');
        if (updatedAuthToken && isBrowser() && isNotCached(res)) {
          accessToken.set(updatedAuthToken);
        }
        return magicJSON ? unJSON(res) : res;
      }
    });
}

function isNotCached (response: Response): boolean {
  const cacheControl = response.headers.get('Cache-Control') || '';
  return cacheControl.includes('no-store');
}

// XXX: get, put and post are not used and can be removed

// XXX: the method and body could technically be overwritten by the supplied opts object.
// We should change the assignment order to prevent this.

// TODO: Add a del(ete) shorthand?

export const get = (url: string, opts: RequestOptions): Promise<any> => {
  return gridfetch(url, Object.assign({ method: 'GET' }, opts));
};

export const put = (url: string, opts: RequestOptions): Promise<any> => {
  return gridfetch(url, Object.assign({ method: 'PUT' }, opts));
};

export const post = (url: string, body: BodyInit, opts: RequestOptions): Promise<any> => {
  return gridfetch(url, Object.assign({ method: 'POST', body: body }, opts));
};

export function getJSON<T = any> (url: string, opts?: RequestOptions): Promise<T> {
  return gridfetch(url, Object.assign({ method: 'GET' }, opts), true);
}

export function putJSON<TResponse = any, TRequest = Record<string, any>> (url: string, body?: TRequest, opts?: RequestOptions): Promise<TResponse> {
  return gridfetch(url, Object.assign({ method: 'PUT', body: body }, opts), true);
}

export function postJSON<TResponse = any, TRequest = Record<string, any>> (url: string, body?: TRequest, opts?: RequestOptions): Promise<TResponse> {
  return gridfetch(url, Object.assign({ method: 'POST', body: body }, opts), true);
}

export function deleteJSON<TResponse = any, TRequest = Record<string, any>> (url: string, body?: TRequest, opts?: RequestOptions): Promise<TResponse> {
  const adjustedOptions: RequestOptions = Object.assign({}, opts, { method: 'DELETE', body: body });
  return gridfetch(url, adjustedOptions, true);
}

export const request = gridfetch;

export function fetchForSWR (url: string, password?: string): Promise<any> {
  const opts: RequestOptions = {};
  if (password) {
    opts.headers = { 'X-Document-Password': encodeURIComponent(password) };
  }
  return getJSON(url, opts);
}
