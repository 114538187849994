import { ForwardedRef, forwardRef, ReactNode } from 'react';
import csx from 'classnames';

import { Icon, IconTypes } from '@grid-is/icon';

import styles from './ToastContents.module.scss';

export type ToastContentsProps = {
  message?: ReactNode,
  icon?: IconTypes | ReactNode,
  className?: string,
  callToAction?: {
    href: string,
    label: ReactNode,
    target?: string,
  },
  onClick?: () => void,
}

export const ToastContents = forwardRef(({ message, icon, className, callToAction, onClick }: ToastContentsProps, ref: ForwardedRef<HTMLDivElement>) => {
  return (
    <div className={csx(styles.toast, className, onClick && styles.onClick, !callToAction && styles.hover)} onClick={onClick} ref={ref} role="alert">
      {icon && (
        <span className={styles.icon}>
          {typeof icon === 'string' ? <Icon size={22} name={icon as IconTypes} /> : icon}
        </span>
      )}
      <span
        className={csx(
          styles.message,
          icon && styles.hasIcon,
          callToAction && styles.hasButton,
        )}
        >{message}
      </span>
      {
        callToAction && (
          <a
            className={styles.ctaButton}
            href={callToAction.href}
            >{callToAction.label}
          </a>)
      }
    </div>
  );
});
