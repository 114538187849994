/* eslint-disable default-param-last */
import localCache from '@borgar/localcache';

import { isBrowser, sessionStorage } from '@grid-is/browser-utils';
import { extractId } from '@grid-is/uuid';
import { assert } from '@grid-is/validation';

import { paths, schemas } from './generatedTypes';
import { deleteJSON, getJSON, postJSON, request, type RequestOptions } from './request';
import { getUserLikes } from './user';

// XXX: this file is quite big, consider splitting up
type generatedDocumentType = schemas['Document'];
export type ViewAccess = generatedDocumentType['view_access'];
export interface Document extends generatedDocumentType {
  isLiked: boolean,
}

export type BaseDocument = schemas['BaseDocument'];
// XXX: Whether to show a "shared with you" badge is purely a presentation concern. This doesn't belong here.
export type MaybeReadDocument = BaseDocument & { isLiked?: boolean, shared_with_you_notification_badge?: boolean };
export type Scenario = schemas['Scenario'];

// Use `Required<>` to strip away `undefined` as one of the possible values for the search filter
export type SearchFilters = Required<Required<paths['/documents']['get']['parameters']>['query']>['filter'];

export type DocumentType = schemas['Document']['type'];
export type CommentType = schemas['Comment'];
export type CommentAnchor = schemas['CommentAnchor'];

type CreateDocumentRequest = paths['/document']['post']['requestBody']['content']['application/json'];
type CreateDocumentResponse = paths['/document']['post']['responses']['200']['content']['application/json'];

export function create (
  body: string,
  source_id: string | undefined,
  title: string,
  type: DocumentType,
  additionalAnalytics: Record<string, any>,
) {
  const payload: CreateDocumentRequest = {
    body,
    title,
    type,
    format: 'v2',
  };
  if (source_id) {
    payload.source_id = source_id;
  }
  return postJSON<CreateDocumentResponse, CreateDocumentRequest>('/document', payload, { additionalAnalytics });
}

type UpdateDocumentRequest = paths['/document/{document_id}']['post']['requestBody']['content']['application/json']
type UpdateDocumentResponse = paths['/document/{document_id}']['post']['responses']['200']['content']['application/json'];

export function update (
  id: string,
  fromVersion: number,
  body: string,
  title = 'Untitled',
  description?: string,
  language?: string,
  settings?: Record<string, any>,
  type?: DocumentType,
) {
  const payload: UpdateDocumentRequest = {
    body: body,
    format: 'v2',
    from_version: fromVersion,
  };
  if (description != null) {
    payload.description = description;
  }
  if (language != null) {
    payload.language = language;
  }
  if (title != null) {
    payload.title = title;
  }
  if (settings != null) {
    payload.client_settings = settings;
  }
  if (type != null) {
    payload.type = type;
  }
  return postJSON<UpdateDocumentResponse, UpdateDocumentRequest>(`/document/${id}`, payload);
}

export function updateTitle (
  id: string,
  title: string,
) {
  const payload = {
    id: id,
    format: 'v2' as const,
    title,
  };
  return postJSON<UpdateDocumentResponse, UpdateDocumentRequest>(`/document/${id}`, payload);
}

export function updateDraft (
  id: string,
  fromVersion: number,
  body?: string,
  description?: string | null,
  language?: string,
) {
  const payload: UpdateDocumentRequest = {
    id: id,
    draft: {},
    format: 'v2',
    from_version: fromVersion,
  };
  assert(payload.draft);
  if (body != null) {
    payload.draft.body = body;
  }
  if (description != null) {
    payload.draft.description = description;
  }
  if (language != null) {
    payload.draft.language = language;
  }
  return postJSON<UpdateDocumentResponse, UpdateDocumentRequest>(`/document/${id}`, payload);
}

type PublishDraftRequest = paths['/document/{document_id}/draft/publish']['post']['requestBody']['content']['application/json'];
type PublishDraftResponse = paths['/document/{document_id}/draft/publish']['post']['responses']['200']['content']['application/json'];

export function publishDraft (id: string, version: number) {
  return postJSON<PublishDraftResponse, PublishDraftRequest>(`/document/${id}/draft/publish`, { version });
}

type DiscardDraftRequest = paths['/document/{document_id}/draft/discard']['post']['requestBody']['content']['application/json'];
type DiscardDraftResponse = paths['/document/{document_id}/draft/discard']['post']['responses']['200']['content']['application/json'];

export function discardDraft (id: string, version: number) {
  return postJSON<DiscardDraftResponse, DiscardDraftRequest>(`/document/${id}/draft/discard`, { version });
}

function setDocumentFlags (id: string, flags: { commenting_enabled?: boolean, is_duplicatable?: boolean, is_embeddable?: boolean, viewers_can_save_scenarios?: boolean }) {
  return postJSON<UpdateDocumentResponse, UpdateDocumentRequest>(`/document/${id}`, flags);
}

export function updateDomainList (id: string, allowed_domains: string[]) {
  return postJSON<UpdateDocumentResponse, UpdateDocumentRequest>(`/document/${id}`, { id, allowed_domains });
}

type DuplicateDocumentRequest = paths['/document/{document_id}/duplicate']['post']['requestBody']['content']['application/json'];
type DuplicateDocumentResponse = paths['/document/{document_id}/duplicate']['post']['responses']['201']['content']['application/json'];

export function duplicate (
  id: string,
  title?: string,
  additionalAnalytics?: Record<string, any>,
) {
  const opts = getRequestOptionsFor(id, { additionalAnalytics });
  const payload = {
    title,
  };
  return postJSON<DuplicateDocumentResponse, DuplicateDocumentRequest>(`/document/${id}/duplicate`, payload, opts);
}

type AcquireLockResponse = paths['/document/{document_id}/lock']['post']['responses']['200']['content']['application/json'];

export function acquireUpdateLock (id: string) {
  return postJSON<AcquireLockResponse>(`/document/${id}/lock`);
}

type ReturnUpdateLockResponse = paths['/document/{document_id}/lock']['delete']['responses']['200']['content']['application/json'];

export function returnUpdateLock (id: string) {
  return deleteJSON<ReturnUpdateLockResponse>(`/document/${id}/lock`);
}

function updateAccess (id: string, access: ViewAccess, password?: string) {
  const payload = {
    id,
    view_access: access,
    password,
  };
  return postJSON<UpdateDocumentResponse, UpdateDocumentRequest>(`/document/${id}`, payload);
}

type SourcesRequest = paths['/document/{document_id}/sources']['post']['requestBody']['content']['application/json'];
type SourcesResponse = paths['/document/{document_id}/sources']['post']['responses']['200']['content']['application/json'];

export function sources (id: string, version: number, source_ids: string[]) {
  const payload = {
    version: version,
    source_ids: source_ids,
  };
  return postJSON<SourcesResponse, SourcesRequest>(`/document/${id}/sources`, payload);
}

export function erase (id: string): Promise<void> {
  return request(`/document/${id}`, { method: 'DELETE' });
}

async function addLikes (documents: BaseDocument[]) {
  if (documents && !documents.length) {
    return [];
  }
  const documentIds = documents.map(d => d.id);
  const likedDocuments = await getUserLikes(documentIds);
  return documents.map(d => addIsLikedToDoc(d, likedDocuments));
}

function addIsLikedToDoc<T extends {id: string}> (doc: T, likedDocumentIds: string[]): T & {isLiked: boolean} {
  return { ...doc, isLiked: likedDocumentIds.includes(doc.id) };
}

type SearchParams = { username?: string, query?: string, filter: SearchFilters, cursor?: string }
type SearchResponse = paths['/documents']['get']['responses']['200']['content']['application/json']; // Same for /documents and /documents/{user}

export async function search ({ username, query = '', filter, cursor }: SearchParams, opts = {}) {
  let url = '/documents';

  if (username) {
    url = '/documents/' + encodeURIComponent(username);
  }

  url += `?query=${encodeURIComponent(query)}`;
  if (cursor) {
    url += `&cursor=${encodeURIComponent(cursor)}`;
  }
  if (filter) {
    url += `&filter=${encodeURIComponent(filter)}`;
  }

  const response = await getJSON<SearchResponse>(url, opts);
  return { ...response, items: await addLikes(response.items) };
}

type ExploreResponse = paths['/explore/{limit}']['get']['responses']['200']['content']['application/json']

export async function firehose (number = 20) {
  const response = await getJSON<ExploreResponse>('/explore/' + number);
  return addLikes(response);
}

export async function statistics (documentId: string) {
  const [ allViews, viewsByDay, viewsByUser ] = await Promise.all([
    getDocumentViews(documentId),
    getDocumentViewsByDay(documentId),
    getDocumentViewsByUser(documentId),
  ]);
  return {
    views: allViews.views,
    viewsByDate: viewsByDay.views,
    viewsByUser: viewsByUser.views,
  };
}

type DocumentViewsResponse = paths['/document/{document_id}/stats/views']['get']['responses']['200']['content']['application/json'];

function getDocumentViews (documentId: string) {
  return getJSON<DocumentViewsResponse>(`/document/${documentId}/stats/views`);
}

type DocumentViewsByDayResponse = paths['/document/{document_id}/stats/views/day']['get']['responses']['200']['content']['application/json'];

function getDocumentViewsByDay (documentId: string) {
  return getJSON<DocumentViewsByDayResponse>(`/document/${documentId}/stats/views/day`);
}

type DocumentViewsByUserResponse = paths['/document/{document_id}/stats/views/user']['get']['responses']['200']['content']['application/json'];

function getDocumentViewsByUser (documentId: string) {
  return getJSON<DocumentViewsByUserResponse>(`/document/${documentId}/stats/views/user`);
}

export async function getDocument (id: string, authToken?: string, embed?: boolean, embedReferrer?: string): Promise<Document> {
  const [ doc, likes ] = await Promise.all([
    _getDocument(id, authToken, embed, embedReferrer),
    getUserLikes([ id ]),
  ]);
  return addIsLikedToDoc(doc, likes);
}

type GetDocumentResponse = paths['/document/{document_id}']['get']['responses']['200']['content']['application/json'];

function _getDocument (id: string, authToken?: string, embed?: boolean, embedReferrer?: string) {
  const opts = getRequestOptionsFor(id, {}, authToken, embedReferrer);
  let url = '/document/' + id;
  if (embed !== undefined) {
    url += `?is_embedded=${embed}`;
  }
  return getJSON<GetDocumentResponse>(url, opts);
}

type GetMetadataResponse = paths['/document/{document_id}/metadata']['get']['responses']['200']['content']['application/json'];

function getDocumentMetadata (id: string) {
  return getJSON<GetMetadataResponse>('/document/' + id + '/metadata');
}
// XXX: caching should probably happen at a layer above this one. Also, localCache is untyped...
export const getMetadata: typeof getDocumentMetadata = localCache(getDocumentMetadata, { lifetime: 60 });

type LikeResponse = paths['/document/{document_id}/like']['post']['responses']['200']['content']['application/json'];

export function like (id: string) {
  return postJSON<LikeResponse>('/document/' + id + '/like');
}

type UnlikeResponse = paths['/document/{document_id}/unlike']['post']['responses']['200']['content']['application/json'];

export function unlike (id: string) {
  return postJSON<UnlikeResponse>('/document/' + id + '/unlike');
}

export function toggleLike (id: string, isLiked?: boolean) {
  if (isLiked) {
    return unlike(id).then(results => results.likes);
  }
  else {
    return like(id).then(results => results.likes);
  }
}

type DocumentSearchRequest = paths['/documents/search']['post']['requestBody']['content']['application/json'];
type DocumentSearchResponse = paths['/documents/search']['post']['responses']['200']['content']['application/json'];

export async function oldDocumentsSearch (terms: string) {
  // XXX: trim?
  if (terms.length < 2) {
    return Promise.resolve({
      query: terms,
      documents: [],
      count: 0,
      page: 0,
    });
  }
  const response = await postJSON<DocumentSearchResponse, DocumentSearchRequest>('/documents/search', { query: terms });
  const documentsWithLikes = await addLikes(response);
  return {
    query: terms,
    documents: documentsWithLikes,
    count: documentsWithLikes.length,
    page: 0,
  };
}

type CountViewRequest = Required<paths['/document/{document_id}/viewed']['post']>['requestBody']['content']['application/json'];
type CountViewResponse = paths['/document/{document_id}/viewed']['post']['responses']['200']['content']['application/json'];

export function countView (id: string, isEmbedded: boolean, referer: string, additionalAnalytics: Record<string, any>) {
  return postJSON<CountViewResponse, CountViewRequest>('/document/' + id + '/viewed', { is_embedded: isEmbedded, referer }, { additionalAnalytics });
}

const PW_SESSION_KEY = 'doc_pw';

function getDocumentPasswordStorageKey (documentId: string): string {
  return `${PW_SESSION_KEY}_${extractId(documentId)}`;
}

export function getDocumentPassword (documentId?: string): string {
  if (documentId && isBrowser()) {
    return sessionStorage.getItem(getDocumentPasswordStorageKey(documentId)) || '';
  }
  return '';
}

export function setDocumentPassword (documentId: string, password: string) {
  sessionStorage.setItem(getDocumentPasswordStorageKey(documentId), password);
}

type CreateDocumentStateRequest = paths['/document/{document_id}/state']['post']['requestBody']['content']['application/json'];

export function createDocumentState (
  documentId: string,
  submitType: 'email' | 'zapier',
  payload: Record<string, any>,
) {
  const body = {
    action: submitType,
    payload,
  };
  return postJSON<{}, CreateDocumentStateRequest>(`/document/${documentId}/state`, body);
}

type FetchPasswordResponse = paths['/document/{document_id}/password']['get']['responses']['200']['content']['text/plain'];

function fetchDocumentPassword (documentId: string) {
  return getJSON<FetchPasswordResponse>(`/document/${documentId}/password`);
}

type CreateCommentRequest = paths['/document/{document_id}/comment']['post']['requestBody']['content']['application/json'];
type CreateCommentResponse = paths['/document/{document_id}/comment']['post']['responses']['200']['content']['application/json'];

export function createComment (
  documentId: string,
  anchorId: string,
  body: string,
  quote?: string,
) {
  const payload = {
    anchor_id: anchorId,
    body: body,
    quote: quote,
  };
  const opts = getRequestOptionsFor(documentId);
  return postJSON<CreateCommentResponse, CreateCommentRequest>(`/document/${documentId}/comment`, payload, opts);
}

export function deleteComment (documentId: string, commentId: string): Promise<void> {
  return request(`/document/${documentId}/comment/${commentId}`, { method: 'DELETE' });
}

export function resolveComment (documentId: string, commentId: string) {
  return postJSON<{}>(`/document/${documentId}/comment/${commentId}/resolve`);
}

type SupportRequest = paths['/document/{document_id}/request_support']['post']['requestBody']['content']['application/json'];

export function requestSupport (documentId: string, description: string, shareWithSupport: boolean) {
  const payload = {
    description, share_with_support: shareWithSupport,
  };
  return postJSON<{}, SupportRequest>(`/document/${documentId}/request_support`, payload);
}

type AccessRequest = paths['/document/{document_id}/request_access']['post']['requestBody']['content']['application/json'];

// XXX: since access can only be 'view', then there's no point in having it as a parameter
export function requestAccess (documentId: string, access: 'view') {
  return postJSON<{}, AccessRequest>(`/document/${documentId}/request_access`, { access });
}

type ScenariosResponse = paths['/document/{document_id}/scenario']['get']['responses']['200']['content']['application/json'];

export function getScenarios (documentId: string) {
  const opts = getRequestOptionsFor(documentId);
  return getJSON<ScenariosResponse>(`/document/${documentId}/scenario`, opts);
}

export function deleteScenario (documentId: string, scenarioId: string): Promise<void> {
  return request(`/document/${documentId}/scenario/${scenarioId}`, { method: 'DELETE' });
}

export function updateScenarioAccess (documentId: string, scenarioId: string, access: string): Promise<{}> {
  const payload = { access };
  return postJSON<{}, {}>(`/document/${documentId}/scenario/${scenarioId}/access`, payload);
}

type SaveScenarioRequest = paths['/document/{document_id}/scenario']['post']['requestBody']['content']['application/json'];

export function saveScenario (documentId: string, title: string, state: string) {
  const opts = getRequestOptionsFor(documentId);
  const payload = { state, title };

  return postJSON<{}, SaveScenarioRequest>(`/document/${documentId}/scenario`, payload, opts);
}

export function getDocumentsWithFormSubmissions () {
  type Response = paths['/documents/with-form-submissions']['get']['responses']['200']['content']['application/json'];
  return getJSON<Response>('/documents/with-form-submissions');
}

export function getRequestOptionsFor (
  documentId: string,
  otherOptions: Partial<RequestOptions> = {},
  authToken?: string,
  embedReferrer?: string,
) {
  const opts = Object.assign({ headers: {} }, otherOptions);
  const password = getDocumentPassword(documentId);
  if (password) {
    opts.headers['X-Document-Password'] = encodeURIComponent(password);
  }
  if (authToken) {
    opts.headers.Authorization = `Bearer ${authToken}`;
  }
  if (embedReferrer) {
    opts.headers['X-Embed-Referrer'] = embedReferrer;
  }
  return opts;
}

export class DocumentService {
  duplicate = duplicate;
  get = getDocument;
  getPassword = fetchDocumentPassword;
  setFlags = setDocumentFlags;
  search = search;
  updateAccess = updateAccess;
}
