import { ReactNode } from 'react';
import csx from 'classnames';

import styles from './subtleCTAButton.module.scss';

type Props = {
  children: ReactNode,
  onClick: () => void,
  loadMore?: boolean,
}

export function SubtleCTAButton ({ children, onClick, loadMore }: Props) {
  return (
    <button
      type="button"
      className={csx(styles.subtleButton, loadMore && styles.loadMore)}
      onClick={onClick}
      >{children}
    </button>
  );
}
