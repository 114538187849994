// @ts-nocheck
import collate from './collate';
import isNumber from './isNumber';

const identity = d => d;

export default function stableSort (list, direction = 1, valueAccessor = identity) {
  return list
    .map((d, i) => [ d, valueAccessor(d, i), i ])
    .sort((a, b) => {
      const nA = isNumber(a[1]) * 1;
      const nB = isNumber(b[1]) * 1;
      const r = (nA !== nB) ? collate(nA, nB) : collate(a[1], b[1]);
      return !r ? (a[2] - b[2]) * direction : r * direction;
    })
    .map(d => d[0]);
}
