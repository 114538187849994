import { useEffect } from 'react';
import Link from 'next/link';

import { isNumber } from '@/grid/utils';
import { Button } from '@/grid-ui/Button';
import { Modal } from '@/grid-ui/Modal';
import { useDeps } from '@/bootstrapping/dependencies';
import { UsageKPI } from '@/components/Settings/Usage/UsageKPI';

import { getOverageTrackingPayload as getOverageTrackingProperties, UsageOverage } from '../../utils';
import { modalTextByPeriod } from './utils';

import styles from './UsageOverageModal.module.scss';

type UsageOverageModalProps = {
  usageOverage: UsageOverage,
  onCancel: () => void,
}

function maybeGetAlert (total: number, limit: number): 'error' | undefined {
  if (isNumber(limit) && total > limit) {
    return 'error';
  }
}

const keyToLabel = {
  document_views: 'Document views',
  form_submissions: 'Form submissions',
  seats: 'Seats',
};

export const UsageOverageModal = ({ onCancel, usageOverage }: UsageOverageModalProps) => {
  // We don't want to show the modal for lastMonths overage.
  const { userEvents } = useDeps();

  const isCurrentMonthOverage = usageOverage.period === 'currentMonth';

  useEffect(() => {
    if (isCurrentMonthOverage) {
      const properties = getOverageTrackingProperties(usageOverage.overages, 'Modal');
      userEvents.overageMessagingShown(
        properties.documentViews,
        properties.embedDomains,
        properties.formSubmissions,
        properties.seats,
        properties.messagingFormat,
      );
    }
  }, [ userEvents, isCurrentMonthOverage, usageOverage.overages ]);

  if (!isCurrentMonthOverage) {
    return null;
  }

  const modalText = modalTextByPeriod[usageOverage.period];
  return (
    <Modal
      open
      header={modalText.header}
      size="small-medium"
      onClose={onCancel}
      >
      <section className={styles.container}>
        <div className={styles.kpiContainer}>
          {
        Object.keys(usageOverage.usage.total).map(key => {
          // Removed for now from our plan limits.
          if (key === 'seats') {
            return null;
          }
          const maybeAlert = maybeGetAlert(usageOverage.usage.total[key], usageOverage.usage.limits[key]);
          return (
            <UsageKPI
              key={key}
              label={keyToLabel[key]}
              alert={maybeAlert}
              value={usageOverage.usage.total[key]}
              limit={usageOverage.usage.limits[key]}
              showLimit={!!usageOverage.usage.limits[key]}
              />
          );
        })
      }
        </div>
        <div className={styles.description}>{modalText.description}</div>
        <span className={styles.button}>
          <Link href={modalText.url}>
            <Button
              onClick={() => {
                const properties = getOverageTrackingProperties(usageOverage.overages, 'Modal');
                userEvents.overageMessagingClicked(
                  properties.documentViews,
                  properties.embedDomains,
                  properties.formSubmissions,
                  properties.seats,
                  properties.messagingFormat,
                );
                onCancel();
              }}
              buttonType="primary"
              buttonSize="large"
              >
              {modalText.confirmButton}
            </Button>
          </Link>
        </span>
      </section>
    </Modal>
  );
};
