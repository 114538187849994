import { FC, useEffect, useState } from 'react';
import csx from 'classnames';
import { useRouter } from 'next/router';

import { query } from '@grid-is/browser-utils';

import { DancingSliders } from '@/grid-ui/DancingSliders/DancingSliders';

import styles from './welcomeBoundary.module.scss';

type WelcomeAnimationProps = {
  onComplete: () => void,
}

const WelcomeAnimation: FC<WelcomeAnimationProps> = ({ onComplete = () => {} }) => {
  const [ animationComplete, setAnimationComplete ] = useState(false);

  return (
    <div data-testid="welcome-animation" className={csx(styles.welcomeAnimation, animationComplete && styles.dissapear)}>
      <DancingSliders
        size={150}
        iterationCount={1}
        onAnimationComplete={() => {
          setAnimationComplete(true);
          setTimeout(() => onComplete(), 800);
        }}
        />
    </div>
  );
};

export const WelcomeBoundary = () => {
  const [ showWelcomeAnimation, setShowWelcomeAnimation ] = useState(false);
  const router = useRouter();

  useEffect(() => {
    function onShowWelcome () {
      setShowWelcomeAnimation(true);
    }
    const onRouteChangeComplete = url => {
      if (url.includes('user/login/authorize')) {
        setShowWelcomeAnimation(true);
      }
      else if (url.includes('showWelcome=1')) {
        router.replace(query.remove(url, 'showWelcome'));
        setShowWelcomeAnimation(true);
      }
    };

    router.events.on('routeChangeComplete', onRouteChangeComplete);
    document.addEventListener('onGridShowWelcomeAnimation', onShowWelcome);
    return () => {
      router.events.off('routeChangeComplete', onRouteChangeComplete);
      document.removeEventListener('onGridShowWelcomeAnimation', onShowWelcome);
    };
  }, [ router ]);

  if (showWelcomeAnimation) {
    return <WelcomeAnimation onComplete={() => setShowWelcomeAnimation(false)} />;
  }

  return null;
};
