import { useCallback, useState } from 'react';
import ReactDOM from 'react-dom';
import csx from 'classnames';
import { useRouter } from 'next/router';
import useSWR, { useSWRConfig } from 'swr';

import { OutsideClickHandler } from '@grid-is/outside-click-handler';
import { tracking } from '@grid-is/tracking';

import {
  markNotificationsAsRead,
  markNotificationsAsSeen,
  notifications as getNotifications,
} from '@/api/notifications';
import { IconButton } from '@/grid-ui/IconButton';
import { TextLink } from '@/grid-ui/TextLink';
import { Tooltip } from '@/grid-ui/Tooltip';
import { isMobile } from '@/utils';
import { useAuth } from '@/utils/auth';

import { EmptyState } from './EmptyState';
import { NotificationItem } from './NotificationItem';
import { GridNotification } from './types';
import { constructNotificationUrl } from './utils';

import styles from './notifications.module.scss';

function populateTrackingData (n: GridNotification) {
  if (n.type === 'document_milestone_reached') {
    return {
      notification_id: n.id,
      notification_type: n.type,
      document_id: n.details.document?.id,
      milestone: `${n.details.milestone} Document View${n.details.milestone > 1 ? 's' : ''}`,
    };
  }
  else if (
    n.type === 'you_were_added_to_group' ||
      n.type === 'user_added_to_group' ||
      n.type === 'email_invited_to_group'
  ) {
    return {
      notification_id: n.id,
      notification_type: n.type,
      triggering_user_id: n.details.user.id,
      group_id: n.details.group.id,
    };
  }
  return {
    notification_id: n.id,
    notification_type: n.type,
    triggering_user_id: n.details.user?.id,
    document_id: n.details.document?.id,
  };
}

function NotificationPanel () {
  const router = useRouter();
  const { isLoggedIn, user } = useAuth();
  const [ open, setOpen ] = useState(false);
  const { mutate } = useSWRConfig();
  const { data } = useSWR('api/notifications', getNotifications, { errorRetryCount: 3 });
  // XXX: this cast may lead to type errors further on as we access attributes that may be undefined
  const notifications = data?.items as GridNotification[];

  const unSeenNotification = notifications?.find(n => n.state === 'unseen');

  const handleButtonClick = useCallback(() => {
    setOpen(!open);

    let noUnseenNotifications = 0;
    let noSeenNotifications = 0;
    let noReadNotifications = 0;
    notifications?.forEach(n => {
      switch (n.state) {
        case 'read':
          noReadNotifications++;
          break;
        case 'seen':
          noSeenNotifications++;
          break;
        case 'unseen':
          noUnseenNotifications++;
          break;
      }
    });

    tracking.logEvent('Notification Panel Opened', {
      num_unseen_notifications: noUnseenNotifications,
      num_seen_notifications: noSeenNotifications,
      num_read_notifications: noReadNotifications,
      num_total_notifications: notifications?.length ?? 0,
    });
    if (!open && notifications?.length) {
      const unseenNotifications = notifications.filter(n => n.state === 'unseen') ?? [];
      unseenNotifications.forEach(n => tracking.logEvent('Notification Seen', populateTrackingData(n)));
      markNotificationsAsSeen(unseenNotifications.map(({ id }) => id)).then(() => mutate('api/notifications'));
    }
  }, [ open, mutate, notifications ]);

  const handleClick = useCallback((notification: GridNotification, isMetaKey: boolean) => {
    if (notification.state !== 'read') {
      tracking.logEvent('Notification Clicked', populateTrackingData(notification));
      markNotificationsAsRead([ notification.id ]).then(() => mutate('api/notifications'));
    }
    if (!isMetaKey) {
      setOpen(false);
    }
  }, [ mutate ]);

  const markAllAsRead = useCallback(() => {
    if (notifications?.length) {
      markNotificationsAsRead(notifications.map(({ id }) => id)).then(() => mutate('api/notifications'));
    }
  }, [ notifications, mutate ]);

  if (!isLoggedIn) {
    return null;
  }
  const hasNotifications = notifications && notifications.length > 0;
  const isOnMobile = isMobile();

  function renderPanel () {
    return (
      <div className={csx(styles.notificationPanel, isOnMobile && styles.mobile)}>
        <div className={styles.header}>
          <div className={styles.title}>Notifications</div>
          {hasNotifications && !isOnMobile &&
            <TextLink
              linkSize="small"
              className={styles.markAllAsRead}
              href="#"
              onClick={e => {
                e.preventDefault(); markAllAsRead();
              }}
              >Mark all as read
            </TextLink>
            }
          {isOnMobile && <IconButton className={styles.close} buttonType="secondary" onClick={() => setOpen(false)} iconName="window-close" ariaLabel="Close" />}
        </div>
        {!hasNotifications && <EmptyState />}
        <div className={styles.list}>
          {notifications?.map(
            notification => (
              <NotificationItem
                key={notification.id}
                notification={notification}
                onClick={handleClick}
                url={(user && constructNotificationUrl(notification, user, router.asPath, isOnMobile)) ?? undefined}
                />
            ),
          )}
        </div>
      </div>
    );
  }

  const content = (
    <>
      <Tooltip label="My notifications" disabled={open} hideOnElementClick>
        <IconButton
          buttonType="secondary"
          buttonSize="medium"
          onClick={handleButtonClick}
          iconName="bell"
          ariaLabel="Notifications"
          data-testid="notifications-button"
          id="notifications-button"
          className={csx(styles.notificationsButton, isOnMobile && styles.notificationsButtonSmall)}
          />
      </Tooltip>
      {unSeenNotification && <div className={csx(styles.notificationIndicator, isOnMobile && styles.mobile)} />}
      {open && !isOnMobile && renderPanel()}
      {open && isOnMobile && ReactDOM.createPortal(renderPanel(), document.body)}
    </>
  );

  if (isOnMobile) {
    return content;
  }
  return (
    <OutsideClickHandler onClickOutside={() => setOpen(false)} className={styles.notifications}>
      {content}
    </OutsideClickHandler>

  );
}

export { NotificationPanel };
