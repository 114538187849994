import { Input } from '@/grid-ui/Input';
import { ToggleButton } from '@/grid-ui/ToggleButton';

import styles from './FlagToggler.module.scss';

type Flag = {
  label: string,
  value: boolean | string | object,
  set: (label: string, value: boolean | string) => void,
};

export const Flag = ({ label, value, set }: Flag) => {
  switch (typeof value) {
    case 'boolean': return (
      <ToggleButton
        id={label}
        name={label}
        testId={'toggle-' + label}
        value={value as boolean}
        onChange={() => set(label, !value)}
        size="small"
        />
    );
    case 'number':
    case 'string': return (
      <>
        <span className={styles.identifier}>TXT</span>
        <label>{label}</label>
        <Input
          id={label}
          name={label}
          testId={'flag-input-' + label}
          value={value.toString()}
          onChange={newValue => set(label, newValue)}
          size="small"
          spellCheck="false"
          className={styles.input}
          />
      </>
    );
    case 'object': return (
      <>
        <span className={styles.identifier}>OBJ</span>
        <label>{label}</label>
        <label className={styles.json} title={JSON.stringify(value)}>{JSON.stringify(value)}</label>
      </>
    );
  }
  return null;
};
