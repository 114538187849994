import { ReactElement, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import csx from 'classnames';

import { getViewport } from '@grid-is/browser-utils';

import { isMobile } from '@/utils';

import styles from './tooltip.module.scss';

export type TooltipProps = {
  children: ReactElement,
  label?: ReactNode,
  disabled?: boolean,
  placement?: 'left' | 'right' | 'top' | 'bottom',
  gap?: number,
  hideArrow?: boolean,
  delay?: number,
  endDelay?: number,
  variety?: 'dark' | 'light',
  // Useful for when we want to display a tooltip over an disabled element.
  wrapElement?: boolean,
  wrapClassName?: string,
  // Useful if clicking the target element opens another UI.
  hideOnElementClick?: boolean,
  // Sometimes we don't want to keep showing the tooltip as it is being hovered.
  hideOnTooltipHover?: boolean,
  // Tooltips are hidden by default on mobile.
  showOnMobile?: boolean,
  // Force show
  show?: boolean,
  disableShowOnHover?: boolean,
  // Custom z-index
  zIndex?: number,
}

export const Tooltip = ({ children, label, disabled, variety = 'dark', placement = 'top', gap = 2, delay = 400, endDelay, hideArrow, wrapElement, wrapClassName, hideOnElementClick, hideOnTooltipHover, showOnMobile, show, disableShowOnHover, zIndex }: TooltipProps) => {
  const [ targetHovering, setTargetHovering ] = useState(false);
  const [ tooltipHovering, setTooltipHovering ] = useState(false);
  const [ lastEvent, setLastEvent ] = useState<'onMouseEnter' | 'onMouseLeave' | 'onClick' | undefined>();
  const [ safePlacement, setSafePlacement ] = useState(placement);

  const tooltipRef = useRef<HTMLDivElement>(null);
  const arrowRef = useRef<HTMLDivElement>(null);
  const anchorRef = useRef<HTMLDivElement>(null);
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  // Tooltips should not be rendered closer to the viewport edge than this number.
  const viewportPadding = 24;

  const onMouseEnter = useCallback(() => {
    setLastEvent('onMouseEnter');
    // When provided with the prop 'hideOnElementClick', the intention is that the tooltip hides after the user clicks the target element.
    // onMouseEnter can get called right after an onClick event without any mouse movement, so we need to make sure to ignore it until
    // the cursor has left the target element and re-entered.
    if (lastEvent !== 'onClick') {
      timeoutRef.current && clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => setTargetHovering(true), delay);
    }
  }, [ delay, lastEvent ]);

  const onMouseLeave = useCallback(() => {
    setLastEvent('onMouseLeave');
    timeoutRef.current && clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => setTargetHovering(false), endDelay !== undefined ? endDelay : delay);
  }, [ delay, endDelay ]);

  const onClick = useCallback(() => {
    setLastEvent('onClick');
    setTargetHovering(false);
    setTooltipHovering(false);
    timeoutRef.current && clearTimeout(timeoutRef.current);
  }, []);

  const getSafePlacement = useCallback((target, tooltip) => {
    const { top, bottom, left, right } = target.getBoundingClientRect();
    const { width: tooltipWidth, height: tooltipHeight } = tooltip.getBoundingClientRect();
    const { width: viewportWidth, height: viewportHeight } = getViewport();
    switch (placement) {
      case 'bottom': return (bottom + gap + tooltipHeight + viewportPadding) > viewportHeight ? 'top' : 'bottom';
      case 'top': return (top - tooltipHeight - gap - viewportPadding) < 0 ? 'bottom' : 'top';
      case 'left': return (left - tooltipWidth - gap - viewportPadding) < 0 ? 'right' : 'left';
      case 'right': return (right + gap + viewportPadding + tooltipWidth) > viewportWidth ? 'left' : 'right';
    }
  }, [ gap, placement ]);

  useEffect(() => {
    return () => {
      timeoutRef.current && clearTimeout(timeoutRef.current);
    };
  }, []);

  useEffect(() => {
    const tooltipAnchor = anchorRef.current;
    if (tooltipAnchor) {
      // The targetRef is an empty div with display set to none.
      // We use this to find the tooltip target.
      const targetRef = tooltipAnchor.previousElementSibling;
      if (targetRef && (!isMobile() || showOnMobile)) {
        targetRef.addEventListener('mouseover', onMouseEnter);
        targetRef.addEventListener('mouseout', onMouseLeave);
        hideOnElementClick && targetRef.addEventListener('click', onClick);

        return () => {
          targetRef.removeEventListener('mouseover', onMouseEnter);
          targetRef.removeEventListener('mouseout', onMouseLeave);
          hideOnElementClick && targetRef.removeEventListener('click', onClick);
        };
      }
    }
  }, [ anchorRef, hideOnElementClick, showOnMobile, onMouseEnter, onMouseLeave, onClick ]);

  useEffect(() => {
    const targetSibling = anchorRef?.current?.previousElementSibling;
    const target: any = wrapElement ? targetSibling?.firstChild : targetSibling;
    const tooltip = tooltipRef.current;
    const arrow = arrowRef.current;
    if (target && tooltip && arrow) {
      // Get a safe placement for the tooltip with the requested placement.
      // If the tooltip is going to be rendered off screen a fallback is provided.
      const safePlacement = getSafePlacement(target, tooltip);
      setSafePlacement(safePlacement);

      const { top, bottom, left, right, width, height } = target.getBoundingClientRect();
      const { width: tooltipWidth, height: tooltipHeight } = tooltip.getBoundingClientRect();
      const { height: arrowHeight } = arrow.getBoundingClientRect();
      const { width: viewportWidth } = getViewport();
      if (width !== 0) {
        switch (safePlacement) {
          case 'bottom':
            tooltip.style.top = `${bottom + gap}px`;
            tooltip.style.left = `${Math.min(Math.max(left - ((tooltipWidth - width) / 2), viewportPadding), viewportWidth - tooltipWidth - viewportPadding)}px`;
            arrow.style.top = `${bottom + gap}px`;
            arrow.style.left = `${left + ((width - arrowHeight) / 2)}px`;
            break;
          case 'top':
            tooltip.style.top = `${top - tooltipHeight - gap}px`;
            tooltip.style.left = `${Math.min(Math.max(left - ((tooltipWidth - width) / 2), viewportPadding), viewportWidth - tooltipWidth - viewportPadding)}px`;
            arrow.style.top = `${top - gap}px`;
            arrow.style.left = `${left + ((width - arrowHeight) / 2)}px`;
            break;
          case 'left':
            tooltip.style.top = `${top + ((height - tooltipHeight) / 2)}px`;
            tooltip.style.left = `${left - tooltipWidth - gap}px`;
            arrow.style.top = `${top + ((height - arrowHeight) / 2)}px`;
            arrow.style.left = `${left - gap}px`;
            break;
          case 'right':
            tooltip.style.top = `${top + ((height - tooltipHeight) / 2)}px`;
            tooltip.style.left = `${right + gap}px`;
            arrow.style.top = `${top + ((height - arrowHeight) / 2)}px`;
            arrow.style.left = `${right + gap}px`;
            break;
        }
      }
      else {
        tooltip.style.bottom = '16px';
        tooltip.style.left = `${((viewportWidth - tooltipWidth) / 2)}px`;
      }
    }
  }, [ targetHovering, tooltipHovering, disabled, placement, gap, getSafePlacement, label, wrapElement, show ]);

  const shouldWrapLabel = label && typeof label === 'string' && label.length > 40;
  const hovering = !disableShowOnHover && (targetHovering || (!hideOnTooltipHover && tooltipHovering));

  if (label) {
    return (
      <>
        {wrapElement
          ? (
            <div className={wrapClassName} data-testid="tooltip-wrap">
              {children}
            </div>
          )
          : children
      }
        <div ref={anchorRef} className={styles.anchor} />
        {(show || (!disabled && hovering)) && ReactDOM.createPortal(
          <div
            className={csx(styles[variety], styles.tooltip, styles[safePlacement], shouldWrapLabel && styles.wrapLabel, (!hovering && !show) && styles.hide)}
            style={{ zIndex }}
            ref={tooltipRef}
            data-testid="tooltip-label"
            onMouseEnter={() => setTooltipHovering(true)}
            onMouseLeave={() => {
              timeoutRef.current = setTimeout(() => setTooltipHovering(false), delay);
            }}
            >
            {label}
            <div className={csx(styles.arrow, styles[variety], styles[safePlacement], hideArrow && styles.hide)} ref={arrowRef} />
          </div>, document.body,
        )}
      </>
    );
  }
  return children;
};
