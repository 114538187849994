import styles from './notifications.module.scss';

export const EmptyState = () => {
  return (
    <div className={styles.emptyState} data-testid="emptyState">
      <img src="/img/ingrid-in-the-jungle.svg" alt="No notifications" />
      <p className={styles.title}>No notifications</p>
      <p className={styles.subTitle}>You're all caught up!</p>
    </div>
  );
};
