// This module is a convenience for handling utm parameters.
// When we render a page e.g. grid.is/@hjalli/document?utm_source=facebook,
// we want all links within the page to have ?utm_source=facebook.
// However, if a user visits grid.is/@hjalli/document without utm parameters
// we might also want to render some links with other utm params.
// Concrete example:
// * user visits grid.is/@hjalli/document, logo link in the header will use ?utm_campaign=header-logo
// * user visits grid.is/@hjalli/document?utm_campaign=producthunt, logo link in the header will use ?utm_campaign=producthunt

const utmKeys = {
  term: true,
  source: true,
  medium: true,
  content: true,
  campaign: true,
  // non-standard
  grid_doc: true,
};

// parses the arguments from string or object form into URLSearchParams object with override semantics (set, not append)
// only objects are parsed without `utm_` prefix, strings are treated as is
export const parse = (...args) => {
  const parsedArgs = args.filter(Boolean).map(arg => {
    if (arg instanceof URLSearchParams) {
      return arg;
    }
    else if (typeof arg === 'string') {
      return new URLSearchParams(arg);
    }
    else {
      return new URLSearchParams(Object.entries(arg).map(([ key, value ]) => {
        if (utmKeys[key]) {
          return [ `utm_${key}`, value ];
        }
        else {
          throw new Error('unsupported utm key: ' + key);
        }
      }));
    }
  });
  const joinedSearchParams = new URLSearchParams();
  for (const search of parsedArgs) {
    search.forEach((value, key) => joinedSearchParams.set(key, value));
  }
  return joinedSearchParams;
};

const hasUtm = search => {
  let hasUtm = false;
  search.forEach((_, key) => {
    if (key.toLowerCase().startsWith('utm_')) {
      hasUtm = true;
    }
  });
  return hasUtm;
};

export const url = (path, ...params) => {
  let search;
  if (typeof window !== 'undefined' && window.location.search?.includes('utm_')) {
    search = new URLSearchParams(window.location.search);
  }
  else if (params.length === 1 && params[0] instanceof URLSearchParams) {
    search = params[0];
  }
  else {
    search = parse(...params);
  }

  // if there are any UTM parameters present, increase utm_seq param
  if (hasUtm(search)) {
    search.set(
      'utm_seq',
      String(parseInt(search.get('utm_seq') || '0', 10) + 1),
    );
  }
  const query = search.toString();
  if (query) {
    return path + (path.includes('?') ? '&' : '?') + query;
  }
  else {
    return path;
  }
};

export const getAnalyticsContext = additionalAnalytics => {
  const additional = { ...additionalAnalytics };
  if (typeof window !== 'undefined') {
    const context = {};
    const url = window.location.href.replace(/#.*$/, '');
    context.page = {
      path: window.location.pathname, // deviates from segment's https://github.com/segmentio/analytics.js-core/blob/da691296b4a184271f85677962fc19741988c0f3/lib/pageDefaults.ts#L21
      referrer: document.referrer,
      search: location.search,
      title: document.title,
      url,
    };

    const search = new URLSearchParams(window.location.search);
    const campaign = Array.from(search.entries())
      .filter(d => /^utm_/i.test(d[0]))
      .map(([ k, v ]) => {
        let utmParam = k.slice(4).toLowerCase();
        // maintain parity with how Segment sends the campaign object
        if (utmParam === 'campaign') {
          utmParam = 'name';
        }
        return [ utmParam, v ];
      });
    if (campaign.length) {
      context.campaign = Object.fromEntries(campaign);
    }
    additional.context = context;
  }
  return additional;
};
