import { NotFoundError } from './errors';
import { paths, schemas } from './generatedTypes';
import { deleteJSON, getJSON, postJSON } from './request';

export type UserIntegrations = schemas['UserIntegrations'];
export type UserIntegration = schemas['UserIntegration'];

export type Integrations = {
  dropbox: {
    client_id: string,
  },
  fusionauth: {
    client_id: string,
    login_url: string,
    logout_url: string,
  },
  google: {
    client_app_id: string,
    client_id: string,
    client_picker_api_key: string,
  },
  slack: {
    client_id: string,
  },
  notion: {
    client_id: string,
  },
  airtable_oauth: {
    client_id: string,
  },
  chargebee: {
    site_id: string,
  },
  hubspot: {
    client_id: string,
    scopes: string,
  },
}

export function integrations () {
  return getJSON<Integrations>('/integrations');
}

type RegisterSlackRequest = paths['/integrations/slack']['post']['requestBody']['content']['application/json'];

export function registerSlack (code: string, redirect_uri: string) {
  const payload = { code, redirect_uri };
  return postJSON<{}, RegisterSlackRequest>('/integrations/slack', payload);
}

type RegisterHubSpotRequest = paths['/integrations/hubspot']['post']['requestBody']['content']['application/json'];

export function registerHubSpot (code: string, redirect_uri: string) {
  const payload = { code, redirect_uri };
  return postJSON<{}, RegisterHubSpotRequest>('/integrations/hubspot', payload);
}

type AuthorizeNotionRequest = paths['/integrations/notion/authorize']['post']['requestBody']['content']['application/json'];
type AuthorizeNotionResponse = paths['/integrations/notion/authorize']['post']['responses']['200']['content']['application/json'];

export function authorizeNotion (
  client_id: string,
  state: string,
  redirect_uri: string,
  notion_unique_code: string,
) {
  const payload = { client_id, state, redirect_uri, notion_unique_code };
  return postJSON<AuthorizeNotionResponse, AuthorizeNotionRequest>('/integrations/notion/authorize', payload);
}

type UserIntegrationsResponse = paths['/integrations/user']['get']['responses']['200']['content']['application/json'];

export function listUserIntegrations () {
  return getJSON<UserIntegrationsResponse>('/integrations/user');
}

type DeleteIntegrationRequest = paths['/integrations/user']['delete']['requestBody']['content']['application/json'];

export function deleteIntegration (integrationId: string, integrationProvider: 'slack' | 'notion') {
  const payload = { id: integrationId, provider: integrationProvider };
  return deleteJSON<{}, DeleteIntegrationRequest>('/integrations/user', payload);
}

export class IntegrationService {
  list = listUserIntegrations;

  async getHubSpotIntegration (integrationId: string) {
    type Response = paths['/integrations/hubspot/{integration_id}']['get']['responses']['200']['content']['application/json'];
    try {
      return await getJSON<Response>(`/integrations/hubspot/${integrationId}`);
    }
    catch (err) {
      // XXX: move into gridFetch, do this for all 404 errors
      if (err && typeof err === 'object' && 'status' in err && err.status === 404) {
        throw new NotFoundError(`Cannot find HubSpot integration with id ${integrationId}`);
      }
      throw err;
    }
  }
}
