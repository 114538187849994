import React from 'react';
import csx from 'classnames';

import styles from './UsageKPI.module.scss';

type Props = {
  value: number,
  showLimit?: boolean,
  limit?: number,
  label: string,
  alert?: 'warning' | 'error',
}

export const UsageKPI = ({ value, showLimit, limit = 1000, label, alert }: Props) => {
  if (label) {
    return (
      <div className={styles.kpi}>
        <div className={styles.kpiValue}>
          <div className={csx(styles.bigNumber, styles[alert || ''])}>{value}</div>
        </div>
        {showLimit
          ? <div className={styles.kpiLabel}>of {limit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} {label}</div>
          : <div className={styles.kpiLabel}>{label}</div>
      }
      </div>
    );
  }
  return null;
};

