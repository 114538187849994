import React, { useEffect, useState } from 'react';
import csx from 'classnames';

import { deleteCookie, getClientFeatureBranchName, sessionStorage } from '@grid-is/browser-utils';
import { Icon } from '@grid-is/icon';

import { CLIENT_FEATURE_BRANCH_COOKIE } from '@/constants';
import { ms } from '@/utils/time';

import styles from './featurebranchbanner.module.scss';

const CLIENT_FEATURE_BRANCH_BANNER_NAME = 'ClientFeatureBranchBannerName';
const CLIENT_FEATURE_BRANCH_BANNER_LAST_SHOWN = 'ClientFeatureBranchBannerLastShown';

const logClientBannerShown = (name: string) => {
  if (name) {
    sessionStorage.setItem(CLIENT_FEATURE_BRANCH_BANNER_NAME, name);
    sessionStorage.setItem(CLIENT_FEATURE_BRANCH_BANNER_LAST_SHOWN, new Date().getTime());
  }
};

export function ClientFeatureBranchBanner () {
  const [ revealClientBanner, setRevealClientBanner ] = useState(false);
  const clientFeatureBranch = getClientFeatureBranchName();

  useEffect(() => {
    if (clientFeatureBranch) {
      const lastShownName = sessionStorage.getItem(CLIENT_FEATURE_BRANCH_BANNER_NAME);
      const lastShownTime = sessionStorage.getItem(CLIENT_FEATURE_BRANCH_BANNER_LAST_SHOWN);
      if (lastShownName && clientFeatureBranch === lastShownName && lastShownTime) {
        // Allow the user an hour of testing before we show the banner again.
        const now = new Date().getTime();
        const then = new Date(JSON.parse(lastShownTime)).getTime();
        if (now - then > ms.hour) {
          setRevealClientBanner(true);
          logClientBannerShown(clientFeatureBranch);
        }
      }
      else {
        setRevealClientBanner(true);
        logClientBannerShown(clientFeatureBranch);
      }
    }
  }, [ clientFeatureBranch ]);

  if (!clientFeatureBranch) {
    return null;
  }

  return (
    <div
      className={csx(styles.container, styles.containerClient, revealClientBanner && styles.sweapIn)}
      onClick={() => {
        deleteCookie(CLIENT_FEATURE_BRANCH_COOKIE, '');
        window.location.reload();
      }}
      >
      <section>
        <header>Client deployment:</header>
        <span className={styles.featureBranch}>{clientFeatureBranch}</span>
      </section>
      <a>
        Back to <strong>ingrid.is</strong>
        <Icon size={24} name="logout" className={styles.icon} />
      </a>
    </div>
  );
}
