import { FunctionComponent, ReactNode, useCallback, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { RemoveScroll } from 'react-remove-scroll';
import { useMeasure } from 'react-use';
import csx from 'classnames';

import { getViewport, isBrowser } from '@grid-is/browser-utils';
import { breakpoints } from '@grid-is/styles';

import { IconButton } from '@/grid-ui/IconButton';

import { trapfocus } from './focustrap';

import styles from './Modal.module.scss';

export type ModalProps = {
  header?: string,
  renderHeader?: () => ReactNode,
  footer?: ReactNode,
  children: ReactNode,
  className?: string,
  overlayClassName?: string,
  open?: boolean,
  onClose?: () => void,
  hideOnClickOutside?: boolean,
  closeButton?: boolean,
  removePadding?: boolean,
  size?: 'small' | 'small-medium' | 'medium' | 'large' | 'x-large',
  id?: string,
  obId?: string,
  animateHeight?: boolean,
}

export const Modal: FunctionComponent<ModalProps> = ({
  children,
  header,
  renderHeader,
  footer,
  className,
  overlayClassName,
  open,
  onClose,
  hideOnClickOutside = true,
  closeButton = true,
  size = 'medium',
  id,
  obId,
  removePadding,
  animateHeight = false,
}) => {
  const dialogRef = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  const [ contentRef, { height: contentHeight } ] = useMeasure<HTMLDivElement>();
  const prevHeight = useRef(0);

  const onKey = useCallback((e: KeyboardEvent) => {
    if (open) {
      if (e.code === 'Escape') {
        if (!e.defaultPrevented && onClose) {
          onClose();
        }
      }
      if (e.code === 'Tab') {
        trapfocus(dialogRef.current, e);
      }
    }
  }, [ open, onClose ]);

  useEffect(() => {
    document.addEventListener('keydown', onKey, false);

    return () => {
      document.removeEventListener('keydown', onKey, false);
    };
  }, [ onKey ]);

  useEffect(() => {
    if (scrollRef.current && animateHeight) {
      const minimumPadding = 40;
      const viewportHeight = getViewport().height;
      const availableSpace = viewportHeight - minimumPadding;

      // We don't want to animate height from zero, i.e. when the pop over opens f.x.
      scrollRef.current.style.transition = prevHeight.current === 0 ? 'none' : 'height 300ms cubic-bezier(0, 1, .5, 1)';

      // Updated height, if exceeding viewport then clamp and enable scrolling.
      if (availableSpace - contentHeight < 0) {
        scrollRef.current.style.overflow = 'auto';
        scrollRef.current.style.height = `${availableSpace}px`;
      }
      else {
        scrollRef.current.style.overflow = 'hidden';
        scrollRef.current.style.height = `${contentHeight}px`;
      }
    }
    prevHeight.current = contentHeight;
  }, [ open, contentHeight, animateHeight, children ]);

  if (isBrowser()) {
    return ReactDOM.createPortal(
      open ? (
        <RemoveScroll allowPinchZoom enabled={getViewport().width > parseInt(breakpoints.designsMobile, 10)}>
          <div role="dialog" aria-modal="true" ref={dialogRef} id={id}>
            <div className={csx(styles.overlay, overlayClassName)} onClick={hideOnClickOutside ? onClose : () => {}} aria-hidden data-testid="modal-overlay" />
            <div className={csx(styles.modal, className)} data-size={size} role="document" data-testid="modal" data-obid={obId}>
              {(header || closeButton || renderHeader) && (
                <div className={styles.header} data-testid="modal-header">
                  {renderHeader ? renderHeader() : <h1>{header}</h1>}
                  {closeButton && <IconButton iconName="window-close" onClick={onClose} buttonType="grayScale" buttonSize="small" ariaLabel="Close modal" />}
                </div>
              )}
              <div className={csx(styles.outer, animateHeight && styles.animateHeight)} ref={scrollRef}>
                <div ref={contentRef} className={styles.content}>
                  <div className={csx(styles.scrollable, styles.scroll)}>
                    <div className={csx(styles.body, removePadding && styles.noPadding)}>
                      <div>
                        {children}
                      </div>
                    </div>
                  </div>
                  {footer &&
                    <div className={styles.footer}>
                      <div>{footer}</div>
                    </div>}
                </div>
              </div>
            </div>
          </div>
        </RemoveScroll>
      ) : null,
      document.body);
  }
  return null;
};
